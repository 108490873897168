<!-- 个人中心 -->
<template>
  <div id="Personalcenter">
      <div class="body">
        <div class="personal">
            <div class="personal-top">
                <div class="personal-top-img">
                    <img class="personal-top-img1" :src="userdata.avatar" :onerror="defaultimgSrc">
                    <img class="personal-top-img2" v-show="userdata.level==1" src="../../assets/image/vip_baiyin.png">
                    <img class="personal-top-img2" v-show="userdata.level==2" src="../../assets/image/vip_huangjin.png">
                    <img class="personal-top-img2" v-show="userdata.level==0"  src="../../assets/image/vip_bojin.png">
                </div>
                <div class="parsonalname">{{userdata.nickname}}</div>
                <!-- <div class="parsonalnumber">账号：{{userdata.id}}</div> -->
            </div>
            <div class="personal-content cursor">
                <div v-for="(item,key) in navlsit" :class="selectnav==item.path?'personal-content-select':'personal-content-text'" @click="changselect(key,item.path)">
                    {{item.name}}
                </div>
            </div>
        </div>
          <router-view></router-view>
      </div>
  </div>
</template>

<script>
export default {
    components: {
    },
  data () {
    return {
        userdata: JSON.parse(sessionStorage.getItem('userdata')), // 用户数据
        // 选中的nav
        selectnav: '/account',
        // 导行的所有nav
        navlsit: [
            {
                name: '我的账户',
                key: '0',
                path: 'account'
            },
            {
                name: '我的会员',
                key: '1',
                path: 'Alreadyvip'
            },
            {
                name: '我的收藏',
                key: '2',
                path: 'Collection'
            },
            {
                name: '学习提醒',
                key: '3',
                path: 'Learnremind'
            },
            {
                name: '我的发票',
                key: '4',
                path: 'Myinvoice'
            },
            {
                name: '翻译记录',
                key: '5',
                path: 'Translatehistory'
            },
            {
                name: '查询记录',
                key: '6',
                path: 'Querylog'
            },
            {
                name: '平台客服',
                key: '7',
                path: 'Customerservice'
            },
        ]
    };
  },
  mounted () {
    const that = this
    window.addEventListener('setItem', () => {
      that.userdata = JSON.parse(sessionStorage.getItem('userdata'))
      
    //   that.formData.deptId是data里面声明的变量
    //   console.log(that.userdata,JSON.parse(sessionStorage.getItem('userdata')),'监听')
    })
  },
  computed: {
      defaultimgSrc () {
          return 'this.src="' + require('../../assets/image/heard.png') + '"';
      }
  },
  watch:{
     //监听页面路由的切换，将选中的nav动态更新
      $route(to,from){
        this.selectnav=to.path.slice(1);
        // console.log(this.selectnav)
        // vip
        if (this.selectnav=='Myvip' ) {
            this.selectnav='Alreadyvip'
        }
      }
    },
    created() {
     //当前选中页面刷新，标签依然选中当前路由对应的标签
        this.selectnav = this.$route.path.slice(1);
        // console.log(this.selectnav)
        if(this.selectnav=='Personalcenter'){
            this.$router.replace('/' + 'account');
            
         }
        //  vip
        if (this.selectnav=='Myvip' ) {
            this.selectnav = 'Alreadyvip'
        }
        
    },
  methods: {
      //   改变当前选中的
      changselect (key, path) {
          if(path==this.selectnav && this.$route.path!="/Myvip"){
              return false
          }
        //   console.log(key, this.selectnav)
          this.$router.replace('/' + path);
      }
  }
};
</script>

<style lang="scss">
#Personalcenter {
    width: 100%;
    background: linear-gradient(90deg, #ECEFF8 2%, #F7FBFF 99%);
    padding: 34px 0 24px 0 ;
    .body{
        width: 1428px;
        margin: 0 auto;
        display: flex;
        .personal{
            width: 208px;
            height: 644px;
            background: #FFFFFF;
            box-shadow: 2px 0px 16px 0px rgba(0,21,41,0.08);
            border-radius: 14px;
            display: flex;
            flex-direction: column;
            .personal-content{
                // width: 60px;
                height: 322px;
                margin:  80px auto 0;
                .personal-content-text{
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #111111;
                    margin:  0 0 22px 0;
                }
                .personal-content-select{
                    font-size: 15px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #3D5CFF;
                    margin:  0 0 22px 0;
                }
            }
            .personal-top{
                width: 150px;
                height: 122px;
                margin: 32px auto 0;
                .parsonalname{
                    width: 150px;
                    margin: 8px 0 0 0;
                    font-size: 16px;
                    font-family: Helvetica-Bold, Helvetica;
                    font-weight: bold;
                    color: #2E3E5C;
                    line-height: 19px;
                    // display: flex;
                    // align-items: center;
                    // justify-content: center;
                    text-align: center;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .parsonalnumber{
                    width: 150px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #8C8C8C;
                    margin: 8px 0 0 0 ;
                    text-align: center;
                    // display: flex;
                    // align-items: center;
                    // justify-content: center;
                    white-space: nowrap;
                    overflow:hidden;
                    text-overflow: ellipsis;
                }
                .personal-top-img{
                    width: 64px;
                    height: 70px;
                    margin: 0 auto;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .personal-top-img1{
                        width: 64px;
                        height: 64px;
                        border-radius: 8px
                    }
                    .personal-top-img2{
                        position: absolute;
                        bottom: 0px;
                        right: 6px;
                        width: 52px;
                        height: 20px;
                    }
                }
            }
        }
    }
}
</style>