<!-- 个人中心-客服 -->
<template>
    <div id="account">
        <div class="content">
            
            <div class="content-bottom">
                <div class="content-bottom-title">
                    <div class="content-top-top-left">
                        <div class="content-top-top-left-text">平台客服</div>
                        <div class="content-top-top-left-back"></div>
                    </div>
                    
                </div>
                <div class="content-content">
                    <div class="content-content-left">
                        <img class="content-content-left-top" src="../../assets/image/kefu.png">
                        <div class="content-content-left-center">
                            {{CustomerService.name}}
                        </div>
                        <div class="content-content-left-bottom">
                            <p>{{CustomerService.tel}}</p>
                            <div class="copies cursor" @click="copynumber(CustomerService.tel)">复制</div>
                        </div>
                    </div>
                    <div class="content-content-right">
                        <img class="content-content-right-top"  :src="CustomerService.qrCode" :onerror="defaultimgSrc">
                        <!-- <div class="content-content-right-top" id="qrcode" ref="qrcode"></div> -->
                        <div class="content-content-right-center">
                            扫描二维码，添加客服进行咨询
                        </div>
                        <div class="content-content-right-bottom">
                            <p>客服工作时间：{{CustomerService.workTime}}
                                    <!-- 非工作时间请耐心等待 -->
                                    </p>
                        </div>
                    </div>
                </div>
                
                
            </div>
            <Globalmessage ref="message" :duration="3000" :top="100" :widths="1196"/>
        </div>
        
    </div>
</template>

<script>
import Globalmessage from '../../components/Globalmessage.vue'
import QRCode from 'qrcodejs2'
export default {
    components: {
        Globalmessage
    },
  data () {
    return {
        CustomerService: {}, // 客服数据
    };
  },
    computed: {
        defaultimgSrc () {
            return 'this.src="' + require('../../assets/image/heard.png') + '"';
        }
    },
  mounted () {
      this.getdata()
  },
  methods: {
    //   获得数据
    getdata () {
        
        this.$userdata.getCustomerService().then((res) => {
            // console.log(res,'客服')
            if (res.code==200) {
                this.CustomerService = res.result
            }
        })
    },
      //   复制
    copynumber (val) {
        let url = val;
        //新建一个文本框
        let oInput = document.createElement('input');
        //赋值给文本框
        oInput.value = url;
        document.body.appendChild(oInput);
        // 选择对象;
        oInput.select(); 
        // 执行浏览器复制命令
        document.execCommand("Copy"); 
        //复制完成删除掉输入框
        oInput.remove()
        // this.$refs.message.success('复制成功') // success调用
        this.$message.success('复制成功')
        // console.log('复制成功')
    },
  }
};
</script>

<style lang="scss" scoped>
#account {
    .nocursor{
        cursor:not-allowed;
    }
    .content{
        width:1196px;
        margin:  0 0 0 24px;
        .content-bottom{
            width: 1196px;
            height: 550px;
            background: #FFFFFF;
            box-shadow: 2px 0px 16px 0px rgba(0,21,41,0.08);
            border-radius: 14px;
            // margin: 24px 0 0 0;
            .content-content{
                width: 703px;
                height: 369px;
                margin: 40px auto 0;
                background-image: url(../../assets/image/bg.png);
                background-size: 703px;
                display: flex;
                // align-items: center;
                .content-content-left{
                    width: 182px;
                    height: 159px;
                    margin: 92px 0 0 80px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .content-content-left-top{
                        width: 88px;
                        height: 88px;
                    }
                    .content-content-left-center{
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        margin: 16px 0 13px 0;
                    }
                    .content-content-left-bottom{
                        display: flex;
                        align-items: center;
                        font-size: 18px;
                        font-family: Helvetica;
                        color: #2E3E5C;
                        p{
                            width: 124px;
                            height: 22px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        .copies{
                            width: 36px;
                            height: 18px;
                            border-radius: 4px;
                            border: 1px solid #3D5CFF;
                            font-size: 10px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #3D5CFF;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin:  0 0 0 7px;
                        }
                    }
                }
                .content-content-right{
                    width: 208px;
                    height: 230px;
                    margin: 69px 0 0 139px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .content-content-right-top{
                        width: 140px;
                        height: 140px;
                    }
                    .content-content-right-center{
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FE550D;
                        margin: 21px 0 6px 0;
                    }
                    .content-content-right-bottom{
                        font-size: 14px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #606266;
                        text-align: center;
                    }
                }
            }
            .content-bottom-title{
                width: 80px;
                height: 25px;
                padding: 23px 0 0 0px;
            }
            
        }
        .content-top-top-left{
            width: 80px;
            height: 25px;
            display: flex;
            align-items: flex-end;
            position: relative;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2F3236;
            margin: 0 0 0 24px;
            &-text {
            margin-left: 8px;
            }
            .content-top-top-left-back {
                width: 26px;
                height: 8px;
                background: #3d5cff;
                opacity: 0.3;
                position: absolute;
                left: 0;
                bottom: 2px;
            }
        }
        
    }
}
</style>