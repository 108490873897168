<!-- 绑定账户 -->
<template>
    <div id="Bindaccount">
        <div class="content">
            
            <div class="content-bottom">
                <!-- 上方面包屑 -->
                <div class="crumbs">
                    <span class="crumbs-before cursor" @click="backhome" >首页</span>
                    <span class="crumbs-fenkai">/</span>
                    <span class="crumbs-before " >设置</span>
                    <span class="crumbs-fenkai">/</span>
                    <span class="crumbs-now">绑定账户</span>
                </div>
                <div class="content-bottom-title">
                    <div class="content-top-top-left">
                        <div class="content-top-top-left-text">绑定账户</div>
                        <div class="content-top-top-left-back"></div>
                    </div>
                </div>
                <div class="content-content">
                    <!-- 绑定手机号 -->
                    <div class="content-title">当前手机号</div>
                    <div class="content-input">
                        <div class="input-input">
                            <el-input class="content-input-input" :disabled="iscanfirstnumber" v-model="nownumber" placeholder="请绑定手机号"></el-input>
                        </div>
                        <div class="sendnumber cursor" v-show="ischangenumber" @click="changenumber">更换手机号</div>
                        <div class="sendnumber2 cursor" v-show="isfirstnumber" @click="firstbindnumber">下一步</div>
                    </div>
                    <!-- 第一次绑定手机号 -->
                    <div class="content-input" v-show="isfirstnumberfrom">
                        <div class="input-input">
                            <el-input class="content-input-input" v-model="verificationcode" placeholder="请输入验证码"></el-input>
                        </div>
                        <div class="sendnumber cursor" @click="sendcode">{{codetime+codetext}}</div>
                    </div>
                    <div class="content-input" v-show="isfirstnumberfrom">
                        <div class="sendnumber3 cursor" @click="firstsurenumber">绑定</div>
                        <div class="sendnumber cursor" @click="nofirstsurenumber">取消</div>
                    </div>
                    <!-- 更换手机号 -->
                    <div class="content-input" v-show="ischangenumberfrom">
                        <div class="input-input">
                            <el-input class="content-input-input" v-model="verificationcode" placeholder="请输入验证码"></el-input>
                        </div>
                        <div class="sendnumber cursor" @click="sendcode">{{codetime+codetext}}</div>
                    </div>

                    <div class="content-title" v-show="ischangenumberfrom">绑定新手机号</div>
                    <div class="content-input" v-show="ischangenumberfrom">
                        <div class="input-input">
                            <el-input class="content-input-input" v-model="newnumber" placeholder="请输入新手机号"></el-input>
                        </div>
                    </div>
                    <div class="content-input" v-show="ischangenumberfrom">
                        <div class="input-input">
                            <el-input class="content-input-input" v-model="newverificationcode" placeholder="请输入验证码"></el-input>
                        </div>
                        <div class="sendnumber cursor" @click="newsendcode">{{newcodetime+newcodetext}}</div>
                    </div>

                    <div class="content-input" v-show="ischangenumberfrom">
                        <div class="sendnumber3 cursor" @click="bindnew">绑定</div>
                        <div class="sendnumber cursor" @click="notbind">取消</div>
                    </div>
                    <!-- 绑定邮箱 -->
                    <div class="content-title">绑定邮箱</div>
                    <div class="content-input">
                        <div class="input-input">
                            <el-input class="content-input-input" :disabled="iscanfirstbindemail" v-model="nowemail" placeholder="请输入邮箱账号"></el-input>
                        </div>
                        <div class="sendnumber2 cursor" v-show="isshowfirstbindemail" @click="firstbindemail">下一步</div>
                        <div class="sendnumber cursor" v-show="ischangeemail" @click="changeemail">更换邮箱</div>
                    </div>
                    <!-- 第一次绑定邮箱 -->
                    <div class="content-input" v-show="isshowfirstemailform">
                        <div class="input-input">
                            <el-input class="content-input-input" v-model="emailcode" placeholder="请输入验证码"></el-input>
                        </div>
                        <div class="sendnumber cursor" @click="getnowemailcode">{{emailcodetime+emailcodetext}}</div>
                    </div>
                    <div class="content-input" v-show="isshowfirstemailform">
                        <div class="sendnumber3 cursor" @click="firstsureemail">绑定</div>
                        <div class="sendnumber cursor" @click="nofirstsureemail">取消</div>
                    </div>
                    <!-- 更换邮箱 -->
                    <div class="content-input" v-show="isshowchangeemailform">
                        <div class="input-input">
                            <el-input class="content-input-input" v-model="emailcode" placeholder="请输入验证码"></el-input>
                        </div>
                        <div class="sendnumber cursor" @click="getnowemailcode">{{emailcodetime+emailcodetext}}</div>
                    </div>
                    <div class="content-title" v-show="isshowchangeemailform">绑定邮箱</div>
                    <div class="content-input" v-show="isshowchangeemailform">
                        <div class="input-input">
                            <el-input class="content-input-input" v-model="newemail" placeholder="请输入新邮箱"></el-input>
                        </div>
                    </div>
                    <div class="content-input" v-show="isshowchangeemailform">
                        <div class="input-input">
                            <el-input class="content-input-input" v-model="newemailcode" placeholder="请输入验证码"></el-input>
                        </div>
                        <div class="sendnumber cursor" @click="getnewemailcode">{{newemailcodetime+newemailcodetext}}</div>
                    </div>

                    <div class="content-input" v-show="isshowchangeemailform">
                        <div class="sendnumber3 cursor" @click="surechangeemail">绑定</div>
                        <div class="sendnumber cursor" @click="nochangeemail">取消</div>
                    </div>

                </div>
                <div class="content-bottom-title">
                    <div class="content-top-top-left">
                        <div class="content-top-top-left-text">绑定第三方账号</div>
                        <div class="content-top-top-left-back"></div>
                    </div>
                </div>
                <div class="bottom-text">绑定第三方账号，可以直接登录科工词汇在线</div>
                <div class="bottom-bind">
                    <img src="../../assets/icon/icon_wx2.png" v-show="!wxisbind">
                    <img src="../../assets/icon/icon_wx3.png" v-show="wxisbind">
                    <div class="bindornobind cursor" v-show="!wxisbind" @click="showbind('微信')">绑定</div>
                    <div class="bindornobind cursor" v-show="wxisbind" @click="showunbind('微信')">解除绑定</div>
                </div>
                <div class="bottom-bind">
                    <img src="../../assets/icon/icon_qq2.png" v-show="qqisbind">
                    <img src="../../assets/icon/icon_qq3.png" v-show="!qqisbind">
                    <div class="bindornobind cursor" v-show="!qqisbind" @click="showbind('QQ')">绑定</div>
                    <div class="bindornobind cursor" v-show="qqisbind" @click="showunbind('QQ')">解除绑定</div>
                </div>
            </div>
            <Globalmessage ref="message" :duration="3000" :top="100" :widths="1196"/>
        </div>
        <!-- 解绑 -->
        <el-dialog
        title=""
        :visible.sync="isshowunbind"
        width="410px"
        top="316px"
        :close-on-click-modal='false'
        :show-close='false'
        >
            <div slot="title" class="dialog1-top-title">
                <span>解绑{{unbindname}}</span>
            </div>
            <div class="dialog1-content">
                确定要解除绑定吗？
            </div>
            <div class="foot2">
                <el-button class="but2" @click="noshowunbind">取消</el-button>
                <el-button class="but" @click="sureunbind">确定</el-button>
            </div>
        </el-dialog>
        <!-- 绑定第三方 -->
        <el-dialog
        title=""
        :visible.sync="isshowbnind"
        width="510px"
        top="150px"
        :close-on-click-modal='true'
        :before-close="noshowbind"
        :show-close='false'
        >
            <div slot="title" class="dialog1-top-title">
                <span>绑定{{bindname}}</span>
            </div>
            <div class="dialog1-content">
                <!-- <div class="QRcode"></div> -->
                <wxlogin
                v-show="bindname=='微信'"
                class="wxlogin"
                self_redirect="false"
                :appid="QRdata.appid"
                :scope="QRdata.scope"
                :redirect_uri='QRdata.redirect_uri+encodeURIComponent("/#/loginSuccess")'
                :href="'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDE4MHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge2Rpc3BsYXk6IG5vbmU7fQ=='"
                rel="external nofollow">
                </wxlogin>
            </div>
            <!-- <div class="foot2">
                <el-button class="but2" @click="noshowbind">取消</el-button>
                <el-button class="but" @click="surebind">确定</el-button>
            </div> -->
        </el-dialog>
        <div class="height156"></div>
    </div>
</template>

<script>
import Globalmessage from '../../components/Globalmessage.vue'
import wxlogin from 'vue-wxlogin';
export default {
    components: {
        Globalmessage,
        wxlogin
    },
  data () {
    return {
        QRdata:'', // 二维码
        userdata: JSON.parse(sessionStorage.getItem('userdata')), // 用户数据

        qqisbind: false, // qq是否已绑定
        wxisbind: false, // 微信是否已绑定
        isshowunbind: false, // 是否显示解绑弹窗
        unbindname: '', // 要解绑的名字
        isshowbnind: false, // 是否显示绑定弹窗
        bindname: '', //  要绑定的第三方的名字


        iscanfirstnumber: false, // 是否可输入手机号
        ischangenumber: false, // 是否显示更换手机号按钮
        ischangenumberfrom: false, // 是否显示更换手机号内容
        isfirstnumber: true, // 是否显示下一步按钮（没绑手机号
        isfirstnumberfrom: false, // 是否第一次绑定手机号内容
        nownumber: '', // 当前手机号
        verificationcode: '', // 验证码
        codetime: '', // 验证码倒计时
        codetext: '发送验证码', // 验证码显示的东西

        newnumber: '', // 新手机号
        newverificationcode: '', // 新手机号的验证码
        newcodetime: '', // 新手机号的验证码倒计时
        newcodetext: '发送验证码', // 新手机号的验证码显示的东西


        iscanfirstbindemail: false, // 是否可输入邮箱
        ischangeemail: false, // 是否显示更换绑定邮箱按钮
        isshowchangeemailform: false, // 是否显示更换绑定内容
        isshowfirstbindemail: true, // 是否显示下一步按钮
        isshowfirstemailform: false, // 是否第一次绑定邮箱内容
        nowemail: '', // 当前的邮箱
        emailcode: '', // 当前邮箱的验证码
        emailcodetime: '', // 当前邮箱验证码倒计时
        emailcodetext: '发送验证码', // 当前邮箱验证码显示的东西

        newemail: '', // 新的邮箱
        newemailcode: '', // 新邮箱的验证码
        newemailcodetime: '', // 新邮箱验证码倒计时
        newemailcodetext: '发送验证码', // 新邮箱验证码显示的东西
    };
  },
    computed: {
    },
    created () {
        
    },
  mounted () {
    this.getdata()
    const that = this
    window.addEventListener('setItem', () => {
      that.userdata = JSON.parse(sessionStorage.getItem('userdata'))
      
    //   that.formData.deptId是data里面声明的变量
    //   console.log(that.userdata,JSON.parse(sessionStorage.getItem('userdata')),'监听')
    })
  },
  methods: {
      //   回首页
    backhome () {
      this.$router.push('/')
    },
    getQR () {
      if (this.bindname=='微信') {
        this.$login.getwxQR().then((res) => {
          if (res.code==200) {
            // console.log(res,"二维码")
              this.QRdata = res.result
            
            
          }
        })
      }
      if (this.bindname=='QQ') {
          const that = this
          
            that.$login.qqLoginAddress().then((res) => {
                if (res.code==200) {
                    console.log(res,"qq")
                    var win=window.open(res.message,"TencentLogin",
                        "width=700,height=400,left=600,top=300,menubar=0,scrollbars=1,resizable=1,status=1,titlebar=0,toolbar=0,location=1");
                        var listener=setInterval(function(){
                        //监听窗口是否关闭
                        if(win.closed){
                            console.log('关闭',that.$route)
                            //进入这个if代表后端验证成功!直接跳转路由
                            clearInterval(listener);//关闭监听
                            //跳转路由

                            // var path = that.$route.query.redirect;
                            // if (path !== undefined) {
                            //     that.$router.replace({
                            //         path:
                            //         path + '/#/loginSuccess'
                            //     });
                            // }
                            var path = that.$route.path
                            if (path !== undefined) {
                            // that.$router.replace({
                            //   path:
                            //     path + '/#/loginSuccess'
                            // });
                            that.$router.replace('/' + 'loginSuccess');
                            }
                            // that.$router.replace({
                            // path:
                            //     path === undefined ? "/" : path + '/#/loginSuccess'
                            // });
                            // that.$router.go(0) //刷新
                        }
                        },500)
                    // that.QRdata = res.result
                }
            })
      }
      
    },
    //   获得用户数据
    getdata () {
        // console.log(this.userdata, "用户数据")
        this.nownumber = this.userdata.tel
        if (this.nownumber != '') {
            this.isfirstnumber = false
            this.ischangenumber = true
            this.iscanfirstnumber = true
        }
        this.nowemail = this.userdata.email
        if (this.nowemail!='') {
            this.isshowfirstbindemail = false
            this.ischangeemail = true
            this.iscanfirstbindemail = true
        }
        // 微信qq是否绑定
        if (this.userdata.qqNickname=='') {
            this.qqisbind = false
        }else {
            this.qqisbind = true
        }

        if (this.userdata.wxNickname=='') {
            this.wxisbind = false
        }else {
            this.wxisbind = true
        }

    },
    //   确定绑定
    surebind () {
        if (this.bindname == 'QQ') {
            this.qqisbind = true
        }
        if (this.bindname == '微信') {
            this.wxisbind = true
        }
        this.noshowbind()
    },
    //   关闭绑定弹窗
    noshowbind () {
        this.isshowbnind = false
        // this.bindname = ''
    },
    //   显示绑定弹窗
    showbind (value) {
        this.bindname = value
        if (this.bindname=='QQ') {
            this.$store.commit("SET_THIRDPARTYNUMBER", 1);
        }else{
            this.$store.commit("SET_THIRDPARTYNUMBER", 0);
            this.isshowbnind = true
        }
        this.getQR()
    },
    //   确定-取消绑定
    sureunbind () {
        
        let params = new FormData
        params.append('type',this.unbindname == 'QQ' ? '1' : '2')
        this.$userdata.removeTripartite(params).then((res) => {
            // console.log(res,'解绑第三方')
            if (res.code==200) {
                this.noshowunbind()
                if (this.unbindname == 'QQ') {
            
                    this.qqisbind = false
                }
                if (this.unbindname == '微信') {
                    this.wxisbind = false
                }
                sessionStorage.clear()
                this.$message({
                    message: '恭喜你，解绑成功',
                    type: 'success'
                });
                this.getUserInfo()
            }
        })
        
    },
    //   关闭解绑弹窗
    noshowunbind () {
        this.isshowunbind = false
        // this.unbindname = ''
    },
    //   显示解绑弹窗
    showunbind (value) {
        this.unbindname = value
        this.isshowunbind = true
    },
    
    //   是否显示下一步按钮
    firstbindemail () {
        this.isshowfirstbindemail = false
        this.isshowfirstemailform = true
    },
    // 取消绑定邮箱(第一次)
    nofirstsureemail () {
        this.isshowfirstbindemail = true
        this.isshowfirstemailform = false
        this.emailcode = '' // 当前邮箱的验证码
        this.emailcodetime = '' // 当前邮箱验证码倒计时
        this.emailcodetext = '发送验证码' // 当前邮箱验证码显示的东西
        
    },
    // 确定绑定邮箱(第一次)
    firstsureemail () {
        if (this.nowemail == '') {
            this.$message({
                message: '请填写邮箱',
                type: 'error'
            });
            return false
        }
        if (this.emailcode=='') {
            this.$message({
                message: '请填写验证码',
                type: 'error'
            });
            return false
        }
        
        let params = new FormData
        params.append('email',this.nowemail)
        params.append('verification',this.emailcode)
        this.$userdata.bindingEmail(params).then((res) => {
            // console.log(res,'第一次绑定邮箱')
            if (res.code==200) {
                this.nofirstsureemail()
                sessionStorage.clear()
                this.$message({
                    message: '恭喜你，绑定成功',
                    type: 'success'
                });
                this.getUserInfo()
            }
        })
    },
    // 获得用户数据
    getUserInfo () {
        // 获得用户数据并保存
        this.$login.getUserInfo().then((ress) => {
            // console.log(ress.result, "获得用户信息")
            // sessionStorage.setItem('userdata', JSON.stringify(ress.result));
            this.setSessionItem('userdata', JSON.stringify(ress.result))
            if (ress.code==200) {
                
                this.getdata()
            }
        })
    },
    //   确定改变绑定的邮箱
    surechangeemail () {
        // console.log(this.emailcode,this.newemail, this.newemailcode)
        if (this.emailcode=='' ) {
            this.$message({
                message: '请填写验证码',
                type: 'error'
            });
            return false
        }
        if (this.newemail=='') {
            this.$message({
                message: '请填写新邮箱',
                type: 'error'
            });
            return false
        }
        if (this.newemailcode=='') {
            this.$message({
                message: '请填写新邮箱的验证码',
                type: 'error'
            });
            return false
        }
        let params = new FormData
        params.append('primaryEmail',this.nowemail)
        params.append('primaryVerification',this.emailcode)
        params.append('email',this.newemail)
        params.append('verification',this.newemailcode)
        this.$userdata.replaceEmail(params).then((res) => {
            // console.log(res,'换绑邮箱')
            if (res.code==200) {
                this.nochangeemail()
                sessionStorage.clear()
                this.$message({
                    message: '恭喜你，绑定成功',
                    type: 'success'
                });
                this.getUserInfo()
            }
        })
        
    },
    //  取消改变绑定的邮箱
    nochangeemail () {
        this.ischangeemail = true
        this.isshowchangeemailform = false


        this.emailcode = '' // 当前邮箱的验证码
        this.emailcodetime = '' // 当前邮箱验证码倒计时
        this.emailcodetext = '发送验证码' // 当前邮箱验证码显示的东西

        this.newemail = '' // 新的邮箱
        this.newemailcode = '' // 新邮箱的验证码
        this.newemailcodetime = '' // 新邮箱验证码倒计时
        this.newemailcodetext = '发送验证码' // 新邮箱验证码显示的东西
    },
    //   获得新邮箱的验证码
    getnewemailcode () {
        if (this.newemailcodetime != '' || this.newemail == '') {
            return false
        }
        let params = new FormData()
        params.append('tel',this.newemail)
        this.$login.getVerification(params).then((res) => {
            if (res.code==200) {
                this.newemailcodetext = 'S后重新获取'
                this.newemailcodetime = '60'
                let timer = setInterval(() => {
                if (this.newemailcodetime != 0) {
                    this.newemailcodetime--;
                    } else {
                    this.newemailcodetext = '发送验证码';
                    this.newemailcodetime = '';
                    clearInterval(timer);
                    }
                }, 1000);
            }

        })
        
    },
    //   获得当前邮箱的验证码
    getnowemailcode () {
        console.log('888',this.nowemail)
        if (this.emailcodetime != ''|| this.nowemail=='') {
            return false
        }
        let params = new FormData()
        params.append('tel',this.nowemail)
        this.$login.getVerification(params).then((res) => {
            if (res.code==200) {
                this.emailcodetext = 'S后重新获取'
                this.emailcodetime = '60'
                let timer = setInterval(() => {
                if (this.emailcodetime != 0) {
                    this.emailcodetime--;
                    } else {
                    this.emailcodetext = '发送验证码';
                    this.emailcodetime = '';
                    clearInterval(timer);
                    }
                }, 1000);
            }
        })
        
    },
    //   显示更换邮箱内容
    changeemail () {
        this.ischangeemail = false
        this.isshowchangeemailform = true
    },
    firstbindnumber () {
        this.isfirstnumber = false // 是否显示下一步按钮（没绑手机号
        this.isfirstnumberfrom = true // 是否第一次绑定手机号内容
    },
    // 确定绑定手机号(第一次)
    firstsurenumber () {
        if (this.verificationcode == '' ) {
            this.$message({
                message: '请填写验证码',
                type: 'error'
            });
            return false
        }
        if (this.nownumber == '') {
            this.$message({
                message: '请填手机号',
                type: 'error'
            });
            return false
        }
        let params = new FormData
        params.append('tel',this.nownumber)
        params.append('verification',this.verificationcode)
        this.$userdata.bindingTel(params).then((res) => {
            // console.log(res,'第一次绑定手机号')
            if (res.code==200) {
                this.nofirstsurenumber()
                sessionStorage.clear()
                this.$message({
                    message: '恭喜你，绑定成功',
                    type: 'success'
                });
                this.getUserInfo()
            }
        })
    },
    // 取消绑定手机号（第一次
    nofirstsurenumber () {
        this.isfirstnumber = true // 是否显示下一步按钮（没绑手机号
        this.isfirstnumberfrom = false // 是否第一次绑定手机号内容
        this.verificationcode = '' // 验证码
        this.codetime = '' // 验证码倒计时
        this.codetext = '发送验证码' // 验证码显示的东西
    },
    //   绑定新手机号
    bindnew () {
        if (this.verificationcode=='') {
            this.$message({
                message: '请填写验证码',
                type: 'error'
            });
            return false
        }
        if (this.newnumber=='') {
            this.$message({
                message: '请填写新手机号',
                type: 'error'
            });
            return false
        }
        if (this.newverificationcode=='') {
            this.$message({
                message: '请填写新手机号的验证码',
                type: 'error'
            });
            return false
        }
        
        let params = new FormData
        params.append('primaryTel',this.nownumber)
        params.append('primaryVerification',this.verificationcode)
        params.append('tel',this.newnumber)
        params.append('verification',this.newverificationcode)
        this.$userdata.replaceTel(params).then((res) => {
            // console.log(res,'换绑手机号')
            if (res.code==200) {
                this.notbind()
                sessionStorage.clear()
                this.$message({
                    message: '恭喜你，绑定成功',
                    type: 'success'
                });
                this.getUserInfo()
            }
        })
    },
    //   取消绑定手机号
    notbind () {
        this.ischangenumber = true
        this.ischangenumberfrom = false
        this.newnumber = '' // 新手机号
        this.newverificationcode = '' // 新手机号的验证码
        this.newcodetime = '' // 新手机号的验证码倒计时
        this.newcodetext = '发送验证码' // 新手机号的验证码显示的东西

        this.verificationcode = '' // 验证码
        this.codetime = '' // 验证码倒计时
        this.codetext = '发送验证码' // 验证码显示的东西
    },
    //   更换手机号
    changenumber () {
        this.ischangenumber = false
        this.ischangenumberfrom = true
    },
    // 新手机号的发送验证码
    newsendcode () {
        if (this.newcodetime != '' || this.newnumber=='') {
            return false
        }
        let params = new FormData()
        params.append('tel',this.newnumber)
        this.$login.getVerification(params).then((res) => {
            if (res.code==200) {
                this.newcodetext = 'S后重新获取'
                this.newcodetime = '60'
                let timer = setInterval(() => {
                if (this.newcodetime != 0) {
                    this.newcodetime--;
                    } else {
                    this.newcodetext = '发送验证码';
                    this.newcodetime = '';
                    clearInterval(timer);
                    }
                }, 1000);
            }
        })
        
    },
    //  发送验证码
    sendcode () {
        
        if (this.codetime != '') {
            return false
        }
        let params = new FormData()
        params.append('tel',this.nownumber)
        this.$login.getVerification(params).then((res) => {
            if (res.code==200) {
                this.codetext = 'S后重新获取'
                this.codetime = '60'
                let timer = setInterval(() => {
                if (this.codetime != 0) {
                    this.codetime--;
                    } else {
                    this.codetext = '发送验证码';
                    this.codetime = '';
                    clearInterval(timer);
                    }
                }, 1000);
            }
        })
        
    },
    //   保存
    toupgrade () {
    },
  }
};
</script>
<style lang="scss">
#Bindaccount {
    .wxlogin{
        width: 200px;
        height: 200px;
        iframe{
        width: 200px;
        height: 200px;
        }
    }
    .content-input-input{
        .el-input__inner{
            width: 510px;
            height: 66px;
            border-radius: 14px;
            // background:rgba(255,255,255,0);
            background: #F9FAFB;
            border: 0;
            font-size: 18px;
        }
        .el-input__inner::placeholder {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #A8ABB8;
        }
        /* 谷歌 */
        .el-input__inner::-webkit-input-placeholder {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #A8ABB8;
        }
        /* 火狐 */
        .el-input__inner:-moz-placeholder {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #A8ABB8;
        }
        /*ie*/
        .el-input__inner:-ms-input-placeholder {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #A8ABB8;
        }
    }
}
</style>
<style lang="scss" scoped>
#Bindaccount {
    .dialog1-top-title{
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2E3E5C;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img{
            position: absolute;
            right: 0px;
            width: 32px;
            height: 32px;
        }
    }
    .dialog1-content{
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #5E5F60;
        margin: 0px auto 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        .QRcode{
            width: 400px;
            height: 400px;
        }
    }
    .foot2{
        width: 335px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        .but{
            width: 211px;
            height: 50px;
            background: #3D5CFF;
            border-radius: 12px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            border: none;
        }
        .but2{
            width: 110px;
            height: 50px;
            background: rgba(61, 92, 255, 0.1);
            border-radius: 12px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #6975AF;
            border: none;
        }
    }
    .bottom-bind{
        display: flex;
        margin: 12px 0 0 26px;
        .bindornobind{
            width: 92px;
            height: 40px;
            background: #E2E7FF;
            border-radius: 12px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #1F1F39;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 0 0 8px;
        }
        img{
            width: 40px;
            height: 40px;
        }
    }
    .bottom-text{
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9199A1;
        margin: 11px 0 29px 24px;
    }
    .crumbs {
        width: 200px;
        height: 20px;
        margin:  0 0 3px 24px;
        padding: 20px 0 0 0;
        .crumbs-before {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
        }
        .crumbs-fenkai {
        font-size: 12px;
        font-family: Helvetica;
        margin: 0 8px;
        color: rgba(0,0,0,0.45)
        }
        .crumbs-now {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0,0,0,0.65);
        }
    }
    .height156{
        height: 156px;
    }
    .nocursor{
        cursor:not-allowed;
    }
    .content{
        width:1196px;
        margin:  0 0 0 24px;
        .content-bottom{
            width: 1196px;
            // height: 550px;
            background: #FFFFFF;
            box-shadow: 2px 0px 16px 0px rgba(0,21,41,0.08);
            border-radius: 14px;
            // margin: 24px 0 0 0;
            padding: 0 0 24px 0;
            .content-content{
                width: 1068px;
                min-height: 223px;
                margin: 0px 0px 0 24px;
                .content-input{
                    display: flex;
                    margin: 16px 0 0 0;
                    .input-input{
                        width: 510px;
                        height: 66px;
                    }
                    .sendnumber3{
                        width: 140px;
                        height: 66px;
                        background: #3D5CFF;
                        border-radius: 14px;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .sendnumber2{
                        width: 140px;
                        height: 66px;
                        background: #3D5CFF;
                        border-radius: 14px;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0 0 0 24px;
                    }
                    .sendnumber{
                        width: 140px;
                        height: 66px;
                        background: #E2E7FF;
                        border-radius: 14px;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #1F1F39;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0 0 0 24px;
                    }
                }
                .content-title{
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #5E5F60;
                    margin: 34px 0 0 0 ;
                }
                .content-text1{
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #2E3E5C;
                    letter-spacing: 1px;
                    margin: 41px  0 0 0;
                }
                .content-text2{
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #858597;
                    margin: 8px 0 13px 0;
                }

            }
            .content-bottom-title{
                width: 80px;
                height: 25px;
                padding: 20px 0 0 0px;
                
            }
            .title-botttom{
                width: 100px;
                display: flex;
                align-items: center;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #8189B0;
                margin: 6px 0 0 31px;
                img{
                    width: 22px;
                    height: 22px;
                }
            }
            
        }
        .content-top-top-left{
            width: 200px;
            height: 25px;
            display: flex;
            align-items: flex-end;
            position: relative;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2F3236;
            margin: 0 0 0 24px;
            &-text {
            margin-left: 8px;
            }
            .content-top-top-left-back {
                width: 26px;
                height: 8px;
                background: #3d5cff;
                opacity: 0.3;
                position: absolute;
                left: 0;
                bottom: 2px;
            }
        }
        
    }
}
</style>