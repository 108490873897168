<template>
  <div id="Singlebuy">
    <div class="body">
      <!-- 内容 -->
      <div class="content">
        <!-- 单篇购买 -->
        <div class="box">
          <div class="days">
              <!-- 上方面包屑 -->
            <div class="crumbs">
                <span class="crumbs-before cursor" @click="back">文本翻译</span>
                <span class="crumbs-fenkai">/</span>
                <span class="crumbs-now">提交订单</span>
            </div>
            <div class="lien-title">
              <div class="lien-title-text">单篇购买</div>
              <div class="lien-back"></div>
            </div>
            <div class="words">
              <div class="words-left">
                <div class="words-content">
                  {{buysdata.content}}
                </div>
              </div>
              <div class="words-bottom">
                <div class="words-bottom-text" >
                  <span class="charging-left">收费标准</span>
                  <span class="charging-right">{{buysdata.charges}}元/千字</span>
                </div>
                <div class="words-bottom-text" >
                  <span class="charging-left">字符数</span>
                  <span class="charging-right">{{buysdata.translate}}</span>
                </div>
                <div class="words-bottom-text" >
                  <span class="charging-left">小计</span>
                  <span class="charging-right">￥{{buysdata.price}}</span>
                </div>
                <div class="words-bottom-text" >
                  <span class="charging-left">
                    优惠（<span v-show="userdata.level==-1">普通</span>
                    <span v-show="userdata.level==0">铂金</span>
                    <span v-show="userdata.level==1">白银</span>
                    <span v-show="userdata.level==2">黄金</span>会员享{{buysdata.discount}}折）
                  </span>
                  <span class="charging-right">-￥{{buysdata.discountPrice}}</span>
                </div>
                <div class="words-bottom-text">
                  <span class="charging-left">总计</span>
                  <span class="charging-all">￥{{buysdata.payPrice}}</span>
                </div>
              </div>
            </div>
            <div class="payway">
              <div class="payway-left">支付方式：</div>
              <div class="cursor" :class="payway==0?'payway-right-select':'payway-right'" @click="changpayway(0)">
                <img src="../../assets/icon/icon_zfb.png">
                支付宝支付
                <img v-show="payway==0" class="payway-right-select-img" src="../../assets/icon/icon_chose.png">
              </div>
              <div class="cursor" :class="payway==1?'payway-right-select':'payway-right'" @click="changpayway(1)">
                <!-- @click="changpayway(1)" -->
                <img src="../../assets/icon/icon_wx.png">
                微信支付
                <img v-show="payway==1" class="payway-right-select-img" src="../../assets/icon/icon_chose.png">
              </div>
            </div>
            <div class="payment">
              <div class="payment-top">
                <div class="payment-title">扫码支付</div>
                <div v-show="payway==0" class="payment-small">支付宝扫码付款</div>
                <div v-show="payway==1" class="payment-small">微信扫码付款</div>
              </div>
              <div class="payment-bottom">
                <div class="payment-bottom-left">
                  <div v-show="payway==0" id="qrcode" ref="qrcode"></div>
                  <div v-show="payway==1" id="qrcode2" ref="qrcode2"></div>
                  <!-- <iframe v-show="payway==1" :src="paydata" frameborder="0"></iframe> -->
                </div>
                <div class="payment-bottom-right">
                  <div class="payment-bottom-right-pay">支付金额</div>
                  <div class="payment-bottom-right-money"><span>￥</span>{{buysdata.payPrice}}</div>
                  <div class="payment-small">支付即视为您同意<span class="payment-small-to" @click="toxieyi">《支付协议》</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 单篇购买 -->
      </div>
      <!-- 内容 -->
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
  components: {
  },
  data () {
    return {
      timer: null, // 定时器
      paydata: '', // 支付二维码
      userdata: JSON.parse(sessionStorage.getItem('userdata')), // 用户数据
      buysdata: {}, // 购买数据
      payway: 0, // 支付方式
      charging: [
      ]
    }
  },
  mounted () {
    this.getbuydata()
    const that = this
    window.addEventListener('setItem', () => {
      that.userdata = JSON.parse(sessionStorage.getItem('userdata'))
      //that.formData.deptId是data里面声明的变量
      // console.log(that.userdata,JSON.parse(sessionStorage.getItem('userdata')),'监听')
    })
  },
  beforeDestroy () {
    this.closetimer()
  },
  methods: {
    toxieyi () {
      this.$router.push({ path: "/payagreement" });
    },
    getbuydata () {
      // console.log(this.$route.query,"文件信息")
      this.buysdata = this.$route.query
      // console.log(this.buysdata,this.userdata,"购买信息")
      this.getAliCode()
    },
    //获取订单状态
    getStatus() {
      let parames = new FormData
      parames.append('id', this.buysdata.id)
      this.$translation.getIsPay(parames).then((resul) => {
        // console.log(resul,"是否支付")
        if (resul.code == 200) {
          if (resul.result.payStatus == 1) {
            this.$message.success("购买成功");
            // this.$router.push({path: '/Querylog'})
            let params = new FormData
            params.append('id', this.buysdata.id)
            this.$translation.getTranslateOrder(parames).then((res) => {
              // console.log(res)
              if (res.code == 200) {
                // this.$router.push({path: '/Texttranslation', query: res.result})
                this.$router.push({name: 'Texttranslation', params: res.result})
              }
            })
            this.closetimer()
          }
        }
      })
    },
    // 清除定时器
    closetimer () {
      clearInterval(this.timer)
      this.timer = null
    },
    // 清除二维码
    clearqrcode () {
        const codeHtml = document.getElementById("qrcode");
        codeHtml.innerHTML = "";
        const codeHtml2 = document.getElementById("qrcode2");
        codeHtml2.innerHTML = "";
    },
    // 获得二维码
    getAliCode () {
      if (this.payway==0) {
        const that = this
        let params = new FormData
        params.append('id', that.buysdata.id)
        that.$translation.getAliCode(params).then((res) => {
          // console.log(res,"支付")
          if (res.code==200) {
            if (res.result.qr_code == null) {
              this.$message.error("请重新提交订单");
            }
            that.paydata = res.result.qr_code
            let a = new QRCode(that.$refs.qrcode, {
              text: res.result.qr_code, // 需要转换为二维码的内容
              width: 100,
              height: 100,
              colorDark: "black", //#000000为黑色
              colorLight: "white",
            });
            that.timer = setInterval(() => {
              that.getStatus();
            }, 2000);
            
          }
        })
      }
      if (this.payway==1) {
        const that = this
        let params = new FormData
        params.append('id', that.buysdata.id)
        this.$translation.getWxCodes(params).then((res) => {
          // console.log(res,"支付")
          if (res.code==200) {
            // this.paydata = res.result.code_url
            if (res.result.code_url == null) {
              this.$message.error("请重新提交订单");
            }
            that.paydata = res.result.code_url
            let a = new QRCode(that.$refs.qrcode2, {
              text: res.result.code_url, // 需要转换为二维码的内容
              width: 100,
              height: 100,
              colorDark: "black", //#000000为黑色
              colorLight: "white",
            });
            that.timer = setInterval(() => {
              that.getStatus();
            }, 2000);
          }
        })
      }
      
    },
    // 切换支付方式
    changpayway (way) {
      if (this.payway == way) {
        return false
      }
      this.payway = way
      this.paydata = ''
      const codeHtml = document.getElementById("qrcode");
      codeHtml.innerHTML = "";
      const codeHtml2 = document.getElementById("qrcode2");
        codeHtml2.innerHTML = "";
      // this.$router.push('/Viewtranslation')
      this.closetimer()
      this.getAliCode()
    },
    back () {
      this.$router.push('/Texttranslation')
    }
  },
};
</script>

<style lang="scss">
#Singlebuy {
  width: 100%;
  margin-top: 33px;
  .body {
    width: 1169px;
    margin: 0 auto;
    padding-left: 48px;
    padding-bottom: 182px;
    .content {
      margin-top: 28px;
      display: flex;
      align-content: center;
      justify-content: space-between;
      .crumbs {
          width: 120px;
          height: 20px;
          margin:  0 0 32px 0;
          .crumbs-before {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.45);
          }
          .crumbs-fenkai {
            font-size: 12px;
            font-family: Helvetica;
            margin: 0 8px;
            color: rgba(0,0,0,0.45)
          }
          .crumbs-now {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0,0,0,0.65);
          }
      }
      .lien-title {
        width: 80px;
        height: 25px;
        display: flex;
        align-items: flex-end;
        position: relative;
        font-size: 18px;
        &-text {
          margin-left: 8px;
        }
        .lien-back {
          width: 26px;
          height: 8px;
          background: #3d5cff;
          opacity: 0.3;
          position: absolute;
          left: 0;
          bottom: 2px;
        }
      }
      .box {
        .days {
          .payment{
            width: 996px;
            height: 265px;
            background: #F7F8FC;
            border-radius: 14px;
            margin: 32px 0 0 0 ;
            // padding: 44px 0 44px 56px;
            .payment-top{
              padding: 44px 0 0 56px ;
            }
            .payment-title{
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #111111;
              margin: 0 0 2px 0 ;
            }
            .payment-small{
              font-size: 11px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #9199A1;
            }
            .payment-small-to{
              cursor: pointer;
            }
            .payment-bottom{
              display: flex;
              flex-wrap: wrap;
              margin:  14px 0 0 56px;
              .payment-bottom-left{
                width: 119px;
                height: 119px;
                margin: 0 20px 0 0;
                img{
                  width: 119px;
                  height: 119px;
                }
              }
              .payment-bottom-right{
                .payment-bottom-right-pay{
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #606266;
                  margin: 25px 0 12px 0 ;
                }
                .payment-bottom-right-money{
                  font-size: 36px;
                  font-family: HelveticaNeue-Bold, HelveticaNeue;
                  font-weight: bold;
                  color: #FE550D;
                  margin:  0 0 5px 0;
                  span{
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FE550D;
                  }
                }
              }
            }
          }
          .payway{
            width: 500px;
            height: 60px;
            display: flex;
            align-items: center;
            .payway-left {
              width: 70px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #555C62;
              margin: 0 6px 0 0;
              
            }
            .payway-right {
              width: 187px;
              height: 60px;
              border-radius: 6px;
              border: 1px solid #DDDDDD;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #111111;
              margin: 0 20px 0 0 ;
              img{
                width: 22px;
                height: 22px;
                margin: 0 6px 0 0;
              }
            }
            .payway-right-select {
              position: relative;
              width: 187px;
              height: 60px;
              border-radius: 6px;
              border: 1px solid #3D5CFF;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #111111;
              margin: 0 20px 0 0 ;
              img{
                width: 22px;
                height: 22px;
                margin: 0 6px 0 0;
              }
              .payway-right-select-img {
                position: absolute;
                bottom: -1px;
                right: -1px;
                width: 24px;
                height:24px;
                margin: 0 0 0 0;
              }
            }
          }
          .words {
            width: 993px;
            // height: 141px;
            min-height: 335px;
            position: relative;
            margin-top: 34px;
            margin-bottom: 16px;
            // display: flex;
            // align-content: center;
            // justify-content: space-between;
            background: #FFFFFF;
            border-radius: 22px;
            border: 1px solid #F5F7FE;
            .words-bottom{
              width: 443px;
              height: 149px;
              margin: 21px 0 41px 28px;
              .words-bottom-text{
                width: 443px;
                height: 22px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 0 11px 0 ;
                .charging-left {
                  font-size: 15px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                }
                .charging-right {
                  font-size: 16px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #1F1F39;
                }
                .charging-all {
                  font-size: 16px;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #FE550D;
                }
              }
            }
            .words-left {
              width: 985px;
              min-height: 120px;
              background: linear-gradient(90deg, #EEF1F9 0%, #F8FCFF 98%);
              border-radius: 14px;
              margin: 4px 4px 0px 4px ;
              display: flex;
              align-items: center;
              padding: 16px 0 16px 0;
              .notcursor{
                cursor:not-allowed;
                // pointer-events: none;
              }
            }
            .words-content{
              font-size: 20px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #6975AF;
              margin: 0 0 0 30px;
            }
          }
        }
      }
    }
  }
}
</style>