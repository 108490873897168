<!-- 我是用户隐私协议弹框组件 -->
<template>
  <div id="agreement">
    <el-dialog
      title=""
      width="1000px"
      :visible.sync="status"
      :before-close="handleClose"
    >
      <!-- title -->
      <!-- <div class="title">用户服务协议和隐私政策</div> -->
      <!-- html -->
      <div :class="hidetext==true?'xieyi showEllipsis':'xieyi'" v-if="!!agreementtext" v-html="agreementtext.szRegisterContent"></div>
      <span class="btnWrap" v-if="nomore" @click="lookmore">
	      <a class="more">{{moretext}}<i :class="hidetext==false?'el-icon-arrow-up':'el-icon-arrow-down'"></i></a>
	    </span>
      <!-- button -->
      <div class="button">
        <div class="button-body">
          <div class="no cursor" @click="buttonClick(0)">不同意并退出</div>
          <div class="yes cursor" @click="buttonClick(1)">同意并继续</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      agreementtext: JSON.parse(sessionStorage.getItem('agreements')), // 协议文本
      moretext: '查看更多',
      nomore: true,
      hidetext: true,
    };
  },
  computed: {
    status: function () {
      return this.$store.state.agreementStatus;
    },
  },
  mounted () {
  },
  created () {
    // var that = this
    this.$login.getUserAgreement().then((reses) => {
      this.setSessionItem('agreements', JSON.stringify(reses.result))
      // console.log(ress, "获得用户协议", that.agreementtext)
    })
    this.$login.getUserAgreement().then((ress) => {
      this.agreementtext = ress.result
      // console.log(ress, "获得用户协议", that.agreementtext)
    })
  },

  methods: {
    //查看更多
    lookmore() {
      this.hidetext = !this.hidetext
      this.moretext = this.hidetext == true ? '查看更多' : '收起'
    },
    // 是否授权函数
    buttonClick(e) {
      this.handleClose()
      if (e) {
        this.$store.commit("SET_INPOWER", true);
      }else {
        this.$store.commit("SET_INPOWER", false);
      }
    },
    // 关闭弹框函数
    handleClose() {
      this.$store.commit("SET_AGREEMENT", false);
    },
  },
};
</script>

<style lang="scss">
#agreement {
  .btnWrap {
    width: 100%;
    margin: 20px 0 0 0;
    display: flex;
    align-content: center;
    justify-content: center;
    cursor: pointer;
    color: #3d5cff;
    font-size: 16px;
  }
  .showEllipsis{ // 文本溢出超出两行显示省略号
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 15;
    line-clamp: 15;
    -webkit-box-orient: vertical;
  }
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    &-body {
      width: 328px;
      height: 56px;
      display: flex;
      justify-content: space-between;
      .no {
        width: 156px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        background-color: #ecedff;
        border-radius: 12px;
        color: #6975af;
      }
      .yes {
        width: 156px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        background-color: #3d5cff;
        border-radius: 12px;
        color: #fff;
      }
    }
  }
  .el-dialog__body {
    padding: 30px 56px;
  }
  .title {
    color: #2e3e5c;
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 30px;
  }
  .el-dialog {
    border-radius: 12px;
  }
}
</style>