<!-- 个人中心 -->
<template>
  <div id="Personalnav">
      <div class="body">
        <div class="personal">
            <div class="personal-content cursor">
                <div v-for="(item,key) in navlsit" :class="selectnav==item.path?'personal-content-select':'personal-content-text'" @click="changselect(key,item.path)">
                    {{item.name}}
                </div>
            </div>
        </div>
          <router-view></router-view>
      </div>
  </div>
</template>

<script>
export default {
    components: {
    },
  data () {
    return {
        // 选中的nav
        selectnav: '/Personaldata',
        // 导行的所有nav
        navlsit: [
            {
                name: '个人资料',
                key: '0',
                path: 'Personaldata'
            },
            {
                name: '绑定账户',
                key: '1',
                path: 'Bindaccount'
            },
            {
                name: '修改密码',
                key: '2',
                path: 'Changepassword'
            },
            {
                name: '注销账号',
                key: '3',
                path: 'Cancelaccount'
            }
        ]
    };
  },
  mounted () {},
  watch:{
     //监听页面路由的切换，将选中的nav动态更新
      $route(to,from){
        this.selectnav=to.path.slice(1);
        // console.log(this.selectnav)
      }
    },
    created() {
     //当前选中页面刷新，标签依然选中当前路由对应的标签
        this.selectnav = this.$route.path.slice(1);
        // console.log(this.selectnav)
        if(this.selectnav=='Personalnav'){
            this.$router.replace('/' + 'Personaldata');
            
         }
        
    },
  methods: {
      //   改变当前选中的
      changselect (key, path) {
          if(path==this.selectnav){
              return false
          }
        //   console.log(key, this.selectnav)
          this.$router.replace('/' + path);
      }
  }
};
</script>

<style lang="scss">
#Personalnav {
    width: 100%;
    background: linear-gradient(90deg, #ECEFF8 2%, #F7FBFF 99%);
    padding: 34px 0 24px 0 ;
    .body{
        width: 1428px;
        margin: 0 auto;
        display: flex;
        .personal{
            width: 208px;
            height: 230px;
            background: #FFFFFF;
            box-shadow: 2px 0px 16px 0px rgba(0,21,41,0.08);
            border-radius: 14px;
            display: flex;
            flex-direction: column;
            .personal-content{
                // width: 60px;
                height: 322px;
                margin:  40px auto 0;
                .personal-content-text{
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #111111;
                    margin:  0 0 22px 0;
                }
                .personal-content-select{
                    font-size: 15px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #3D5CFF;
                    margin:  0 0 22px 0;
                }
            }
        }
    }
}
</style>