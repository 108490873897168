<template>
  <div id="Viewtranslation">
    <div class="body">
      <!-- 内容 -->
      <div class="content">
        <!-- 查看译文 -->
        <div class="box">
          <div class="days">
              <!-- 上方面包屑 -->
            <div class="crumbs">
                <span class="crumbs-before cursor" @click="back">文档翻译</span>
                <span class="crumbs-fenkai">/</span>
                <span class="crumbs-now">查看译文</span>
            </div>
            <div class="lien-title">
              <div class="lien-title-text">原文</div>
              <div class="lien-back"></div>
            </div>
            <div class="words">
              <div class="words-left">
                <div class="words-content">
                  {{feildata.content}}
                </div>
                <img class="cursor" @click="downFile(feildata.originalUrl,'原文'+feildata.content)" src="../../assets/icon/icon_xiazai.png"/>
              </div>
            </div>
            <div class="lien-title">
              <div class="lien-title-text">译文</div>
              <div class="lien-back"></div>
            </div>
            <div class="translation">
              <div class="translation-left">
                <div class="translation-content">
                  {{feildata.content}}
                </div>
                <img class="cursor" @click="downFile(feildata.translationUrl,feildata.translationContent)" src="../../assets/icon/icon_xiazai.png"/>
              </div>
            </div>
          </div>
        </div>
        <!-- 查看译文 -->
      </div>
      <!-- 内容 -->
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
      feildata: {}, //文件信息
    }
  },
  mounted () {
    this.getfieldata()
  },
  methods: {
    getfieldata () {
      // console.log(this.$route.query,"文件信息")
      this.feildata = this.$route.query
      // console.log(this.feildata,"文件信息")

    },
    // 下载原本未翻译文档
    downOriginal (val,name) {
      // console.log(val,name)
      // window.location.href = val;
      let a = document.createElement('a')
      a.style.display = 'none';
      a.href = val
      a.download = '' + name
      a.click();
      
    },
    //文件下载
    downFile(val,name) {
      let fileUrl = val  //服务器文件地址
      this.getBlob(fileUrl).then(blob => {
        this.saveAs(blob, name)
      })
    },
    //通过文件下载url拿到对应的blob对象
    getBlob(url) {
      return new Promise(resolve => {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.responseType = 'blob'
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(xhr.response)
          }
        }

        xhr.send()
      })
    },
    //下载文件 　　js模拟点击a标签进行下载
    saveAs(blob, filename) {
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      link.click()
    },
    back () {
      this.$router.push('/Filetranslate')
    }
  },
};
</script>

<style lang="scss">
#Viewtranslation {
  width: 100%;
  margin-top: 33px;
  .body {
    width: 1169px;
    margin: 0 auto;
    padding-left: 48px;
    padding-bottom: 182px;
    .content {
      margin-top: 28px;
      display: flex;
      align-content: center;
      justify-content: space-between;
      .crumbs {
          width: 120px;
          height: 20px;
          margin:  0 0 32px 0;
          .crumbs-before {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.45);
          }
          .crumbs-fenkai {
            font-size: 12px;
            font-family: Helvetica;
            margin: 0 8px;
            color: rgba(0,0,0,0.45)
          }
          .crumbs-now {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0,0,0,0.65);
          }
      }
      .lien-title {
        width: 80px;
        height: 25px;
        display: flex;
        align-items: flex-end;
        position: relative;
        font-size: 18px;
        &-text {
          margin-left: 8px;
        }
        .lien-back {
          width: 26px;
          height: 8px;
          background: #3d5cff;
          opacity: 0.3;
          position: absolute;
          left: 0;
          bottom: 2px;
        }
      }
      .box {
        .days {
          .words {
            width: 602px;
            // height: 141px;
            // min-height: 141px;
            position: relative;
            margin-top: 30px;
            margin-bottom: 38px;
            // display: flex;
            // align-content: center;
            // justify-content: space-between;
            .words-left {
              width: 602;
              height: 120px;
              background: linear-gradient(90deg, #EEF1F9 0%, #F8FCFF 98%);
              border-radius: 14px;
            //   margin: 4px 4px 0px 4px ;
              display: flex;
              align-items: center;
              justify-content: space-between;
              img{
                  width: 32px;
                  height: 32px;
                  margin:  0 32px 0 0;
              }
            }
            .words-content{
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #6975AF;
                margin: 0 0 0 30px;
            }
          }
          .translation {
            width: 602px;
            // height: 141px;
            // min-height: 141px;
            position: relative;
            margin-top: 30px;
            margin-bottom: 38px;
            // display: flex;
            // align-content: center;
            // justify-content: space-between;
            .translation-left {
                width: 602;
                height: 120px;
                background: #FFFFFF;
                box-shadow: 0px 0px 12px 2px rgba(184,184,210,0.2);
                border-radius: 14px;
                //   margin: 4px 4px 0px 4px ;
                display: flex;
                align-items: center;
                justify-content: space-between;
                img{
                    width: 32px;
                    height: 32px;
                    margin:  0 32px 0 0;
                }
            }
            .translation-content{
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #6975AF;
                margin: 0 0 0 30px;
            }
          }
        }
      }
    }
  }
}
</style>