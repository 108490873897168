import request from '../utils/request.js'

// 收藏夹内的单词 有分页
export function getCollectWordList (params) {
    return request.post('/collect/webCollectWordList', params)
}

// 删除收藏文件夹
export function deleteCollect (params) {
    return request.post('/collect/deleteCollect', params)
}
// 修改收藏文件夹
export function editCollect (params) {
    return request.post('/collect/editCollect', params)
}
// 收藏夹搜索 -- web 默认100条
export function webSelectCollectWordList (params) {
    return request.post('/collect/webSelectCollectWordList', params)
}

