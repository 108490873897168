<template>
  <div id="Filetranslate">
    <div class="body">
      <!-- 内容 -->
      <div class="content">
        <!-- 文档翻译 -->
        <div class="box">
          <div class="days">
            <div class="lien-title">
              <div class="lien-title-text">文档翻译</div>
              <div class="lien-back"></div>
            </div>
            <div class="quota">
              <div class="quota-left">
                <div class="quota-left-left">
                  免费额度:
                  <span>{{ freequota }}</span>
                </div>
                <div
                  class="quota-left-right cursor"
                  v-show="userdata.level != 0"
                  @click="tovip"
                >
                  获取更多额度
                  <img src="../../assets/icon/icon_more.png" />
                </div>
              </div>
              <div class="quota-right">
                <div class="quota-right-word">{{ language1 }}</div>
                <img
                  class="cursor"
                  @click="changelanguage"
                  src="../../assets/icon/icon_qiehuan.png"
                />
                <div class="quota-right-word">{{ language2 }}</div>
              </div>
            </div>
            <div class="words">
              <div class="words-left">
                <div
                  id="drop-area"
                  disabled="disabled"
                  :class="[
                    dropActive ? 'drop-active' : '',
                    fileloading == 0 ? 'cursor' : 'notcursor',
                  ]"
                  @click.stop="handleUpClick"
                >
                  <p v-show="fileloading == 0">点击上传文档</p>
                  <p v-show="fileloading != 0">文档上传中</p>
                  <img src="../../assets/icon/icon_wendang.png" />
                  <input
                    ref="fileinput"
                    id=""
                    class="file-selector-input"
                    type="file"
                    multiple="false"
                    accept=".DOC,.DOCX,.PDF"
                    @change.stop="uploadFile"
                  />
                </div>
              </div>
            </div>
            <div class="typerestriction">
              {{ typerestriction }}
            </div>
            <!-- <div style="height:200px"></div> -->
            <div class="history">
              <div class="history-title">
                <div>历史记录</div>
                <img
                  class="cursor"
                  @click="cleanhistory"
                  v-show="history.length != 0"
                  src="../../assets/icon/icon_del.png"
                />
              </div>
              <div v-show="history.length == 0" class="history-none">
                <img src="../../assets/image/img_none.png" />
              </div>
              <div v-show="history.length != 0" class="history-words">
                <div v-for="item in history" class="history-word cursor" @click="myTranslate">
                  {{ item }}
                  <!-- <img @click="delhistory(item)" src="../../assets/icon/icon_close.png"/> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 文档翻译 -->
      </div>
      <!-- 内容 -->
    </div>
    <Globalmessage ref="message" :duration="3000" :top="30" />
    <!-- <Friendlytips :dialogVisible='isshowdialog' @closedialog='closedialog' /> -->
    <el-dialog
      class="dialogs"
      title=""
      :visible.sync="isshowdialog"
      width="380px"
      top="320px"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <div slot="title" class="top-title">
        <span>友情提示</span>
      </div>
      <div class="content">
        <span v-if="istopvip == 0"
          >翻译字符超过免费额度，您可以升级会员，或购买单篇翻译</span
        >
        <span v-if="istopvip == 1"
          >翻译字符超过免费额度，您可以购买单篇翻译</span
        >
      </div>
      <div class="image">
        <img src="../../assets/image/vip.png" />
      </div>
      <div v-if="istopvip == 0" class="foot1">
        <el-button class="but1" @click="buysingle">单篇购买</el-button>
        <el-button class="but2" @click="tovip">立即解锁</el-button>
      </div>
      <div v-if="istopvip == 1" class="foot2">
        <el-button class="but" @click="buysingle">单篇购买</el-button>
      </div>
      <div class="close cursor" @click="closedialog">
        <img src="../../assets/image/Element／close.png" />
      </div>
    </el-dialog>

    <el-dialog
      class="dialogs"
      title=""
      :visible.sync="isshowtrans"
      width="380px"
      top="320px"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <div slot="title" class="top-title">
        <span>友情提示</span>
      </div>
      <div class="content">
        <span v-if="translateWordState == 1">目前是英译中，您确定继续进行翻译吗？</span>
        <span v-if="translateWordState == 2">目前是中译英，您确定继续进行翻译吗？</span>
      </div>
      <div class="image">
        <!-- <img src="../../assets/image/vip.png" /> -->
      </div>
      <div class="foot1">
        <el-button class="but1" @click="translateWord">确定</el-button>
        <el-button class="but2" @click="closetrans">取消</el-button>
      </div>
      <div class="close cursor" style="top: 220px;" @click="closetrans">
        <img src="../../assets/image/Element／close.png" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Globalmessage from "../../components/Globalmessage.vue";
// import Friendlytips from './Friendlytips.vue'
export default {
  components: {
    Globalmessage,
    // Friendlytips
  },
  data() {
    return {
      userdata: JSON.parse(sessionStorage.getItem("userdata")), // 用户数据
      buydata: {}, // 购买信息
      filesdata: {}, // 上传文件后拿到的数据
      istopvip: 0, // 是否是只能单篇购买
      dropActive: false, // 拖动上传文件

      typerestriction: "支持文档格式：DOC、DOCX、PDF", // 文档的上传限制
      fileloading: 0, // 文件上传的状态

      isshowdialog: false, // 友情提示弹窗

      searchValue: "", // 搜索value
      login: this.$store.state.loginStatus,
      history: [], // 历史记录
      language1: "英文",
      language2: "中文",
      iscollect: 1, // 是否收藏
      freequota: "", // 免费额度
      isshowtrans:false,//翻译类型 中译英，英译中提示弹框
      translateWordState:1
    };
  },
  mounted() {
    //如果本地存储的数据texthistoryList有值，直接赋值给data中的texthistoryList
    if (JSON.parse(localStorage.getItem("imghistoryList"))) {
      this.history = JSON.parse(localStorage.getItem("imghistoryList"));
    }
    this.freeCredit();
    this.fileloading = 0;
    // 拖动上传文件
    let dropArea = document.getElementById("drop-area");
    dropArea.addEventListener("drop", this.dropEvent, false);
    dropArea.addEventListener("dragleave", (e) => {
      e.stopPropagation();
      e.preventDefault();
      this.dropActive = false;
    });
    dropArea.addEventListener("dragenter", (e) => {
      e.stopPropagation();
      e.preventDefault();
      this.dropActive = true;
    });
    dropArea.addEventListener("dragover", (e) => {
      e.stopPropagation();
      e.preventDefault();
      this.dropActive = true;
    });
    const that = this;
    window.addEventListener("setItem", () => {
      that.userdata = JSON.parse(sessionStorage.getItem("userdata"));
      //that.formData.deptId是data里面声明的变量
      // console.log(that.userdata,JSON.parse(sessionStorage.getItem('userdata')),'监听')
    });
  },
  methods: {
    myTranslate() {
      this.$router.push({ path: "/Translatehistory" });
    },
    // 单篇购买
    buysingle() {
      this.$router.push({ path: "/Singlebuy", query: this.buydata });
    },
    // 去开vip
    tovip() {
      // console.log('88')
      this.$router.push({ path: "/Myvip" });
    },
    // 免费额度
    freeCredit() {
      this.$translation.freeCredit().then((res) => {
        // console.log(res,"免费额度")
        if (res.code == 200) {
          this.freequota = res.result.remaining;
        }
      });
    },
    // 显示友情提示弹窗
    showdialog() {
      // console.log('显示友情提示弹窗')
      this.isshowdialog = true;
    },
    // 关闭友情提示弹窗
    closedialog() {
      // console.log('关闭友情提示弹窗')
      this.isshowdialog = false;
      this.filesdata = {};
      this.fileloading = 0;
    },
    // 删除历史记录
    delhistory(item) {
      // console.log(item)
      for (let i = 0; i < this.history.length; i++) {
        if (this.history[i].id == item.id) {
          this.history.splice(i, 1);
        }
      }
    },
    // 上传文件
    handleUpClick() {
      if (this.fileloading == 1) {
        return false;
      }
      this.$refs.fileinput.click();
    },
    // 拖拽上传
    dropEvent(e) {
      console.log('拖拽上传',e)
      this.dropActive = false;
      e.stopPropagation();
      e.preventDefault();

      var files = [];
      [].forEach.call(e.dataTransfer.files, function(file) {
        files.push(file);
      },false);
      console.log('文件：',files);

      this.uploadFile(e.dataTransfer.files)
    },
    uploadFile(file) {
      // 渲染上传文件
      console.log(file, 'file')

      // console.log(file.target.files[0], 'file')
      var files;
      if(file.target){
        files = file.target.files[0];
      }else{
        files = file[0]
      }
      this.files = files
      if (files == undefined) {
        return false;
      }
      let filename = files.name;
      this.filename = filename;
      const isLt500k = files.size / 1024 / 1024 < 20;
      let pos = filename.lastIndexOf(".");
      let lastName = filename.substring(pos, filename.length);
      // console.log(lastName, lastName.toLowerCase())
      if (
        lastName.toLowerCase() !== ".pdf" &&
        lastName.toLowerCase() !== ".doc" &&
        lastName.toLowerCase() !== ".docx"
        // lastName.toLowerCase() !== ".zip"&&
        // lastName.toLowerCase() !== ".rar"&&
        // lastName.toLowerCase() !== ".xls"&&
        // lastName.toLowerCase() !== ".xlsx"
      ) {
        this.$refs.message.error("文件必须为 DOC、DOCX、PDF 类型"); // success调用
        // this.$message.error("文件必须为.pdf .doc .docx .zip .rar .xls .xlsx类型");
        // this.$refs.fileinput.clearFiles()
        return false;
      }
      if (!isLt500k) {
        this.$refs.message.error("文件大小不能超过 20M!"); // success调用
        // this.$message.error("文件必须为.pdf .doc .docx .zip .rar .xls .xlsx类型");
        // this.$refs.fileinput.clearFiles()
        return false;
      }
      // console.log(files)
      if (files) {
        const that = this;
        that.loading = this.$loading({
          lock: true,
          text: '文档上传中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        

        let time = new Date().getTime()
        this.time = time;
        let p = new FormData();
        p.append("file", files);
        p.append("stamp", time);
        p.append("transType", that.language1 == "英文" ? "1" : "2");
        this.$translation.DocumentationTranslationDistinguish(p).then((res) => {
          if (res.code==200) {
            // status 0 为正常  1 是 原文是中文 选的是英译中  2 是原文是英文 选的是中译英
            if (res.result.status == 1 || res.result.status == 2) {
              that.loading.close();

              that.isshowtrans = true;
              that.translateWordState = res.result.status;
            }else if(res.result.status == 0){
              that.translateWord(1)
            }
          }else{
            that.loading.close();
          }
          
          console.log(res)
        });
        


        

        
      }
    },
    translateWord(e){
      let that = this;
      console.log('that.loading',e)
      if(e != 1){
        that.loading = this.$loading({
          lock: true,
          text: '文档翻译中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      }
      if(that.isshowtrans){
        that.isshowtrans = false;
      }
      

      let params = new FormData();
      params.append("file", this.files);
      params.append("stamp", this.time);
      params.append("transType", that.language1 == "英文" ? "1" : "2");

      that.$translation.DocumentationTranslation(params).then((res) => {
        console.log('接口请求结果:',res);
        that.loading.close();

        if (res.code == 200) {
          that.filesdata = res.result;
          if (res.result.mark == 0) {
            // 正常可以直接翻译
            this.$router.push({
              path: "/Viewtranslation",
              query: this.filesdata,
            });
          } else if (res.result.mark == 1) {
            // 可以单篇购买 也可以升级会员
            that.istopvip = 0;
            that.$translation
              .webDocumentationTranslationOrder(params)
              .then((resulr) => {
                // console.log(resulr,"购买数据")
                if (resulr.code == 200) {
                  that.buydata = resulr.result;
                  that.showdialog();
                }
              });
          } else if (res.result.mark == 2) {
            // 只能单篇购买
            that.istopvip = 1;
            that.$translation
              .webDocumentationTranslationOrder(params)
              .then((resulr) => {
                // console.log(resulr,"购买数据")
                if (resulr.code == 200) {
                  that.buydata = resulr.result;
                  that.showdialog();
                }
              });
          }
          that.freeCredit();
        } else {
          that.loading.close();
          this.fileloading = 0;
        }
        if(res.code == 500){
          let params = new FormData();
          params.append("stamp", this.time);
          this.$translation.translategetByStamp(params).then((res) => {
            if (res.code == 200) {
              
            }
            console.log(res)
          });
        }
      }).catch(error => {
        console.log('error1111',error)
      });




      // 没有搜索记录，把搜索值push进数组首位，存入本地
      let filename = this.filename
      // console.log(filename)
      if (!this.history.includes(filename)) {
        this.history.unshift(filename);
        localStorage.setItem("imghistoryList", JSON.stringify(this.history));
      } else {
        //有搜索记录，删除之前的旧记录，将新搜索值重新push到数组首位
        let i = this.history.indexOf(filename);
        this.history.splice(i, 1);
        this.history.unshift(filename);
        localStorage.setItem("imghistoryList", JSON.stringify(this.history));
      }
      // console.log(this.history,"记录")
      this.freeCredit();
      // console.log(files)
      // 自行发挥，存本地或上传服务器
      // let formFile = new FormData();
      // formFile.append("file", event.target.files[0]);
      // formFile.append("apply_info_id", this.RndNum(12));
      // formFile.append("file_type", '');
      // //调用接口
      // file_upload(formFile).then(res => {
      //   this.addInformation.addPoupTableData.data.push({name:res.data.name,id:res.data.id,size:(event.target.files[0].size/1024).toFixed(0) + "kb",path:res.data.path})
      //   //调用接口后清除文件
      //   this.$refs.fileinput.value = ''
      // })
      this.$refs.fileinput.value = "";
      this.fileloading = 1;
    },
    // 关闭提示弹窗
    closetrans() {
      // console.log('关闭提示弹窗')
      this.$refs.fileinput.value = "";
      this.fileloading = 0;
      this.isshowtrans = false;
    },
    // 清除历史记录
    cleanhistory() {
      localStorage.removeItem("imghistoryList");
      this.history = [];
    },
    // 收藏
    collect() {
      if (this.iscollect == 0) {
        this.iscollect = 1;
      } else {
        this.iscollect = 0;
      }
    },
    // 翻译转换
    changelanguage() {
      if (this.language1 == "英文") {
        this.language1 = "中文";
        this.language2 = "英文";
      } else {
        this.language1 = "英文";
        this.language2 = "中文";
      }
    },
  },
};
</script>

<style lang="scss">
#Filetranslate {
  width: 100%;
  margin-top: 33px;
  .dialogs {
    .el-dialog {
      border-radius: 12px;
    }
    .el-dialog__header {
      padding: 20px 20px 0px;
    }
    .el-dialog__body {
      padding: 9px 20px 40px 20px;
    }
    .top-title {
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #252223;
    }
    .content {
      height: 48px;
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #5e5f60;
    }
    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 190px;
        height: 162px;
      }
    }
    .foot1 {
      width: 332px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .but1 {
        width: 156px;
        height: 50px;
        background: rgba(61, 92, 255, 0.1);
        border-radius: 12px;
        border: none;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3d5cff;
      }
      .but2 {
        width: 156px;
        height: 50px;
        background: #3d5cff;
        border-radius: 12px;
        border: none;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .foot2 {
      width: 332px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      .but {
        width: 240px;
        height: 50px;
        background: #3d5cff;
        border-radius: 12px;
        border: none;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .close {
      position: absolute;
      top: 420px;
      right: 168px;
      width: 44px;
      height: 44px;
      // background: black;
      img {
        width: 44px;
        height: 44px;
      }
    }
  }
  .body {
    width: 1169px;
    margin: 0 auto;
    padding-left: 48px;
    padding-bottom: 182px;
    .content {
      margin-top: 28px;
      display: flex;
      align-content: center;
      justify-content: space-between;
      .lien-title {
        width: 80px;
        height: 25px;
        display: flex;
        align-items: flex-end;
        position: relative;
        font-size: 18px;
        &-text {
          margin-left: 8px;
        }
        .lien-back {
          width: 26px;
          height: 8px;
          background: #3d5cff;
          opacity: 0.3;
          position: absolute;
          left: 0;
          bottom: 2px;
        }
      }
      .quota {
        width: 703px;
        height: 52px;
        display: flex;
        align-content: center;
        justify-content: space-between;
        margin: 30px 0 0 0;
        .quota-left {
          width: 335px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: #fff6f1;
          border-radius: 14px;
          .quota-left-left {
            margin-left: 10px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ff6905;
          }
          .quota-left-right {
            font-size: 11px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #c1a491;
            display: flex;
            align-items: center;
            margin: 0 5px 0 0;
            img {
              width: 22px;
              height: 22px;
            }
          }
        }
        .quota-right {
          width: 348px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .quota-right-word {
            width: 140px;
            height: 32px;
            background: #ffffff;
            border-radius: 6px;
            border: 1px solid #dcdcdc;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          img {
            width: 24px;
            height: 16px;
            margin: 0 22px;
          }
        }
      }
      .box {
        .days {
          .history {
            width: 602px;
            // height: 210px;
            padding-bottom: 20px;
            background: #f9f9f9;
            border-radius: 14px;
            .history-words {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              width: 567px;
              padding: 0px 13px 0px 22px;
              .history-word {
                max-width: 500px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                position: relative;
                height: 20px;
                text-align: center;
                // display: flex;
                // align-items: center;
                // justify-content: center;
                padding: 1px 7px 1px 7px;
                margin: 0 10px 5px 0;
                background: #ffffff;
                border-radius: 4px;
                border: 1px solid #dbdbdb;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #505560;
                img {
                  position: absolute;
                  top: -9px;
                  right: -9px;
                  width: 18px;
                  height: 18px;
                }
              }
            }
            .history-title {
              display: flex;
              align-content: center;
              justify-content: space-between;
              width: 567px;
              height: 22px;
              padding: 16px 13px 20px 22px;
              font-size: 13px;
              font-family: PingFangSC-Regular, PingFang SC;
              color: #8c8c8c;
              img {
                width: 22px;
                height: 22px;
              }
            }
            .history-none {
              display: flex;
              align-content: center;
              justify-content: center;
              // margin-bottom: 20px;
              img {
                width: 100px;
                height: 100px;
              }
            }
          }
          .typerestriction {
            width: 602px;
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #a8abb8;
            margin: 0 0 16px 0;
          }
          .words {
            width: 1220px;
            // height: 141px;
            min-height: 141px;
            position: relative;
            opacity: 0.9;
            margin-top: 20px;
            margin-bottom: 16px;
            display: flex;
            align-content: center;
            justify-content: space-between;
            .words-left {
              width: 602px;
              // height: 141px;
              background: linear-gradient(90deg, #eef1f9 0%, #f8fcff 98%);
              border-radius: 14px;

              #drop-area {
                position: relative;
                width: 602px;
                min-height: 141px;
                p {
                  font-size: 24px;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #8189b0;
                  padding: 18px 0 0 20px;
                }
                img {
                  position: absolute;
                  right: 16px;
                  top: 19px;
                  width: 32px;
                  height: 32px;
                }
                input {
                  display: none;
                }
              }
              .drop-active {
                background-color: rgba(231, 234, 246, 0.8);
              }
              .notcursor {
                cursor: not-allowed;
                // pointer-events: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>