<!-- 修改密码 -->
<template>
    <div id="Cancelaccount">
        <div class="content">
            
            <div class="content-bottom">
                <!-- 上方面包屑 -->
                <div class="crumbs">
                    <span class="crumbs-before cursor" @click="backhome" >首页</span>
                    <span class="crumbs-fenkai">/</span>
                    <span class="crumbs-before " >设置</span>
                    <span class="crumbs-fenkai">/</span>
                    <span class="crumbs-now">修改密码</span>
                </div>
                <div class="content-bottom-title">
                    <div class="content-top-top-left">
                        <div class="content-top-top-left-text">修改密码</div>
                        <div class="content-top-top-left-back"></div>
                    </div>
                </div>
                <div class="content-content">
                    <div class="content-title">当前手机号或邮箱</div>
                    <div class="content-input">
                        <div class="input-input">
                            <el-input class="content-input-input"  v-model="nownumber" placeholder="请绑定手机号或邮箱"></el-input>
                        </div>
                    </div>
                    <div class="content-input">
                        <div class="input-input">
                            <el-input class="content-input-input" v-model="verificationcode" placeholder="请输入验证码"></el-input>
                        </div>
                        <div class="sendnumber cursor" @click="sendcode">{{codetime+codetext}}</div>
                    </div>

                    <div class="content-title">新密码</div>
                    <div class="content-input">
                        <div class="input-input">
                            <el-input class="content-input-input" type="password" v-model="newpassword" placeholder="请输入新的登录密码"></el-input>
                        </div>
                    </div>
                    <div class="content-title">校验密码</div>
                    <div class="content-input">
                        <div class="input-input">
                            <el-input class="content-input-input" type="password" v-model="checknewpassword" placeholder="请再次输入新登录密码"></el-input>
                        </div>
                    </div>
                </div>
                <div class="vip-button">
                    <div class="toupgrade cursor" @click="toupgrade">保存</div>
                </div>
                
            </div>
            <Globalmessage ref="message" :duration="3000" :top="100" :widths="1196"/>
        </div>
        <div class="height156"></div>
    </div>
</template>

<script>
import Globalmessage from '../../components/Globalmessage.vue'
export default {
    components: {
        Globalmessage
    },
  data () {
    return {
        nownumber: '', // 当前手机号
        verificationcode: '', // 验证码
        codetime: '', // 验证码倒计时
        codetext: '发送验证码', // 验证码显示的东西
        newpassword: '', // 新密码
        checknewpassword: '', //校验新密码
        userdata: JSON.parse(sessionStorage.getItem('userdata')), // 用户数据
        
    };
  },
    computed: {
    },
    created () {
    },
  mounted () {
      this.getdata()
    const that = this
    window.addEventListener('setItem', () => {
      that.userdata = JSON.parse(sessionStorage.getItem('userdata'))
      
    //   that.formData.deptId是data里面声明的变量
    //   console.log(that.userdata,JSON.parse(sessionStorage.getItem('userdata')),'监听')
    })
  },
  methods: {
      //   回首页
    backhome () {
      this.$router.push('/')
    },
    //   获取数据
    getdata () {
        this.nownumber = this.userdata.tel
    },
    //  发送验证码
    sendcode () {
        if (this.nownumber=='') {
            return false
        }
        if (this.codetime != '') {
            return false
        }
        let params = new FormData()
        params.append('tel',this.nownumber)
        this.$login.getVerification(params).then((res) => {
            if (res.code==200) {
                this.codetext = 'S后重新获取'
                this.codetime = '60'
                let timer = setInterval(() => {
                if (this.codetime != 0) {
                    this.codetime--;
                    } else {
                    this.codetext = '发送验证码';
                    this.codetime = '';
                    clearInterval(timer);
                    }
                }, 1000);
            }

        })
        
    },
    //   保存
    toupgrade () {
        if (this.nownumber=='') {
            this.$message({
                message: '请填写手机号/邮箱',
                type: 'error'
            });
            return false
        }
        if (this.verificationcode=='') {
            this.$message({
                message: '请填写验证码',
                type: 'error'
            });
            return false
        }
        if (this.newpassword =='') {
            this.$message({
                message: '请填写新密码',
                type: 'error'
            });
            return false
        }
        if (this.checknewpassword == '') {
            this.$message({
                message: '请再次输入密码',
                type: 'error'
            });
            return false
        }
        
        if (this.newpassword !=this.checknewpassword) {
            this.$message({
                message: '新密码不一致',
                type: 'error'
            });
            return false
        }
        let params = new FormData
        params.append('tel',this.nownumber)
        params.append('verification',this.verificationcode)
        params.append('password',this.newpassword)
        params.append('confirmPassword',this.checknewpassword)
        this.$userdata.editPassword(params).then((res) => {
            // console.log(res,'修改密码')
            if (res.code==200) {
                this.$message({
                    message: '修改成功',
                    type: 'success'
                });
                this.verificationcode = ''
                this.newpassword = ''
                this.checknewpassword = ''
            }
        })
        

    },
  }
};
</script>
<style lang="scss">
#Cancelaccount {
    .content-input-input{
        .el-input__inner{
            width: 510px;
            height: 66px;
            border-radius: 14px;
            // background:rgba(255,255,255,0);
            background: #F9FAFB;
            border: 0;
            font-size: 18px;
        }
        .el-input__inner::placeholder {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #A8ABB8;
        }
        /* 谷歌 */
        .el-input__inner::-webkit-input-placeholder {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #A8ABB8;
        }
        /* 火狐 */
        .el-input__inner:-moz-placeholder {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #A8ABB8;
        }
        /*ie*/
        .el-input__inner:-ms-input-placeholder {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #A8ABB8;
        }
    }
}
</style>
<style lang="scss" scoped>
#Cancelaccount {
    .crumbs {
        width: 200px;
        height: 20px;
        margin:  0 0 3px 24px;
        padding: 20px 0 0 0;
        .crumbs-before {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
        }
        .crumbs-fenkai {
        font-size: 12px;
        font-family: Helvetica;
        margin: 0 8px;
        color: rgba(0,0,0,0.45)
        }
        .crumbs-now {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0,0,0,0.65);
        }
    }
    .height156{
        height: 156px;
    }
    .vip-button{
        margin: 40px 0 0 24px;
        width: 704px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .toupgrade{
            width: 335px;
            height: 50px;
            background: #3D5CFF;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            
        }
    }
    .nocursor{
        cursor:not-allowed;
    }
    .content{
        width:1196px;
        margin:  0 0 0 24px;
        .content-bottom{
            width: 1196px;
            // height: 550px;
            background: #FFFFFF;
            box-shadow: 2px 0px 16px 0px rgba(0,21,41,0.08);
            border-radius: 14px;
            // margin: 24px 0 0 0;
            padding: 0 0 24px 0;
            .content-content{
                width: 1068px;
                min-height: 223px;
                margin: 0px 0px 0 24px;
                .content-input{
                    display: flex;
                    margin: 16px 0 0 0;
                    .input-input{
                        width: 510px;
                        height: 66px;
                    }
                    .sendnumber{
                        width: 140px;
                        height: 66px;
                        background: #E2E7FF;
                        border-radius: 14px;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #1F1F39;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0 0 0 24px;
                    }
                }
                .content-title{
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #5E5F60;
                    margin: 34px 0 0 0 ;
                }
                .content-text1{
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #2E3E5C;
                    letter-spacing: 1px;
                    margin: 41px  0 0 0;
                }
                .content-text2{
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #858597;
                    margin: 8px 0 13px 0;
                }

            }
            .content-bottom-title{
                width: 80px;
                height: 25px;
                padding: 20px 0 0 0px;
                
            }
            .title-botttom{
                width: 100px;
                display: flex;
                align-items: center;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #8189B0;
                margin: 6px 0 0 31px;
                img{
                    width: 22px;
                    height: 22px;
                }
            }
            
        }
        .content-top-top-left{
            width: 80px;
            height: 25px;
            display: flex;
            align-items: flex-end;
            position: relative;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2F3236;
            margin: 0 0 0 24px;
            &-text {
            margin-left: 8px;
            }
            .content-top-top-left-back {
                width: 26px;
                height: 8px;
                background: #3d5cff;
                opacity: 0.3;
                position: absolute;
                left: 0;
                bottom: 2px;
            }
        }
        
    }
}
</style>