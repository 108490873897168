<!-- 我是头部导航组件 -->
<template>
  <div id="nav">
    <div class="body">
      <div class="logo">
        <!-- <img src="../assets/image/logo1.png" alt="" class="logo-img1" /> -->
        <img src="../assets/image/logo.png" alt="" class="logo-img" />
      </div>
      <div class="nav">
        <div class="nav-title">
          <div
            v-for="(item, key) in tavList"
            :key="key"
            class="cursor nav-titles"
            @click="navClick(item)"
          >
            <p :class="selectnav==item.path?'selectnavs':''">{{ item.name }}</p>
          </div>
          <div class="lifelong1">
            <div class="lifelong cursor" v-show="userdata!=null&&userdata.level==-1||userdata==null" @click="toopenvip"><p >开通终身VIP</p></div>
          </div>
          <div class="download" :style="'opacity:' + downloadStatus" v-show="downloadStatus">
            <div class="download-body">
              <img
                :src="iosqr"
                alt=""
                :onerror="defaultimgSrc"
              />
              IOS
            </div>
            <div class="download-body">
              <img
                :src="appqr"
                alt=""
                :onerror="defaultimgSrc"
              />
              安卓
            </div>
          </div>
        </div>
        <div class="login ">
          <p class="login-text cursor" v-if="userdata==null" @click="Login()">登录</p>
          <div class="login-title" v-else>
            <!-- 通知图标 -->
            <div class="inform">
              <img
                class="inform-img  cursor"
                src="../assets/icon/icon_inform.png"
                alt=""
                @click="redotclick"
              />
              <!-- 通知红点 -->
              <div class="redot cursor" @click="redotclick" v-show="redotStatus=='0'">{{ redotNums }}</div>
              <!-- 通知框 -->
              <div class="inform-body" v-show="showStatus == '通知'">
                <p class="inform-nodata" v-if="informList.length==0">暂无通知</p>
                <div
                  class="inform-body-row cursor"
                  v-for="(item, key) in informList"
                  :key="key"
                  @click="tojump(item.jump,item)"
                >
                  <p class="title">{{ item.title }}</p>
                  <div class="line">
                    <p class="content">{{ item.content }}</p>
                    <img class="img" src="../assets/icon/right.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <!-- 通知弹框 -->
            <div class="userimg">
              <img class="userimg1" :src="userdata.avatar" alt="" :onerror="defaultimgSrc" />
              <img class="uservipimg" v-show="userdata.level==1" src="../assets/image/vip_baiyin.png" alt="" />
              <img class="uservipimg" v-show="userdata.level==2"  src="../assets/image/vip_huangjin.png" alt="" />
              <img class="uservipimg" v-show="userdata.level==0"  src="../assets/image/vip_bojin.png" alt="" />
            </div>
            <!-- 用户姓名 -->
            <p class="username cursor" @click="nameClick()" >{{userdata.nickname}}</p>
            <!-- 设置弹框 -->
            <div class="set" v-show="showStatus == '设置'">
              <p class="set-text cursor" @click="setClick()">设置</p>
              <p class="set-text cursor" @click="loginOut()">退出登录</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 选中的nav
      userdata: JSON.parse(sessionStorage.getItem('userdata')),
      selectnav: '/',
      tavList: [
        {
          name: "首页",
          path: "/",
        },
        {
          name: "文本翻译",
          path: "/Texttranslation",
        },
        {
          name: "文档翻译",
          path: "/Filetranslate",
        },
        {
          name: "人工翻译",
          path: "/Humantranslat",
        },
        {
          name: "个人中心",
          path: "/account",
        },
        {
          name: "下载APP",
          path: "#",
        },
      ],
      // 通知提醒
      informList: [
      ],
      downloadStatus: 0,
      showStatus: '',
      redotStatus: '1',
      redotNums:'1',
      appqr: '', // 下载二维码
      iosqr: '', // 下载二维码

    };
  },
  computed: {
      defaultimgSrc () {
          return 'this.src="' + require('../assets/image/heard.png') + '"';
      }
  },
  watch:{
    
     //监听页面路由的切换，将选中的nav动态更新
      $route(to,from){
        this.selectnav=to.path;
        // console.log(this.selectnav,to.path)
        if (this.selectnav=='') {
            this.selectnav = '/'
        }
        // 
        if (this.selectnav=='#') {
          
          return false
        }
      }
    },
    created() {
      // console.log(JSON.parse(sessionStorage.getItem('userdata')))
     //当前选中页面刷新，标签依然选中当前路由对应的标签
        this.selectnav = this.$route.path;
        // console.log(this.selectnav,this.$route.path)
        if (this.selectnav=='') {
            this.selectnav = '/'
        }
        // 
        if (this.selectnav=='#') {
          
          return false
        }
        // 获取用户消息图标
        this.getIcon()
        
    },
  mounted() {
    this.$login.getUserAgreement().then((reses) => {
      this.setSessionItem('agreements', JSON.stringify(reses.result))
      // console.log(ress, "获得用户协议", that.agreementtext)
    })
    this.$login.getSetting().then((ress) => {
      // console.log(ress, "下载二维码",)
      this.iosqr = ress.result.iosQr
      this.appqr = ress.result.androidQr
    })
    document.addEventListener('click', this.hidder,true);
    const that = this
    window.addEventListener('setItem', () => {
      that.userdata = JSON.parse(sessionStorage.getItem('userdata'))
      this.getIcon()
      //that.formData.deptId是data里面声明的变量
      // console.log(that.userdata,JSON.parse(sessionStorage.getItem('userdata')),'监听')
    })
  },
  methods: {
    // 点击通知
    redotclick () {
      this.$userdata.myInformation().then((res) => {
          if (res.code==200) {
            this.informList = res.result
          }
          // console.log(res,"用户消息")
        })
      this.showStatus = '通知'
      this.redotStatus = '1'
      

    },
    // 消息跳转
    tojump (val, item) {
      console.log(val,item)
      // if(this.$route.path == "/Alreadyvip" || this.$route.path == "/Translatehistory"){
      //       return false
      // }
      if (val==0) {
        this.$router.push({name: "/Alreadyvip"},() => {})
      }
      if (val==1) {
        this.$router.push({name: "Translatehistory"},() => {})
      }
      if (val==2) {
        this.$router.push({name: "Todaylearn",query:{item:item}},() => {})
      }
      if (val==3) {
        this.$router.push({
        path: "/news",
        query: {
          id : item.id,
          content: item.content,
          title: item.title,
          createTime: item.createTime,
          userId: item.userId,
          type: item.type
        },
      },() => {});
      }
    },
    // 点击开通vip
    toopenvip () {
      if (this.userdata==null) {
        this.$store.commit("SET_LOGIN", true);
        
        return false
      }
      // console.log(this.$route.path)
      if (this.$route.path=="/Alreadyvip") {
        return false
      }
      this.$router.push({path: "/Alreadyvip"})
    },
    // 获取用户消息图标
    getIcon () {
      if (this.userdata==null) {
        return false
      }
      this.$userdata.getuserIcon().then((ress) => {
        if (ress.code==200) {
          this.redotStatus = ress.result.type
          this.redotNums = ress.result.size

          // this.redotStatus = 0
          // this.redotNums = 10

          // this.$userdata.myInformation().then((res) => {
          //   if (res.code==200) {
          //     this.informList = res.result
          //   }
          //   // console.log(res,"用户消息")
          // })
        }
      })
    },
    // 点击事件
    navClick(e) {
      if (this.userdata==null&&e.path!='/'&&e.path!='#') {
        this.$store.commit("SET_LOGIN", true);
        
        return false
      }
      // console.log(e,this.selectnav,e.path);
      if (e.name == "下载APP") {
        this.downloadStatus = 1
        return
      }
      
      if (this.selectnav == e.path) {
        return false
      }
      this.$router.push({path: e.path})
    },
    // 登录
    Login() {
      this.$store.commit("SET_LOGIN", true);
    },
    // 设置弹框
    nameClick() {
      this.showStatus = '设置'
    },
    // 设置
    setClick() {
      this.$router.replace('/' + 'Personaldata');
      // alert("我是设置");

    },
    // 退出登录
    loginOut() {
      this.$login.userQuit().then((ress) => {
        // console.log(ress.result, "退出账号")
        if (ress.code==200) {
          this.setSessionItem('userdata', null)
          sessionStorage.clear()
          if (this.$route.path!='/') {
            this.$router.push('/')
            
          }else{
            location. reload()
          }
        }
      })
      
      // this.userdata = null
      // console.log(this.userdata)
      // alert("我是退出登录");
    },
    hidder() {
      this.showStatus = ''
      this.downloadStatus = 0
    }
  },
};
</script>

<style lang="scss" scoped>
#nav {
  min-width: 1450px;
  display: flex;
  justify-content: center;
  padding-top: 14px;
  padding-bottom: 14px;
  box-shadow: 0px 4px 12px 0px rgba(61, 92, 255, 0.18);
  .nav-titles{
    margin: 0 40px 0 0;
  }
  .selectnavs{
    color: #3D5CFF;
  }
  .body {
    width: 1289px;
    display: flex;
    .nav {
      margin: 0 0 0 300px;
      height: 44px;
      display: flex;
      align-items: center;
      .login {
        
        // width: 32px;
        // margin-left: 71px;
        font-size: 15px;
        font-weight: bold;
        .login-text{
          margin-left: 51px;
          white-space: nowrap;
        }
        .username{
          width: 100px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .userimg{
          width: 52px;
          height: 50px;
          display: flex;
          align-items: center;
          position: relative;
          justify-content: center;
          .userimg1{
            width: 40px;
            height: 40px;
            border-radius: 11px;
          }
          .uservipimg{
            width: 52px;
            height: 20px;
            position: absolute;
            bottom: 0px;
          }
        }
        &-title {
          min-width: 150px;
          display: flex;
          align-items: center;
          position: relative;

          .inform {
            position: relative;
            margin: 0 10px;

            &-body {
              width: 310px;
              min-height: 100px;
              max-height: 300px;
              overflow: hidden;
              overflow-y: scroll;
              scrollbar-width: none; /* Firefox */
              -ms-overflow-style: none; /* IE 10+ */

              padding: 12px 24px;
              z-index: 99;
              position: absolute;
              left: -330px;
              top: 36px;
              background: #ffffff;
              box-shadow: 0px 0px 20px 2px rgba(184, 184, 210, 0.4);
              border-radius: 20px;
              border: 0px solid #e2e7ff;
              .inform-nodata{
                margin: 20px 0 0 0;
                width: 100%;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: rgb(164, 164, 164);
              }
              &-row {
                width: 279px;
                height: 46px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 14px;
                margin: 5px 0;
                background: #ffffff;
                border-radius: 14px;
                border: 1px solid #eff0f4;
                .title {
                  color: #1f1f39;
                  font-size: 13px;
                  font-weight: 400;
                  margin-bottom: 6px;
                  min-height: 17px;
                  overflow: hidden;
                  text-overflow: ellipsis; /* 超出部分省略号 */
                  white-space: nowrap;
                }
                .line {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  .content {
                    overflow: hidden;
                    text-overflow: ellipsis; /* 超出部分省略号 */
                    word-break: break-all; /* break-all(允许在单词内换行。) */
                    display: -webkit-box; /* 对象作为伸缩盒子模型显示 */
                    -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
                    -webkit-line-clamp: 2; /* 显示的行数 */
                    
                    color: #858597;
                    font-size: 11px;
                    font-weight: 400;
                  }
                }
              }
            }
            .inform-body::-webkit-scrollbar {
                display: none; /* Chrome Safari */
            }

            &-img {
              width: 22px;
              height: 22px;
              margin-top: 8px;
            }
            .redot {
              width: 18px;
              height: 17px;
              position: absolute;
              background-color: #ff2020;
              border-radius: 50%;
              top: 10px;
              right: 2px;
              top: 6px;
              right: -4px;
              font-size: 12px;
              color: #fff;
              text-align: center;
            }
          }

          .set {
            width: 132px;
            height: 48px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: absolute;
            bottom: -90px;
            right: -50px;
            padding: 14px;
            background-color: #ffffff;
            box-shadow: 0px 0px 20px 2px rgba(184, 184, 210, 0.4);
            border-radius: 20px;
            border: 0px solid #e2e7ff;
            &-text:hover {
              color: #5e5f60;
              font-weight: 600;
            }
            &-text {
              color: #a8abb8;
              font-size: 12px;
              font-weight: 500;
            }
          }
        }
      }
      &-title {
        width: 712px;
        height: 44px;
        position: relative;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        font-size: 15px;
        font-weight: bold;
        color: #111;
        .download {
          width: 228px;
          height: 125px;
          background-color: #fff;
          position: absolute;
          top: 50px;
          right: 100px;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: space-around;
          border-radius: 20px;
          box-shadow: 0px 0px 20px 2px rgba(184, 184, 210, 0.4);
          transition: opacity 0.3s;
          &-body {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          img {
            width: 80px;
            height: 80px;
          }
        }
        .lifelong1{
          width: 100px;
          height: 34px;
        }
        .lifelong {
          width: 100px;
          height: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          color: #724e2c;
          border-radius: 10px;
          border: 1px solid #fff3d6;
          background: linear-gradient(90deg, #f9ddbc 0%, #d1a67c 100%);
        }
      }
    }
    .logo {
      display: flex;
      align-items: center;
      &-img1 {
        width: 48px;
        height: 48px;
      }
      &-img {
        width: 160px;
        height: 48px;
      }
    }
  }
}
</style>