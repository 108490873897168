<!-- 我是忘记密码弹框组件 -->
<template>
  <div id="forgepass">
    <el-dialog
      title=""
      width="440px"
      :visible.sync="status"
      :before-close="handleClose"
    >
      <!-- title -->
      <div class="title">忘记密码</div>
      <!-- form -->
      <div class="info">
        <div class="info-input">
          <input
            type="text"
            v-model="form.account"
            placeholder="请输入手机号"
          />
          <img
            src="../assets/icon/icon_shouji.png"
            class="info-input-img"
            alt=""
          />
        </div>
        <div class="info-input">
          <input
            type="text"
            class="info-input-code"
            maxlength="8"
            v-model="form.code"
            placeholder="请输入验证码"
          />
          <img
            src="../assets/icon/icon_password.png"
            class="info-input-img"
            alt=""
          />
          <div class="info-input-gain cursor" @click="codeClick()">
            {{ codeTime + codeStatus }}
          </div>
        </div>
        <div class="info-input">
          <input
            type="password"
            v-model="form.userpass"
            placeholder="请输入新密码"
            @blur="inputBlur"
          />
          <img
            src="../assets/icon/icon_mima.png"
            class="info-input-img"
            alt=""
          />
        </div>
        <div class="info-input">
          <input
            type="password"
            class="info-input-code"
            v-model="form.repepass"
            placeholder="请输入再次输入密码"
          />
          <img
            src="../assets/icon/icon_mima.png"
            class="info-input-img"
            alt=""
          />
        </div>
      </div>
      <!-- button -->
      <div class="button">
        <div class="button-title cursor" @click="confirmClick()">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      codeStatus: "获取", // code
      codeTime: "", // code倒计时
      form: {
        account: "",
        code: "",
        userpass: "",
        repepass: "",
      },
    };
  },
  computed: {
    status: function () {
      return this.$store.state.forgeStatus;
    },
  },
  mounted() {},
  methods: {
    // 密码验证
    inputBlur(e){
      console.log(e)
      // if(this.form.userpass){
      //   if(!this.CheckPassWord(this.form.userpass)){
      //     this.$message.error("密码必须由字母、数字组成，且长度为8-22位");
      //   }
      // }
    },
    CheckPassWord(password) {//必须为字母加数字且长度不小于8位
      var str = password;
        if (str == null || str.length < 8 || str.length > 22) {
            return false;
        }
        var reg1 = new RegExp(/^[0-9A-Za-z]+$/);
        if (!reg1.test(str)) {
            return false;
        }
        var reg = new RegExp(/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/);
        if (reg.test(str)) {
            return true;
        } else {
            return false;
        }
    },
    // 获取验证码函数
    codeClick() {
      if (this.form.account == '') {
        return false
      }
      if (this.codeTime != '') {
        return false
      }
      let params = new FormData()
      params.append('tel',this.form.account)
      this.$login.getVerification(params).then((res) => {
        if (res.code==200) {
          this.codeStatus = "S后重新获取";
          this.codeTime = 60;
          let timer = setInterval(() => {
            if (this.codeTime != 0) {
              this.codeTime--;
            } else {
              this.codeStatus = "获取";
              this.codeTime = "";
              clearInterval(timer);
            }
          }, 1000);
        }

      })
      
    },
    // 确定
    confirmClick() {
      if (this.form.account==''||this.form.code==''||this.form.userpass == ''||this.form.repepass=='') {
        return false
      }
      if(this.form.userpass){
        if(!this.CheckPassWord(this.form.userpass)){
          this.$message.error("密码必须由字母、数字组成，且长度为8-22位");
          return false
        }
      }
      if(this.form.userpass != this.form.repepass){
        this.$message.error("密码不一致");
        return false
      }
      let params = new FormData()
      params.append('tel',this.form.account)
      params.append('verification',this.form.code)
      params.append('password',this.form.userpass)
      params.append('confirmPassword',this.form.repepass)
      this.$login.forgetPassword(params).then((res) => {
        if (res.code==200) {
          this.$message({
            message: '恭喜你，修改成功',
            type: 'success'
          });
          this.handleClose()
        }
      })
      
    },
    // 关闭弹框函数
    handleClose() {
      this.$store.commit("SET_FORGE", false);
    },
  },
};
</script>

<style lang="scss">
#forgepass {
  .button {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    &-title {
      width: 328px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      background-color: #3d5cff;
      color: #fff;
      font-size: 16px;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    .info-input {
      position: relative;
      &-gain {
        padding: 6px 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 20px;
        top: calc(50% - 16px);
        background-color: #3d5cff;
        border-radius: 6px;
        opacity: 0.5;
        color: #fff;
        font-size: 14px;
      }
      &-img {
        width: 22px;
        height: 22px;
        position: absolute;
        top: calc(50% - 12px);
        left: 16px;
      }
      input {
        width: 216px;
        height: 56px;
        border-radius: 12px;
        padding: 0 56px;
        margin: 9px 0;
        color: #8189b0;
        font-size: 15px;
        background-color: #f1f6fb;
        border: none;
      }
      input::-webkit-input-placeholder {
        color: #8189b0;
      }
    }
  }
  .title {
    color: #2e3e5c;
    font-weight: bold;
    font-size: 18px;
  }
  .el-dialog__body {
    padding: 30px 56px;
  }
  .el-dialog {
    border-radius: 12px;
  }
}
</style>