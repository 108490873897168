import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue' // 首页
import loginSuccess from '../components/loginSuccess.vue'// 微信扫码登录后跳转的
import Specialtynav from '../views/specialtyNav/specialtyNav.vue' // 专业导航
import searchResultnav from '../views/specialtyNav/components/searchResult' // 专业导航
import Allplan from '../views/allPlan/allPlan.vue' // 学习计划
import Todaylearn from '../views/todayLearn/todayLearn.vue' // 今日学习
import Searchresult from '../views/searchResult/searchResult.vue' // 搜索结果
import Texttranslation from '../views/Texttranslation/Texttranslation.vue' // 文本翻译
import Filetranslate from '../views/Filetranslate/Filetranslate.vue' // 文档翻译
import Singlebuy from '../views/Filetranslate/Singlebuy.vue' // 文档翻译-单篇购买
import Singlebuytext from '../views/Texttranslation/Singlebuy.vue' // 文本翻译-购买
import Viewtranslation from '../views/Filetranslate/Viewtranslation.vue' // 文档翻译-完之后的查看译文
import Humantranslat from '../views/Humantranslat/Humantranslat.vue' // 人工翻译

import Personalcenter from '../views/Personalcenter/Personalcenter.vue' // 个人中心
import account from '../views/Personalcenter/account.vue' // 个人中心-账户
import Myvip from '../views/Personalcenter/Myvip.vue' // 个人中心-vip
import Alreadyvip from '../views/Personalcenter/Alreadyvip.vue' // 个人中心已开通vip
import Applyinvoice from '../views/Personalcenter/Applyinvoice.vue'// 个人中心-vip-申请发票
import Customerservice from '../views/Personalcenter/Customerservice.vue'// 个人中心-客服
import Myinvoice from '../views/Personalcenter/Myinvoice.vue'// 个人中心-我的发票
import Querylog from '../views/Personalcenter/Querylog.vue'// 个人中心-查询记录
import Translatehistory from '../views/Personalcenter/Translatehistory.vue'// 个人中心-文档翻译记录
import Learnremind from '../views/Personalcenter/Learnremind.vue' // 个人中心-学习提醒
import Collection from '../views/Personalcenter/Collection.vue' // 个人中心收藏
import searchcollection from '../views/Personalcenter/searchcollection.vue' // 个人中心收藏-单词详情

import Personalnav from '../views/Personaldata/Personalnav.vue' // 个人资料左侧的导航
import Personaldata from '../views/Personaldata/Personaldata.vue' // 个人资料
import Cancelaccount from '../views/Personaldata/Cancelaccount.vue' // 注销账号
import Changepassword from '../views/Personaldata/Changepassword.vue' // 修改密码
import Bindaccount from '../views/Personaldata/Bindaccount.vue' // 绑定账户

import Agreementsnav from '../views/Agreements/Agreementsnav.vue' // 协议左侧导航
import Aboutas from '../views/Agreements/Aboutas.vue' // 关于我们
import Useragreement from '../views/Agreements/Useragreement.vue' // 科工词汇用户协议
import Privacyagreement from '../views/Agreements/Privacyagreement.vue' // 科工词汇隐私协议
import Vipagreement from '../views/Agreements/Vipagreement.vue' // 科工词汇会员服务协议
import payagreement from '../views/Agreements/payagreement.vue' // 支付协议
import Translationpolicy from '../views/Agreements/Translationpolicy.vue' // 科工词汇翻译政策协议

import news from '../components/news.vue' // 消息详情页面
Vue.use(VueRouter)

const routes = [
    // 首页
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta:{
            // 
            title: '科工词汇在线'
        }
    },
    // 消息详情页面
    {
        path: '/news',
        name: 'news',
        component: news,
        meta:{
            // 
            title: '科工词汇在线'
        }
    },
    // 微信登录后跳转的页面
    {
        path: '/loginSuccess',
        name: 'loginSuccess',
        component: loginSuccess,
        meta:{
            shovalues: true,
            title: '科工词汇在线'
        }
    },
    // 专业导航
    {
        path: '/specialtyNav',
        name: 'Specialtynav',
        component: Specialtynav,
        meta:{
            // 
            title: '科工词汇在线'
        }
    },
    // 专业导航单词点击后
    {
        path: '/searchResultnav',
        name: 'searchResultnav',
        component: searchResultnav,
        meta:{
            // 
            title: '科工词汇在线'
        }
    },
    // 全部计划
    {
        path: '/allPlan',
        name: 'Allplan',
        component: Allplan,
        meta:{
            // 
            title: '科工词汇在线'
        }
    },
    // 今日学习
    {
        path: '/todayLearn',
        name: 'Todaylearn',
        component: Todaylearn,
        meta:{
            // 
            title: '科工词汇在线'
        }
    },
    // 搜索结果
    {
        path: '/searchResult',
        name: 'Searchresult',
        component: Searchresult,
        meta:{
            // 
            title: '科工词汇在线'
        }
    },
    // {
    //     path: '/login',
    //     name: 'Login',
    //     component: () =>
    //         import ( /* webpackChunkName: "about" */ '../views/Login.vue')
    // }
    // 文本翻译
    {
        path: '/Texttranslation',
        name: 'Texttranslation',
        component: Texttranslation,
        meta:{
            // 
            title: '科工词汇在线'
        }
    },
    // 文档翻译
    {
        path: '/Filetranslate',
        name: 'Filetranslate',
        component: Filetranslate,
        meta:{
            // 
            title: '科工词汇在线'
        }
    },
    // 文档翻译-单篇购买
    {
        path: '/Singlebuy',
        name: 'Singlebuy',
        component: Singlebuy,
        meta:{
            // 
            title: '科工词汇在线'
        }
    },
    // 文本翻译-购买
    {
        path: '/Singlebuytext',
        name: 'Singlebuytext',
        component: Singlebuytext,
        meta:{
            // 
            title: '科工词汇在线'
        }
    },
    // 文档翻译-完之后的查看译文
    {
        path: '/Viewtranslation',
        name: 'Viewtranslation',
        component: Viewtranslation,
        meta:{
            // 
            title: '科工词汇在线'
        }
    },
    // 人工翻译
    {
        path: '/Humantranslat',
        name: 'Humantranslat',
        component: Humantranslat,
        meta:{
            // 
            title: '科工词汇在线'
        }
    },
    // 个人中心
    {
        path: '/Personalcenter',
        name: 'Personalcenter',
        component: Personalcenter,
        children: [
            // 个人中心-账户
            {
                path: '/account',
                name: 'account',
                component: account,
                meta:{
                    // 
                    title: '科工词汇在线'
                }
            },
            // 个人中心-vip
            {
                path: '/Myvip',
                name: 'Myvip',
                component: Myvip,
                meta:{
                    // 
                    title: '科工词汇在线'
                }
            },
            // 个人中心-已开通vip
            {
                path: '/Alreadyvip',
                name: '/Alreadyvip',
                component: Alreadyvip,
                meta:{
                    // 
                    title: '科工词汇在线'
                }
            },
            // 个人中心-vip-申请发票
            {
                path: '/Applyinvoice',
                name: '/Applyinvoice',
                component: Applyinvoice,
                meta:{
                    // 
                    title: '科工词汇在线'
                }
            },
            // 个人中心-客服
            {
                path: '/Customerservice',
                name: 'Customerservice',
                component: Customerservice,
                meta:{
                    // 
                    title: '科工词汇在线'
                }
            },
            // 个人中心-发票
            {
                path: '/Myinvoice',
                name: 'Myinvoice',
                component: Myinvoice,
                meta:{
                    // 
                    title: '科工词汇在线'
                }
            },
            // 个人中心-查询记录
            {
                path: '/Querylog',
                name: 'Querylog',
                component: Querylog,
                meta:{
                    // 
                    title: '科工词汇在线'
                }
            },
            // 个人中心-文档翻译记录
            {
                path: '/Translatehistory',
                name: 'Translatehistory',
                component: Translatehistory,
                meta:{
                    // 
                    title: '科工词汇在线'
                }
            },
            // 个人中心-学习提醒
            {
                path: '/Learnremind',
                name: 'Learnremind',
                component: Learnremind,
                meta:{
                    // 
                    title: '科工词汇在线'
                }
            },
            // 个人中心-收藏
            {
                path: '/Collection',
                name: 'Collection',
                component: Collection,
                meta:{
                    // 
                    title: '科工词汇在线'
                }
            }
            
        ]
    },
    // 个人中心-收藏-单词详情
    {
        path: '/searchcollection',
        name: 'searchcollection',
        component: searchcollection,
        meta:{
            // 
            title: '科工词汇在线'
        }
    },
    // 个人资料
    {
        path: '/Personalnav',
        name: 'Personalnav',
        component: Personalnav,
        children: [
            // 个人资料
            {
                path: '/Personaldata',
                name: 'Personaldata',
                component: Personaldata,
                meta:{
                    // 
                    title: '科工词汇在线'
                }
            },
            // 注销账号
            {
                path: '/Cancelaccount',
                name: 'Cancelaccount',
                component: Cancelaccount,
                meta:{
                    // 
                    title: '科工词汇在线'
                }
            },
            // 修改密码
            {
                path: '/Changepassword',
                name: 'Changepassword',
                component: Changepassword,
                meta:{
                    // 
                    title: '科工词汇在线'
                }
            },
            // 绑定账户
            {
                path: '/Bindaccount',
                name: 'Bindaccount',
                component: Bindaccount,
                meta:{
                    // 
                    title: '科工词汇在线'
                }
            }
        ]
    },
    // 协议
    {
        path: '/Agreementsnav',
        name: 'Agreementsnav',
        component: Agreementsnav,
        children: [
            // 关于我们
            {
                path: '/Aboutas',
                name: 'Aboutas',
                component: Aboutas,
                meta:{
                    // 
                    title: '科工词汇在线'
                }
            },
            // 科工词汇用户协议
            {
                path: '/Useragreement',
                name: 'Useragreement',
                component: Useragreement,
                meta:{
                    // 
                    title: '科工词汇在线'
                }
            },
            // 科工词汇隐私协议
            {
                path: '/Privacyagreement',
                name: 'Privacyagreement',
                component: Privacyagreement,
                meta:{
                    // 
                    title: '科工词汇在线'
                }
            },
            
            // 科工词汇会员服务协议
            {
                path: '/Translationpolicy',
                name: 'Translationpolicy',
                component: Translationpolicy,
                meta:{
                    // 
                    title: '科工词汇在线'
                }
            }
        ]
    },
    // 科工词汇会员服务协议
    {
        path: '/Vipagreement',
        name: 'Vipagreement',
        component: Vipagreement,
        meta:{
            // 
            title: '科工词汇在线'
        }
    },
    // 支付协议
    {
        path: '/payagreement',
        name: 'payagreement',
        component: payagreement,
        meta:{
            // 
            title: '科工词汇在线'
        }
    },
]

const router = new VueRouter({
    routes
})

export default router