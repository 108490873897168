<!-- 注销账号 -->
<template>
    <div id="Cancelaccount">
        <div class="content">
            
            <div class="content-bottom">
                <div class="content-bottom-title">
                    <div class="content-top-top-left">
                        <div class="content-top-top-left-text">注销账号</div>
                        <div class="content-top-top-left-back"></div>
                    </div>
                </div>
                <div class="content-content" v-if="!!agreementtext" v-html="agreementtext.logoutContent">
                    <!-- <div class="content-title">注销前，请确认以下内容</div>
                    <div class="content-text1">账号无任何资产</div>
                    <div class="content-text2">账号资产包括但不限于双泽翻译各种会员，收藏内容等。</div>
                    <div class="content-text1">账号将不可找回</div>
                    <div class="content-text2">注销后、账号绑定手机、邮箱、第三方等相关信息将被释放，账号内资产将无法找回。</div> -->
                </div>
                <div class="vip-button">
                    <div class="toupgrade cursor" @click="toupgrade">确认注销</div>
                </div>
                
            </div>
            <Globalmessage ref="message" :duration="3000" :top="100" :widths="1196"/>
        </div>
        <div class="height400"></div>
    </div>
</template>

<script>
import Globalmessage from '../../components/Globalmessage.vue'
export default {
    components: {
        Globalmessage
    },
  data () {
    return {
        agreementtext: JSON.parse(sessionStorage.getItem('agreements')), // 协议文本
    };
  },
    computed: {
    },
    created () {
    },
  mounted () {
    // this.$login.getUserAgreement().then((ress) => {
    //   this.agreementtext = ress.result.logoutContent
    //   // console.log(ress, "获得用户协议", that.agreementtext)
    // })
    this.$login.getUserAgreement().then((ress) => {
      this.agreementtext = ress.result
      // console.log(ress, "获得用户协议", that.agreementtext)
    })
  },
  methods: {
    //   确认注销
    toupgrade () {
        this.$confirm('您确定注销当前账号?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {

            this.$userdata.userLogout().then((res) => {
                // console.log(res,'账号注销')
                if (res.code==200) {
                    this.setSessionItem('userdata', null)
                    sessionStorage.clear()
                    this.$router.push('/')
                }
             })

        }).catch(() => {        
        });
        
    },
  }
};
</script>

<style lang="scss" scoped>
#Cancelaccount {
    .height400{
        height: 400px;
    }
    .vip-button{
        margin: 40px 0 0 24px;
        width: 704px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .toupgrade{
            width: 335px;
            height: 50px;
            background: #3D5CFF;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            
        }
    }
    .nocursor{
        cursor:not-allowed;
    }
    .content{
        width:1196px;
        margin:  0 0 0 24px;
        .content-bottom{
            width: 1196px;
            // height: 550px;
            background: #FFFFFF;
            box-shadow: 2px 0px 16px 0px rgba(0,21,41,0.08);
            border-radius: 14px;
            // margin: 24px 0 0 0;
            padding: 0 0 24px 0;
            .content-content{
                width: 1068px;
                min-height: 223px;
                margin: 53px 0px 0 24px;
            
                .content-title{
                    font-size: 19px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #2E3E5C;
                }
                .content-text1{
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #2E3E5C;
                    letter-spacing: 1px;
                    margin: 41px  0 0 0;
                }
                .content-text2{
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #858597;
                    margin: 8px 0 13px 0;
                }

            }
            .content-bottom-title{
                width: 80px;
                height: 25px;
                padding: 20px 0 0 0px;
                
            }
            .title-botttom{
                width: 100px;
                display: flex;
                align-items: center;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #8189B0;
                margin: 6px 0 0 31px;
                img{
                    width: 22px;
                    height: 22px;
                }
            }
            
        }
        .content-top-top-left{
            width: 80px;
            height: 25px;
            display: flex;
            align-items: flex-end;
            position: relative;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2F3236;
            margin: 0 0 0 24px;
            &-text {
            margin-left: 8px;
            }
            .content-top-top-left-back {
                width: 26px;
                height: 8px;
                background: #3d5cff;
                opacity: 0.3;
                position: absolute;
                left: 0;
                bottom: 2px;
            }
        }
        
    }
}
</style>