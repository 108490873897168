<template>
  <div id="popupCollect">
    <el-dialog
      title=""
      width="480px"
      :visible.sync="status"
      :before-close="Closecollect"
    >
      <div class="popup">
        <p class="popup__title flex justify">收藏</p>
        <div class="popup__line flex align between">
          <p class="title">选择收藏夹</p>
          <div class="flex align cursor" @click="newClick">
            <img src="../../../assets/icon/icon_add.png" class="img" alt="" />
            <p class="text">新建文件夹</p>
          </div>
        </div>
        <div class="popup__row">
          <div
            v-for="(item, key) in collectList"
            :key="key"
            class="popup__row__content flex align between cursor"
            @click="collertClick(item)"
          >
            <div class="title flex align">
              <p class="text">{{ item.name }}</p>
              <p class="default flex align justify" v-show="item.collectDefault==0">默认</p>
            </div>
            <img
              src="../../../assets/icon/nochose.png"
              class="img"
              v-show="item.id!=selectcollert"
            />
            <img
              src="../../../assets/icon/chose2.png"
              class="img"
              v-show="item.id==selectcollert"
            />
          </div>
        </div>
        <!-- 按钮 -->
        <div class="popup__button flex justify">
          <p class="text flex align justify cursor" @click="sureselect">确定</p>
        </div>
      </div>
    </el-dialog>
    <!-- 新建文件夹 -->
    <popupNewadd :status="popupNewaddStatus" @newCloses="newCloses"  />
  </div>
</template>

<script>
import popupNewadd from "./popupNewadd.vue";

export default {
  components: {
    popupNewadd
  },
  props: {
    status: {
      // 自动关闭的延时
      type: Boolean,
      default: false,
    },
    wordsid: {
        type: String,
        default: ''
    },
  },
  data() {
    return {
      popupNewaddStatus: false, // 新建文件夹弹窗
      collectList: [
      ],
      selectcollert: '', // 选中的收藏夹的id
    };
  },
  watch:{
        wordsid: {  
            handler: function (val) {
                // console.log(val,"收藏单词id")
                
            },
            immediate: true,
            deep: true
        },
        status: {  
            handler: function (val) {
              if (val==true) {
                this.getWebCollect()
              }
                
            },
            immediate: true,
            deep: true
        }
  },
  mounted() {
    this.getWebCollect()
  },
  methods: {
    // 新建文件夹
    newClick() {
      this.popupNewaddStatus = true;
      // this.handleClose();
    },
    // 关闭新建文件夹弹窗
    newCloses () {
      this.getWebCollect()
      this.popupNewaddStatus = false;
    },
    // 获得收藏夹
    getWebCollect () {
      this.$apis.getWebCollect().then((res) => {
        if (res.code==200) {
          this.collectList = res.result
          for(let i = 0;i<this.collectList.length;i++){
            if (this.collectList[i].collectDefault==0) {
              this.selectcollert = this.collectList[i].id
            }
          }
        }
        
        // console.log(res,"获得收藏夹")
      })
    },
    // 选择
    collertClick(e) {
        // console.log(e)
        this.selectcollert = e.id
    },
    // 确定收藏
    sureselect () {
      let params = new FormData
      params.append('collectId', this.selectcollert)
      params.append('thesaurusId', this.wordsid)
      this.$apis.addCollectWord(params).then((res) => {
        if (res.code==200) {
          this.$message({ message: "收藏成功", type: "success" });
          this.Closecollect()
        }
        
        // console.log(res,"收藏单词")
      })
      
    },
    //   关闭弹窗
    Closecollect () {
        
        this.$emit("Closecollect",false)
    },
  },
};
</script>

<style lang="scss">
#popupCollect {
  .el-dialog {
    border-radius: 12px;
  }
  .popup {
    width: 100%;
    &__button {
      width: 100%;
      margin-top: 43px;
      .text {
        width: 400px;
        height: 50px;
        color: #fff;
        font-size: 16px;
        border-radius: 12px;
        background: #3d5cff;
      }
    }
    &__row {
      margin-left: 16px;
      &__content {
        margin-top: 26px;
        .title {
          .text {
            color: #1f1f39;
            font-size: 15px;
          }
          .default {
            width: 34px;
            height: 22px;
            margin-left: 12px;
            background: #f1f1f1;
            border-radius: 6px;
            font-size: 12px;
          }
        }
        .img {
          width: 32px;
          height: 32px;
        }
      }
    }
    &__line {
      margin-top: 22px;
      .title {
        font-size: 18px;
        color: #2e3e5c;
      }
      .img {
        width: 22px;
        height: 22px;
      }
      .text {
        font-size: 13px;
        color: #2e3e5c;
      }
    }
    &__title {
      width: 100%;
      margin-top: -42px;
      font-size: 18px;
      color: #2e3e5c;
    }
  }
}
</style>