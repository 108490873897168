<!-- 个人中心-查询记录 -->
<template>
    <div id="Querylog">
        <div class="content">
            
            <div class="content-bottom">
                <div class="content-bottom-title">
                    <div class="content-top-top-left">
                        <div class="content-top-top-left-text">查询记录</div>
                        <div class="content-top-top-left-back"></div>
                    </div>
                    
                </div>
                <div class="content-content">
                    <div v-show="tabledata.length!=0" class="content-left" v-loading="leftload">
                        <div v-for="item in tabledata" :class="item.id==selectdata?'isselect    ':'noselect cursor'" @click="selectone(item)">
                            <div class="select-text">
                                <p>{{item.name1}}</p>
                                <span>{{item.name2}}{{item.name3}}</span>
                            </div>
                            <img v-show="item.id==selectdata" src="../../assets/icon/icon_more3.png"/>
                        </div>
                    </div>
                    
                    <div v-show="tabledata.length!=0" class="content-right" >
                        <div class="words-right" v-loading="rightload">
                            <div class="kong"></div>
                            <div class="words-right-content" v-show="selectdata!=''">
                                <p>{{righttitle.name1}}</p>
                                <span>{{righttitle.name3}}</span>
                            </div>
                            <div class="words-right-bottom">
                            <div class="words-right-bottom-left cursor" v-show="selectdata!=''" @click="showerrordialogs">
                                <img src="../../assets/icon/icon_fankui.png">
                                纠错
                            </div>
                            <div class="words-right-bottom-right" v-show="selectdata!=''">
                                <img class="words-right-bottom-right-img1 cursor" @click="copyClick(righttitle.name3)" src="../../assets/icon/icon_copy.png">
                                <img v-show="iscollect==1" @click="collect" class="words-right-bottom-right-img2 cursor" src="../../assets/icon/icon_yishoucang.png">
                                <img v-show="iscollect==0" @click="collect" class="words-right-bottom-right-img2 cursor" src="../../assets/icon/icon_shoucang.png">
                                <span v-show="iscollect==1" @click="collect" class="iscollect cursor">已收藏</span>
                                <span v-show="iscollect==0" @click="collect" class="iscollect cursor">收藏</span>

                            </div>
                            </div>
                        </div>
                        <div class="content-right-bottom" v-loading="rightload">
                            <div class="content-bottom-title">
                                <div class="content-top-top-left">
                                    <div class="content-top-top-left-text">释义</div>
                                    <div class="content-top-top-left-back"></div>
                                </div>
                                
                            </div>
                            <div class="right-bottom-content">
                                <!-- <div class="text-top">
                                    <span class="subject">【化学】</span>
                                    <p class="wordsname">量子</p><span class="wordsname">量子</span>
                                </div> -->
                                <div class="text-bottom" v-for="(item,key) in righttext">
                                    <span class="subject">{{item.name1}}</span>
                                    
                                    <p class="texts"> {{item.name2}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div v-show="tabledata.length==0" class="history-none">
                    <img src="../../assets/image/img_none.png"/>
                </div>
                
                
            </div>
        </div>
        <Globalmessage ref="message" :duration="3000" :top="100" :widths="1000"/>
        <!-- 收藏弹窗 -->
        <popupCollect :status="popupCollectStatus" :wordsid="selectdata" @Closecollect="Closecollect"/>
        <!-- 纠错弹窗 -->
      <Errorcorrection :dialogVisible='showerrordialog' :wordsid="selectdata" :words="righttitle.name1" :translationContent="righttitle.name3" :wordtype="wordtype" @closedialog='closedialog' />
    </div>
</template>

<script>
import Globalmessage from '../../components/Globalmessage.vue'
import Errorcorrection from '../components/Errorcorrection.vue'
import popupCollect from "../components/popupCollect.vue";
export default {
    components: {
        Globalmessage,
        Errorcorrection,
        popupCollect
    },
  data () {
    return {
        wordtype: '1', // 纠错类型
        popupCollectStatus: false, // 收藏弹窗
        leftload: false, // 左侧的加载
        rightload: false, // 右侧的加载
        showerrordialog: false, // 纠错弹窗
        iscollect: '0', // 是否收藏
        selectdata: '', // 选中的数据
        // table的数据
        tabledata: [
        ], 
        righttitle: {}, // 右侧上面的数据
        righttext: [], // 右侧的下面的释义数据
        textnumber: '123',
        filenumber: '0',
        // 分页
        totalPage: 7, // 统共页数，默认为1
        currentPage: 1, // 当前页数 ，默认为1
        pageSize: 4, // 每页显示数量
    };
  },
    computed: {
    },
  mounted () {
      this.getdata()
  },
  methods: {
       // 复制
    copyClick(val) {
      let url = val;
      //新建一个文本框
      let oInput = document.createElement('input');
      //赋值给文本框
      oInput.value = url;
      document.body.appendChild(oInput);
      // 选择对象;
      oInput.select(); 
      // 执行浏览器复制命令
      document.execCommand("Copy"); 
      //复制完成删除掉输入框
      oInput.remove()
      // this.$refs.message.success('复制成功') // success调用
      // console.log('复制成功')
      // const save = function (e) {
      //   e.clipboardData.setData("text/plain", '我是复制的内容');
      //   e.preventDefault(); // 阻止默认行为
      // };
      // document.addEventListener("copy", save); // 添加一个copy事件
      // document.execCommand("copy"); // 执行copy方法
      this.$message({ message: "复制成功", type: "success" });
    },
      // 关闭收藏夹
    Closecollect () {
      this.popupCollectStatus = false;
      this.ifUserCollect(this.selectdata)
    },
    //   获得数据
    getdata () {
        this.leftload = true
        let params = new FormData
        params.append('pageNo',1)
        params.append('pageSize',9999)
        this.$apis.getSearchRecordList(params).then((res) => {
            // console.log(res,"获得数据")
            if (res.code == 200) {
                this.tabledata = res.result
                if (this.tabledata.length!=0) {
                    this.selectdata = this.tabledata[0].id
                    this.righttitle = this.tabledata[0]
                    this.getrighttext(this.selectdata)
                    this.ifUserCollect(this.selectdata)
                }
                this.leftload = false
            }
        })
    },
    // 获得右边释义
    getrighttext (val) {
      let params = new FormData
      params.append('id', val)
      this.$apis.appThreeSearch(params).then((res) => {
        // console.log(res,"右侧释义")
        if (res.code==200) {
          this.righttext = res.result
        }
        
      })
    },
    // 判断是否收藏
    ifUserCollect (val) {
      let params = new FormData
      params.append('id', val)
      this.$apis.ifUserCollect(params).then((res) => {
        if (res.code==200) {
            this.iscollect = res.result.mark
        //   this.collectStatus = res.result.mark
        }
        
        // console.log(res,"是否收藏")
      })
    },
    // 取消收藏
    cancelCollectWord() {
      let params = new FormData
      params.append('collectWordId', this.selectdata)
      this.$apis.cancelCollectWord(params).then((res) => {
        if (res.code==200) {
          this.ifUserCollect(this.selectdata)
        }
        
        // console.log(res,"取消收藏")
      })
    },
      // 显示纠错
    showerrordialogs () {
      this.showerrordialog = true
    },
    // 关闭纠错弹窗
    closedialog () {
    //   console.log('关闭纠错弹窗')
      this.showerrordialog = false
    },
      // 收藏
    collect () {
      if (this.iscollect == 0) {
          this.popupCollectStatus = true;
        return;
        // this.iscollect = 1
      }
      if (this.iscollect == 1) {
          this.cancelCollectWord()
      }
        // this.iscollect = 0
    },
    // 选中那条数据
      selectone (id) {
          if (this.selectdata == id.id) {
              return false
          }
          this.selectdata = id.id
          this.righttitle = id
          this.getrighttext(this.selectdata)
          this.ifUserCollect(this.selectdata)
        //   console.log(id)
      },
    
    
  }
};
</script>
<style lang="scss">
</style>
<style lang="scss" scoped>
#Querylog {
    .kong{
        height: 20px;
    }
    .isselect{
        width: 522px;
        height: 85px;
        background: linear-gradient(90deg, #EEF1F9 0%, #F8FCFF 98%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        direction: ltr;
        img{
            width: 28px;
            height: 29px;
            margin:  0 20px 0 0 ;
        }
        
    }
    .select-text{
        width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin:  0 0 0 30px;
        
        p{
            font-size: 17px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
        }
        span{
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #8C8C8C;
            margin: 8px 0 0 0;
        }
    }
    .noselect{
        width: 522px;
        height: 85px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        direction: ltr;
        img{
            width: 28px;
            height: 29px;
            margin:  0 20px 0 0 ;
        }
    }
    .history-none {
        display: flex;
        align-content: center;
        justify-content: center;
        margin: 177px auto;
        img {
        width: 100px;
        height: 100px;
        }
    }
    .nocursor{
        cursor:not-allowed;
    }
    
    .content{
        width:1196px;
        margin:  0 0 0 24px;
        .content-bottom{
            width: 1196px;
            min-height: 550px;
            background: #FFFFFF;
            box-shadow: 2px 0px 16px 0px rgba(0,21,41,0.08);
            border-radius: 17px;
            // margin: 24px 0 0 0;
            .content-content{
                width: 1148px;
                display: flex;

                .content-right{
                    width: 602px;
                    // margin: 30px 0 0 0;
                    .content-right-bottom{
                        width: 602px;
                        height: 164px;
                        background: #FFFFFF;
                        box-shadow: 0px 0px 12px 2px rgba(184,184,210,0.2);
                        border-radius: 14px;
                        margin: 16px 0 0 24px;
                        .right-bottom-content{
                            width: 542px;
                            .text-top{
                                width: 542px;
                                display: flex;
                                margin: 18px 0 0 30px;
                            }
                            .text-bottom{
                                width: 542px;
                                display: flex;
                                margin: 14px 0 0 30px;
                            }
                            .subject{
                                font-size: 16px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #8C8C8C;
                                white-space:nowrap
                            }
                            .wordsname{
                                font-size: 16px;
                                font-family: PingFangSC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #1F1F1F;
                            }
                            .texts{
                                font-size: 14px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #5E5F60;
                            }

                        }
                        .content-bottom-title{
                            width: 80px;
                            height: 25px;
                            padding: 18px 0 0 0px;
                        }
                        .content-top-top-left{
                            width: 80px;
                            height: 25px;
                            display: flex;
                            align-items: flex-end;
                            position: relative;
                            font-size: 18px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #2F3236;
                            margin: 0 0 0 20px;
                            &-text {
                            margin-left: 8px;
                            }
                            .content-top-top-left-back {
                                width: 26px;
                                height: 8px;
                                background: #3d5cff;
                                opacity: 0.3;
                                position: absolute;
                                left: 0;
                                bottom: 2px;
                            }
                        }
                    }
                    .words-right {
                        position:relative;
                        // font-family: PingFangSC-Medium, PingFang SC;
                        // color: #8189B0;
                        // padding: 18px 0 0 20px;
                        width: 602px;
                        height: 101px;
                        border-radius: 14px;
                        opacity: 0.9;
                        border: 0;
                        resize: none;
                        background: linear-gradient(90deg, #EEF1F9 0%, #F8FCFF 98%);
                        font-size: 24px;
                        padding-bottom: 40px;
                        margin: 30px 0 0 24px;
                    }
                        .words-right-bottom {
                            position:absolute;
                            bottom: 10px;
                            width: 576px;
                            height: 30px;
                            margin: 0px 10px 0 16px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .words-right-bottom-left{
                                font-size: 11px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #A3A6AB;
                                display: flex;
                                align-items: center;
                                img{
                                width: 16px;
                                height: 16px;
                                margin: 0 4px 0 0 ;
                                }
                            }
                            .words-right-bottom-right{

                                font-size: 12px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #50555E;
                                display: flex;
                                align-items: center;
                                .words-right-bottom-right-img1{
                                    width: 22px;
                                    height: 22px;
                                    margin: 0 18px 0 0 ;
                                }
                                .words-right-bottom-right-img2{
                                    width: 22px;
                                    height: 22px;
                                    margin: 0 4px 0 0 ;
                                }
                                .iscollect{
                                    width: 36px;
                                    display: flex;
                                    // justify-content: flex-end;
                                }
                            }
                        }
                    .words-right-content {
                        width: 554px;
                        // height: 109px;
                        margin: 0px 0 0 20px;
                        overflow: auto;
                        // text-overflow:ellipsis;
                        // white-space: pre-warp;
                        word-wrap: break-word;
                        p{
                            font-size: 20px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #1F1F1F;
                        }
                        span{
                            font-size: 24px;
                            font-family: Helvetica;
                            color: #1F1F1F;
                        }
                    }
                }
                .content-left{
                    width: 522px;
                    height: 877px;
                    background: #F7F8FC;
                    border-radius: 14px;
                    margin: 30px 0 24px 24px;
                    overflow-y:auto;
                    overflow-x: hidden;
                    direction: rtl;
                }
                // 定义滚动条的轨道颜色，内阴影及圆角
                .content-left::-webkit-scrollbar-track{
                    // background-color:#F5F5F5;
                    // -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3);
                    // border-radius:10px;
                }
                .content-left::-webkit-scrollbar{
                    width:6px;
                    height:200px;
                    background-color:rgba(0,0,0,0);
                }
                /*定义滑块颜色、内阴影及圆角*/
                .content-left::-webkit-scrollbar-thumb {
                    // -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);
                    background: #D8D8D8;
                    border-radius: 3px;
                }
            }
            
            
            .content-bottom-title{
                width: 80px;
                height: 25px;
                padding: 23px 0 0 0px;
            }
            
        }
        .content-top-top-left{
            width: 80px;
            height: 25px;
            display: flex;
            align-items: flex-end;
            position: relative;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2F3236;
            margin: 0 0 0 24px;
            &-text {
            margin-left: 8px;
            }
            .content-top-top-left-back {
                width: 26px;
                height: 8px;
                background: #3d5cff;
                opacity: 0.3;
                position: absolute;
                left: 0;
                bottom: 2px;
            }
        }
        
    }
}
</style>