<template>
  <div id="todayLearn">
    <div class="body">
      <!-- nav -->
      <p class="body__title">
        <span @click="backhome" class="cursor">首页</span> / 今日学习
      </p>
      <!-- title -->
      <div class="flex start">
        <div class="title">
          <p>今日学习</p>
          <div class="title__shadow"></div>
        </div>
        <div class="mark flex align cursor" @click="skip('Allplan')">
          <p class="text">全部学习计划</p>
          <img src="../../assets/icon/icon_more2.png" class="img" alt="" />
        </div>
      </div>
      <!-- content -->
      <div class="body__content flex" v-loading="topload">
        <!-- list -->
        <div class="body__content__list flex align between">
          <!-- add -->
          <div class="roll flex align">
            <div class="row" :class="item.id == studyItem.id?'on-select':''" v-for="(item, key) in listdata" :key="key" @click="updateToStudy(item,key)">
              <div class="delicon">
                <img
                  src="../../assets/icon/icon_del.png"
                  class="cursor"
                  alt=""
                  @click.stop="delStudyPlan(item)"
                />
              </div>
              <div class="row-subject">
                <div :style="item.studyState == 1 ? 'margin-left: 60px' : ''">
                  {{ item.name }}
                </div>
                <div class="row-subject-time">
                  <!-- {{ item.actualDay }}/{{ item.planDay }}天 -->
                  已学{{ item.studyWord }}/{{ item.allWord }}
                </div>
              </div>
              <div class="row-line">
                <div class="row-line-detail">
                  <div class="row-line-detail-title">{{ item.firstName }}</div>
                  <div class="row-line-detail-num">
                    今日待学习<span class="row-line-detail-num-text">{{item.quantity}}</span>词
                    <!-- <span class="row-line-detail-num-text">{{
                      item.studyWord
                    }}</span>
                    /{{ item.planWord }}个 -->
                  </div>
                </div>
                <div
                  class="row-content-plan cursor"
                  v-show="item.studyState != 1"
                >
                <!-- @click="updateToStudy(item)" -->
                <!-- :style="item.studyState == 4 ? '-webkit-filter: grayscale(100%);' : ''" -->

                  {{ studyList[item.studyState]
                  }}<i
                    class="el-icon-arrow-right"
                    v-show="item.studyState != 4"
                  ></i>
                </div>
              </div>
              <!-- 进度条 -->
              <div class="row-schedule">
                <div
                  class="row-schedule-achieve"
                  :style="
                    'width: ' + (item.studyWord / item.planWord) * 100 + '%'
                  "
                ></div>
              </div>
              <div class="row-present" v-show="item.studyState == 1">
                当前在学
              </div>
            </div>
          </div>
          <div
            class="body__content__add flex column align justify cursor"
            @click.stop="tonew"
          >
            <img src="../../assets/icon/add.png" class="img" alt="" />
            <p class="text">新建计划</p>
          </div>
        </div>
      </div>
      <!-- word -->
      <div v-loading="bottomload" v-show="!isnull">
        <div class="body__word" v-if="!!learndatalist[learndata.subscript]">
          <p>{{ learndatalist[learndata.subscript].chinese }}</p>
          <p class="translate">
            {{ learndatalist[learndata.subscript].english }}
          </p>
          <div class="line flex between">
            <div
              class="label1 flex align cursor"
              @click="showerrordialog = true"
            >
              <img src="../../assets/icon/icon_fankui.png" class="img" alt="" />
              <p>纠错</p>
            </div>
            <div
              class="label2 flex align cursor"
              @click="
                collectClick(learndatalist[learndata.subscript].isCollect)
              "
            >
              <img
                src="../../assets/icon/icon_yishoucang.png"
                class="img"
                alt=""
                v-show="learndatalist[learndata.subscript].isCollect == 1"
              />
              <img
                src="../../assets/icon/icon_shoucang.png"
                class="img"
                alt=""
                v-show="learndatalist[learndata.subscript].isCollect == 0"
              />
              <p v-show="learndatalist[learndata.subscript].isCollect == 1">
                已收藏
              </p>
              <p v-show="learndatalist[learndata.subscript].isCollect == 0">
                收藏
              </p>
            </div>
          </div>
          <div class="rightbut">
            <!-- <div class="delbut cursor" @click="delStudyPlan">删除计划</div> -->
            <!-- <div
              class="editbut cursor"
              v-show="this.learndata.subscript < this.learndatalist.length - 1"
              @click="editStudyPlan"
            >
              修改计划
            </div> -->
            <div
              class="editbut cursor"
              @click="editStudyPlan"
            >
              修改计划
            </div>
          </div>
        </div>
        <!-- paraphrase -->
        <div
          class="body__paraphrase"
          v-if="!!learndatalist[learndata.subscript]"
        >
          <div class="titles">
            <p>释义</p>
            <div class="titles__shadow"></div>
          </div>
          <div
            class="body__paraphrase__row flex align"
            v-for="(item, key) in learndatalist[learndata.subscript].paraphrases"
            :key="key"
          >
            <p class="tag">{{ item.name1 }}</p>
            <p class="text">{{ item.name2 }}</p>
          </div>
        </div>
        <!-- button -->
        <div class="body__button flex align between">
          <div class="top flex align justify cursor" @click="upwords">
            上一个
          </div>
          <div
            class="bottom flex align justify cursor"
            v-show="this.learndata.subscript < this.learndatalist.length - 1"
            @click="nextwords(learndatalist[learndata.subscript + 1])"
            v-loading.fullscreen.lock="fullscreenLoading"
          >
            下一个
          </div>
          <div
            class="bottom flex align justify cursor"
            v-show="this.learndata.subscript == this.learndatalist.length - 1"
          >
            当前学习计划已完成
          </div>
        </div>
      </div>
      <div class="nodata" v-show="isnull">
        暂无学习单词
        <div class="rightbut">
          <!-- <div class="delbut cursor" @click="delStudyPlan">删除计划</div> -->
          <!-- <div class="editbut cursor" v-show="updata!=null" @click="editStudyPlan">修改计划</div> -->
        </div>
      </div>
    </div>
    <!-- 收藏弹窗 -->
    <popupCollect
      :status="popupCollectStatus"
      :wordsid="wordsid"
      @Closecollect="Closecollect"
    />
    <!-- 纠正弹窗 -->
    <Errorcorrection
      :wordsid="wordsid"
      :wordtype="wordtype"
      v-if="!!learndatalist[learndata.subscript]"
      :translationContent="learndatalist[learndata.subscript].english"
      :words="learndatalist[learndata.subscript].chinese"
      :dialogVisible="showerrordialog"
      @closedialog="closedialog"
    />
    <!-- 制定学习计划弹窗 -->
    <el-dialog
      title=""
      width="410px"
      :visible.sync="popupStatus"
      :before-close="handleClose"
    >
      <div class="popup flex column align">
        <!-- title -->
        <p class="popup__title">修改学习计划</p>
        <!-- tag -->
        <div class="popup__special flex align">
          <p class="popup__special__tag">{{ updata.firstName }}</p>
          <p class="popup__special__title">{{ updata.name }}</p>
          <p class="popup__special__num">({{ updata.allWord }})</p>
        </div>
        <!-- content -->
        <div class="popup__content flex column align">
          <p class="popup__content__day">预计剩余完成时间 {{ howtime }} 天</p>
          <p class="popup__content__hint">请输入每天学习单词量</p>
          <div class="popup__content__num flex align">
            <input
              type="text"
              onkeyup="this.value=this.value.replace(/\D|^0/g,'')"
              v-on:input="howtimes"
              v-model="studyNum"
            />
            <p>词</p>
          </div>
        </div>
        <!-- button -->
        <div class="popup__button flex justify">
          <p
            class="popup__button__confirm flex align justify cursor"
            @click="planconfirmClick()"
          >
            确定
          </p>
        </div>
      </div>
    </el-dialog>
    <!-- 删除学习计划 -->
    <el-dialog
      title=""
      width="410px"
      top="300px"
      :visible.sync="isshowdel"
      :before-close="delclos"
    >
      <div class="popup flex column align">
        <!-- title -->
        <p class="popup__title">删除学习计划</p>
        <!-- tag -->
        <!-- content -->
        <div slot="title" class="dialog1-top-title"></div>
        <div class="dialog1-content">确定要删除学习计划吗？</div>
        <div class="foot2">
          <el-button class="but2" @click="delclos">取消</el-button>
          <el-button class="but" @click="suredel">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Errorcorrection from "../components/Errorcorrection.vue";
import popupCollect from "../components/popupCollect.vue";
export default {
  components: {
    Errorcorrection,
    popupCollect,
  },
  data() {
    return {
      fullscreenLoading: false, // 全页加载
      deldata: {}, // 要删除的数据
      isshowdel: false, // 是否显示删除
      updata: {}, // 要修改的数据
      howtime: "0", // 需要花费的天数
      studyNum: "0", // 输入每天学习的单词俩
      popupStatus: false, // 制定学习计划弹窗
      isnull: false, // 当前是否没有学习
      wordtype: "3", // 纠错类型
      popupCollectStatus: false, // 收藏弹窗
      wordsid: "", // 收藏单词id
      textend: "", // 右侧翻译结果
      searchValue: "", // 翻译的词语
      listdata: [], // 学习计划列表
      studyIndex:0,
      studyItem:{},
      topload: false, // 上面的加载
      learndata: {}, // 正在学的计划
      learndatalist: [], // 正在学的词汇
      bottomload: false, // 下面的加载

      collectStatus: false, // 是否收藏
      showerrordialog: false, // 纠错弹窗
      studyList: ["没有学习计划", "当前在学", "未学习", "继续学习", "已完成"],
    };
  },
  mounted() {
    let querys = this.$route.query.item
    let studyIndex = this.$route.query.studyIndex
    if(studyIndex){
      this.studyIndex = studyIndex
    }
    console.log('querys',querys)
    if (querys==undefined) {
      this.studyItem.id = ''
    }else{
      this.studyItem = querys;

    }
    // studyState 0没有学习计划 1当前在学 2未学习 3继续学习 4已完成 5重新学习
    if(querys && querys.studyState == 4){
      this.getStudyPlanList(querys);
    }else{
      this.getStudyPlanList();
    }
  },
  methods: {
    // 上一个
    upwords() {
      if (this.learndata.subscript == 0) {
        return false;
      }
      this.learndata.subscript = this.learndata.subscript - 1;
      this.wordsid = this.learndatalist[this.learndata.subscript].id;
      // console.log(this.learndata.subscript)
    },
    // 计算多长时间
    howtimes() {
      // console.log(this.studyNum)
      if (this.studyNum == "") {
        this.howtime = "1";
        return false;
      }
      this.howtime = Math.ceil(this.updata.allWord / this.studyNum);
      if (this.howtime == "NaN") {
        this.howtime = "1";
      }
    },
    // 学习计划弹窗确定
    planconfirmClick() {
      if (this.studyNum == 0 || this.studyNum == "") {
        return false;
      }
      let params = new FormData();
      params.append("planId", this.updata.id);
      params.append("planDay", this.howtime);
      params.append("planWord", this.studyNum);
      this.$apis.editStudyPlan(params).then((res) => {
        // console.log(res,"修改成功")
        if (res.code == 200) {
          this.$message({ message: "修改成功", type: "success" });
          this.getStudyPlanList();
          this.handleClose();
        }
      });
    },
    // 关闭学习计划弹窗
    handleClose() {
      this.popupStatus = false;
      // this.howtime = '0' // 需要花费的天数
      // this.studyNum = '0' // 输入每天学习的单词俩
    },
    // 删除
    delStudyPlan(val) {
      this.isshowdel = true;
      // console.log(val)
      this.deldata = val;
    },
    // 关闭删除弹窗
    delclos() {
      this.isshowdel = false;
      this.deldata = {};
    },
    // 确定删除
    suredel() {
      let params = new FormData();
      params.append("planId", this.deldata.id);
      this.$apis.delStudyPlan(params).then((res) => {
        // console.log(res,"删除成功")
        if (res.code == 200) {
          this.$message({ message: "删除成功", type: "success" });
          this.delclos();
          this.getStudyPlanList();
        }
      });
    },
    // 打开修改弹窗
    editStudyPlan() {
      // console.log(this.updata)
      this.howtime = this.updata.planDay;
      this.studyNum = this.updata.planWord;
      this.popupStatus = true;
    },
    // 去学习
    updateToStudy(val,key) {
      // console.log(val,"去学习")
      this.studyIndex = key;
      this.studyItem1 = val;
      if (val.studyState == "4") {
        // this.$message({
        //   message: "已完成的计划不能进入",
        //   type: "warning",
        // });
        this.getStudyPlanList(val);

        return false;
      }
      let params = new FormData();
      params.append("id", val.id);
      this.$apis.updateToStudy(params).then((res) => {
        // console.log(res,"去学习")
        if (res.code == 200) {
          this.getStudyPlanList();
        }
      });
    },
    // 下个单词
    nextwords(val) {
      // console.log(val,"要学习的单词")
      this.fullscreenLoading = true
      let params = new FormData();
      params.append("spWordId", val.spWordId);
      this.$apis.studyPlanWord(params).then((res) => {
        // console.log(res,"刚学习的单词")
        if (res.code == 200) {
          
          // this.getStudyPlanList()
          this.getStudyPlanListfirst();
          this.learndata.subscript = this.learndata.subscript + 1;
          if (this.learndata.subscript >= this.learndatalist.length - 1) {
            this.learndata.subscript = this.learndatalist.length - 1;
          }
          this.wordsid = this.learndatalist[this.learndata.subscript].id;
          this.fullscreenLoading = false
          if (this.learndata.subscript >= this.learndatalist.length - 1) {
            this.$message({
              message: "今日此计划已学习完成",
              type: "success",
            });
          }
        }
      });
      //
    },
    // 去新建计划
    tonew() {
      this.$router.push({
        path: "/specialtyNav",
        query: {
          planStatus: 1,
        },
      });
    },
    // 获得学习计划列表
    getStudyPlanList(val) {
      this.topload = true;
      this.bottomload = true;

      this.$apis.getStudyPlanList().then((res) => {
        // console.log(res,"学习计划列表")
        if (res.code == 200) {
          this.listdata = res.result;
          for (let i = 0; i < this.listdata.length; i++) {
            if (this.listdata[i].studyState == 1) {
              this.updata = this.listdata[i];
            }
          }
          this.topload = false;
          this.getStudyPlan(val);
          if(this.studyItem1){
            this.studyItem = this.studyItem1;
          }
        } else {
          this.topload = false;
        }
      });
    },
    // 正在学的计划
    getStudyPlan(val) {
      let params = {}
      if(val){
        params = new FormData();
        params.append("planId", val.id);
      }
      this.$apis.getStudyPlan(params).then((res) => {
        // console.log(res,"学习计划")
        if (res.code == 200) {
          this.learndata = res.result;
          if (this.learndata.planId == null) {
            this.bottomload = false;
            this.isnull = true;
            return false;
          }
          if (this.learndata.subscript == -1 || this.learndata.subscript == 0) {
            this.learndata.subscript = 0;
          }
          // this.learndata.subscript = this.learndata.subscript + 1
          this.learndatalist = this.learndata.words;
          if (this.learndata.subscript >= this.learndatalist.length - 1) {
            this.learndata.subscript = this.learndatalist.length - 1;
          }
          this.wordsid = this.learndatalist[this.learndata.subscript].id;
          if (this.learndata.subscript == 0) {
            this.nextwordsfirst(this.learndatalist[this.learndata.subscript]);
            // if (this.learndatalist.length!=1) {
            //   this.nextwordsfirst(this.learndatalist[this.learndata.subscript])
            // }
            // this.learndata.subscript = 0
          }
          localStorage.setItem(
            "learndatalist",
            JSON.stringify(this.learndatalist)
          );
          // this.nextwords(this.learndatalist[this.learndata.subscript])
          this.bottomload = false;
          this.isnull = false;
        } else if (res.code == 500) {
          this.bottomload = false;
          this.isnull = true;
          return false;
        }
      });
    },
    // 下个单词
    nextwordsfirst(val) {
      // console.log(val,"要学习的单词")
      let params = new FormData();
      params.append("spWordId", val.spWordId);
      this.$apis.studyPlanWord(params).then((res) => {
        // console.log(res,"刚学习的单词")
        if (res.code == 200) {
          this.getStudyPlanListfirst();
        }
      });
      //
    },
    // 获得学习计划列表
    getStudyPlanListfirst() {
      this.topload = true;
      // this.bottomload = true

      this.$apis.getStudyPlanList().then((res) => {
        // console.log(res,"学习计划列表")
        if (res.code == 200) {
          this.listdata = res.result;
          for (let i = 0; i < this.listdata.length; i++) {
            if (this.listdata[i].studyState == 1) {
              this.updata = this.listdata[i];
            }
          }
          
          this.topload = false;
        }
      });
    },
    // 关闭收藏夹
    Closecollect() {
      this.bottomload = true;
      this.getStudyPlans();
      this.popupCollectStatus = false;
    },
    // 取消收藏
    cancelCollectWord() {
      let params = new FormData();
      params.append("collectWordId", this.wordsid);
      this.$apis.cancelCollectWord(params).then((res) => {
        if (res.code == 200) {
          this.bottomload = true;
          for(let i = 0; i<this.learndatalist.length; i++){
            if (this.learndatalist[i].id == this.wordsid) {
              this.learndatalist[i].isCollect = 0
            }
          }
          localStorage.setItem(
            "learndatalist",
            JSON.stringify(this.learndatalist)
          );
          this.getStudyPlans();
        }

        // console.log(res,"取消收藏")
      });
    },
    // 正在学的计划
    getStudyPlans() {
      this.$apis.getStudyPlan().then((res) => {
        // console.log(res,"学习计划")
        if (res.code == 200) {
          // this.learndata = res.result
          if (this.learndata.planId == null) {
            this.bottomload = false;
            this.isnull = true;
            return false;
          }
          // if (this.learndata.subscript == -1) {
          //   this.learndata.subscript = 0
          // }
          // console.log(this.learndata.subscript)
          if (res.result.words==null) {
            this.learndatalist = JSON.parse(localStorage.getItem("learndatalist"))
          }else{
            this.learndatalist = res.result.words;

          }
          this.wordsid = this.learndatalist[this.learndata.subscript].id;
          // if (this.learndata.subscript == 0) {
          //   if (this.learndatalist.length!=1) {
          //     this.nextwordsfirst(this.learndatalist[this.learndata.subscript])
          //   }
          //   // this.learndata.subscript = 0
          // }
          // this.nextwords(this.learndatalist[this.learndata.subscript])
          this.bottomload = false;
          this.isnull = false;
        } else if (res.code == 500) {
          this.bottomload = false;
          this.isnull = true;
          return false;
        }
      });
    },
    // 收藏点击
    collectClick(val) {
      if (val == 0) {
        this.popupCollectStatus = true;
        // this.collectStatus = true
        for(let i = 0; i<this.learndatalist.length; i++){
          if (this.learndatalist[i].id == this.wordsid) {
            this.learndatalist[i].isCollect = 1
          }
        }
        localStorage.setItem(
          "learndatalist",
          JSON.stringify(this.learndatalist)
        );
        return;
      }
      if (val == 1) {
        this.cancelCollectWord();
      }
      this.collectStatus = false;
    },
    // 关闭纠错弹窗
    closedialog() {
      this.showerrordialog = false;
    },
    skip(e) {
      this.$router.push(e);
    },
    backhome() {
      this.$router.push("/");
    },
  },
};
</script>
<style lang="scss" scoped>
#todayLearn {
  .delicon {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 45px;
    height: 30px;
    img {
      width: 30px;
      height: 30px;
    }
  }
  .dialog1-content {
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #5e5f60;
    margin: 30px auto 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    .QRcode {
      width: 400px;
      height: 400px;
    }
  }
  .foot2 {
    width: 335px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    .but {
      width: 211px;
      height: 50px;
      background: #3d5cff;
      border-radius: 12px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      border: none;
    }
    .but2 {
      width: 110px;
      height: 50px;
      background: rgba(61, 92, 255, 0.1);
      border-radius: 12px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6975af;
      border: none;
    }
  }
  .nodata {
    width: 562px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    position: relative;
    margin-top: 34px;
  }
  .rightbut {
    position: absolute;
    right: -120px;
    top: 0px;
    .delbut {
      width: 100px;
      height: 30px;
      background: #3d5cff;
      border-radius: 6px;
      color: #fff;
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .editbut {
      width: 100px;
      height: 30px;
      background: #3d5cff;
      border-radius: 6px;
      color: #fff;
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0 0 0;
    }
  }
}
</style>
<style lang="scss" >
#todayLearn {
.el-dialog {
    border-radius: 14px;
  }
}

</style>
<style lang="scss" scoped>
#todayLearn {
  width: 100%;
  
  .popup {
    &__content {
      width: 362px;
      height: 200px;
      margin-top: 24px;
      background: linear-gradient(90deg, #eef1f9 0%, #f8fcff 98%);
      border-radius: 14px;
      &__num {
        margin-top: 13px;
        input {
          width: 100px;
          text-align: center;
          border: none;
          background: none;
          font-size: 32px;
          color: #141414;
        }
      }
      &__day {
        color: #858597;
        font-size: 13px;
        margin-top: 27px;
      }
      &__hint {
        color: #1f1f1f;
        font-size: 15px;
        margin-top: 18px;
      }
    }
    &__button {
      width: 100%;
      margin-top: 30px;
      &__confirm {
        width: 362px;
        height: 50px;
        border-radius: 12px;
        font-size: 16px;
        color: #fff;
        background: #3d5cff;
      }
    }
    &__special {
      width: 338px;
      height: 32px;
      padding: 12px 16px;
      margin-top: 32px;
      background: #ffffff;
      box-shadow: 0px 8px 12px -4px rgba(184, 184, 210, 0.2);
      border-radius: 12px;
      &__num {
        color: #909090;
        font-size: 13px;
      }
      &__title {
        font-size: 16px;
        color: #1f1f39;
        font-weight: 600;
      }
      &__tag {
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin-right: 13px;
        background: #f4f9ff;
        box-shadow: 0px 8px 12px -4px rgba(184, 184, 210, 0.2);
        border-radius: 10px;
        font-size: 20px;
        color: #8189b0;
      }
    }
    &__title {
      width: 100%;
      text-align: center;
      margin-top: -46px;
      color: #2e3e5c;
      font-size: 20px;
    }
  }
  .body {
    width: 1220px;
    margin: 0 auto;
    padding-left: 96px;
    padding-bottom: 100px;
    .on-select{
      background: #e0e6f8 !important;
      
    }
    &__button {
      width: 602px;
      margin-top: 48px;
      font-size: 14px;
      .top {
        width: 198px;
        height: 56px;
        background: #eef1f9;
        border-radius: 12px;
        color: #6975af;
      }
      .bottom {
        width: 386px;
        height: 56px;
        background: #3d5cff;
        border-radius: 12px;
        color: #fff;
      }
    }
    &__paraphrase {
      width: 564px;
      margin-top: 16px;
      padding: 18px 20px;
      background: #ffffff;
      box-shadow: 0px 0px 12px 2px rgba(184, 184, 210, 0.2);
      border-radius: 14px;
      &__row {
        margin-top: 14px;
        .tag {
          min-width: 64px;
          color: #8c8c8c;
          font-size: 14px;
        }
        .text {
          font-size: 13px;
        }
      }
      .titles {
        position: relative;
        font-size: 15px;
        margin-bottom: 18px;
        &__shadow {
          width: 26px;
          height: 8px;
          background: #3d5cff;
          opacity: 0.3;
          position: absolute;
          left: -6px;
          bottom: 2px;
        }
      }
    }
    &__word {
      position: relative;
      width: 562px;
      height: 158px;
      padding: 18px 20px 10px;
      margin-top: 34px;
      background: linear-gradient(90deg, #eef1f9 0%, #f8fcff 98%);
      border-radius: 14px;
      color: #1f1f1f;
      font-size: 22px;
      .line {
        width: 100%;
        .label1 {
          color: #a3a6ab;
          font-size: 11px;
          .img {
            width: 16px;
            height: 16px;
          }
        }
        .label2 {
          color: #a3a6ab;
          font-size: 11px;
          .img {
            width: 22px;
            height: 22px;
          }
        }
      }
      .translate {
        margin-top: 20px;
        margin-bottom: 56px;
      }
    }
    &__content {
      width: 1220px;
      height: 130px;
      padding: 16px 16px;
      margin-top: 28px;
      background: linear-gradient(90deg, #eceff8 2%, #f7fbff 99%);
      border-radius: 22px;
      &__list {
        width: 1220px;
        height: 130px;
        border-radius: 22px;
        opacity: 0.9;
        .row:hover {
          transition: 0.3s;
          margin-top: -4px;
          margin-bottom: 4px;
          box-shadow: 0px 4px 12px 0px rgba(61, 92, 255, 0.18);
        }
        // ::-webkit-scrollbar {
        //   width: 0 !important;
        //   height: 0;
        // }
        // 定义滚动条的轨道颜色，内阴影及圆角
        .roll::-webkit-scrollbar-track {
          // background-color:#F5F5F5;
          // -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3);
          // border-radius:10px;
        }
        .roll::-webkit-scrollbar {
          width: 6px;
          height: 5px;
          background-color: rgba(0, 0, 0, 0);
        }
        /*定义滑块颜色、内阴影及圆角*/
        .roll::-webkit-scrollbar-thumb {
          // -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);
          background: #d8d8d8;
          border-radius: 3px;
        }
        .roll {
          width: 1070px;
          height: 130px;
          overflow-y: hidden;
          overflow-x: scroll;
          scrollbar-width: none; /* Firefox */
          -ms-overflow-style: none; /* IE 10+ */
          .row {
            min-width: 310px;
            height: 86px;
            position: relative;
            padding: 12px 15px;
            margin-right: 16px;
            background: #ffffff;
            box-shadow: 0px 8px 12px -4px rgba(184, 184, 210, 0.2);
            border-radius: 12px;
            &-schedule {
              width: 250px;
              height: 6px;
              position: absolute;
              bottom: 16px;
              left: 70px;
              background: #f4f3fd;
              border-radius: 22px;
              overflow: hidden;
              &-achieve {
                height: 6px;
                background: linear-gradient(
                  90deg,
                  rgba(255, 255, 255, 0) 0%,
                  #ff5106 100%
                );
              }
            }
            &-line {
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              margin-top: 10px;
              &-detail {
                display: flex;
                align-items: flex-start;
                &-title {
                  width: 40px;
                  height: 40px;
                  color: #8189b0;
                  font-size: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background: #f4f9ff;
                  box-shadow: 0px 8px 12px -4px rgba(184, 184, 210, 0.2);
                  border-radius: 10px;
                  margin-right: 13px;
                }
                &-num {
                  color: #858597;
                  font-size: 12px;
                  &-text {
                    font-size: 18px;
                    font-weight: bold;
                    color: #1f1f39;
                  }
                }
              }
            }
            &-present {
              width: 68px;
              height: 22px;
              position: absolute;
              left: 0px;
              top: 20px;
              font-size: 12px;
              color: #fff;
              display: flex;
              justify-content: center;
              line-height: 24px;
              background-image: url("../../assets/image/bg_present.png");
              background-size: 100% 100%;
            }
            &-subject {
              display: flex;
              align-items: flex-end;
              justify-content: space-between;
              color: #69729c;
              margin-top: 10px;
              &-time {
                font-size: 12px;
              }
            }
            &-content {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 11px;
              &-honorific {
                font-size: 14px;
                color: #757fb3;
              }
              &-plan {
                width: 76px;
                height: 27px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #3d5cff;
                border-radius: 6px;
                font-size: 13px;
                color: #fff;
              }
            }
            &-title {
              color: #858597;
              font-size: 14px;
              margin-top: 10px;
            }
          }
        }
      }
      &__add {
        width: 136px;
        height: 110px;
        background: #fff;
        box-shadow: 0px 8px 12px -4px rgba(184, 184, 210, 0.2);
        border-radius: 12px;
        .img {
          width: 32px;
          height: 32px;
        }
        .text {
          color: #69729c;
          font-size: 14px;
          margin-top: 14px;
        }
      }
    }
    .mark {
      margin-left: 16px;
      .text {
        font-size: 12px;
        color: #3d5cff;
      }
      .img {
        width: 22px;
        height: 22px;
      }
    }
    .title {
      position: relative;
      &__shadow {
        width: 26px;
        height: 8px;
        background: #3d5cff;
        opacity: 0.3;
        position: absolute;
        left: -6px;
        bottom: 2px;
      }
    }
    &__title {
      margin: 34px 0;
      font-size: 12px;
      color: rgb(100, 99, 99);
    }
  }
}
</style>