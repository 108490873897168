<!-- 个人中心-学习提醒 -->
<template>
    <div id="Learnremind">
        <div class="content">
            
            <div class="content-bottom">
                <div class="content-bottom-title">
                    <div class="content-top-top-left">
                        <div class="content-top-top-left-text">学习提醒</div>
                        <div class="content-top-top-left-back"></div>
                    </div>
                    
                </div>
                <div class="content-content">
                    <div class="content-text">
                        <div class="content-text-left">
                            <div class="text-title">系统提醒</div>
                            <p v-show="false">系统未授权通知，请启用<span>系统通知</span></p>
                        </div>
                        <div class="content-text-right">
                            <div class="openornot">
                                <div class=" cursor" :class="systemreminder==0?'opening':''" @click="changeopening(0)">禁用</div>
                                <div class=" cursor" :class="systemreminder==1?'opening':''" @click="changeopening(1)">启用</div>
                            </div>
                        </div>
                    </div>
                    <div class="content-text">
                        <div class="content-text-left">
                            <div class="text-title">提醒时间</div>
                        </div>
                            
                        <div class="content-text-right">
                            <div v-show="settime==''" class="setbutton cursor" @click="showset">立即设置</div>
                            <div v-show="settime!=''" class="setbuttons cursor" @click="showset">
                                <div class="setbutton2">{{settime}}</div>
                                <div class="setbutton3">
                                    <p>修改</p>
                                    <img class="cursor" src="../../assets/icon/icon_more6.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div style="position: relative;">
                        <div id="scroll" :v-model="dataPopRadio" class="solidUl" @scroll="scrollChange">
                                <ul>
                                <li
                                    v-for="(item, index) in dataPopOption"
                                    :key="index"
                                >{{item.label}}</li>
                                </ul>
                                //滚动过程中滚动到的所需位置
                                <div class="backgroundFloat"></div>
                        </div>
                </div> -->
                        
                
            </div>
            <div class="height600"></div>
            <Globalmessage ref="message" :duration="3000" :top="100" :widths="1196"/>
        </div>
        <!-- 设置提醒时间 -->
        <el-dialog
        title=""
        :visible.sync="isshowset"
        width="410px"
        top="316px"
        :close-on-click-modal='false'
        :show-close='false'
        >
            <div slot="title" class="dialog1-top-title">
                <span>设置提醒时间</span>
                <img class="cursor" @click="Closeset" src="../../assets/icon/search_close.png">
            </div>
            <div class="dialog1-content">
                <el-time-picker
                    :editable="false"
                    v-model="timeselect"
                    format="HH时        mm分"
                    value-format="HH:mm"
                    placeholder="请选择时间">
                </el-time-picker>
            </div>
            <div class="foot2">
                <el-button class="but" @click="sureset">确定</el-button>
            </div>
        </el-dialog>
        
    </div>
</template>

<script>
import Globalmessage from '../../components/Globalmessage.vue'
export default {
    components: {
        Globalmessage
    },
  data () {
    return {
        learndata: {}, // 数据
        systemreminder: 0, // 系统提醒
        settime: '00:00', // 提醒时间

        isshowset: false, // 设置提醒时间弹窗
        timeselect: '', // 设置的时间

    //     dataPopRadio: '',
    //     dataPopOption : [{
    //       name: '年假',
    //       label: '年假'
    //     }, {
    //       name: '事假',
    //       label: '事假'
    //     }, {
    //       name: '病假',
    //       label: '病假'
    //     }, 
    //     {
    //       name: '病假',
    //       label: '病假'
    //     }, 
    //     {
    //       name: '病假',
    //       label: '病假'
    //     }, 
    //   ],

    };
  },
    computed: {
    },
  mounted () {
      this.getlearndata()
  },
  methods: {
    //   获得数据
    getlearndata () {
        this.$userdata.getLearningReminder().then((res) => {
            // console.log(res,'学习提醒')
            if (res.code==200) {
                this.learndata = res.result
                this.systemreminder = res.result.remind
                this.settime = res.result.remindTime
            }
        })
    },
//       scrollChange(e){
// //首先获取到li元素
//       var lis=document.getElementsByTagName('li')
// //对li元素进行遍历
//       for(var i=0;i<lis.length;i++){
// //offsetTop返回当前元素相对于节点顶部偏移量，scrollTop返回一个元素垂直滚动的距离
// //在滚动过程中，lis[i].offsetTop-e.target.scrollTop可获取到当前元素距离顶部的位置
// //打印出结果自己对比，选择自己需要范围的值，我给li设置的高度为40px，因此185与225之间相差40，
// //而185与225是我实际输出对比测量的我需要元素滚动到该位置时设置样式。
//         if((lis[i].offsetTop-e.target.scrollTop) > 105&& (lis[i].offsetTop-e.target.scrollTop) < 145){
// //当元素滚动到当前位置时，先将元素原来为"active2"的class属性去掉
// //再给元素增加"active"的属性
//           lis[i].classList.remove("active2")
//           lis[i].classList.add("active")
// //给当前元素以上的元素动态设置文字大小，离当前文字越远，文字大小越小
//           for(var j=1;j<=i;j++){
//             lis[i-j].style.fontSize = (20-2*j)+'px'
//           }
// //给当前元素以下的元素动态设置文字大小，离当前文字越远，文字大小越小
//           for(var a=1;a<lis.length-i;a++){
//             lis[i+a].style.fontSize = (20-2*a)+'px'
//           }
// //当没滚动到期待位置时，也就是其他所有元素，移除"active"的样式，增加"active2"的样式
//         }else{
//           lis[i].classList.remove("active")
//           lis[i].classList.add("active2")
//         }
//             console.log(lis[i].offsetTop-e.target.scrollTop)

//       }
//     },
      
    //   确定设置提醒时间
    sureset () {
        let params = new FormData
        params.append('remindTime', this.timeselect)
        this.$userdata.learningReminderTime(params).then((res) => {
            // console.log(res,'学习提醒时间')
            if (res.code==200) {
                this.getlearndata()
            }
        })
        this.Closeset()
    },
    //   关闭设置提醒弹窗
    Closeset () {
        this.isshowset = false
    },
    //   显示设置提醒弹窗
    showset () {
        // console.log(this.timeselect,this.settime)
        this.timeselect = this.settime == ''? '00:00':this.settime
        this.isshowset = true
    },
    //  改变启用或禁用状态
    changeopening (val) {
        // console.log(val)
        if (val == this.systemreminder) {
        return false
        }
        let params = new FormData
        params.append('remind', val)
        this.$userdata.learningReminder(params).then((res) => {
            // console.log(res,'是否开启学习提醒')
            if (res.code==200) {
                this.getlearndata()
            }
        })
        this.systemreminder = val
    }
  }
};
</script>
<style lang="scss">
    .el-time-panel{
        border-radius: 14px !important;
    }
#Learnremind {
    
    .el-dialog {
        border-radius: 14px;
    }
    .dialog1-content{
        .el-input--prefix .el-input__inner{
            border: 0px;
            background: rgba(0,0,0,0);
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #111111;
        }
        .el-input__icon{
            display: none;
        }
    }
}
</style>
<style lang="scss" scoped>
#Learnremind {
    /* 注意点2 */
.oil-coupons {
  height: 20px; /* 这个属性要有的 */
  font-size: 14px;
  background: pink;
  overflow-y: auto; /* 这个属性要有的 */
}
//     .solidUl{
//   padding-top: 40px;
//   margin-top: 15px;
//   height: 350px;
// //滚动设置在div内
//   overflow-y: scroll;
// //增加滚动的流畅性
//   touch-action: pan-y;
//   -webkit-overflow-scrolling: touch;
  
// }
// ul {
//       padding: 80px 0 230px 0;
//       margin: 0;
//       background-color: #fff;
//       li {
//         list-style: none;
//         font-size: 18px;
//         line-height: 20px;
//         text-align: center;
//         opacity: 0.3;
//         height: 40px;
//         background-color: #fff;
//       }
//     }
// .active {
//   font-weight: 400;
//   font-size: 20px !important;
//   color: #333;
// //不透明度需要高点，因为有backgroundFloat颜色的影响
//   opacity: 1.2 !important;
//   top: 150px;
// }
// .active2 {
//   color: #333;
// //设置不透明度
//   opacity: 0.6 !important;
// }
// //给滚动到的所需位置增加背景色等样式
// .backgroundFloat{
//   width: 100%;
//   height: 40px;
//   background-color: #d1d1d1;
// //降低不透明度，以防遮挡文字
//   opacity: 0.2;
//   position: absolute;
//   top: 110px;
// }
    .setbuttons{
        display: flex;
        align-items: center;
    }
    .setbutton3{
        width: 50px;
        height: 22px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8C8C8C;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin:  0 0 0 8px;
        p{
        }
        img{
            width: 22px;
            height: 22px;
        }
    }
    .setbutton2{
        width: 60px;
        height: 27px;
        background: #3D5CFF;
        border-radius: 6px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .setbutton{
        width: 76px;
        height: 27px;
        background: #3D5CFF;
        border-radius: 6px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .opening{
        width: 38px;
        height: 25px;
        background: #FFFFFF;
        box-shadow: 0px 0px 4px 0px rgba(102,102,102,0.2);
        border-radius: 6px;
    }
    .dialog1-top-title{
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2E3E5C;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img{
            position: absolute;
            right: 0px;
            width: 32px;
            height: 32px;
        }
    }
    .dialog1-content{
        width: 336px;
        height: 140px;
        background: linear-gradient(90deg, #EEF1F9 0%, #F8FCFF 98%);
        border-radius: 14px;
        margin: 0px auto 34px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .foot2{
        width: 335px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        .but{
            width: 335px;
            height: 50px;
            background: #3D5CFF;
            border-radius: 12px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            border: none;
        }
    }
    .height600{
        height: 600px;
    }
    .nocursor{
        cursor:not-allowed;
    }
    .content{
        width:1196px;
        margin:  0 0 0 24px;
        .content-bottom{
            width: 1196px;
            min-height: 233px;
            background: #FFFFFF;
            box-shadow: 2px 0px 16px 0px rgba(0,21,41,0.08);
            border-radius: 14px;
            // margin: 24px 0 0 0;
            .content-content{
                width: 574px;
                min-height: 115px;
                background: #F7F8FC;
                border-radius: 14px;
                margin: 28px 0 0 24px;
                display: flex;
                flex-direction: column;
                // align-items: center;
                .content-text1{
                    width: 542px;
                    min-height: 76px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0 0 0 16px;
                    border-bottom: #F4F4F5 1px solid;
                    .content-text-left{
                        .text-title{
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                        }
                        p{
                            font-size: 11px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #8189B0;
                            margin: 4px 0 0 0;
                            span{
                                text-decoration:underline;
                            }
                        }
                    }
                    .content-text-right{
                        
                        .openornot{
                            width: 82px;
                            height: 27px;
                            background: #F0F0F0;
                            border-radius: 6px;
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #595959;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            div{
                                width: 38px;
                                height: 25px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }
                }
                .content-text{
                    width: 542px;
                    min-height: 57px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0 0 0 16px;
                    .content-text-left{
                        .text-title{
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                        }
                        p{
                            font-size: 11px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #8189B0;
                            span{
                                text-decoration:underline;
                            }
                        }
                    }
                    .content-text-right{
                        .openornot{
                            width: 82px;
                            height: 27px;
                            background: #F0F0F0;
                            border-radius: 6px;
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #595959;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            div{
                                width: 38px;
                                height: 25px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }
                }
            }
            .content-bottom-title{
                width: 80px;
                height: 25px;
                padding: 23px 0 0 0px;
            }
            
        }
        .content-top-top-left{
            width: 80px;
            height: 25px;
            display: flex;
            align-items: flex-end;
            position: relative;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2F3236;
            margin: 0 0 0 24px;
            &-text {
            margin-left: 8px;
            }
            .content-top-top-left-back {
                width: 26px;
                height: 8px;
                background: #3d5cff;
                opacity: 0.3;
                position: absolute;
                left: 0;
                bottom: 2px;
            }
        }
        
    }
}
</style>