import request from '../utils/request.js'
// 获取我的免费额度
export function freeCredit (params) {
    return request.post('/translate/freeCredit', params)
}

// 文本翻译
export function textTranslation (params) {
    return request.post('/translate/textTranslation', params)
}
// 文档翻译
export function DocumentationTranslation (params) {
    return request.post('/translate/DocumentationTranslation', params)
}

export function DocumentationTranslationDistinguish (params) {
    return request.post('/translate/DocumentationTranslationDistinguish', params)
}
// 
export function translategetByStamp (params) {
    return request.post('/translategetByStamp', params)
}

// 人工翻译
export function getHumanTranslation (params) {
    return request.post('/assist/getHumanTranslation', params)
}

// WEB端提交文档翻译订单
export function webDocumentationTranslationOrder (params) {
    return request.post('/webOrder/webDocumentationTranslationOrder', params)
}
// web端提交文本翻译订单
export function webtextTranslationOrder (params) {
    return request.post('/webOrder/webtextTranslationOrder', params)
}
// 根据翻译订单id 获取支付宝支付二维码
export function getAliCode (params) {
    return request.post('/webOrder/getAliCode', params)
}
// 根据翻译订单id 获取微信支付二维码
export function getWxCodes (params) {
    return request.post('/webOrder/getWxCode', params)
}
// web根据订单id获取此订单有无支付
export function getIsPay (params) {
    return request.post('/webOrder/getIsPay', params)
}
// 我的翻译订单详情
export function getTranslateOrder (params) {
    return request.post('/allOrder/getTranslateOrder', params)
}
// 我的文档翻译记录
export function getTranslationList (params) {
    return request.post('/translate/getTranslationList', params)
}