<template>
  <div id="specialtyNav">
    <div class="body">
      <!-- title -->
      <p class="body__title"><span class=" cursor" @click="back">首页</span> / 专业导航</p>
      <div class="body__nav flex between start">
        <div class="title">
          <p>专业导航</p>
          <div class="title__shadow"></div>
        </div>
        <!-- 搜索search -->
        <div class="box-search" v-show="!indexStatus">
          <div class="search-big" >
            <div class="search-big-left">
                <img class="cursor" @click="searchthing" src="../../assets/icon/icon_sear.png">
            </div>
            <div class="search-big-right">
                <el-input class="search-big-input" v-model="searchValue" placeholder="请输入关键词" @keyup.enter.native="searchthing" @input="searchInput"></el-input>
                <img class="cursor" v-show="searchValue!=''" @click="cleansearchval" src="../../assets/icon/icon_qingkong2.png">

            </div>
          </div>
          <!-- <div class="search">
            <div class="search-icon">
              <img
                src="../../assets/icon/icon_sear.png"
                alt=""
                class="search-img"
              />
            </div>
            <div class="search-value">
              <el-input
                v-model="searchValue"
                placeholder="请输入文字或单词"
                clearable
              ></el-input>
            </div>
          </div> -->
        </div>
      </div>
      <!-- content -->
      <div class="content flex">
        <!-- nav -->
        <div class="content__nav" v-loading="leftloading">
          <el-menu v-if="issearend==false" :default-active="leftdefault" class="el-menu-vertical-demo">
            <el-menu-item
              v-for="(item, key) in leftnavdata"
              :index="item.id"
              @click="navClick(item)"
            >
              <span slot="title">{{item.name}}</span>
            </el-menu-item>
          </el-menu>
           <el-menu v-if="issearend==true" :default-active="leftdefault" class="el-menu-vertical-demo">
            <el-menu-item
              v-for="(item, key) in searchenddata"
              :index="item.id"
              @click="navClickRes(item)"
            >
              <span slot="title">{{item.name}}</span>
            </el-menu-item>
          </el-menu>
        </div>
        <!-- list -->
        <div
          class="content__list flex column"
          id="content"
          v-show="!indexStatus"
          v-loading="rightloading"
        >
          <div class="patch" v-if="issearend==false">
            <div class="content__list__title">
              <p class="title">{{leftselect.name}}</p>
              <p class="number">共{{leftselect.vocabulary}}个单词</p>
            </div>
            <div class="content__list__body">
              <div v-for="(item, key) in rightdata" :key="key">
                <div class="title">
                  <p>{{ item.name}}</p>
                  <div class="title__shadow"></div>
                </div>
                <div class="flex wrap">
                  <div
                    class="subject flex column align cursor"
                    v-for="(items, key) in item.subList"
                    :key="key"
                    @click="showindexStatus(item,items)"
                  >
                    <img
                      :onerror="defaultimgSrc"
                      :src="items.image"
                      class="img"
                      alt=""
                    />
                    <p class="subject__text">{{ items.name }}</p>
                  </div>
                  <div class="nodata" v-show="item.subList.length==0">
                      暂无数据
                  </div>
                </div>
              </div>
              <div class="nodata" v-show="rightdata.length==0">
                  暂无数据
              </div>
            </div>
          </div>
          <!-- 搜索后的 -->
          <div class="patch" v-if="issearend==true" v-for="(item,key) in searchendrightdata">
            <div class="content__list__title">
              <p class="title">{{item.name}}</p>
              <p class="number">共{{item.vocabulary}}个单词</p>
            </div>
            <div class="content__list__body">
              <div v-for="(items, key) in item.twoList" :key="key">
                <div class="title">
                  <p>{{ items.name}}</p>
                  <div class="title__shadow"></div>
                </div>
                <div class="flex wrap">
                  <div
                    class="subject flex column align cursor"
                    v-for="(itemss, key) in items.treeList"
                    :key="key"
                    @click="showindexStatus(items,itemss)"
                  >
                    <img
                      :onerror="defaultimgSrc"
                      :src="itemss.image"
                      class="img"
                      alt=""
                    />
                    <p class="subject__text">{{ itemss.name }}</p>
                  </div>
                  <div class="nodata" v-show="items.treeList.length==0">
                      暂无数据
                  </div>
                </div>
              </div>
              <div class="nodata" v-show="item.twoList.length==0">
                  暂无数据
              </div>
            </div>
          </div>
          <div class="nodata" v-show="searchendrightdata.length==0&&issearend==true">
              暂无数据
          </div>
        </div>
        
        <!-- indexes 索引列表 ———— 页面列表展示等在当前页面 制定学习计划功能、字母索引列表功能在此组件内 -->
        <Indexes ref="Indexes" v-if="indexStatus" :twolevel="twolevel" :threelevel="threelevel" />
      </div>
    </div>
  </div>
</template>

<script>
import Indexes from "./components/indexes.vue";
export default {
  components: {
    Indexes,
  },
  data() {
    return {
      twolevel: {}, // 二级数据
      threelevel: {}, // 三级数据

      searchValue: "", // 搜索value
      issearend: false, // 是否搜索
      searchenddata: [], // 搜索后的数据
      searchendrightdata: [], // 搜索后右侧的数据



      leftnavdata: [], // 左侧的导航栏数据
      leftdefault: '', // 左侧默认选中
      leftselect: '', // 左侧选中
      leftloading: false, // 左侧加载

      rightloading: false, // 右侧加载
      rightdata: [], // 右侧数据
      indexStatus: false, // 导航右侧元素显示 false 显示列表、搜索元素 | true 显示单词元素
      indexKey:1 //右侧元素key
    };
  },
  computed: {
      defaultimgSrc () {
          return 'this.src="' + require('../../assets/image/nav-img.png') + '"';
      }
  },
  mounted() {
    this.getOneClassify()
  },
  methods: {
    //   面包屑点击上一个的
    back () {
        this.$router.push('/')
    },
    // 显示三级词汇
    showindexStatus (val,val2) {
      this.indexStatus = true
      this.twolevel = val
      this.threelevel = val2
      this.$nextTick(()=>{
 // console.log(val,val2,"三级")

      // console.log(this.$route.query.planStatus)
      if (this.$route.query.planStatus==1) {
        this.$refs.Indexes.planStatus = false
      }else{
        this.$refs.Indexes.planStatus = true
      }
      this.$refs.Indexes.pickIndex = '#'
      // this.$refs.Indexes.dataList = []
      this.indexKey = this.indexKey+1
      })
     
      
    },
    // 获得左侧导航栏数据
    getOneClassify () {
      this.leftloading = true
      this.$apis.getOneClassify().then((res) => {
        // console.log(res,"一级导航")

        if (res.code==200) {
          this.leftnavdata = res.result
          this.leftdefault = res.result[0].id
          this.leftselect = res.result[0]
          this.leftloading = false
          this.rightloading = true
          this.getTwoClassifyById()
        }
        
      })
    },
    // 获得右侧数据
    getTwoClassifyById () {
      let params = new FormData
      params.append('id',this.leftselect.id)
      params.append('pageNo','1')
      params.append('pageSize','999')
      this.$apis.getTwoClassifyById(params).then((res) => {
        // console.log(res,"右侧数据")

        if (res.code==200) {
          this.rightdata = res.result
          this.rightloading = false
        }
        
      })
    },
    //   搜素
    searchthing () {
      if (this.searchValue=='') {
        this.issearend = false
        this.getOneClassify()
        return false
      }
      // this.leftdefault = ''
      this.issearend = true
       this.leftloading = true
      this.rightloading = true
      let params = new FormData
      params.append('name',this.searchValue)
      this.$apis.getProfessionalSearch(params).then((res) => {
        // console.log(res,"搜索数据")

        if (res.code==200) {
          this.searchenddata = res.result
          if(res.result && res.result.length!=0){
            this.navClickRes(res.result[0])
          }
          this.rightloading = false
           this.leftloading = false
        }
        
      })
        // this.defaultcollection = ''
        // this.isshowsearchend = true
        // if (this.searchval=='') {
        //     return false
        // }
        // this.searloading = true
        // this.searchend()
        
        // console.log('555')
    },
    searchInput(e){
      if(e){}else{
         this.issearend = false
        this.getOneClassify()
      }
    },
    //   清空搜素框
    cleansearchval () {
        this.searchValue = ''
        this.issearend = false
        this.getOneClassify()
        // this.isshowsearchend = false
        // this.getWebCollect()
    },
    // 菜单点击
    navClick(val) {
      this.issearend = false
      this.indexStatus = false
      this.leftselect = val
      this.rightloading = true
      this.$nextTick(()=>{
      if (this.$route.query.planStatus==1) {
        this.$refs.Indexes.planStatus = false
      }else{
        this.$refs.Indexes.planStatus = true
      }
      this.$refs.Indexes.dataList = []
      })
      
      this.getTwoClassifyById()

    },
    //点击结果
    navClickRes(val){
      console.log(val)
      this.leftselect = val
       this.indexStatus = false
      this.issearend = true
      this.searchendrightdata = [val]
    }
  },
};
</script>
<style lang="scss" scoped>
#specialtyNav {
  .nodata{
        width: 100%;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 17px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
    }
  .search-big{
    width: 460px;
    height: 56px;
    background: linear-gradient(90deg, #ECEFF8 2%, #F7FBFF 99%);
    border-radius: 14px;
    display: flex;
    .search-big-left{
        width: 56px;
        height: 56px;
        background: #E2E7FF;
        border-radius: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 22px;
            height: 22px;
        }
    }
    .search-big-right{
        position: relative;
        .search-big-input{

        }
        img{
            width: 22px;
            height: 22px;
            position: absolute;
            right: 17px;
            top: 17px;
        }
    }
}
}
</style>
<style lang="scss">
#specialtyNav {
  width: 100%;
  .search-big-input{
        .el-input__inner{
            width: 404px;
            height: 56px;
            border-radius: 14px;
            background:rgba(255,255,255,0);
            border: 0;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #1F1F1F;
        }
        .el-input__inner::placeholder {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #A8ABB8;
        }
        /* 谷歌 */
        .el-input__inner::-webkit-input-placeholder {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #A8ABB8;
        }
        /* 火狐 */
        .el-input__inner:-moz-placeholder {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #A8ABB8;
        }
        /*ie*/
        .el-input__inner:-ms-input-placeholder {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #A8ABB8;
        }
    }
  .el-menu-item.is-active {
    padding-left: 20px;
    color: #3d5cff;
    outline: none;
    background-color: #f0f3ff;
    border-right: 2px solid #3d5cff;
  }
  .el-menu-item {
    height: 48px;
    line-height: 48px;
    padding: 0 40px !important;
    font-size: 14px;
  }
  .el-input__inner {
    background: linear-gradient(90deg, #eceff8 2%, #f7fbff 99%);
    border: none;
    color: #8189b0;
    font-size: 14px;
  }
  .content {
    width: 1188px;
    height: 700px;
    background: linear-gradient(90deg, #eceff8 2%, #f7fbff 99%);
    border-radius: 14px;
    padding: 0 16px 16px;
    margin-bottom: 30px;
    &__list {
      width: 966px;
      // height: 700px;
      background: linear-gradient(90deg, #eceff8 2%, #f7fbff 99%);
      margin-left: 14px;
      margin-top: 16px;
      border-radius: 14px;
      overflow: hidden;
      overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      ::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      .patch {
        // margin-top: 16px;
      }
      &__body {
        width: 942px;
        min-height: 684px;
        padding: 0 0 32px 24px;
        background: #fff;
        border-radius: 0 0 14px 14px;
        margin: 0 0 16px 0;
        .subject {
          width: 80px;
          margin-right: 24px;
          &__text {
            font-size: 13px;
            margin: 6px 0 0 0;
          }
        }
        .title {
          position: relative;
          font-size: 15px;
          margin-bottom: 20px;
          padding-top: 32px;
          color: #545c81;
          &__shadow {
            width: 24px;
            height: 4px;
            background: #3d5cff;
            opacity: 0.3;
            position: absolute;
            left: 0px;
            bottom: 2px;
          }
        }
        .img {
          width: 80px;
          height: 80px;
        }
      }
      &__title {
        width: 926px;
        padding: 18px 20px;
        background: linear-gradient(360deg, #f7fbff 0%, #ffffff 100%);
        border-radius: 14px 14px 0px 0px;
        .title {
          font-size: 20px;
          color: #2e3e5c;
          font-weight: 600;
        }
        .number {
          color: #6975af;
          font-weight: 400;
          margin-top: 10px;
          font-size: 12px;
        }
      }
    }
    &__nav {
      width: 208px;
      height: 684px;
      margin-top: 16px;
      background: #ffffff;
      box-shadow: 2px 0px 6px 0px rgba(0, 21, 41, 0.12);
      border-radius: 14px;
      overflow: hidden;
      overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
    }
    ::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
  .body {
    width: 1220px;
    margin: 0 auto;
    padding-left: 96px;
    &__nav {
      width: 100%;
      height: 56px;
      margin-bottom: 14px;
      color: #2f3236;
      font-size: 18px;
      .title {
        position: relative;
        &__shadow {
          width: 26px;
          height: 8px;
          background: #3d5cff;
          opacity: 0.3;
          position: absolute;
          left: -6px;
          bottom: 2px;
        }
      }
    }
    &__title {
      margin: 34px 0;
      font-size: 12px;
      color: rgb(100, 99, 99);
    }
    .box-search {
      position: relative;
      .search {
        width: 404px;
        display: flex;
        align-items: center;
        border-radius: 14px;
        overflow: hidden;
        background: linear-gradient(90deg, #eceff8 2%, #f7fbff 99%);
        &-value {
          width: 348px;
          height: 56px;
          display: flex;
          align-items: center;
          opacity: 0.9;
          .el-input__inner {
            background: linear-gradient(90deg, #eceff8 2%, #f7fbff 99%);
            border: none;
            color: #8189b0;
            font-size: 14px;
          }
        }
        &-icon {
          width: 56px;
          height: 56px;
          background: #e2e7ff;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 14px;
        }
        &-img {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
}
</style>