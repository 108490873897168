<!-- 个人中心-我的发票 -->
<template>
    <div id="Myinvoice">
        <div class="content">
            
            <div class="content-bottom">
                <div class="content-bottom-title">
                    <div class="content-top-top-left">
                        <div class="content-top-top-left-text">我的发票</div>
                        <div class="content-top-top-left-back"></div>
                    </div>
                    
                </div>
                <div class="content-content" v-loading="loading">
                    <div class="content-table" v-show="tabledata.length!=0"  v-for="(item,key) in tabledata">
                        <div class="content-table-top">
                            <p>{{item.name}}</p>
                            <span v-show="item.invoiceState==1">开票中</span>
                            <span v-show="item.invoiceState==2">已开票</span>
                            <span v-show="item.invoiceState==3">已开票</span>
                        </div>
                        <div class="content-table-center">
                            <div class="content-table-center-left">
                                <p>{{item.purpose}}</p>
                                <span>抬头名称 {{item.invoiceHeader||''}}</span>
                                <br>
                                <span>支付时间 {{item.createTime||''}}</span>
                            </div>
                            <div class="content-table-center-right">
                                <span>￥</span>
                                <p>{{item.price}}</p>
                                <!-- <span>{{item.moneylast}}</span> -->
                            </div>
                        </div>
                        <div class="content-table-bottom">
                            <div class="checkbutt1" v-show="item.invoiceState==1">开票中</div>
                            <div class="checkbutt1 cursor" v-show="item.invoiceState==2" @click="showViewinvoice(item)">查看发票</div>
                            <div class="checkbutt2 cursor" v-show="item.invoiceState==3" @click="showcouriernumber(item)">查看快递单号</div>
                        </div>
                    </div>
                    <div v-show="tabledata.length==0" class="history-none">
                        <img src="../../assets/image/img_none.png"/>
                    </div>
                    <!-- 分页 -->
                    <div class="paging" v-show="tabledata.length!=0" >
                        
                        <!-- <div :class="currentPage==1?'nopagbutton nocursor':'pagbutton cursor'" @click="firstpage">首页</div> -->
                        <input type="button" value="首页" @click="firstpage" :class="currentPage==1?'nopagbutton nocursor':'pagbutton cursor'" >
                        <!-- <div :class="currentPage==1?'nopagbutton nocursor':'pagbutton cursor'" @click="uppage">上一页</div> -->
                        <input type="button" value="上一页" @click="uppage" :class="currentPage==1?'nopagbutton nocursor':'pagbutton cursor'" >
                        <el-pagination
                            layout="pager"
                            :page-size="pageSize"
                            :page-count="totalPage"
                            :current-page="currentPage"
                            @current-change="currentchange"
                            >
                        </el-pagination>
                        <!-- <div :class="currentPage==totalPage?'nopagbutton nocursor':'pagbutton cursor'" @click="nextpage">下一页</div> -->
                        <input type="button" value="下一页" @click="nextpage" :class="currentPage==totalPage?'nopagbutton nocursor':'pagbutton cursor'"  >
                        <!-- <div :class="currentPage==totalPage?'nopagbutton nocursor':'pagbutton cursor'" @click="lastpage">尾页</div> -->
                        <input type="button" value="尾页" @click="lastpage" :class="currentPage==totalPage?'nopagbutton nocursor':'pagbutton cursor'">
                    </div>
                </div>
                
                
            </div>
        </div>
        <!-- 快递单号 -->
        <el-dialog
        title=""
        :visible.sync="iscouriernumber"
        width="410px"
        top="416px"
        :close-on-click-modal='false'
        :show-close='false'
        >
            <div slot="title" class="dialog1-top-title">
                <span>快递单号</span>
                <img class="cursor" @click="Closecouriernumber" src="../../assets/icon/search_close.png">
            </div>
            <div class="dialog1-content">
                <p>{{couriernumber}}</p>
                <img class="cursor" @click="copynumber" src="../../assets/icon/icon_copy.png">
            </div>
            <div class="foot2">
                <el-button class="but" @click="Closecouriernumber">确定</el-button>
            </div>
        </el-dialog>
        <!-- 查看发票 -->
        <el-dialog
        title=""
        :visible.sync="isViewinvoice"
        width="860px"
        top="105px"
        :close-on-click-modal='false'
        :show-close='false'
        >
            <div slot="title" class="dialog1-top-title">
                <span></span>
                <img class="cursor" @click="CloseViewinvoice" src="../../assets/icon/search_close.png">
            </div>
            <div class="dialog2-content">
                <img class="dialog2-content-img"  :src="Viewinvoice" >
            </div>
            <div class="foot2">
                <el-button class="but" @click="downViewinvoice">下载</el-button>
            </div>
        </el-dialog>
        <Globalmessage ref="message" :duration="3000" :top="100" :widths="1000"/>
    </div>
</template>

<script>
import Globalmessage from '../../components/Globalmessage.vue'
export default {
    components: {
        Globalmessage
    },
  data () {
    return {
        loading: false, // 加载
        isViewinvoice: false, // 查看发票
        couriernumber: '', // 快递单号
        iscouriernumber: false, // 快递单号
        Viewinvoice: '', // 电子发票
        tabledata: [
        ], // table的数据
        // 分页
        totalPage: 7, // 统共页数，默认为1
        currentPage: 1, // 当前页数 ，默认为1
        pageSize: 4, // 每页显示数量
    };
  },
    computed: {
        defaultimgSrc () {
            return 'this.src="' + require('../../assets/image/heard.png') + '"';
        }
    },
  mounted () {
        this.getdata()
  },
  methods: {
    //   下载电子发票
    downViewinvoice () {
        let fileUrl = this.Viewinvoice  //服务器文件地址
        this.getBlob(fileUrl).then(blob => {
            this.saveAs(blob, this.Viewinvoice)
        })
    },
    //通过文件下载url拿到对应的blob对象
    getBlob(url) {
      return new Promise(resolve => {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.responseType = 'blob'
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(xhr.response)
          }
        }

        xhr.send()
      })
    },
    //下载文件 　　js模拟点击a标签进行下载
    saveAs(blob, filename) {
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      link.click()
    },
    //   获得数据
    getdata () {
        this.loading = true
        let params = new FormData
        params.append('pageNo',this.currentPage)
        params.append('pageSize',this.pageSize)
        this.$userdata.getMyBill(params).then((res) => {
            // console.log(res,'我的发票')
            if (res.code==200) {
                this.tabledata = res.result.records
                this.totalPage = res.result.size
            }
            this.loading = false
        })
        
    },
    //   打开查看发票弹窗
    showViewinvoice (val) {
        // console.log(val)
        if (val.invoiceUrl == '') {
            return false
        }
        this.Viewinvoice = val.invoiceUrl
        // this.isViewinvoice = true
        // 弹窗弃用改为新开页面
        window.location.href = this.Viewinvoice
    },
    //   关闭查看发票弹窗
    CloseViewinvoice () {
        this.isViewinvoice = false
    },
    //   复制快递单号
    copynumber () {
        let url = this.couriernumber;
        //新建一个文本框
        let oInput = document.createElement('input');
        //赋值给文本框
        oInput.value = url;
        document.body.appendChild(oInput);
        // 选择对象;
        oInput.select(); 
        // 执行浏览器复制命令
        document.execCommand("Copy"); 
        //复制完成删除掉输入框
        oInput.remove()
        this.$refs.message.success('复制成功') // success调用
        // console.log('复制成功')
    },
    //   打开快递单号
    showcouriernumber (val) {
        if (val.courierNumber == '') {
            return false
        }
        this.couriernumber = val.courierNumber
        this.iscouriernumber = true
    },
    //   关闭快递单号显示
    Closecouriernumber () {
        this.iscouriernumber = false
    },
    //   点击首页
      firstpage () {
          if(this.currentPage <= 1) {
              return false
          }
          this.currentPage = 1
          this.getdata()
      },
    //   上一页
        uppage () {
            if(this.currentPage <= 1) {
                return false
            }
            this.currentPage = this.currentPage - 1
            this.getdata()
        },
        // 点击尾页
        lastpage () {
            if(this.currentPage >= this.totalPage) {
                return false
            }
          this.currentPage = this.totalPage
          this.getdata()
        },
        // 点击下一页
        nextpage () {
            if(this.currentPage >= this.totalPage) {
                return false
            }
            this.currentPage = this.currentPage + 1
            this.getdata()
        },
    //   当前页数变化
      currentchange (val) {
          this.currentPage = val
          this.getdata()
      }
  }
};
</script>
<style lang="scss">
#Myinvoice {
    .el-dialog {
        border-radius: 14px;
    }
}
</style>
<style lang="scss" scoped>
#Myinvoice {
    .history-none {
        display: flex;
        align-content: center;
        justify-content: center;
        // margin-bottom: 20px;
        img {
        width: 100px;
        height: 100px;
        }
    }
    .nocursor{
        cursor:not-allowed;
    }
    .foot2{
        width: 335px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        .but{
            width: 432px;
            height: 50px;
            background: #3D5CFF;
            border-radius: 12px;
            border: none;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
        }
    }
    .dialog2-content{
        width: 800px;
        height: 534px;
        margin: 0 auto 20px;
        .dialog2-content-img{
            width: 800px;
            height: 534px;
        }
    }
    .dialog1-content{
        width: 336px;
        height: 56px;
        background: linear-gradient(90deg, #EEF1F9 0%, #F8FCFF 98%);
        border-radius: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto 40px;
        p{
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #111111;
            margin: 0 0 0 16px;
        }
        img{
            width: 22px;
            height: 22px;
            margin: 0 14px 0 0px;
        }
    }
    .dialog1-top-title{
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2E3E5C;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img{
            position: absolute;
            right: 0px;
            width: 32px;
            height: 32px;
        }
    }
    .paging{
        margin:  24px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        ::v-deep {
        .el-pagination {
                margin:  0 12px;
                .el-pager li:not(.disabled){
                    line-height: 35px;
                    width: 35px;
                    height: 35px;
                    font-size: 14px;
                    font-family: HelveticaNeue;
                    color: #565D64;
                    border-radius: 100%;
                }
                .el-pager li:not(.disabled).active{
                    line-height: 35px;
                    width: 35px;
                    height: 35px;
                    background: #4E555C;
                    font-size: 14px;
                    font-family: HelveticaNeue;
                    color: #FFFFFF;
                }
            }
        }
        .el-pager li.active{
            width: 32px;
            height: 32px;
            background: #4E555C;
            font-size: 14px;
            font-family: HelveticaNeue;
            color: #FFFFFF;
        }
        .pagbutton{
            background: #fff;
            border: none;
            width: 42px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #565D64;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .nopagbutton{
            background: #fff;
            border: none;
            width: 42px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #CCD1D4;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .content{
        width:1196px;
        margin:  0 0 0 24px;
        .checkbutt1{
            width: 72px;
            height: 30px;
            border-radius: 8px;
            border: 1px solid #B8B8D2;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #858597;
        }
        .checkbutt2{
            width: 98px;
            height: 30px;
            border-radius: 8px;
            border: 1px solid #B8B8D2;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #858597;
        }
        .content-bottom{
            width: 1196px;
            max-height: 961px;
            background: #FFFFFF;
            box-shadow: 2px 0px 16px 0px rgba(0,21,41,0.08);
            border-radius: 14px;
            // margin: 24px 0 0 0;
            .content-content{
                width: 811px;
                min-height: 300px;
                max-height: 860px;
                margin: 30px 0 0 24px;
                padding: 0 0 24px 0;
                .content-table{
                    width: 811px;
                    height: 186px;
                    background: #FFFFFF;
                    border-radius: 14px;
                    border: 1px solid #E2E7FF;
                    margin: 20px 0 0 0;
                    .content-table-bottom{
                        width: 783px;
                        margin: 14px 0 0 14px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                    }
                    .content-table-center{
                        width: 783px;
                        height: 80px;
                        background: #F9FAFB;
                        border-radius: 8px;
                        margin: 14px 0 0 14px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .content-table-center-right{
                            margin:  0 18px 0 0;
                            display: flex;
                            align-items: center;
                            p{
                                font-size: 13px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #1F1F39;
                            }
                            span{
                                font-size: 10px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #1F1F39;
                            }
                        }
                        .content-table-center-left{
                            width: 180px;
                            height: 60px;
                            margin:  0px 0 0 18px;
                            p{
                                font-size: 13px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #262626;
                            }
                            span{
                                font-size: 11px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #A8B4C3;
                            }
                        }
                    }
                    .content-table-top{
                        width: 783px;
                        height: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: 14px 0 0 14px;
                        p{
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #5E5F60;
                        }
                        span{
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #5E5F60;
                        }
                    }
                }

            }
            .content-bottom-title{
                width: 80px;
                height: 25px;
                padding: 23px 0 0 0px;
            }
            
        }
        .content-top-top-left{
            width: 80px;
            height: 25px;
            display: flex;
            align-items: flex-end;
            position: relative;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2F3236;
            margin: 0 0 0 24px;
            &-text {
            margin-left: 8px;
            }
            .content-top-top-left-back {
                width: 26px;
                height: 8px;
                background: #3d5cff;
                opacity: 0.3;
                position: absolute;
                left: 0;
                bottom: 2px;
            }
        }
        
    }
}
</style>