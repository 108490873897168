<!-- 个人资料 -->
<template>
    <div id="Personaldata">
        <div class="content">
            
            <div class="content-bottom">
                <!-- 上方面包屑 -->
                <div class="crumbs">
                    <span class="crumbs-before cursor" @click="backhome" >首页</span>
                    <span class="crumbs-fenkai">/</span>
                    <span class="crumbs-before " >设置</span>
                    <span class="crumbs-fenkai">/</span>
                    <span class="crumbs-now">个人资料</span>
                </div>
                <div class="content-bottom-title">
                    <div class="content-top-top-left">
                        <div class="content-top-top-left-text">个人资料</div>
                        <div class="content-top-top-left-back"></div>
                    </div>
                </div>
                <div class="content-content">
                    <div class="contentform-img-left">
                        <span>
                            <img class="" :src="imgSrc" :onerror="defaultimgSrc" />
                        </span>
                        <div class="contentform-img-right cursor" @click="handleUpClick">
                            <span>上传头像</span>
                            <input class="" ref="filesinput" type="file" @change="uploadImage($event)" accept=".JPG,.JPEG,.PNG" />
                        </div>
                    </div>

                    <div class="content-title">昵称</div>
                    <div class="content-input">
                        <div class="input-input">
                            <el-input class="content-input-input" v-model="nickname" placeholder="请输入昵称"></el-input>
                        </div>
                    </div>
                    <div class="content-title">单位名称</div>
                    <div class="content-input">
                        <div class="input-input">
                            <el-input class="content-input-input" v-model="companyname" placeholder="请输入单位名称"></el-input>
                        </div>
                    </div>
                </div>
                <div class="vip-button">
                    <div class="toupgrade cursor" @click="toupgrade">保存</div>
                </div>
                
            </div>
            <Globalmessage ref="message" :duration="3000" :top="100" :widths="1196"/>
        </div>
        <div class="height271"></div>
    </div>
</template>

<script>
import Globalmessage from '../../components/Globalmessage.vue'
export default {
    components: {
        Globalmessage
    },
  data () {
    return {
        nickname: '', // 昵称
        companyname: '', //单位名称

        imgSrc: '', //图片路径用来预览的
        imgupload: null, // 图片用来上传
        userdata: JSON.parse(sessionStorage.getItem('userdata')), // 用户数据

        
    };
  },
    computed: {
        defaultimgSrc () {
            return 'this.src="' + require('../../assets/image/heard.png') + '"';
        }
    },
    created () {
    },
  mounted () {
      
    this.getdata()
    const that = this
    window.addEventListener('setItem', () => {
      that.userdata = JSON.parse(sessionStorage.getItem('userdata'))
      
    //   that.formData.deptId是data里面声明的变量
    //   console.log(that.userdata,JSON.parse(sessionStorage.getItem('userdata')),'监听')
    })
  },
  methods: {
    //   获取用户数据
    getdata () {
        this.nickname = this.userdata.nickname
        this.companyname = this.userdata.company
        this.imgSrc = this.userdata.avatar
        console.log(this.userdata,"用户数据")
    },
    //   回首页
    backhome () {
      this.$router.push('/')
    },
      // 上传文件
    handleUpClick () {
      this.$refs.filesinput.click()
    },
    //   上传图片并预览
    uploadImage (e) {
        let file = e.target.files[0]; //获取第一个文件
        this.imgupload = file
        if (file == undefined) {
            return false
        }
        let filename = file.name

        let pos = filename.lastIndexOf(".");
        let lastName = filename.substring(pos, filename.length);
        // console.log(lastName, lastName.toLowerCase())
        if (
            lastName.toLowerCase() !== ".jpg" &&
            lastName.toLowerCase() !== ".png"&&
            lastName.toLowerCase() !== ".jpeg"
            // lastName.toLowerCase() !== ".zip"&&
            // lastName.toLowerCase() !== ".rar"&&
            // lastName.toLowerCase() !== ".xls"&&
            // lastName.toLowerCase() !== ".xlsx"
        ) {
            this.$refs.message.error('文件必须为 JPG、PNG、JPEG 类型') // success调用
            // this.$message.error("文件必须为.pdf .doc .docx .zip .rar .xls .xlsx类型");
            // this.$refs.fileinput.clearFiles()
            return false
        }

        let img = new FileReader();
        img.readAsDataURL(file);
        // console.log("img:",img,file)
        img.onload = ({ target }) => {
        this.imgSrc = target.result; //将img转化为二进制数据
        // console.log("imgSrc:",this.imgSrc)
        };
    },
    //   保存
    toupgrade () {
        if (this.nickname == '') {
            this.$message({
                message: '请填写昵称',
                type: 'error'
            });
            return false
        }
        // if (this.companyname=='') {
        //     this.$message({
        //         message: '请填写单位名称',
        //         type: 'error'
        //     });
        //     return false
        // }
        
        console.log(this.nickname,this.companyname,this.imgSrc,this.imgupload)
        if (this.imgupload!=null) {
            let parimg = new FormData
            parimg.append("files", this.imgupload)
            this.$apis.UploadImg(parimg).then((resimg) => {
                if (resimg.code==200) {
                    this.submitall(resimg.result.imgUrl)
                }
                
                // console.log(resimg,"上传图片")
            })
        }else{
            this.submitall(this.imgSrc)
        }
        
    },
    // 提交
    submitall (val) {
        
        // console.log(val,val.indexOf('http')>-1,val.slice(val.indexOf('temp'),-1),"提交")
        // // return false
        // if (val.indexOf('http')>-1) {
        //     val = val.slice(val.indexOf('temp'),-1)
        // }

        if (val!='' && val!=null && val!=undefined) {
            // 修改头像
            // let params = new FormData
            // params.append('avatar', val)
            // this.$userdata.editAvatar(params).then((res) => {
            //     // console.log(res,"修改头像")
            // })
        }
        // 修改昵称
        let paramss = new FormData
        // paramss.append('avatar', val)
        if (val.indexOf('http')>-1) {
            // console.log(val,'提交的')
        }else{
            paramss.append('avatar', val)
        }
        paramss.append('nickname', this.nickname)
        paramss.append('company', this.companyname)
        this.$userdata.editUserInfo(paramss).then((ress) => {
            if (ress.code==200) {
                sessionStorage.clear()
                // 获得用户数据并保存
                this.$login.getUserInfo().then((ress) => {
                    // console.log(ress.result, "获得用户信息")
                    // sessionStorage.setItem('userdata', JSON.stringify(ress.result));
                    this.setSessionItem('userdata', JSON.stringify(ress.result))
                    if (ress.code==200) {
                        this.$message({
                            message: '恭喜你，修改成功',
                            type: 'success'
                        });
                    }
                })
            }
        })
        // this.$userdata.editNickname(paramss).then((ress) => {
        //     // console.log(ress,"修改昵称")
        // })
        return;
        // 修改单位名称
        if(this.companyname){
            let paramsss = new FormData
            paramsss.append('company', this.companyname)
            this.$userdata.editCompany(paramsss).then((resss) => {
                // console.log(resss,'修改单位名称')
                if (resss.code==200) {
                    sessionStorage.clear()
                    // 获得用户数据并保存
                    this.$login.getUserInfo().then((ress) => {
                        // console.log(ress.result, "获得用户信息")
                        // sessionStorage.setItem('userdata', JSON.stringify(ress.result));
                        this.setSessionItem('userdata', JSON.stringify(ress.result))
                        if (ress.code==200) {
                            this.$message({
                                message: '恭喜你，修改成功',
                                type: 'success'
                            });
                        }
                    })
                }
            })
        }
        
        
        
    },
  }
};
</script>
<style lang="scss">
#Personaldata {
    .content-input-input{
        .el-input__inner{
            width: 510px;
            height: 66px;
            border-radius: 14px;
            // background:rgba(255,255,255,0);
            background: #F9FAFB;
            border: 0;
            font-size: 18px;
        }
        .el-input__inner::placeholder {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #A8ABB8;
        }
        /* 谷歌 */
        .el-input__inner::-webkit-input-placeholder {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #A8ABB8;
        }
        /* 火狐 */
        .el-input__inner:-moz-placeholder {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #A8ABB8;
        }
        /*ie*/
        .el-input__inner:-ms-input-placeholder {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #A8ABB8;
        }
    }
}
</style>
<style lang="scss" scoped>
#Personaldata {
    .crumbs {
        width: 200px;
        height: 20px;
        margin:  0 0 3px 24px;
        padding: 20px 0 0 0;
        .crumbs-before {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
        }
        .crumbs-fenkai {
        font-size: 12px;
        font-family: Helvetica;
        margin: 0 8px;
        color: rgba(0,0,0,0.45)
        }
        .crumbs-now {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0,0,0,0.65);
        }
    }
    .height271{
        height: 271px;
    }
    .vip-button{
        margin: 40px 0 0 24px;
        width: 704px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .toupgrade{
            width: 335px;
            height: 50px;
            background: #3D5CFF;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            
        }
    }
    .nocursor{
        cursor:not-allowed;
    }
    .content{
        width:1196px;
        margin:  0 0 0 24px;
        .content-bottom{
            width: 1196px;
            // height: 550px;
            background: #FFFFFF;
            box-shadow: 2px 0px 16px 0px rgba(0,21,41,0.08);
            border-radius: 14px;
            // margin: 24px 0 0 0;
            padding: 0 0 24px 0;
            .content-content{
                width: 1068px;
                min-height: 223px;
                margin: 0px 0px 0 24px;
                .contentform-img-left{
                    width: 432px;
                    display: flex;
                    align-items: center;
                    margin: 33px 0 0 0 ;
                    img{
                        width: 80px;
                        height: 80px;
                        border-radius: 8px;
                        margin: 0px 14px 0 0 ;
                    }
                    span{
                        position: relative;
                        .closebutten{
                            position: absolute;
                            width: 18px;
                            height: 18px;
                            top: 0px;
                            right: 0px;
                        }
                    }
                    .contentform-img-right{
                        width: 90px;
                        height: 42px;
                        background: #E2E7FF;
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0px 0px 0 0 ;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #1F1F39;
                        input{
                            display: none;
                        }
                    }
                }
                .content-input{
                    display: flex;
                    margin: 16px 0 0 0;
                    .input-input{
                        width: 510px;
                        height: 66px;
                    }
                    .sendnumber{
                        width: 140px;
                        height: 66px;
                        background: #E2E7FF;
                        border-radius: 14px;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #1F1F39;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0 0 0 24px;
                    }
                }
                .content-title{
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #5E5F60;
                    margin: 34px 0 0 0 ;
                }
                .content-text1{
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #2E3E5C;
                    letter-spacing: 1px;
                    margin: 41px  0 0 0;
                }
                .content-text2{
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #858597;
                    margin: 8px 0 13px 0;
                }

            }
            .content-bottom-title{
                width: 80px;
                height: 25px;
                padding: 20px 0 0 0px;
                
            }
            .title-botttom{
                width: 100px;
                display: flex;
                align-items: center;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #8189B0;
                margin: 6px 0 0 31px;
                img{
                    width: 22px;
                    height: 22px;
                }
            }
            
        }
        .content-top-top-left{
            width: 80px;
            height: 25px;
            display: flex;
            align-items: flex-end;
            position: relative;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2F3236;
            margin: 0 0 0 24px;
            &-text {
            margin-left: 8px;
            }
            .content-top-top-left-back {
                width: 26px;
                height: 8px;
                background: #3d5cff;
                opacity: 0.3;
                position: absolute;
                left: 0;
                bottom: 2px;
            }
        }
        
    }
}
</style>