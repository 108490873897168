<template>
  <div id="indexes" v-loading="loading">
    <!-- title -->
    <div class="title flex align between">
      <!-- line -->
      <div class="flex column">
        <!-- 标题 -->
        <div class="title__line flex align">
          <span class="father cursor" @click="backtwo">{{twolevel.name}}</span>
          <img
            src="../../../assets/icon/icon_more_major.png"
            class="img"
            alt=""
          />
          <span class="son cursor">{{threelevel.name}}</span>
        </div>
        <!-- 单词数量 -->
        <p class="num">共{{threelevel.total}}个词</p>
      </div>
      <!-- 按钮 -->
      <p class="button cursor" v-show="planStatus" @click="planStatus = false">
        制定学习计划
      </p>
    </div>
    <!-- 选择 -->
    <div class="select flex align between" v-show="!planStatus">
      <!-- 未选、已选、全选 -->
      <div class="select__content flex align between">
        <div
          class="select__content__piece bakc-violet"
        >
          <p class="piece-title">{{ threelevel.total-chooseNumber }}</p>
          <p class="piece-num">未选</p>
        </div>
        <div
          class="select__content__piece"
        >
          <p class="piece-title">{{ chooseNumber }}</p>
          <p class="piece-num">已选</p>
        </div>
        <div
          class="select__content__piece"
        >
          <p class="piece-title">{{ threelevel.total }}</p>
          <p class="piece-num">全部</p>
        </div>
      </div>
      <!-- 按钮 -->
      <div class="select__button flex align">
        <div class="botton-all flex align justify cursor" @click="allClick()">
          <img
            src="../../../assets/icon/chose.png"
            class="all-img"
            v-show="allStatus==0"
            alt=""
          />
          <img
            src="../../../assets/icon/choses.png"
            class="all-img"
            v-show="allStatus==1"
            alt=""
          />
          <img
            src="../../../assets/icon/choose-half.png"
            class="all-img"
            v-show="allStatus==2"
            alt=""
          />
          全选
        </div>
        <div
          class="botton-confirm flex align justify cursor"
          @click="showpopupStatus"
        >
          确定
          <p class="confirm-num">(已选{{ this.chooseNumber }})</p>
        </div>
      </div>
    </div>
    <div class="present">
      <p>{{ pickIndex }}</p>
    </div>
    <div class="list flex between" id="out" >
      <!-- 索引列表 -->
      <div>
        <div id="out-data" style="min-height:580px">
          <p
            class="list__line cursor flex align"
            v-for="(items, keys) in dataList"
            :key="keys"
            id="text"
            @click="wordClick(items, keys)"
          >
            <span v-show="!planStatus" class="flex align">
              <img
                src="../../../assets/icon/chose.png"
                class="line-img"
                v-show="!items.isselect"
                alt=""
              />
              <img
                src="../../../assets/icon/choses.png"
                class="line-img"
                v-show="items.isselect"
                alt=""
              />
            </span>
            {{ items.english }}
          </p>
        </div>
        <div style="height: 20px"></div>
      </div>
      <!-- 索引key -->
      <div class="list__key">
        <p
          v-for="(item, key) in showIndexList"
          :key="key"
          @click="indexClick(item)"
          :class="pickIndex == item ? 'cursor back-orange' : 'cursor'"
        >
          {{ item }}
        </p>
      </div>
    </div>
    <!-- 制定学习计划弹窗 -->
    <el-dialog
      title=""
      width="410px"
      :visible.sync="popupStatus"
      :before-close="handleClose"
    >
      <div class="popup flex column align">
        <!-- title -->
        <p class="popup__title">制定学习计划</p>
        <!-- tag -->
        <div class="popup__special flex align">
          <p class="popup__special__tag">{{letter}}</p>
          <p class="popup__special__title">{{twolevel.name}}</p>
          <p class="popup__special__num">({{chooseNumber}})</p>
        </div>
        <!-- content -->
        <div class="popup__content flex column align">
          <p class="popup__content__day">预计剩余完成时间 {{howtime}} 天</p>
          <p class="popup__content__hint">请输入每天学习单词量</p>
          <div class="popup__content__num flex align">
            <input type="text" onkeyup="this.value=this.value.replace(/\D|^0/g,'')" v-on:input="howtimes" v-model="studyNum" />
            <p>词</p>
          </div>
        </div>
        <!-- button -->
        <!-- <div class="popup__button flex justify">
          <p
            class="popup__button__confirm flex align justify cursor"
            @click="planconfirmClick()"
          >
            确定
          </p>
        </div> -->
        <el-button class="popup__button popup__button__confirm"  @click="planconfirmClick()" type="primary" :loading="confirmLoading">{{confirmLoading?'加载中':'确定'}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
let pinyin = require('js-pinyin');
export default {
  props: {
    threelevel: {
      // 自动关闭的延时
      type: Object,
      default: {},
    },
    twolevel: {
      // 自动关闭的延时
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      loading: false, // 加载
      howtime: '0', // 需要花费的天数
      letter: 'A', // 大写的字母
      studyNum: '20', // 输入每天学习的单词俩
      planStatus: true, // 初始化 制定学习计划按钮
      allStatus: 0, // 初始化 是否全选
      chooseNumber:0,
      needPostIds:[], //指定学习计划提交的ids 半选时提交取消勾选的  未全选时提交勾选的
      popupStatus: false, // 制定学习计划弹窗
      confirmLoading:false,//指定学习计划加载中
      indexList: [
        "#"
      ],
      showIndexList:[
        "#",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      dataList: [
      ],
      //舍弃分页加载
      dateListPage:{
        pageNo:1,
        pageSize:5000,
        queryIndex:0
      },
      //区间加载 页面只存在100条数据
      dataListRange:{
        mainIndex:0, //初始index
        mark:2,  //1上翻100条  2下翻100条
        english:''
      },
      //防抖参数
      debounceTime:null,
      pickIndex: "#",
    };
  },
  watch:{
    threelevel: {  
        handler: function (val) {
          if (val!={}) {
            this.dataListRange.mainIndex = 0
            this.dataListRange.mark = 2
            this.getThreeVocabularyById()
          } 
        },
        immediate: true,
        deep: true
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    document.getElementById('out').addEventListener("scroll", this.checkRoll);
  },
  methods: {
    // 计算多长时间
    howtimes () {
      // console.log(this.studyNum)
      if (this.studyNum == '') {
        this.howtime = '1'
        return false
      }
      this.howtime = Math.ceil(this.chooseNumber / this.studyNum)
      if (this.howtime == 'NaN') {
        this.howtime = '1'
      }
    },
    // 回去
    backtwo () {
      this.$parent.indexStatus = false
    },
    // 根据三级分类id获取词汇数据
    getThreeVocabularyById () {
      let self=this;
      console.log(this._isDestroyed)
      if(this._isDestroyed){
        return
      }
      if (this.threelevel=={}||this.threelevel.name==null||this.threelevel.name==undefined) {
        return false
      }
      this.letter = pinyin.getFullChars(this.twolevel.name)
      this.letter = this.letter.slice(0,1)
      this.loading = true
       
      let params = new FormData
      params.append('id',this.threelevel.id)
      params.append('mainIndex',this.dataListRange.mainIndex)
      params.append('mark',this.dataListRange.mark)
      if(this.dataListRange.english){
        params.append('english',this.dataListRange.english)
      }
      this.$apis.getResultThesaurusList(params).then((res) => {
        // console.log(res,"词汇数据")
        
        if (res.code==200) {
         
          if (res.result.length!=0) {
           
            for(let i = 0; i < res.result.length; i++){
               
              if(this.allStatus== 1 || this.allStatus==2){
                if(this.needPostIds.filter(v=>v == res.result[i].id).length!=0){
                  res.result[i].isselect = false
                }else{
                  res.result[i].isselect = true
                }
                
              }else{
                if(this.needPostIds.filter(v=>v == res.result[i].id).length!=0){
                  res.result[i].isselect = true
                }else{
                  res.result[i].isselect = false
                }
              }
              
              // console.log(this.indexList.indexOf(res.result.records[i].english.slice(0,1).toUpperCase()))
              //导出索引
              // if(this.indexList.indexOf(res.result.records[i].english.slice(0,1).toUpperCase())==-1 && this.CheckStr(res.result.records[i].english.slice(0,1))){
              //   this.indexList.push(res.result.records[i].english.slice(0,1).toUpperCase())
              // }else{
                
              // }
            }
            if(this.dataListRange.mark==2){
              this.dataList = this.dataList.concat(res.result)
              if(this.dataListRange.english!='')
              setTimeout(() => {
                document.getElementById("out").scrollTop=5
              }, 10);
            }else if(this.dataListRange.mark==1){
              this.dataList = res.result.concat(this.dataList)
              let preIndex = this.dataList.findIndex(d=>d.mainIndex == this.dataListRange.mainIndex)
              this.$nextTick(()=>{
                document.getElementById("out").scrollTop = (preIndex*57)-10
              })

            }else{}
            }
            this.loading = false
            this.dataListRange.english =''
        }
        
      })
    },
    // 显示学习计划弹窗
    showpopupStatus () {
      if (this.chooseNumber==0) {
        return false
      }
      this.howtime = '0', // 需要花费的天数
      this.studyNum = '20', // 输入每天学习的单词俩
      this.howtimes()
      this.confirmLoading = false
      this.popupStatus = true
    },
    // 学习计划弹窗确定
    planconfirmClick() {
      
      if (this.studyNum == 0 || this.studyNum == '') {
        this.$message({
                message: '请填写每日学习词数',
                type: 'error'
            });
        return false
      }
      let type = 0
      let ids = ''
      let postList = []
      let allWord = 0
       if(this.allStatus==0){
        //没有全选  ids提交选中的itemid
        type=3
        postList = this.dataList.filter(d=>d.isselect==true)
        allWord=postList.length
      }else if(this.allStatus==1){
        //全选
        type=1
        allWord=this.chooseNumber
      }else if(this.allStatus==2){
        //半全选  ids提交取消选中的itemid
        type=2
        postList = this.dataList.filter(d=>d.isselect==false)
        allWord= this.chooseNumber - postList.length
      }else{

      }
      
      for(let i = 0; i<postList.length; i++){
        ids += postList[i].id + ','
      }
      if (ids.length > 0) {
        ids = ids.substr(0, ids.length - 1);
      }
      
      let params = new FormData
      params.append('allWord',allWord)
      params.append('firstName',this.letter)
      params.append('majorId',this.threelevel.id)
      params.append('name',this.twolevel.name)
      params.append('planDay',this.howtime)
      params.append('planWord',this.studyNum)
      params.append('ids',ids)
      params.append('type',type)
      this.confirmLoading = true
      this.$apis.addStudyPlan(params).then((res) => {
        // console.log(res,"新增计划")
        if (res.code == 200) {
          this.confirmLoading = false
          this.$message({ message: "制定成功", type: "success" });
          this.handleClose()
          this.$router.push('/')
          
        }
      })
      
    },
    // 关闭学习计划弹窗
    handleClose() {
      
      this.popupStatus = false;
    },
    // 全选
    allClick() {
      this.allStatus = this.allStatus==1?0:1;
      this.needPostIds=[]
      if (this.allStatus==1) {
        this.chooseNumber = this.threelevel.total
        for (let i in this.dataList) {
            this.dataList[i].isselect = true
        }
      } else {
        this.chooseNumber = 0
        for (let k in this.dataList) {
            this.dataList[k].isselect = false
        }
      }
    },
    // 单词点击
    wordClick(e, i) {
      if (this.planStatus==true) {
        this.$router.push({
          path: '/searchResultnav',
          query: {
            searchvalue: e.id,
            searchname1: e.chinese,
            searchname3: e.english,
          }
        })
        return false
      }
      
      for(let l = 0; l < this.dataList.length; l++){
        if (e.id == this.dataList[l].id) {
          let idForIndex = this.needPostIds.findIndex((d)=>{return d == this.dataList[l].id})
          if(idForIndex==-1){this.needPostIds.push(e.id)}else{this.needPostIds.splice(idForIndex,1)}
          if (this.dataList[l].isselect == true) {
            this.dataList[l].isselect = false
            this.chooseNumber =this.chooseNumber-1
            if(this.allStatus==1){
              this.allStatus=2
            }
          }else{
            this.dataList[l].isselect = true
            this.chooseNumber =this.chooseNumber+1
             if(this.allStatus==2){
               console.log(this.dataList.filter(d=>d.isselect==false))
              if(this.dataList.filter(d=>d.isselect==false).length==0){this.allStatus=1}
            }
          }
        }
      }
    },
    // 索引点击事件    
    indexClick(e){
      this.dataList = []
      this.dataListRange.mainIndex=0
      this.dataListRange.mark=2
      this.dataListRange.english = e
      this.pickIndex = e
      this.getThreeVocabularyById()
    },
    // 控制滚动条
    roll(e) {
      document.getElementById("out").scrollTop =
        e * document.getElementById("text").offsetHeight;
      // console.log(e);
    },
    
    //监听滚动 改变index
    checkRoll(){
      this.updatePickIndex()

      //防抖
      if(this.debounceTime){
        clearTimeout(this.debounceTime)
      }
      this.debounceTime = setTimeout(() => {
         this.dataListRange.english=''
        //监听上下翻页请求
        if(document.getElementById("out").scrollTop==0){
          //请求上面数据
          console.log("Load Up")
         
          this.dataListRange.mainIndex=this.dataList[0].mainIndex
          this.dataListRange.mark=1
          this.getThreeVocabularyById()
        }else if(document.getElementById("out").scrollTop+document.getElementById("out").offsetHeight>document.getElementById("out-data").offsetHeight){
          //请求下面数据
          console.log("Load More")
          this.dataListRange.mainIndex=this.dataList[this.dataList.length-1].mainIndex
          this.dataListRange.mark=2
          this.getThreeVocabularyById()
        }
      }, 100);
      
    },
    updatePickIndex(){
      //首字母变化
      let rollTopItemIndex =Math.trunc(document.getElementById("out").scrollTop / document.getElementById("text").offsetHeight)
      let rollTopItemWrold
      if(this.CheckStr( this.dataList[rollTopItemIndex].english.slice(0,1))){
        rollTopItemWrold = this.dataList[rollTopItemIndex].english.slice(0,1).toUpperCase()
      }else{
        rollTopItemWrold = '#'
      }
      this.pickIndex = rollTopItemWrold
    },
    CheckStr(str) {
      let code = str.charCodeAt(0)
      return (code>=65 && code<=90) || (code>=97 && code<=122)
    }

  },
};
</script>

<style lang="scss">
#indexes {
  width: 966px;
  height: 684px;
  background: #fff;
  border-radius: 14px;
  margin-left: 14px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  .popup {
    &__content {
      width: 362px;
      height: 200px;
      margin-top: 24px;
      background: linear-gradient(90deg, #eef1f9 0%, #f8fcff 98%);
      border-radius: 14px;
      &__num {
        margin-top: 13px;
        input {
          width: 100px;
          text-align: center;
          border: none;
          background: none;
          font-size: 32px;
          color: #141414;
        }
      }
      &__day {
        color: #858597;
        font-size: 13px;
        margin-top: 27px;
      }
      &__hint {
        color: #1f1f1f;
        font-size: 15px;
        margin-top: 18px;
      }
    }
    &__button {
      width: 100%;
      margin-top: 30px;
      &__confirm {
        width: 362px;
        height: 50px;
        border-radius: 12px;
        font-size: 16px;
        color: #fff;
        background: #3d5cff;
      }
    }
    &__special {
      width: 338px;
      height: 32px;
      padding: 12px 16px;
      margin-top: 32px;
      background: #ffffff;
      box-shadow: 0px 8px 12px -4px rgba(184, 184, 210, 0.2);
      border-radius: 12px;
      &__num {
        color: #909090;
        font-size: 13px;
      }
      &__title {
        font-size: 16px;
        color: #1f1f39;
        font-weight: 600;
      }
      &__tag {
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin-right: 13px;
        background: #f4f9ff;
        box-shadow: 0px 8px 12px -4px rgba(184, 184, 210, 0.2);
        border-radius: 10px;
        font-size: 20px;
        color: #8189b0;
      }
    }
    &__title {
      width: 100%;
      text-align: center;
      margin-top: -46px;
      color: #2e3e5c;
      font-size: 20px;
    }
  }
  .select {
    padding: 14px 10px;
    &__button {
      .botton-confirm {
        width: 220px;
        height: 50px;
        border-radius: 12px;
        background: #3d5cff;
        color: #fff;
        margin-left: 14px;
        font-size: 16px;
        .confirm-num {
          font-size: 12px;
        }
      }
      .botton-all {
        width: 122px;
        height: 50px;
        color: #6975af;
        font-size: 17px;
        border-radius: 12px;
        background: #f4f7ff;
        .all-img {
          width: 32px;
          height: 32px;
        }
      }
    }
    &__content {
      width: 280px;
      height: 54px;
      padding: 0 4px;
      background: #f4f7ff;
      border-radius: 12px;
      &__piece {
        width: 78px;
        height: 46px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        .piece-title {
          color: #434343;
          font-size: 15px;
        }
        .piece-num {
          color: #8c8c8c;
          font-size: 13px;
        }
      }
    }
  }
  .bakc-violet {
    background: #e4e5ff;
  }
  .back-orange {
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    background: #fe550d;
    color: #fff;
    border-radius: 50%;
  }
  .present {
    width: 862px;
    height: 18px;
    line-height: 18px;
    padding: 9px 52px;
    background: #f5f6f8;
    color: #999999;
  }
  .list {
    overflow: hidden;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &__line {
      width: 862px;
      height: 56px;
      line-height: 56px;
      margin: 0 52px;
      border-bottom: 1px solid #f4f4f5;
      .line-img {
        width: 32px;
        height: 32px;
        margin-top: 2px;
      }
    }
    &__key {
      position: absolute;
      right: 22px;
      top: 210px;
      font-size: 11px;
      color: #999999;
      text-align: center;
    }
  }
  .title {
    width: 930px;
    height: 51px;
    padding: 18px 20px;
    background: linear-gradient(360deg, #f7fbff 0%, #ffffff 100%);
    border-radius: 14px 14px 0px 0px;
    .button {
      width: 114px;
      height: 32px;
      background: #3d5cff;
      border-radius: 8px;
      color: #fff;
      line-height: 32px;
      text-align: center;
      font-size: 14px;
    }
    .num {
      color: #6975af;
      font-size: 12px;
      margin-top: 14px;
    }
    &__line {
      font-size: 20px;
      font-weight: 600;
      .father {
        color: #6975af;
      }
      .son {
        color: #2e3e5c;
      }
      .img {
        width: 22px;
        height: 22px;
      }
    }
  }
}
</style>