<template>
  <div id="popupNewadd">
    <el-dialog
      title=""
      width="480px"
      :visible.sync="status"
      :before-close="newClose"
    >
      <div class="popup">
        <p class="popup__title flex justify">新建收藏夹</p>
        <!-- input -->
        <div class="popup__content">
          <p class="text">标题</p>
          <input type="text" v-model="name" placeholder="请输入收藏夹标题" />
        </div>
        <!-- set default -->
        <div class="popup__default">
          <p :class="defaultStatus ? 'texts' : 'text'" @click="defaultClick()">
            设为默认文件夹
          </p>
        </div>
        <!-- button -->
        <div class="popup__button flex justify">
          <p class="text flex align justify cursor" @click="surecreate">创建</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      // 自动关闭的延时
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultStatus: false,
      name: '', //收藏夹名字
    };
  },
  mounted() {},
  methods: {
    // 设为默认文件夹
    defaultClick() {
      this.defaultStatus = !this.defaultStatus;
    },
    // 创建收藏夹
    surecreate () {
      if (this.name=='') {
        return false
      }
          let params = new FormData
          params.append('name', this.name)
          params.append('collectDefault', this.defaultStatus ? '0' : '1')
          this.$apis.addCollect(params).then((res) => {
            if (res.code==200) {
              this.$message({ message: "创建成功", type: "success" });
              this.newClose()
            }
            
            // console.log(res,"添加收藏夹")
          })
      
    },
    // 关闭弹窗
    newClose () {
      this.defaultStatus = false
      this.name=''
      this.$emit("newCloses",false)
    }
  },
};
</script>

<style lang="scss">
#popupNewadd {
  .el-dialog {
    border-radius: 12px;
  }
  .popup {
    width: 100%;
    &__default {
      margin-top: 12px;
      .text {
        width: 96px;
        height: 26px;
        border-radius: 6px;
        border: 1px solid #dde0e6;
        font-size: 12px;
        color: #8189b0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .texts {
        width: 96px;
        height: 26px;
        border-radius: 6px;
        border: 1px solid #dde0e6;
        font-size: 12px;
        color: #fff;
        background: #3d5cff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
    &__content {
      margin-top: 32px;
      input {
        width: 412px;
        height: 66px;
        background: #f9fafb;
        border: none;
        color: #a8abb8;
        font-size: 18px;
        border-radius: 14px;
        padding-left: 20px;
      }
      input::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #a8abb8;
      }

      input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #a8abb8;
      }

      input::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #a8abb8;
      }

      input:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: #a8abb8;
      }
      .text {
        color: #5e5f60;
        font-size: 15px;
        margin-bottom: 16px;
      }
    }
    &__button {
      width: 100%;
      margin-top: 43px;
      .text {
        width: 400px;
        height: 50px;
        color: #fff;
        font-size: 16px;
        border-radius: 12px;
        background: #3d5cff;
      }
    }
    &__title {
      width: 100%;
      margin-top: -42px;
      font-size: 18px;
      color: #2e3e5c;
    }
  }
}
</style>