<!-- 个人中心我的账户 -->
<template>
    <div id="account">
        <div class="content">
            <div class="content-top">
                <div class="content-top-top">
                    <div class="content-top-top-left">
                        <div class="content-top-top-left-text">账户总览</div>
                        <div class="content-top-top-left-back"></div>
                    </div>
                    <div class="content-top-top-right cursor" v-show="userdata.level!=0" @click="tovip">
                        获取更多免费额度
                        <img src="../../assets/icon/icon_more2.png">
                    </div>
                </div>
                <div class="content-top-bottom">
                    <div class="content-top-bottom-top">
                        <div class="content-top-bottom-top-text">免费翻译额度</div>
                        <div class="content-top-bottom-top-num">
                            <div>{{Overview.freeAll-Overview.freeUsed? Overview.freeAll-Overview.freeUsed: '0'}}（已用）</div>
                            <div class="rate">
                                <div
                                    class="rateing"
                                    :style="'width: ' + ((Overview.freeAll-Overview.freeUsed)/Overview.freeAll) * 100 + '%'"
                                ></div>
                            </div>
                            <div>{{Overview.freeUsed?Overview.freeUsed:'0'}}（剩余）</div>
                        </div>
                       
                    </div>
                    <div class="content-top-bottom-bottom">
                        <div class="content-top-bottom-bottom-text">
                            <img src="../../assets/icon/icon_wenben.png">
                            <div class="bottom-text-text">
                                <span class="bottom-text-top">文本字数</span>
                                <span class="bottom-text-bottom">{{Overview.word?Overview.word:'0'}}</span>
                            </div>
                        </div>
                        <div class="content-top-bottom-bottom-text">
                            <img src="../../assets/icon/icon_wenjian.png">
                            <div class="bottom-text-text">
                                <span class="bottom-text-top">文档个数</span>
                                <span class="bottom-text-bottom">{{Overview.doc?Overview.doc:'0'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-bottom">
                <div class="content-bottom-title">
                    <div class="content-top-top-left">
                        <div class="content-top-top-left-text">我的订单</div>
                        <div class="content-top-top-left-back"></div>
                    </div>
                </div>
                <div class="content-bottom-table">
                    <div class="content-bottom-table-list" v-show="listData.length!=0" v-for="item in listData" v-loading="loading">
                        <div class="content-bottom-table-list-left">
                            <div class="table-list-left-title">
                                <p>订单号：{{item.payNumber}}</p>
                                <span>￥{{item.payPrice}}</span>
                            </div>
                            <div class="table-list-left-content">
                                <div class="original-text">
                                    <img src="../../assets/icon/yuanwen.png">
                                    <div class="original-text-content">
                                        <div class="original-text-content-left">
                                            <p class="text-name">{{item.content}}</p>
                                            <p class="text-number">{{item.translate}}字</p>
                                            
                                        </div>
                                        <img class="original-text-content-right cursor" v-show="item.type==1" @click="downoriginalUrl(item)" src="../../assets/icon/icon_donload.png">
                                    </div>
                                </div>
                                <div class="translation-text">
                                        <img src="../../assets/icon/yiwen.png">
                                        <div class="original-text-content">
                                            <div class="original-text-content-left">
                                                <p class="text-name" >{{item.translationContent}}</p>
                                                <!-- v-show="item.type==0" -->
                                                <!-- <p class="text-name" v-show="item.type==1">{{item.content}}</p> -->
                                                <p class="text-number">{{item.translate}}字</p>
                                                
                                            </div>
                                            <img class="original-text-content-right cursor" v-show="item.type==1"  @click="downtranslationUrl(item)" src="../../assets/icon/icon_donload.png">
                                        </div>
                                </div>
                            </div>
                            <div class="table-list-left-button">
                                <div class="button1 cursor"  v-show="item.invoiceState==0" @click="toinvoiceState(item)">申请发票</div>
                                <div class="button1 cursor" v-show="item.invoiceState!=0" @click="lookinvoiceState">查看发票</div>
                                <div class="button2 cursor" @click="downtranslationUrl(item)" v-show="item.type==1">下载译文</div>
                            </div>
                        </div>
                        <div class="content-bottom-table-list-right">
                            <div class="table-list-right-content">
                                <div class="table-list-right-content-title">订单明细</div>
                                <div class="table-list-right-content-text">
                                    <span class="textstyle1">收费标准</span><span class="textstyle2">{{item.charges}}元/千字</span>
                                </div>
                                <div class="table-list-right-content-text">
                                    <span class="textstyle1">字符数</span><span class="textstyle2">{{item.translate}}</span>
                                </div>
                                <div class="table-list-right-content-text">
                                    <span class="textstyle1">小计</span><span class="textstyle2">￥{{item.price}}</span>
                                </div>
                                <div class="table-list-right-content-text">
                                    <span class="textstyle1">优惠（
                                        <span v-show="userdata.level==-1">普通</span>
                                        <span v-show="userdata.level==0">铂金</span>
                                        <span v-show="userdata.level==1">白银</span>
                                        <span v-show="userdata.level==2">黄金</span>会员享{{item.discount}}折）
                                    </span><span class="textstyle2">-￥{{item.discountPrice}}</span>
                                </div><div class="table-list-right-content-text">
                                    <span class="textstyle1">付款时间</span><span class="textstyle2">{{item.payTime}}</span>
                                </div>
                                <div class="table-list-right-content-text">
                                    <span class="textstyle1">总计</span><span class="textstyle2">￥{{item.payPrice}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
                <div v-show="listData.length==0" class="history-none" v-loading="loading">
                        <img src="../../assets/image/img_none.png"/>
                </div>
                 <!-- 分页 -->
                    <div class="paging" v-show="listData.length!=0">
                        
                        <!-- <div :class="currentPage==1?'nopagbutton nocursor':'pagbutton cursor'" @click="firstpage">首页</div> -->
                        <input type="button" value="首页" @click="firstpage" :class="currentPage==1?'nopagbutton nocursor':'pagbutton cursor'" >
                        <!-- <div :class="currentPage==1?'nopagbutton nocursor':'pagbutton cursor'" @click="uppage">上一页</div> -->
                        <input type="button" value="上一页" @click="uppage" :class="currentPage==1?'nopagbutton nocursor':'pagbutton cursor'" >
                        <el-pagination
                            layout="pager"
                            :page-size="pageSize"
                            :page-count="totalPage"
                            :current-page="currentPage"
                            @current-change="currentchange"
                            >
                        </el-pagination>
                        <!-- <div :class="currentPage==totalPage?'nopagbutton nocursor':'pagbutton cursor'" @click="nextpage">下一页</div> -->
                        <input type="button" value="下一页" @click="nextpage" :class="currentPage==totalPage?'nopagbutton nocursor':'pagbutton cursor'"  >
                        <!-- <div :class="currentPage==totalPage?'nopagbutton nocursor':'pagbutton cursor'" @click="lastpage">尾页</div> -->
                        <input type="button" value="尾页" @click="lastpage" :class="currentPage==totalPage?'nopagbutton nocursor':'pagbutton cursor'">
                    </div>
                
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
    },
  data () {
    return {
        userdata: JSON.parse(sessionStorage.getItem('userdata')), // 用户数据
        loading: false, // 是否再加载
        Overview: {}, // 剩余翻译数
        textnumber: '123',
        filenumber: '0',
        // 分页
        totalPage: 1, // 统共页数，默认为1
        currentPage: 1, // 当前页数 ，默认为1
        pageSize: 4, // 每页显示数量
        listData: [
        ], // 当前页显示内容
    };
  },
    computed: {
    },
  mounted () {
      this.$login.getUserInfo().then((ress) => {
        // console.log(ress.result, "获得用户信息")
        // sessionStorage.setItem('userdata', JSON.stringify(ress.result));
        this.setSessionItem('userdata', JSON.stringify(ress.result))
        console.log(JSON.parse(sessionStorage.getItem('userdata')),'55555')
        // if (ress.code==200) {
        //     this.$message({
        //         message: '恭喜你，修改成功',
        //         type: 'success'
        //     });
        // }
    })

      this.getuserOverview()
      this.myTranslateOrderList()
      const that = this
        window.addEventListener('setItem', () => {
            that.userdata = JSON.parse(sessionStorage.getItem('userdata'))
            //that.formData.deptId是data里面声明的变量
            // console.log(that.userdata,JSON.parse(sessionStorage.getItem('userdata')),'监听')
        })
  },
  methods: {
    //   下载原文
    downoriginalUrl (e) {
        if (e.originalUrl=='') {
            this.$message({
                message: '订单原文异常',
                type: 'error'
            });
            return false
        }
        let fileUrl = e.originalUrl  //服务器文件地址
        this.getBlob(fileUrl).then(blob => {
            this.saveAs(blob, e.content)
        })
    },
    //   下载译文
    downtranslationUrl (e) {
        if (e.translateState == 0) {
            this.$message({
                message: '文档翻译中',
                type: 'error'
            });
            return false
        }
        if (e.translationUrl=='') {
            this.$message({
                message: '订单译文异常',
                type: 'error'
            });
            return false
        }
        let fileUrl = e.translationUrl  //服务器文件地址
        this.getBlob(fileUrl).then(blob => {
            this.saveAs(blob, e.translationContent)
        })
    },
    //通过文件下载url拿到对应的blob对象
    getBlob(url) {
      return new Promise(resolve => {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.responseType = 'blob'
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(xhr.response)
          }
        }

        xhr.send()
      })
    },
    //下载文件 　　js模拟点击a标签进行下载
    saveAs(blob, filename) {
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      link.click()
    },
    //   申请发票
    toinvoiceState (e) {
        this.$router.push({
            path: '/Applyinvoice',
            query: {
            id: e.id,
            type: '1'
            }
        })
    },
    //  查看发票
    lookinvoiceState () {
        this.$router.push({path: "/Myinvoice"})
    },
    //   列表数据
    myTranslateOrderList () {
        this.loading = true
        let params = new FormData
        params.append('pageNo',this.currentPage)
        params.append('pageSize',this.pageSize)
        // let params = {
        //     pageNo: this.currentPage,
        //     pageSize: this.pageSize
        // }
        this.$userdata.myTranslateOrderListWeb(params).then((res) => {
            // console.log(res,'列表')
            if (res.code==200) {
                this.listData = res.result.records
                this.totalPage = res.result.size
                this.loading = false
            }
        })
        
    },
    //   获得用户还有多少剩余可免费翻译
    getuserOverview () {
        this.$userdata.userOverview().then((res) => {
            // console.log(res,'获得用户还有多少剩余可免费翻译')
            if (res.code==200) {
                this.Overview = res.result
            }
        })
    },
    //   去开vip
    tovip () {
        this.$router.push({path: "/Myvip"})
    },
    //   点击首页
      firstpage () {
          if(this.currentPage <= 1) {
              return false
          }
          this.currentPage = 1
          this.myTranslateOrderList()
      },
    //   上一页
        uppage () {
            if(this.currentPage <= 1) {
                return false
            }
            this.currentPage = this.currentPage - 1
            this.myTranslateOrderList()
        },
        // 点击尾页
        lastpage () {
            if(this.currentPage >= this.totalPage) {
                return false
            }
          this.currentPage = this.totalPage
          this.myTranslateOrderList()
        },
        // 点击下一页
        nextpage () {
            if(this.currentPage >= this.totalPage) {
                return false
            }
            this.currentPage = this.currentPage + 1
            this.myTranslateOrderList()
        },
    //   当前页数变化
      currentchange (val) {
          this.currentPage = val
          this.myTranslateOrderList()
      }
  }
};
</script>

<style lang="scss" scoped>
#account {
    .history-none {
        display: flex;
        align-content: center;
        justify-content: center;
        // margin-bottom: 20px;
        img {
        width: 100px;
        height: 100px;
        }
    }
    .nocursor{
        cursor:not-allowed;
    }
    .content{
        width:1196px;
        margin:  0 0 0 24px;
        .paging{
            margin:  24px 0 0 0;
            display: flex;
            align-items: center;
            justify-content: center;
            ::v-deep {
            .el-pagination {
                    margin:  0 12px;
                    .el-pager li:not(.disabled){
                        line-height: 35px;
                        width: 35px;
                        height: 35px;
                        font-size: 14px;
                        font-family: HelveticaNeue;
                        color: #565D64;
                        border-radius: 100%;
                    }
                    .el-pager li:not(.disabled).active{
                        line-height: 35px;
                        width: 35px;
                        height: 35px;
                        background: #4E555C;
                        font-size: 14px;
                        font-family: HelveticaNeue;
                        color: #FFFFFF;
                    }
                }
            }
            .el-pager li.active{
                width: 32px;
                height: 32px;
                background: #4E555C;
                font-size: 14px;
                font-family: HelveticaNeue;
                color: #FFFFFF;
            }
            .pagbutton{
                background: #fff;
                border: none;
                width: 42px;
                height: 20px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #565D64;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .nopagbutton{
                background: #fff;
                border: none;
                width: 42px;
                height: 20px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #CCD1D4;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .content-bottom{
            width: 1196px;
            min-height: 300px;
            max-height: 1057px;
            background: #FFFFFF;
            box-shadow: 2px 0px 16px 0px rgba(0,21,41,0.08);
            border-radius: 14px;
            margin: 24px 0 0 0;
            padding:  0 0px 24px 0;
            .content-bottom-title{
                width: 80px;
                height: 25px;
                padding: 22px 0 0 0px;
            }
            .content-bottom-table{
                width: 1148px;
                max-height: 876px;
                margin: 20px auto 0;
                
                .content-bottom-table-list{
                    width: 1148px;
                    height: 230px;
                    margin: 20px 0;
                    position:relative;
                    .content-bottom-table-list-left{
                        position: absolute;
                        left: 0px;
                        top: 0px;
                        z-index: 99;
                        width: 811px;
                        height: 230px;
                        background: #FFFFFF;
                        border-radius: 14px;
                        border: 1px solid #E2E7FF;
                        .table-list-left-content{
                            width: 783px;
                            height: 89px;
                            margin: 20px 14px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .original-text{
                                width: 384px;
                                height: 89px;
                                background: #F9FAFB;
                                border-radius: 8px;
                                .original-text-content{
                                    width: 346px;
                                    height: 32px;
                                    margin: 6px 0 0 18px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    .original-text-content-right{
                                        width: 22px;
                                        height: 22px;
                                        margin: 0;
                                    }
                                    .original-text-content-left{
                                        .text-name{
                                            width: 300px;
                                            font-size: 13px;
                                            font-family: PingFangSC-Regular, PingFang SC;
                                            font-weight: 400;
                                            color: #262626;
                                            white-space:nowrap;
                                            overflow:hidden;
                                            text-overflow:ellipsis;
                                        }
                                        .text-number{
                                            font-size: 11px;
                                            font-family: PingFangSC-Regular, PingFang SC;
                                            font-weight: 400;
                                            color: #A8B4C3;
                                            margin: 6px 0 0 0;
                                        }
                                    }
                                }
                                img{
                                    width: 35px;
                                    height: 16px;
                                    margin: 8px 0 0 18px;
                                }
                            }
                            .translation-text{
                                width: 384px;
                                height: 89px;
                                background: linear-gradient(90deg, #EEF1F9 0%, #F8FCFF 98%);
                                border-radius: 8px;
                                border-radius: 8px;
                                .original-text-content{
                                    width: 346px;
                                    height: 32px;
                                    margin: 6px 0 0 18px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    .original-text-content-right{
                                        width: 22px;
                                        height: 22px;
                                        margin: 0;
                                    }
                                    .original-text-content-left{
                                        .text-name{
                                            width: 300px;
                                            font-size: 13px;
                                            font-family: PingFangSC-Regular, PingFang SC;
                                            font-weight: 400;
                                            color: #262626;
                                            white-space:nowrap;
                                            overflow:hidden;
                                            text-overflow:ellipsis;
                                        }
                                        .text-number{
                                            font-size: 11px;
                                            font-family: PingFangSC-Regular, PingFang SC;
                                            font-weight: 400;
                                            color: #A8B4C3;
                                            margin: 6px 0 0 0;
                                        }
                                    }
                                }
                                img{
                                    width: 35px;
                                    height: 16px;
                                    margin: 8px 0 0 18px;
                                }
                                
                            }
                        }
                        .table-list-left-button{
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            .button1{
                                width: 72px;
                                height: 30px;
                                border-radius: 8px;
                                border: 1px solid #B8B8D2;
                                font-size: 13px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #858597;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin: 0 14px 0 0 ;
                            }
                            .button2{
                                width: 72px;
                                height: 30px;
                                border-radius: 8px;
                                border: 1px solid #3D5CFF;
                                font-size: 13px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #3D5CFF;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin: 0 14px 0 0 ;
                            }
                            
                        }
                        .table-list-left-title{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            p{
                                font-size: 12px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #A8ABB8;
                                margin: 14px 0 0 14px;
                            }
                            span{
                                font-size: 14px;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #FE550D;
                                margin: 14px 14px 0 0;
                            }
                            
                        }
                    }
                    .content-bottom-table-list-right{
                        position: absolute;
                        right: 0px;
                        top: 0px;
                        width: 360px;
                        height: 230px;
                        background: #F9FAFB;
                        border-radius: 14px;
                        display: flex;
                        justify-content: flex-end;
                        .table-list-right-content{
                            width: 307px;
                            height: 176px;
                            margin: 14px 0 14px 0;
                            .table-list-right-content-title{
                                font-size: 11px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #A8ABB8;
                            }
                            .table-list-right-content-text{
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin:  15px 16px 0 0;
                                .textstyle1{
                                    font-size: 12px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #666666;
                                }
                                .textstyle2{
                                    font-size: 12px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #1F1F39;
                                }
                            }
                        }
                    }
                }
            }
        }
        .content-top-top-left{
            width: 80px;
            height: 25px;
            display: flex;
            align-items: flex-end;
            position: relative;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2F3236;
            margin: 0 0 0 24px;
            &-text {
            margin-left: 8px;
            }
            .content-top-top-left-back {
                width: 26px;
                height: 8px;
                background: #3d5cff;
                opacity: 0.3;
                position: absolute;
                left: 0;
                bottom: 2px;
            }
        }
        .content-top{
            width: 1196px;
            height: 283px;
            .content-top-bottom{
                height: 214px;
                border-radius: 0px 0px 14px 14px ;
                background: #FFFFFF;
                // padding: 24px 0 24px 26px ;
                .content-top-bottom-bottom{
                    width: 1148px;
                    height: 124px;
                    background: #F7F8FC;
                    border-radius: 14px;
                    margin: 24px 0 24px 24px ;
                    display: flex;
                    align-items: center;
                    .content-top-bottom-bottom-text{
                        width: 414px;
                        height: 56px;
                        margin:  0 0 0 42px;
                        display: flex;
                        align-items: center;
                        .bottom-text-text{
                            display: flex;
                            flex-direction: column;
                            margin:  0 0 0 18px;
                            .bottom-text-top{
                                font-size: 14px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #8C8C8C;
                            }
                            .bottom-text-bottom{
                                margin:  6px 0 0 0 ;
                                font-size: 28px;
                                font-family: Helvetica-Bold, Helvetica;
                                font-weight: bold;
                                color: #333333;
                            }
                        }
                        
                        img{
                            width: 56px;
                            height: 56px;
                        }
                    }
                }
                .content-top-bottom-top{
                    display: flex;
                    align-items: center;
                    padding: 24px 0 0px 26px ;
                        .content-top-bottom-top-text{
                            font-size: 13px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #8C8C8C;
                        }
                        .content-top-bottom-top-num{
                            font-size: 13px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #8C8C8C;
                            display: flex;
                            align-items: center;
                            margin: 0 0 0 54px ;
                        }
                    .rate {
                        width: 250px;
                        height: 6px;
                        background: #f4f3fd;
                        border-radius: 22px;
                        overflow: hidden;
                        margin: 0 10px 0 5px;
                        .rateing {
                            height: 6px;
                            background: linear-gradient(
                            90deg,
                            rgba(255, 255, 255, 0) 0%,
                            #ff5106 100%
                            );
                        }
                    }
                }
            }
            .content-top-top{
                width: 1196px;
                height: 69px;
                background: linear-gradient(360deg, #F7FBFF 0%, #FFFFFF 100%);
                border-radius: 14px 14px 0px 0px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                box-shadow: 2px 0px 16px 0px rgba(0,21,41,0.08);
                
                .content-top-top-right{
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #3D5CFF;
                    display: flex;
                    align-items: center;
                    margin: 0 18px 0 0;
                    img {
                    width: 22px;
                    height: 22px;
                    }
                }
                
            }
        }
    }
}
</style>