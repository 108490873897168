<!-- 科工词汇用户协议 -->
<template>
    <div id="Useragreement">
        <div class="content">
            
            <div class="content-bottom">
                <div class="content-bottom-title">
                    <!-- <div class="content-top-top-left">
                        <div class="content-top-top-left-text">科工词汇用户协议</div>
                        <div class="content-top-top-left-back" ></div>
                    </div> -->
                </div>
                <div class="content-content" v-if="!!agreementtext" v-html="agreementtext.userContent">
                </div>
                
            </div>
            <Globalmessage ref="message" :duration="3000" :top="100" :widths="1196"/>
        </div>
    </div>
</template>

<script>
import Globalmessage from '../../components/Globalmessage.vue'
export default {
    components: {
        Globalmessage
    },
  data () {
    return {
        agreementtext: {}
    };
  },
    computed: {
    },
    created () {
    },
  mounted () {
    this.$login.getUserAgreement().then((reses) => {
      this.agreementtext = reses.result
    //   console.log( "获得用户协议", this.agreementtext)
    })
  },
  methods: {
    //   确认注销
    toupgrade () {
    },
  }
};
</script>

<style lang="scss" scoped>
#Useragreement {
    min-height: 745px;
    .nocursor{
        cursor:not-allowed;
    }
    .content{
        width:1196px;
        margin:  0 0 0 24px;
        .content-bottom{
            width: 1196px;
            // height: 550px;
            background: #FFFFFF;
            box-shadow: 2px 0px 16px 0px rgba(0,21,41,0.08);
            border-radius: 14px;
            // margin: 24px 0 0 0;
            padding: 0 0 24px 0;
            .content-content{
                width: 1068px;
                min-height: 223px;
                margin: 53px 0px 0 24px;
            
                .content-title{
                    font-size: 19px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #2E3E5C;
                }
                .content-text1{
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #2E3E5C;
                    letter-spacing: 1px;
                    margin: 41px  0 0 0;
                }
                .content-text2{
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #858597;
                    margin: 8px 0 13px 0;
                }

            }
            .content-bottom-title{
                // width: 200px;
                // height: 25px;
                padding: 10px 0 0 0px;
                
            }
            .title-botttom{
                // width: 100px;
                display: flex;
                align-items: center;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #8189B0;
                margin: 6px 0 0 31px;
                img{
                    width: 22px;
                    height: 22px;
                }
            }
            
        }
        .content-top-top-left{
            // width: 200px;
            height: 25px;
            display: flex;
            align-items: flex-end;
            position: relative;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2F3236;
            margin: 0 0 0 24px;
            &-text {
            margin-left: 8px;
            }
            .content-top-top-left-back {
                width: 26px;
                height: 8px;
                background: #3d5cff;
                opacity: 0.3;
                position: absolute;
                left: 0;
                bottom: 2px;
            }
        }
        
    }
}
</style>