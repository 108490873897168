<!-- 个人中心-文档翻译记录 -->
<template>
    <div id="Translatehistory">
        <div class="content">
            
            <div class="content-bottom">
                <div class="content-bottom-title">
                    <div class="content-top-top-left">
                        <div class="content-top-top-left-text">文档翻译记录</div>
                        <div class="content-top-top-left-back"></div>
                    </div>
                    
                </div>
                <div class="content-content" v-loading="loading">
                    <div v-show="tabledata.length!=0" class="content-left">
                        <div v-for="item in tabledata" :class="item.id==selectdata?'isselect    ':'noselect cursor'" @click="selectone(item)">
                            <div class="select-text">
                                <p>{{item.content}}</p>
                            </div>
                            <img v-show="item.id==selectdata" src="../../assets/icon/icon_more3.png"/>
                        </div>
                    </div>
                    
                    <div v-show="tabledata.length!=0" class="content-right">
                        <div class="content-right-top">
                            <div class="content-bottom-title">
                                <div class="content-top-top-left">
                                    <div class="content-top-top-left-text">原文</div>
                                    <div class="content-top-top-left-back"></div>
                                </div>
                                
                            </div>
                            <div class="content-words">
                                <div class="content-words-left">
                                    <div class="content-words-content">
                                    {{rightdata.content}}
                                    </div>
                                    <img class="cursor" @click="downFile(rightdata.originalUrl,'原文'+rightdata.content)" src="../../assets/icon/icon_xiazai.png"/>
                                </div>
                            </div>
                            <!-- <div class="right-bottom-content">
                                <p>报纸</p>
现今报纸拥有极大的价值,人人都应该看它。它每天提供我们各种类类的消息。它告诉我们世界政治局势。如果我们养成看报的习惯，我们就能得到足够的知识来因应我们的环境。 学生虽然每天须做功课,但他们至少应该匀出一两个小时来看报。哪些,他们不但能增加知识而且也能赶上时代。总而言之,看报对学生很有益处。
                            </div> -->
                        </div>
                        <div class="content-right-bottom">
                            <div class="content-bottom-title">
                                <div class="content-top-top-left">
                                    <div class="content-top-top-left-text">译文</div>
                                    <div class="content-top-top-left-back"></div>
                                </div>
                                
                            </div>
                            <div class="content-words">
                                <div class="content-words-left">
                                    <div class="content-words-content">
                                    {{rightdata.translationContent}}
                                    </div>
                                    <img class="cursor" @click="downFile(rightdata.translationUrl,rightdata.translationContent)" src="../../assets/icon/icon_xiazai.png"/>
                                </div>
                            </div>
                            <!-- <div class="right-bottom-content">
                               <p>THE NEWSPAPER </p>
 Nowadays the newspaper possesses considerable value Everybody should read it. It supplies us with a variety of news every day. It tells us the political situation of the world. If we form the habit of reading the newspaper, we shall (will) get enough knowledge to cope with our circumstances. 
                            </div> -->
                        </div>
                        <div class="content-right-top" style="margin-top:20px;color:#6975AF">
                            
                            <div class="content-bottom-title" style="display:flex;width: 100%;justify-content: space-between;align-items: center;">
                                <div class="content-top-top-left">付款时间</div>
                                <div class="content-top-top-right" style="margin-right:20px">{{rightdata.createTime}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div v-show="tabledata.length==0" class="history-none">
                    <img src="../../assets/image/img_none.png"/>
                </div>
                
                
            </div>
        </div>
        <Globalmessage ref="message" :duration="3000" :top="100" :widths="1000"/>
    </div>
</template>

<script>
import Globalmessage from '../../components/Globalmessage.vue'
export default {
    components: {
        Globalmessage
    },
  data () {
    return {
        loading: false, // 加载
        selectdata: '0', // 选中的数据
        rightdata: {}, // 选中的数据
        // table的数据
        tabledata: [
        ], 
        textnumber: '123',
        filenumber: '0',
        // 分页
        totalPage: 7, // 统共页数，默认为1
        currentPage: 1, // 当前页数 ，默认为1
        pageSize: 4, // 每页显示数量
    };
  },
    computed: {
    },
  mounted () {
      this.getdata()
  },
  methods: {
      //文件下载
    downFile(val,name) {
      let fileUrl = val  //服务器文件地址
      this.getBlob(fileUrl).then(blob => {
        this.saveAs(blob, name)
      })
    },
    //通过文件下载url拿到对应的blob对象
    getBlob(url) {
      return new Promise(resolve => {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.responseType = 'blob'
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(xhr.response)
          }
        }

        xhr.send()
      })
    },
    //下载文件 　　js模拟点击a标签进行下载
    saveAs(blob, filename) {
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      link.click()
    },
    //   获得数据
    getdata () {
        this.loading = true
        let params = new FormData
        params.append('pageNo',1)
        params.append('pageSize',9999)
        this.$translation.getTranslationList(params).then((res) => {
            // console.log(res,"获得数据")
            if (res.code == 200) {
                this.tabledata = res.result
                if (this.tabledata.length!=0) {
                    this.selectdata = this.tabledata[0].id
                    this.rightdata = this.tabledata[0]
                }
                this.loading = false
            }
        })
    },
      // 收藏
    collect () {
      if (this.iscollect == 0) {
        this.iscollect = 1
      } else {
        this.iscollect = 0
      }
    },
    // 选中那条数据
      selectone (id) {
          if (this.selectdata == id.id) {
              return false
          }
          this.selectdata = id.id
          this.rightdata = id
        //   console.log(id)
      },
    
    //   复制
    copynumber () {
        let url = 'this.couriernumber';
        //新建一个文本框
        let oInput = document.createElement('input');
        //赋值给文本框
        oInput.value = url;
        document.body.appendChild(oInput);
        // 选择对象;
        oInput.select(); 
        // 执行浏览器复制命令
        document.execCommand("Copy"); 
        //复制完成删除掉输入框
        oInput.remove()
        this.$refs.message.success('复制成功') // success调用
        // console.log('复制成功')
    },
    
  }
};
</script>
<style lang="scss">
</style>
<style lang="scss" scoped>
#Translatehistory {
    .content-words {
        width: 602px;
        // height: 141px;
        // min-height: 141px;
        position: relative;
        // display: flex;
        // align-content: center;
        // justify-content: space-between;
        .content-words-left {
            // width: 602;
            height: 120px;
            // background: linear-gradient(90deg, #EEF1F9 0%, #F8FCFF 98%);
            border-radius: 14px;
        //   margin: 4px 4px 0px 4px ;
            display: flex;
            align-items: center;
            justify-content: space-between;
            img{
                width: 32px;
                height: 32px;
                margin:  0 32px 0 0;
            }
        }
        .content-words-content{
            width: 300px;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #6975AF;
            margin: 0 0 0 30px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .kong{
        height: 20px;
    }
    .isselect{
        width: 522px;
        height: 63px;
        background: linear-gradient(90deg, #EEF1F9 0%, #F8FCFF 98%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        direction: ltr;
        img{
            width: 28px;
            height: 29px;
            margin:  0 20px 0 0 ;
        }
        
    }
    .select-text{
        width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin:  0 0 0 30px;
        
        p{
            font-size: 17px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
        }
        span{
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #8C8C8C;
            margin: 8px 0 0 0;
        }
    }
    .noselect{
        width: 522px;
        height: 63px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        direction: ltr;
        img{
            width: 28px;
            height: 29px;
            margin:  0 20px 0 0 ;
        }
    }
    .history-none {
        display: flex;
        align-content: center;
        justify-content: center;
        margin: 177px auto;
        img {
        width: 100px;
        height: 100px;
        }
    }
    .nocursor{
        cursor:not-allowed;
    }
    
    .content{
        width:1196px;
        margin:  0 0 0 24px;
        .content-bottom{
            width: 1196px;
            min-height: 550px;
            background: #FFFFFF;
            box-shadow: 2px 0px 16px 0px rgba(0,21,41,0.08);
            border-radius: 17px;
            // margin: 24px 0 0 0;
            .content-content{
                width: 1148px;
                display: flex;

                .content-right{
                    width: 602px;
                    margin: 30px 0 0 0;
                    .content-right-top{
                        width: 602px;
                        height: 180px;
                        background: linear-gradient(90deg, #EEF1F9 0%, #F8FCFF 98%);
                        border-radius: 14px;
                        margin: 0px 0 0 24px;
                    }
                    .right-bottom-content{
                        width: 542px;
                        margin: 15px auto 0;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #1F1F1F;
                    }
                    .content-right-bottom{
                        width: 602px;
                        height: 180px;
                        background: #FFFFFF;
                        box-shadow: 0px 0px 12px 2px rgba(184,184,210,0.2);
                        border-radius: 14px;
                        margin: 16px 0 0 24px;
                        .content-bottom-title{
                            width: 108px;
                            height: 25px;
                            padding: 18px 0 0 0px;
                        }
                        .content-top-top-left{
                            width: 108px;
                            height: 25px;
                            display: flex;
                            align-items: flex-end;
                            position: relative;
                            font-size: 18px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #2F3236;
                            margin: 0 0 0 20px;
                            &-text {
                            margin-left: 8px;
                            }
                            .content-top-top-left-back {
                                width: 26px;
                                height: 8px;
                                background: #3d5cff;
                                opacity: 0.3;
                                position: absolute;
                                left: 0;
                                bottom: 2px;
                            }
                        }
                    }
                    
                        
                    

                }
                .content-left{
                    width: 522px;
                    height: 614px;
                    background: #F7F8FC;
                    border-radius: 14px;
                    margin: 30px 0 24px 24px;
                    overflow-y:auto;
                    overflow-x: hidden;
                    direction: rtl;
                }
                // 定义滚动条的轨道颜色，内阴影及圆角
                .content-left::-webkit-scrollbar-track{
                    // background-color:#F5F5F5;
                    // -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3);
                    // border-radius:10px;
                }
                .content-left::-webkit-scrollbar{
                    width:6px;
                    height:200px;
                    background-color:rgba(0,0,0,0);
                }
                /*定义滑块颜色、内阴影及圆角*/
                .content-left::-webkit-scrollbar-thumb {
                    // -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);
                    background: #D8D8D8;
                    border-radius: 3px;
                }
            }
            
            
            .content-bottom-title{
                width: 120px;
                height: 25px;
                padding: 23px 0 0 0px;
            }
            
        }
        .content-top-top-left{
            width: 120px;
            height: 25px;
            display: flex;
            align-items: flex-end;
            position: relative;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2F3236;
            margin: 0 0 0 24px;
            &-text {
            margin-left: 8px;
            }
            .content-top-top-left-back {
                width: 26px;
                height: 8px;
                background: #3d5cff;
                opacity: 0.3;
                position: absolute;
                left: 0;
                bottom: 2px;
            }
        }
        
    }
}
</style>