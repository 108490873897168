<template>
  <div id="home">
    <div class="body">
      <!-- 搜索 -->
      <div class="box-search">
        <div class="search">
          <div class="search-icon cursor" >
            <img src="../assets/icon/icon_sear.png" @click="enterInput" alt="" class="search-img" />
          </div>
          <div class="search-value">
            <el-input
              v-model="searchValue"
              placeholder="请输入文字或单词"
              @focus="focusInput()"
              v-on:input="onInput()"
              clearable
              @clear="clearsearch"
              @keyup.enter.native="enterInput"
            ></el-input>
            <!-- @keyup.enter.native="searchend(searchValue)" -->
          </div>
        </div>
        <!-- 搜索中 -->
        <div class="entering" :style="'opacity:' + entering" v-show="entering">
          <div id="entering-row">
            <div
              class="entering-row cursor"
              @click="searchend(item, 1)"
              v-for="(item, key) in SearchingList"
              :key="key"
            >
              <div
                class="entering-row-title"
                v-html="brightenKeyword(item.name1, searchValue)"
              >
                {{ item.name1 }}
              </div>
              <div class="entering-row-content">
                <span v-html="brightenKeyword(item.name2, searchValue)">{{
                  item.name2
                }}</span
                ><span v-html="brightenKeyword(item.name3, searchValue)">{{
                  item.name3
                }}</span>
              </div>
            </div>
            <div class="record-null" v-show="SearchingList.length == 0">
              暂无数据
            </div>
          </div>
        </div>
        <!-- 搜索记录 -->
        <div
          class="record"
          :style="'opacity:' + opacityValue"
          v-show="opacityValue"
        >
          <div class="record-history">
            <div>历史记录</div>
            <i
              class="el-icon-delete cursor"
              @click="deleteSearchRecord"
              style="color: #ccc; font-size: 16px"
            ></i>
          </div>
          <div class="record-null" v-show="SearchRecordList.length == 0">
            暂无历史记录~
          </div>
          <div id="record-row" style="overflow-y: auto;height: 276px;">
            <div
              class="record-row cursor"
              v-for="(item, key) in SearchRecordList"
              @click="searchend(item, 0)"
              :key="key"
            >
              <div class="record-row-title">{{ item.name1 }}</div>
              <div class="record-row-content">
                {{ item.name3 }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 内容 -->
      <div class="content">
        <!-- 每日金句 -->
        <div class="box">
          <div class="days">
            <div class="lien-title">
              <div class="lien-title-text">每日金句</div>
              <div class="lien-back"></div>
            </div>
            <div class="words" v-loading="Dailyloading">
              <div class="words-title">{{ DailySentence.showTime }}</div>
              <div class="words-content">
                <div class="words-content-text">
                  {{ DailySentence.english }}
                </div>
                <div class="words-content-title">
                  {{ DailySentence.chinese }}
                </div>
              </div>
              <img src="../assets/image/yinhao.png" alt="" class="words-img2" />
              <img
                src="../assets/image/img_mrjj.png"
                alt=""
                class="words-img"
              />
            </div>
          </div>
          <!-- 导航 -->
          <div class="nav">
            <div class="nav-major cursor" @click="toskip('Specialtynav')">
              <div class="nav-major-title">专业导航</div>
              <img
                src="../assets/image/img_nav.png"
                alt=""
                class="nav-major-img"
              />
            </div>
            <div class="nav-my cursor" @click="toskip('Collection')">
              <div class="nav-my-title">个人收藏</div>
              <img src="../assets/image/img_my.png" alt="" class="nav-my-img" />
            </div>
          </div>
        </div>
        <!-- 今日学习 -->
        <div class="study">
          <div class="lien-title">
            <div class="lien-title-text">今日学习</div>
            <div class="lien-back"></div>
          </div>
          <div class="study-content" v-loading="Studyloding">
            <!-- 制定计划 -->
            <div
              class="row"
              @click="toskip('Allplan')"
              v-show="StudyPlanListWeb.length == 0"
            >
              <div class="row-title">今日学习</div>
              <div class="row-content">
                <div class="row-content-honorific">
                  您还没有学习计划，快去制定吧~
                </div>
                <div class="row-content-plan cursor">
                  制定计划<i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
            <!-- 学习计划 -->
            <div
              class="row"
              v-show="StudyPlanListWeb.length != 0"
              v-for="(item, key) in StudyPlanListWeb"
              :key="key"
            >
              <div class="row-subject">
                <div :style="item.studyState == 1 ? 'margin-left: 60px' : ''">
                  {{ item.name }}
                </div>
                <div class="row-subject-time">
                  <!-- {{ item.actualDay }}/{{ item.planDay }}天 -->
                  已学{{ item.studyWord }}/{{ item.allWord }}
                </div>
              </div>
              <div class="row-line">
                <div class="row-line-detail">
                  <div class="row-line-detail-title">{{ item.firstName }}</div>
                  <div class="row-line-detail-num">
                    <!-- <span class="row-line-detail-num-text">{{item.studyWord}}</span>/{{ item.planWord }}个 -->
                    今日待学习<span class="row-line-detail-num-text">{{item.quantity}}</span>词
                    
                  </div>
                </div>
                <div class="row-content-plan cursor" @click="skip(item,key)">
                  {{ studyList[item.studyState]
                  }}<i class="el-icon-arrow-right"></i>
                </div>
              </div>
              <!-- 进度条 -->
              <div class="row-schedule">
                <div
                  class="row-schedule-achieve"
                  :style="
                    'width: ' + (item.studyWord / item.planWord) * 100 + '%'
                  "
                ></div>
              </div>
              <div class="row-present" v-show="item.studyState == 1">
                当前在学
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 内容 -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      wxCode: "", // 微信code
      StudyPlanListWeb: [], // 学习计划
      Studyloding: false, // 学习计划加载
      DailySentence: {}, // 每日金句
      Dailyloading: false, // 每日金句的加载
      SearchingList: [], // 搜索中的
      entering: 0, // 搜索输入中的下来框是否显示
      SearchRecordList: [], // 搜索记录
      userdata: JSON.parse(sessionStorage.getItem("userdata")), // 用户数据
      searchValue: "", // 搜索value
      // studyList: ["没有学习计划", "继续学习", "未学习", "开始学习", "已完成"],
      // studyState 0没有学习计划 1当前在学 2未学习 3继续学习 4已完成 5重新学习
      studyList: ["没有学习计划", "当前在学", "未学习", "继续学习", "已完成"],
      opacityValue: 0, // 搜索框是否显示
      login: this.$store.state.loginStatus,
      searchHtml: "", // 搜索关键字颜色初始化Html
    };
  },
  mounted() {
    //如果本地存储的数据historyList有值，直接赋值给data中的historyList
    if (JSON.parse(localStorage.getItem("historyList"))) {
      this.SearchRecordList = JSON.parse(localStorage.getItem("historyList"));
    }
    this.getDailySentence();
    this.getStudyPlanListWeb();
    this.searchHtml = document.getElementById("record-row").innerHTML; // 记录原始的Html
    document.addEventListener("click", (res) => {
      // console.log(res.target.className)
      // 这个判断的效果  document.addEventListener点击页面执行hidder
      // 这样我们点击弹框里面的事件也会触发很不友好 所以给当前点击的事件加上判断而不执行hidder
      if (res.target.className == "el-input__inner") {
        return;
      }
      if (res.target.className == "record-row") {
        return;
      }
      if (res.target.className == "el-icon-delete") {
        return;
      }
      if (res.target.className == "record-row-content") {
        return;
      }
      if (res.target.className == "record-row-title") {
        return;
      }

      this.hidder();
    });
    this.$login.getUserAgreement().then((reses) => {
      this.setSessionItem("agreements", JSON.stringify(reses.result));
      // console.log(ress, "获得用户协议", that.agreementtext)
    });

    const that = this;
    window.addEventListener("setItem", () => {
      console.log('1111111111111',)
      that.userdata = JSON.parse(sessionStorage.getItem("userdata"));
      //that.formData.deptId是data里面声明的变量
      // console.log(that.userdata,JSON.parse(sessionStorage.getItem('userdata')),'监听')
    });
  },
  created() {
    // console.log(JSON.parse(sessionStorage.getItem('userdata')))
  },
  //监听路由可以检测返回的code
  watch: {
    // $route: {
    //   handler: function(route) {
    //     this.wxCode = this.$route.query.code||''
    //     //本地存储code是因为从其他页面返回vue页面存在缓存需要自定义刷新
    //     if(this.wxCode == localStorage["wxCode"]&&this.wxCode!=''){
    //       window.location.href=this.redirect_uri//回调地址
    //     }else{
    //       localStorage.setItem("wxCode",this.wxCode);//设置b为"isaac"
    //     }
    //     if(this.wxCode){
    //       let params = {
    //         code:this.wxCode,
    //         state:'',
    //       }
    //       // 调接口
    //       // wechatLogin(params).then(res=>{
    //       //   if(res.status){
    //       //     //返回参数如果绑定微信返回token正常存储登录未绑定账号返回unionid绑定当然也可以以微信信息为主题自动生成账号
    //       //   }
    //       // }).catch(() => {
    //       // //返回失败因为二维码已经被使用过所以需要刷新重新获取
    //       //   window.location.href=this.redirect_uri
    //       // });
    //     }
    //   },
    //   immediate: true
    // }
  },
  methods: {
    // 获得路由穿的值
    getdata() {
      // console.log(this.$route.query,this.$route.params,"文件信息")
      this.feildata = this.$route.params;
      // console.log(this.feildata.translationContent,this.$route.params,"文件信息")
    },
    // 删除查询记录列表
    deleteSearchRecord() {
      localStorage.removeItem("historyList");
      this.SearchRecordList = [];
      // this.$apis.deleteSearchRecord().then((res) => {
      //   console.log(res,"学习计划")
      //   if (res.code==200) {
      //   }
      // })
    },
    // 获得右侧学习计划
    getStudyPlanListWeb() {
      if (this.userdata == null) {
        return false;
      }
      this.Studyloding = true;

      this.$apis.getStudyPlanList().then((res) => {
        // console.log(res,"学习计划")
        if (res.code == 200) {
          this.StudyPlanListWeb = res.result;
          this.Studyloding = false;
        }
      });
      // this.$apis.getStudyPlanListWeb().then((res) => {
      //   console.log(res,"学习计划")
      //   if (res.code==200) {
      //     this.StudyPlanListWeb = res.result
      //     this.Studyloding = false
      //   }
      // })
    },
    // 每日金句
    getDailySentence() {
      this.Dailyloading = true;
      this.$apis.getDailySentence().then((res) => {
        if (res.code == 200) {
          this.DailySentence = res.result;
          this.Dailyloading = false;
        }

        // console.log(res,"每日金句")
      });
    },
    // 跳转搜索结果
    searchend(e, k) {
      console.log('searchend',e,k)
      // if (k==1&&this.userdata!=null) {
      //   let params = new FormData
      //   params.append('id', e.id)
      //   params.append('name4', e.name4)
      //   this.$apis.addSearchRecord(params).then((res) => {
      //     if (res.code==200) {
      //     }

      //     // console.log(res,"添加了历史")
      //   })
      // }
      if (this.userdata == null) {
        this.$store.commit("SET_LOGIN", true);
        return false;
      }
      if (k == 1) {
        if (k == 1 && this.userdata != null) {
          let params = new FormData();
          params.append("id", e.id);
          params.append("name4", e.name4);
          this.$apis.addSearchRecord(params).then((res) => {
            if (res.code == 200) {
            }

            // console.log(res,"添加了历史")
          });
        }
        // 没有搜索记录，把搜索值push进数组首位，存入本地
        console.log('this.SearchRecordList.includes(e)',this.SearchRecordList.includes(e))
        let is_have = this.SearchRecordList.filter((x)=>x.id == e.id);
        // if (!this.SearchRecordList.includes(e)) {
        if(is_have.length == 0){
          this.SearchRecordList.unshift(e);
          localStorage.setItem(
            "historyList",
            JSON.stringify(this.SearchRecordList)
          );
        }
        
        // else {
        //   //有搜索记录，删除之前的旧记录，将新搜索值重新push到数组首位
        //   let i = this.SearchRecordList.indexOf(e);
        //   this.SearchRecordList.splice(i, 1);
        //   this.SearchRecordList.unshift(e);
        //   localStorage.setItem(
        //     "historyList",
        //     JSON.stringify(this.SearchRecordList)
        //   );
        // }
        // console.log(this.SearchRecordList,"记录")
      }
      this.$router.push({
        path: "/Searchresult",
        query: {
          searchvalue: e.id,
          searchname1: e.name1,
          searchname3: e.name3,
        },
      });

      // console.log('跳转')
    },
    //   关键词变亮
    brightenKeyword(val, keyword) {
      // 方法1：筛选变色（区分大小写）
      // val = val + '';
      // if (val.indexOf(keyword) !== -1 && keyword !== '') {
      //   if (val === this.currentNodeLabel) {
      //     const resCurrent = val.replace(keyword, `<span style="color: #000;background-color: orange;">${keyword}</span>`)
      //     return resCurrent
      //   } else {
      //     const res = val.replace(keyword, `<span style="color: #000;background-color: yellow;">${keyword}</span>`)
      //     return res
      //   }
      // } else {
      //   return val
      // }
      // 方法2：用正则表达式 （不区分大小写）
      const Reg = new RegExp(keyword, "i");
      if (val) {
        if (val === this.currentNodeLabel) {
          // 这里为什么使用$&不使用keyword，因为这里使用正则表达式不区分大小写，如果是文本时大写，搜索的关键字是小写也是会被匹配的，这样匹配替换掉的话，文本内的文字会被替换成搜索的keyword，也就是改成了小写，这样不太合理
          // const resCurrent = val.replace(Reg, `<span style="color: #000;background-color: orange;">${keyword}</span>`)
          const resCurrent = val.replace(
            Reg,
            `<span style="color: rgba(61, 92, 255, 1);">$&</span>`
          );
          return resCurrent;
        } else {
          const res = val.replace(
            Reg,
            `<span style="color: rgba(61, 92, 255, 1);">$&</span>`
          );
          return res;
        }
      }
    },
    // 清除搜索框
    clearsearch() {
      // console.log(this.searchValue)
    },
    toskip(e) {
      if (this.userdata == null && e.path != "/" && e.path != "#") {
        this.$store.commit("SET_LOGIN", true);
        return false;
      }
      this.$router.push(e);
    },
    // 跳转
    skip(e,key) {
      // studyState 0没有学习计划 1当前在学 2未学习 3继续学习 4已完成 5重新学习

      if (this.userdata == null) {
        this.$store.commit("SET_LOGIN", true);
        return false;
      }
      // console.log(e)
      if (e.studyState == "4") {
        this.$router.push({path:'/Todaylearn',query:{item:e,studyIndex:key}});
        // this.$router.push("/Todaylearn");
        return false;
      }
      if (e.studyState == "1") {
        this.$router.push({path:"/Todaylearn",query:{item:e,studyIndex:key}});
      } else {
        let params = new FormData();
        params.append("id", e.id);
        this.$apis.updateToStudy(params).then((res) => {
          // console.log(res,"去学习")
          if (res.code == 200) {
            this.$router.push({path:"/Todaylearn",query:{item:e,studyIndex:key}});
          }
        });
      }
    },
    // 搜索获得焦点
    focusInput() {
      if (this.searchValue != "") {
        this.onInput();
        return false;
      }
      // if (this.userdata==null) {
      //   return false
      // }
      if (this.opacityValue == 0) {
        // let params = {
        //   pageNo: '1',
        //   pageSize: '7'
        // }
        // this.$apis.getSearchRecordList(params).then((res) => {
        //   if (res.code==200) {
        //     this.SearchRecordList = res.result
        //   }
        //   console.log(res)
        // })
      }

      this.opacityValue = 1;
      this.entering = 0;
    },
    // 监听input输入
    onInput() {
      // console.log(this.searchValue);
      if (this.searchValue == "") {
        this.focusInput();
        return false;
      }
      this.opacityValue = 0;
      this.entering = 1;
      let params = new FormData();
      params.append("name", this.searchValue);
      this.$apis.homeSearch(params).then((res) => {
        if (res.code == 200) {
          this.SearchingList = res.result;
        }
        // console.log(res)
      });

      // var res = new RegExp("[\u4E00-\u9FA5]+");
      // if (this.searchValue) {
      //   this.changeColor("record-row");
      // }else {
      //   document.getElementById("record-row").innerHTML = this.searchHtml
      // }
    },
    // 用户按下回车时触发
    enterInput(e){
      console.log(e);
      if (this.userdata == null) {
        this.$store.commit("SET_LOGIN", true);
        return false;
      }
      
      if(this.SearchingList.length != 0){
        this.$router.push({
          path: "/Searchresult",
          query: {
            searchvalue: this.SearchingList[0].id,
            searchname1: this.SearchingList[0].name1,
            searchname3: this.SearchingList[0].name3,
          },
        });
        let is_have = this.SearchRecordList.filter((x)=>x.id == this.SearchingList[0].id);
        // if (!this.SearchRecordList.includes(e)) {
        if(is_have.length == 0){
          this.SearchRecordList.unshift(this.SearchingList[0]);
          localStorage.setItem(
            "historyList",
            JSON.stringify(this.SearchRecordList)
          );
        }
      }
    },
    // 搜索关键字变色
    changeColor(e) {
      document.getElementById(e).innerHTML = this.searchHtml; // 初始化Html
      var html = document.getElementById(e).innerHTML;
      //定义一个正则表达式
      var reg = new RegExp(this.searchValue, "ig");
      //替换内容 (变色)
      var res = "<span style='color:#3D5CFF;'>" + this.searchValue + "</span>";
      document.getElementById(e).innerHTML = html.replace(reg, res);
    },
    // 点击页面
    hidder() {
      this.opacityValue = 0;
      this.entering = 0;
    },
  },
};
</script>

<style lang="scss">
#home {
  width: 100%;
  margin-top: 33px;
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  .body {
    width: 1169px;
    margin: 0 auto;
    padding-left: 48px;
    padding-bottom: 182px;
    .entering {
      width: 364px;
      max-height: 300px;
      overflow-y: scroll;
      scrollbar-width: none;
      // overflow: hidden;
      // overflow-y: scroll;
      // scrollbar-width: none; /* Firefox */
      // -ms-overflow-style: none; /* IE 10+ */
      position: absolute;
      top: 60px;
      padding: 0px 20px;
      background: #ffffff;
      box-shadow: 0px 0px 12px 2px rgba(184, 184, 210, 0.2);
      border-radius: 14px;
      z-index: 2;
      transition: opacity 0.3s;
      &-row {
        height: 60px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f4f4f5;
        &-title {
          max-width: 250px;
          max-height: 40px;

          overflow: hidden;
          text-overflow: ellipsis; /* 超出部分省略号 */
          word-break: break-all; /* break-all(允许在单词内换行。) */
          display: -webkit-box; /* 对象作为伸缩盒子模型显示 */
          -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
          -webkit-line-clamp: 2; /* 显示的行数 */
          color: #505560;
          font-size: 14px;
        }
        &-content {
          // width: 174px;
          flex: 1;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          text-overflow: ellipsis;
          color: #8c8c8c;
          font-size: 13px;
          margin-left: 24px;
        }
      }
      &-row:last-child {
        border-bottom: none;
      }
      &-null {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgb(164, 164, 164);
      }
      &-history {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #8c8c8c;
        font-size: 12px;
        margin-bottom: 26px;
      }
    }
    .content {
      margin-top: 28px;
      display: flex;
      align-content: center;
      justify-content: space-between;
      .study {
        width: 365px;
        border-radius: 22px;
        opacity: 0.9;
        &-content {
          width: 100%;
          min-height: 100px;
          max-height: 500px;
          overflow: hidden;
          overflow-y: scroll;
          scrollbar-width: none; /* Firefox */
          -ms-overflow-style: none; /* IE 10+ */
          padding-bottom: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 28px;
          background: linear-gradient(90deg, #eceff8 2%, #f7fbff 99%);
          border-radius: 22px;
          opacity: 0.9;
          .row:hover {
            transition: 0.3s;
            margin-top: 14px;
            margin-bottom: 4px;
            box-shadow: 0px 4px 12px 0px rgba(61, 92, 255, 0.18);
          }
          .row {
            width: 305px;
            height: 86px;
            position: relative;
            padding: 12px 15px;
            margin-top: 18px;
            background: #ffffff;
            box-shadow: 0px 8px 12px -4px rgba(184, 184, 210, 0.2);
            border-radius: 12px;
            &-schedule {
              width: 250px;
              height: 6px;
              position: absolute;
              bottom: 16px;
              left: 70px;
              background: #f4f3fd;
              border-radius: 22px;
              overflow: hidden;
              &-achieve {
                height: 6px;
                background: linear-gradient(
                  90deg,
                  rgba(255, 255, 255, 0) 0%,
                  #ff5106 100%
                );
              }
            }
            &-line {
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              margin-top: 10px;
              &-detail {
                display: flex;
                align-items: flex-start;
                &-title {
                  width: 40px;
                  height: 40px;
                  color: #8189b0;
                  font-size: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background: #f4f9ff;
                  box-shadow: 0px 8px 12px -4px rgba(184, 184, 210, 0.2);
                  border-radius: 10px;
                  margin-right: 13px;
                }
                &-num {
                  color: #858597;
                  font-size: 12px;
                  &-text {
                    font-size: 18px;
                    font-weight: bold;
                    color: #1f1f39;
                  }
                }
              }
            }
            &-present {
              width: 68px;
              height: 22px;
              position: absolute;
              left: -2px;
              top: 20px;
              font-size: 12px;
              color: #fff;
              display: flex;
              justify-content: center;
              line-height: 24px;
              background-image: url("../assets/image/bg_present.png");
              background-size: 100% 100%;
            }
            &-subject {
              display: flex;
              align-items: flex-end;
              justify-content: space-between;
              color: #69729c;
              margin-top: 10px;
              &-time {
                font-size: 12px;
              }
            }
            &-content {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 11px;
              &-honorific {
                font-size: 14px;
                color: #757fb3;
              }
              &-plan {
                width: 76px;
                height: 27px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #3d5cff;
                border-radius: 6px;
                font-size: 13px;
                color: #fff;
              }
            }
            &-title {
              color: #858597;
              font-size: 14px;
              margin-top: 10px;
            }
          }
        }
      }
      .study-content::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      .lien-title {
        width: 80px;
        height: 25px;
        display: flex;
        align-items: flex-end;
        position: relative;
        font-size: 16px;
        &-text {
          margin-left: 8px;
        }
        .lien-back {
          width: 26px;
          height: 8px;
          background: #3d5cff;
          opacity: 0.3;
          position: absolute;
          left: 0;
          bottom: 2px;
        }
      }
      .box {
        .nav {
          width: 758px;
          height: 156px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &-my:hover {
            transition: 0.3s;
            margin-top: -10px;
            box-shadow: 0px 4px 12px 0px rgba(61, 92, 255, 0.18);
          }
          &-my {
            width: 360px;
            height: 156px;
            background: #cee6fe;
            border-radius: 24px;
            display: flex;
            justify-content: space-between;
            &-img {
              width: 174px;
              height: 156px;
            }
            &-title {
              width: 156px;
              height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 32px;
              color: #9065be;
              font-size: 24px;
              background: #f3fbff;
              border-radius: 0px 200px 200px 0px;
            }
          }
          &-major:hover {
            transition: 0.3s;
            margin-top: -10px;
            box-shadow: 0px 4px 12px 0px rgba(61, 92, 255, 0.18);
          }
          &-major {
            width: 360px;
            height: 156px;
            background: #cee6fe;
            border-radius: 24px;
            display: flex;
            justify-content: space-between;
            &-img {
              width: 174px;
              height: 156px;
            }
            &-title {
              width: 156px;
              height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 32px;
              color: #3d5cff;
              font-size: 24px;
              background: #f3fbff;
              border-radius: 0px 200px 200px 0px;
            }
          }
          &-my {
            width: 360px;
            height: 156px;
            background: #e9e6ff;
            border-radius: 24px;
          }
        }
        .days {
          width: 758px;
          .words {
            width: 100%;
            min-height: 154px;
            position: relative;
            background: linear-gradient(90deg, #eceff8 2%, #f7fbff 99%);
            border-radius: 22px;
            opacity: 0.9;
            margin-top: 28px;
            margin-bottom: 34px;
            padding-bottom: 60px;
            &-img {
              width: 180px;
              height: 180px;
              position: absolute;
              bottom: -20px;
              right: 0;
            }
            .words-img2 {
              width: 64px;
              height: 53px;
              position: absolute;
              top: 39px;
              left: 26px;
              z-index: -1;
            }
            &-content {
              padding-left: 72px;
              padding-top: 32px;
              &-text {
                width: 550px;
                color: #293235;
                font-size: 30px;
              }
              &-title {
                color: #83868a;
                font-size: 13px;
                margin-top: 12px;
              }
            }
            &-title {
              color: #e2776f;
              font-size: 14px;
              padding-top: 17px;
              padding-left: 26px;
            }
          }
        }
      }
    }
    .box-search {
      position: relative;
      // 搜索记录
      .record {
        // width: 364px;
        max-height: 300px;
        overflow: hidden;
        // overflow-y: scroll;
        // scrollbar-width: none; /* Firefox */
        // -ms-overflow-style: none; /* IE 10+ */
        position: absolute;
        top: 60px;
        padding: 20px;
        background: #ffffff;
        box-shadow: 0px 0px 12px 2px rgba(184, 184, 210, 0.2);
        border-radius: 14px;
        z-index: 2;
        transition: opacity 0.3s;
        &-row {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          &-title {
            max-width: 250px;
            max-height: 40px;

            overflow: hidden;
            text-overflow: ellipsis; /* 超出部分省略号 */
            word-break: break-all; /* break-all(允许在单词内换行。) */
            display: -webkit-box; /* 对象作为伸缩盒子模型显示 */
            -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
            -webkit-line-clamp: 2; /* 显示的行数 */
            color: #505560;
            font-size: 14px;
          }
          &-content {
            width: 174px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            color: #8c8c8c;
            font-size: 13px;
            margin-left: 24px;
          }
        }
        &-null {
          width: 100%;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgb(164, 164, 164);
        }
        &-history {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #8c8c8c;
          font-size: 12px;
          margin-bottom: 26px;
        }
      }
      // .record::-webkit-scrollbar {
      //     display: none; /* Chrome Safari */
      // }
      .search {
        // width: 404px;
        display: flex;
        align-items: center;
        border-radius: 14px;
        overflow: hidden;
        // background: linear-gradient(90deg, #eceff8 2%, #f7fbff 99%);
        background: linear-gradient(to right, #C7D3F1, #F7FBFF);

        // background: #dee4f8;
        &-value {
          // width: 348px;
          width: 1148px;

          height: 56px;
          display: flex;
          align-items: center;
          opacity: 0.9;
          .el-input__inner {
            // background: linear-gradient(90deg, #eceff8 2%, #f7fbff 99%);
            background: linear-gradient(to right, #C7D3F1, #F7FBFF);

            // background: #dee4f8;
            border: none;
            // color: #8189b0 !important;
            font-size: 14px;
          }
        }
        input::-webkit-input-placeholder {
    color: #8189B0;
}

        &-icon {
          width: 56px;
          height: 56px;
          background: #e2e7ff;
          background: #E2E7FF;

          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 14px;
        }
        &-img {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
}
</style>