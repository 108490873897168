import axios from 'axios' //引入
import { getToken, removeToken } from '../utils/auth'
import { Message } from 'element-ui'

// let baseURL = 'http://192.168.10.182:8020/shuangze/api'
// let baseURL = 'http://192.168.10.176:8020/shuangze/api'
// let baseURL = 'http://yilian22.cnnn.net.cn/shuangze/api'
// let baseURL = 'http://192.168.10.182:8020/shuangze/api'
// let baseURL = 'http://192.168.10.85:7700/shuangze/api'
// let baseURL ='http://192.168.10.99:7700/shuangze/api'
let baseURL ='http://yilian22.cnnn.net.cn/shuangze/api'
// let baseURL ='http://192.168.10.81:7700/shuangze/api'
// let baseURL = 'http://kgchm.sunther.com/shuangze/api'


//这一步的目的是判断出当前是开发环境还是生成环境，方法不止一种，达到目的就行
// if(process.env.NODE_ENV=="development"){
//   baseURL=''
// }else{
//   baseURL=''
// }

let config = {
    baseURL: baseURL,
    timeout: 300000 //设置最大请求时间
}
const _axios = axios.create(config)
    /* 请求拦截器（请求之前的操作） */
_axios.interceptors.request.use(
    config => {
        //如果有需要在这里开启请求时的loading动画效果
        config.headers.token = getToken(); //添加token,需要结合自己的实际情况添加，
        return config;
    },
    err => Promise.reject(err)
);

/* 请求之后的操作 */
_axios.interceptors.response.use(
    res => {
        //在这里关闭请求时的loading动画效果
        //这里用于处理返回的结果，比如如果是返回401无权限，可能会是跳回到登录页的操作，结合自己的业务逻辑写
        // console.log(res)
        if (res.data.code !== 200) {
            Message({
                message: res.data.message || 'Error',
                type: 'error',
                duration: 5 * 1000
            })
        }
        if (res.data.code == 403) {
            removeToken()
            sessionStorage.removeItem("userdata")
        }
        return res;
    },
    err => {
        if (err) {
            console.log('err',err)
            //在这里关闭请求时的loading动画效果
            // Toast("请求网络失败")
        }
        return Promise.reject(err);
    }
);

//封装post,get方法
const request = {
    get(url = '', params = {}) {
        return new Promise((resolve, reject) => {
            _axios({
                url,
                params,
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                method: 'GET'
            }).then(res => {
                resolve(res.data)
                return res
            }).catch(error => {
                reject(error)
            })
        })
    },
    post(url = '', params = {}) {
        return new Promise((resolve, reject) => {
            _axios({
                url,
                data: params,
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                method: 'POST'
            }).then(res => {
                resolve(res.data)
                return res
            }).catch(error => {
                reject(error)
            })
        })
    }
}


export default request