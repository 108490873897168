<template>
  <div id="allPlan">
    <div class="body">
      <!-- nav -->
      <p class="body__title"><span class=" cursor" @click="backhome">首页</span> /<span class=" cursor" @click="backup">今日学习</span>  / 全部学习计划</p>
      <!-- title -->
      <div class="title">
        <p>学习计划</p>
        <div class="title__shadow"></div>
      </div>
      <!-- content -->
      <div class="body__content flex">
        <!-- list -->
        <div class="body__content__list flex wrap" v-loading="loading">
          <!-- add -->
          <div class="body__content__add flex column align justify cursor" @click="tonew">
            <img src="../../assets/icon/add.png" class="img" alt="" />
            <p class="text">新建计划</p>
          </div>
          <div class="row" v-for="(item, key) in listdata" :key="key">
            <div class="delicon"><img src="../../assets/icon/icon_del.png" class=" cursor" alt="" @click="delStudyPlan(item)" /></div>
            <div class="row-subject">
              <div :style="item.studyState == 1 ? 'margin-left: 60px' : ''">{{item.name}}</div>
              <!-- <div class="row-subject-time">{{item.actualDay}}/{{item.planDay}}天</div> -->
              <div class="row-subject-time">已学{{ item.studyWord }}/{{ item.allWord }}</div>
               
            </div>
            <div class="row-line">
              <div class="row-line-detail">
                <div class="row-line-detail-title">{{item.firstName}}</div>
                <div class="row-line-detail-num">
                  <!-- <span class="row-line-detail-num-text">{{item.studyWord}}</span>
                  /{{item.planWord}}个 -->
                  今日待学习<span class="row-line-detail-num-text">{{item.quantity}}</span>词
                </div>
              </div>
              <div class="row-content-plan cursor" @click="skip(item,key)">
                {{ studyList[item.studyState] }}<i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <!-- 进度条 -->
            <div class="row-schedule">
              <div
                class="row-schedule-achieve"
                :style="'width: ' + (item.studyWord/item.planWord)* 100 + '%'"
              ></div>
            </div>
            <div class="row-present" v-show="item.studyState == 1">当前在学</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 删除学习计划 -->
    <el-dialog
      title=""
      width="410px"
      top="300px"
      :visible.sync="isshowdel"
      :before-close="delclos"
    >
      <div class="popup flex column align">
        <!-- title -->
        <p class="popup__title">删除学习计划</p>
        <!-- tag -->
        <!-- content -->
        <div slot="title" class="dialog1-top-title">
          </div>
          <div class="dialog1-content">
            确定要删除学习计划吗？
          </div>
          <div class="foot2">
              <el-button class="but2" @click="delclos">取消</el-button>
              <el-button class="but" @click="suredel">确定</el-button>
          </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      deldata: {}, // 要删除的数据
      isshowdel: false, // 是否显示删除
      loading: false, // 加载
      studyList: ["没有学习计划", "当前在学", "未学习", "继续学习", "已完成"],
      listdata: [], // 学习计划列表
    };
  },
  mounted() {
    this.getStudyPlanList()
  },
  methods: {
    // 删除
    delStudyPlan (val) {
      this.isshowdel = true
      // console.log(val)
      this.deldata = val
    },
    // 关闭删除弹窗
    delclos () {
      this.isshowdel = false
      this.deldata = {}
    },
    // 确定删除
    suredel () {
      let params = new FormData
      params.append('planId',this.deldata.id)
      this.$apis.delStudyPlan(params).then((res) => {
        // console.log(res,"删除成功")
        if (res.code == 200) {
          this.$message({ message: "删除成功", type: "success" });
          this.delclos()
          this.getStudyPlanList()
          
        }
      })
    },
    // 获得学习计划列表
    getStudyPlanList () {
      this.loading = true
      this.$apis.getStudyPlanList().then((res) => {
        // console.log(res,"学习计划列表")
        if (res.code==200) {
          this.listdata = res.result
          this.loading = false
        }
      })
    },
    backup () {
      this.$router.push('/todayLearn')
    },
    backhome () {
      this.$router.push('/')
    },
    // 去新建计划
    tonew () {
      this.$router.push({
        path: '/specialtyNav',
        query: {
          planStatus: 1
        }
      })
      // this.$router.push('/specialtyNav')
    },
    skip(e,key) {
      // console.log(e)
      if (e.studyState=='4') {
        this.$router.push({path:'/Todaylearn',query:{item:e,studyIndex:key}});

        return false
      }
      let params = new FormData
      params.append('id', e.id)
      this.$apis.updateToStudy(params).then((res) => {
        // console.log(res,"去学习")
        if (res.code==200) {
          this.$router.push({path:"/Todaylearn",query:{item:e,studyIndex:key}});
        }
      })
    },
  },
};
</script>

<style lang="scss">
#allPlan {
  width: 100%;
  .delicon{
    position: absolute;
    right: 0px;
    top: 0px;
    width: 45px;
    height: 30px;
    img{
      width: 30px;
      height: 30px;
    }
  }
  .el-dialog{
    border-radius: 14px;
  }
  .dialog1-content{
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #5E5F60;
        margin: 30px auto 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        .QRcode{
            width: 400px;
            height: 400px;
        }
    }
    .foot2{
        width: 335px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        .but{
            width: 211px;
            height: 50px;
            background: #3D5CFF;
            border-radius: 12px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            border: none;
        }
        .but2{
            width: 110px;
            height: 50px;
            background: rgba(61, 92, 255, 0.1);
            border-radius: 12px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #6975AF;
            border: none;
        }
    }
  .body {
    width: 1220px;
    margin: 0 auto;
    padding-left: 96px;
    padding-bottom: 100px;
    
    &__content {
      width: 1220px;
      padding: 16px 16px 0;
      margin-top: 28px;
      background: linear-gradient(90deg, #eceff8 2%, #f7fbff 99%);
      border-radius: 22px;
      &__list {
        width: 1220px;
        border-radius: 22px;
        opacity: 0.9;
        .row:hover {
          transition: 0.3s;
          margin-top: -4px;
          margin-bottom: 4px;
          box-shadow: 0px 4px 12px 0px rgba(61, 92, 255, 0.18);
        }
        .row {
          width: 310px;
          height: 86px;
          position: relative;
          padding: 12px 15px;
          margin-right: 16px;
          margin-bottom: 16px;
          background: #ffffff;
          box-shadow: 0px 8px 12px -4px rgba(184, 184, 210, 0.2);
          border-radius: 12px;
          &-schedule {
            width: 250px;
            height: 6px;
            position: absolute;
            bottom: 16px;
            left: 70px;
            background: #f4f3fd;
            border-radius: 22px;
            overflow: hidden;
            &-achieve {
              height: 6px;
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 0%,
                #ff5106 100%
              );
            }
          }
          &-line {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-top: 10px;
            &-detail {
              display: flex;
              align-items: flex-start;
              &-title {
                width: 40px;
                height: 40px;
                color: #8189b0;
                font-size: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #f4f9ff;
                box-shadow: 0px 8px 12px -4px rgba(184, 184, 210, 0.2);
                border-radius: 10px;
                margin-right: 13px;
              }
              &-num {
                color: #858597;
                font-size: 12px;
                &-text {
                  font-size: 18px;
                  font-weight: bold;
                  color: #1f1f39;
                }
              }
            }
          }
          &-present {
            width: 68px;
            height: 22px;
            position: absolute;
            left: 0px;
            top: 20px;
            font-size: 12px;
            color: #fff;
            display: flex;
            justify-content: center;
            line-height: 24px;
            background-image: url("../../assets/image/bg_present.png");
            background-size: 100% 100%;
          }
          &-subject {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            color: #69729c;
            margin-top: 10px;
            &-time {
              font-size: 12px;
            }
          }
          &-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 11px;
            &-honorific {
              font-size: 14px;
              color: #757fb3;
            }
            &-plan {
              width: 76px;
              height: 27px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #3d5cff;
              border-radius: 6px;
              font-size: 13px;
              color: #fff;
            }
          }
          &-title {
            color: #858597;
            font-size: 14px;
            margin-top: 10px;
          }
        }
      }
      &__add {
        width: 136px;
        height: 110px;
        margin-right: 16px;
        margin-bottom: 16px;
        background: #fff;
        box-shadow: 0px 8px 12px -4px rgba(184, 184, 210, 0.2);
        border-radius: 12px;
        .img {
          width: 32px;
          height: 32px;
        }
        .text {
          color: #69729c;
          font-size: 14px;
          margin-top: 14px;
        }
      }
    }
    .title {
      position: relative;
      &__shadow {
        width: 26px;
        height: 8px;
        background: #3d5cff;
        opacity: 0.3;
        position: absolute;
        left: -6px;
        bottom: 2px;
      }
    }
    &__title {
      margin: 34px 0;
      font-size: 12px;
      color: rgb(100, 99, 99);
    }
  }
}
</style>