<!-- 个人中心-我的会员-申请发票 -->
<template>
    <div id="Applyinvoice">
        <div class="content">
            
            <div class="content-bottom">
                  <!-- 上方面包屑 -->
                <div class="crumbs">
                    <span class="crumbs-before cursor" @click="back">我的账户</span>
                    <span class="crumbs-fenkai">/</span>
                    <span class="crumbs-now">申请发票</span>
                </div>
                <div class="content-bottom-title">
                    <div class="content-top-top-left">
                        <div class="content-top-top-left-text">申请发票</div>
                        <div class="content-top-top-left-back"></div>
                    </div>
                </div>
                <div class="content-content">
                    <div class="forms">
                        <div class="forms-content">
                            <div class="forms-content-title">
                                发票类型
                            </div>
                            <div class="forms-input">
                                <div class="forms-radio margleft34">
                                    <img v-show="invoicetype==1" src="../../assets/icon/chose2.png" class="cursor">
                                    <img v-show="invoicetype!=1" src="../../assets/icon/nochose.png" class="cursor" @click="changeinvoice(1)">
                                    <p>
                                        普通发票
                                    </p>
                                </div>
                                <div class="forms-radio">
                                    <img v-show="invoicetype==2" src="../../assets/icon/chose2.png" class="cursor">
                                    <img v-show="invoicetype!=2" src="../../assets/icon/nochose.png" class="cursor" @click="changeinvoice(2)">
                                    <p>
                                        增值税专用发票
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="forms-content" v-show="invoicetype==1">
                            <div class="forms-content-title">
                                抬头类型
                            </div>
                            <div class="forms-input">
                                <div class="forms-radio margleft34">
                                    <img v-show="headertype==1" src="../../assets/icon/chose2.png" class="cursor">
                                    <img v-show="headertype!=1" src="../../assets/icon/nochose.png" class="cursor" @click="changheader(1)">
                                    <p>
                                        个人
                                    </p>
                                </div>
                                <div class="forms-radio">
                                    <img v-show="headertype==2" src="../../assets/icon/chose2.png" class="cursor">
                                    <img v-show="headertype!=2" src="../../assets/icon/nochose.png" class="cursor" @click="changheader(2)">
                                    <p>
                                        单位
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="forms-content" v-show="invoicetype==2">
                            <div class="forms-content-title">
                                发票抬头
                            </div>
                            <div class="forms-input">
                                <el-input class="forms-input-input" v-model="invoiceheader" placeholder="请输入发票抬头"></el-input>
                            </div>
                        </div>
                    </div>
                    <!-- 普通发票情况下的 -->
                    <div class="forms" v-show="invoicetype==1">
                        <div class="forms-content">
                            <div class="forms-content-title">
                                发票抬头
                            </div>
                            <div class="forms-input">
                                <el-input class="forms-input-input" v-model="invoiceheader" placeholder="请输入发票抬头"></el-input>
                            </div>
                        </div>
                        <div class="forms-content" v-show="headertype==2&&invoicetype==1">
                            <div class="forms-content-title">
                                单位税号
                            </div>
                            <div class="forms-input">
                                <el-input class="forms-input-input" v-model="unittaxno" placeholder="请输入单位税号"></el-input>
                            </div>
                        </div>
                    </div>
                    <div class="forms"  v-show="headertype==2&&invoicetype==1">
                        <div class="forms-content">
                            <div class="forms-content-title">
                                注册地址
                            </div>
                            <div class="forms-input">
                                <el-input class="forms-input-input" v-model="registeraddress" placeholder="选填"></el-input>
                            </div>
                        </div>
                        <div class="forms-content">
                            <div class="forms-content-title">
                                开户银行
                            </div>
                            <div class="forms-input">
                                <el-input class="forms-input-input" v-model="bankofdeposit" placeholder="选填"></el-input>
                            </div>
                        </div>
                    </div>
                    <div class="forms"  v-show="headertype==2&&invoicetype==1">
                        <div class="forms-content">
                            <div class="forms-content-title">
                                银行账号
                            </div>
                            <div class="forms-input">
                                <el-input class="forms-input-input" v-model="bankaccount" placeholder="选填"></el-input>
                            </div>
                        </div>
                    </div>
                    <!-- 增值税专用发票情况下的 -->
                    <div class="forms"  v-show="invoicetype==2">
                        <div class="forms-content">
                            <div class="forms-content-title">
                                单位税号
                            </div>
                            <div class="forms-input">
                                <el-input class="forms-input-input" v-model="unittaxno" placeholder="请输入单位税号"></el-input>
                            </div>
                        </div>
                        <div class="forms-content">
                            <div class="forms-content-title">
                                注册地址
                            </div>
                            <div class="forms-input">
                                <el-input class="forms-input-input" v-model="registeraddress" placeholder="选填"></el-input>
                            </div>
                        </div>
                    </div>
                    <div class="forms"  v-show="invoicetype==2">
                        <div class="forms-content">
                            <div class="forms-content-title">
                                开户银行
                            </div>
                            <div class="forms-input">
                                <el-input class="forms-input-input" v-model="bankofdeposit" placeholder="选填"></el-input>
                            </div>
                        </div>
                        <div class="forms-content">
                            <div class="forms-content-title">
                                银行账号
                            </div>
                            <div class="forms-input">
                                <el-input class="forms-input-input" v-model="bankaccount" placeholder="选填"></el-input>
                            </div>
                        </div>
                    </div>
                    <div class="forms"  v-show="invoicetype==2">
                        <div class="forms-content">
                            <div class="forms-content-title">
                                邮寄地址
                            </div>
                            <div class="forms-input cursor" @click="showaddressselect">
                                <div class="forms-input-text">
                                    <p v-show="mailingaddress==''">请选择邮寄地区</p>
                                    <p v-show="mailingaddress!=''">{{mailingaddress}}</p>
                                    <img v-show="mailingaddress==''" src="../../assets/icon/icon_more5.png" >
                                </div>
                            </div>
                        </div>
                        <div class="forms-content">
                            <div class="forms-content-title">
                                详细地址
                            </div>
                            <div class="forms-input">
                                <el-input class="forms-input-input" v-model="detailaddress" placeholder="请输入详细地址"></el-input>
                            </div>
                        </div>
                    </div>
                    <div class="forms"  v-show="invoicetype==2">
                        <div class="forms-content">
                            <div class="forms-content-title">
                                联系电话
                            </div>
                            <div class="forms-input">
                                <el-input class="forms-input-input" v-model="contactnumber" placeholder="请输入接收快递人的联系电话"></el-input>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="vip-button">
                    <div class="toapplyinvoice cursor" @click="suersubmit">提交</div>
                </div>
                
            </div>
            <Globalmessage ref="message" :duration="3000" :top="100" :widths="1196"/>
        </div>
        <!-- 快递单号 -->
        <el-dialog
        title=""
        :visible.sync="isaddressselect"
        width="600px"
        top="232px"
        :close-on-click-modal='false'
        :show-close='false'
        >
            <div slot="title" class="dialog1-top-title">
                <span>请选择邮寄地区</span>
                <img class="cursor" @click="Closeaddressselect" src="../../assets/icon/search_close.png">
            </div>
            <div class="dialog1-content">
                <el-cascader-panel popper-class="pc-sel-area-cascader" v-model="mailingaddressvalue" @change="selectaddress" @expand-change="selectaddresses" :options="addressoptions"></el-cascader-panel>
            </div>
            <div class="foot2">
                <el-button class="but"  @click="sureaddress">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Globalmessage from '../../components/Globalmessage.vue'
import { regionData, CodeToText } from "element-china-area-data";
export default {
    components: {
        Globalmessage
    },
  data () {
    return {
        invoices: {}, // 存下的数据
        id: '', // id
        type :'', // 发票来源：0、会员订单 1、翻译订单
        invoicetype: '1', // 发票类型
        headertype: '1', // 抬头类型

        invoiceheader: '', // 发票抬头
        unittaxno: '', // 单位税号
        registeraddress: '', // 注册地址
        bankofdeposit: '', // 开户银行
        bankaccount: '', // 银行账号
        mailingaddress: '', // 邮寄地址显示的值
        mailingaddressvalue: [], // 邮寄地址实际的值
        detailaddress: '', // 详细地址
        contactnumber: '', // 联系电话

        isaddressselect: false, // 显示地区选择弹窗
        addressoptions: regionData, // 省市区的所有数据
        addressvalue: '', // 省市区选完之后处理好的字符串

    };
  },
    computed: {
    },
    created () {
    },
  mounted () {
      if (JSON.parse(localStorage.getItem("invoice"))) {
        this.invoices = JSON.parse(localStorage.getItem("invoice"));
        }
      this.getvalue()
      this.invalue()
  },
  methods: {
      invalue () {
          console.log('666',this.invoices)
            if (this.invoicetype == '1' && this.headertype == '1') {
                this.invoiceheader = this.invoices.invoiceheader
                console.log(this.invoiceheader,this.invoices.invoiceheader)
            }
            if (this.invoicetype == '1' && this.headertype == '2') {
                this.invoiceheader = this.invoices.invoiceheader
                this.unittaxno = this.invoices.unittaxno // 单位税号
                this.registeraddress = this.invoices.registeraddress // 注册地址
                this.bankofdeposit = this.invoices.bankofdeposit // 开户银行
                this.bankaccount = this.invoices.bankaccount // 银行账号
            }
            if (this.invoicetype == '2') {
                this.invoiceheader = this.invoices.invoiceheader // 发票抬头
                this.unittaxno = this.invoices.unittaxno // 单位税号
                this.registeraddress = this.invoices.registeraddress // 注册地址
                this.bankofdeposit = this.invoices.bankofdeposit // 开户银行
                this.bankaccount = this.invoices.bankaccount // 银行账号
                this.mailingaddress = this.invoices.mailingaddress // 邮寄地址显示的值
                this.mailingaddressvalue = this.invoices.mailingaddressvalue // 邮寄地址实际的值
                this.detailaddress = this.invoices.detailaddress // 详细地址
                this.contactnumber = this.invoices.contactnumber // 联系电话
            }
      },
    suersubmit () {
        // console.log(this.id,this.type,this.invoiceheader)
        if (this.id == '' || this.type == '') {
            return false
        }
        if (this.invoicetype == '1' && (this.invoiceheader == ''||this.invoiceheader == undefined)) {
            this.$message({
                message: '发票抬头不能为空',
                type: 'error'
            })
            return false
        }
        // 发票为增值发票
        if (this.invoicetype == '2' && (this.invoiceheader == ''||this.invoiceheader == undefined)) {
            this.$message({
                message: '发票抬头不能为空',
                type: 'error'
            })
            return false
        }
        if (this.invoicetype == '2' && (this.unittaxno == ''||this.unittaxno == undefined)) {
            this.$message({
                message: '单位税号不能为空',
                type: 'error'
            })
            return false
        }
        if (this.invoicetype == '2' && (this.mailingaddress == ''||this.mailingaddress == undefined)) {
            this.$message({
                message: '邮寄地址不能为空',
                type: 'error'
            })
            return false
        }
        if (this.invoicetype == '2' && (this.detailaddress == ''||this.detailaddress == undefined)) {
            this.$message({
                message: '详细地址不能为空',
                type: 'error'
            })
            return false
        }
        
        if (this.invoicetype == '2' && (this.contactnumber == ''||this.contactnumber == undefined)) {
            this.$message({
                message: '联系电话不能为空',
                type: 'error'
            })
            return false
        }
        
        // 发票为单位时判断
        if (this.headertype == '2' && (this.invoiceheader ==''||this.invoiceheader ==undefined)) {
            this.$message({
                message: '发票抬头不能为空',
                type: 'error'
            })
            return false
        }
        if (this.headertype == '2' && (this.unittaxno == ''||this.unittaxno == undefined)) {
            this.$message({
                message: '单位税号不能为空',
                type: 'error'
            })
            return false
        }
        let params = new FormData
        params.append('headerType', this.headertype)
        params.append('id', this.id)
        params.append('invoiceHeader', this.invoiceheader || '')
        params.append('type', this.type)
        params.append('bank', this.bankofdeposit || '')
        params.append('bankAccount', this.bankaccount || '')
        params.append('contactNumber', this.contactnumber || '')
        params.append('detailedAddress', this.detailaddress || '')
        params.append('dutyParagraph', this.unittaxno || '')
        params.append('invoiceType', this.invoicetype || '')
        params.append('mailingAddress', this.mailingaddress || '')
        params.append('registeredAddress', this.registeraddress || '')
        this.invoices = {
            invoiceheader: this.invoiceheader || '',
            unittaxno : this.unittaxno || '',
            contactnumber: this.contactnumber || '',
            detailaddress: this.detailaddress || '',
            mailingaddress: this.mailingaddress || '',
            mailingaddressvalue: this.mailingaddressvalue || '',
            bankofdeposit: this.bankofdeposit || '',
            registeraddress: this.registeraddress || '',
            bankaccount: this.bankaccount || ''
        }
        console.log("提交",...params)
        // return false
        this.$userdata.submitBill(params).then((res) => {
            // console.log(res,'发票提交')
            if (res.code==200) {
                this.$message({
                    message: '申请成功',
                    type: 'success'
                })
                localStorage.setItem(
                    "invoice",
                    JSON.stringify(this.invoices)
                );
                this.$router.go(-1)
            }
        })
    },
    //   /获得数据
    getvalue () {
        // console.log(this.$route.query.id,this.$route.query.type)
        this.id = this.$route.query.id
        this.type = this.$route.query.type
    },
    //   避免再次进入选地址，但是选了一个却不选了直接点确定
    selectaddresses (value) {
        // console.log('4')
        this.mailingaddress = ''
        // this.mailingaddressvalue = []
        // this.mailingaddressvalue = value
        this.addressvalue = ''
        // console.log(this.mailingaddressvalue, value)
    },
    //   确定地区
    sureaddress () {
        if (this.addressvalue =='' && this.mailingaddress == '') {
            return false
        }else if (this.addressvalue =='' && this.mailingaddress != '') {
            // return false
        }else{
            this.mailingaddress = this.addressvalue
        }
            this.addressvalue = ''
            this.Closeaddressselect()
            // console.log(this.mailingaddress, this.addressvalue, this.mailingaddressvalue)

    },
    //   选择地区出发
    selectaddress (value) {
        // console.log(value)
        var loc = "";
        for (let i = 0; i < this.mailingaddressvalue.length; i++) {
            loc += CodeToText[this.mailingaddressvalue[i]] + "-";
        }
        // console.log(loc)
        this.addressvalue = loc.substr(0, loc.length - 1)
    },
    //   关闭地区选择弹窗
    Closeaddressselect () {
        this.isaddressselect = false
    },
    //   显示地区选择弹窗
    showaddressselect () {
        this.isaddressselect = true
        // this.addressvalue = this.mailingaddress
    },
    //   改变抬头类型
    changheader (key) {
        this.headertype = key
        this.unittaxno = '' // 单位税号
        this.registeraddress = '' // 注册地址
        this.bankofdeposit = '' // 开户银行
        this.bankaccount = '' // 银行账号
        this.invalue()
    },
    //   改变发票类型
    changeinvoice (key) {
        this.invoicetype = key
        this.invoiceheader = '' // 发票抬头
        this.unittaxno = '' // 单位税号
        this.registeraddress = '' // 注册地址
        this.bankofdeposit = '' // 开户银行
        this.bankaccount = '' // 银行账号
        this.mailingaddress = '' // 邮寄地址
        this.mailingaddressvalue = [] // 邮寄地址
        this.detailaddress = '' // 详细地址
        this.contactnumber = '' // 联系电话
        this.invalue()
    },
    //   面包屑点击上一个的
    back () {
        this.$router.push('/account')
    }
  }
};
</script>
<style lang="scss">
#Applyinvoice {
    .el-dialog {
        border-radius: 14px;
    }
    .forms-input-input{
        .el-input__inner{
            width: 510px;
            height: 66px;
            border-radius: 14px;
            background:rgba(255,255,255,0);
            border: 0;
            font-size: 18px;
        }
        .el-input__inner::placeholder {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #A8ABB8;
        }
        /* 谷歌 */
        .el-input__inner::-webkit-input-placeholder {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #A8ABB8;
        }
        /* 火狐 */
        .el-input__inner:-moz-placeholder {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #A8ABB8;
        }
        /*ie*/
        .el-input__inner:-ms-input-placeholder {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #A8ABB8;
        }
    }
}
</style>
<style lang="scss" scoped>
#Applyinvoice {
    .dialog1-content{
        width: 550px;
        margin: 0 auto;
    }
    // 地区选择器弹窗
    .dialog1-top-title{
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2E3E5C;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img{
            position: absolute;
            right: 0px;
            width: 32px;
            height: 32px;
        }
    }
    .foot2{
        .butno{
            width: 335px;
            height: 50px;
            background: #A0CFFF;
            border-radius: 12px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 34px auto 0;
        }
        .but{
            width: 335px;
            height: 50px;
            background: #3D5CFF;
            border-radius: 12px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 34px auto 0;
        }
    }
    // 地区选择器弹窗结束
    .margleft34{
        margin: 0 0 0 34px;
    }
    .margleft107{
        margin: 0 0 0 107px;
    }
    .forms{
        width: 1120px;
        height: 103px;
        display: flex;
        justify-content: space-between;
        margin: 32px 0 0 0;
        .forms-content{
            width: 510px;
            height: 103px;
            .forms-input{
                display: flex;
                align-items: center;
                .forms-input-text{
                    width: 510px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    p{
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #A8ABB8;
                        margin:  0 0 0 15px;
                    }
                    img{
                        width: 32px;
                        height: 32px;
                        margin: 0 17px 0 0;
                    }
                }
                .forms-input-input{
                    border: none;
                    .el-input__inner{
                        border: 0;
                    }
                }
                .forms-radio{
                    width: 221px;
                    display: flex;
                    align-items: center;
                    p{
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #1F1F39;
                    }
                    img{
                        width: 32px;
                        height: 32px;
                    }
                }
            }
            .forms-content-title{
                font-size: 17px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #111111;
                margin: 0 0 13px 0;
            }
            .forms-input{
                width: 510px;
                height: 66px;
                background: #F9FAFB;
                border-radius: 14px;
            }
        }
    }
    .crumbs {
        width: 120px;
        height: 20px;
        margin:  0px 0 0px 24px;
        padding: 20px 0 0 0;
        .crumbs-before {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
        }
        .crumbs-fenkai {
        font-size: 12px;
        font-family: Helvetica;
        margin: 0 8px;
        color: rgba(0,0,0,0.45)
        }
        .crumbs-now {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0,0,0,0.65);
        }
    }
    .vip-button{
        margin: 40px auto 62px;
        width: 704px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        .toapplyinvoice{
            width: 335px;
            height: 50px;
            background: #3D5CFF;
            border-radius: 12px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    
    .nocursor{
        cursor:not-allowed;
    }
    .content{
        width:1196px;
        margin:  0 0 0 24px;
        .content-bottom{
            width: 1196px;
            // height: 550px;
            background: #FFFFFF;
            box-shadow: 2px 0px 16px 0px rgba(0,21,41,0.08);
            border-radius: 14px;
            // margin: 24px 0 0 0;
            padding: 0 0 24px 0;
            .content-content{
                width: 1120px;
                min-height: 298px;
                margin: 0px 0px 0 38px;
                background: #FFFFFF;
                border-radius: 22px;
                // border: 1px solid #F5F7FE;
            }
            .content-bottom-title{
                width: 80px;
                height: 25px;
                padding: 23px 0 0 0px;
                
            }
            .title-botttom{
                width: 100px;
                display: flex;
                align-items: center;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #8189B0;
                margin: 6px 0 0 31px;
                img{
                    width: 22px;
                    height: 22px;
                }
            }
            
        }
        .content-top-top-left{
            width: 80px;
            height: 25px;
            display: flex;
            align-items: flex-end;
            position: relative;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2F3236;
            margin: 0 0 0 24px;
            &-text {
            margin-left: 8px;
            }
            .content-top-top-left-back {
                width: 26px;
                height: 8px;
                background: #3d5cff;
                opacity: 0.3;
                position: absolute;
                left: 0;
                bottom: 2px;
            }
        }
        
    }
}
</style>