<template>
  <div id="searchResult">
    <div class="body">
      <!-- nav -->
      <p class="body__title"><span class=" cursor" @click="back">首页</span> / <span class=" cursor" @click="tonav">专业导航</span> / 搜索结果</p>
      <!-- content -->
      <div class="body__content flex start between wrap">
        <!-- input -->
        <div class="enterorrec">
          <div class="body__content__search">
            <el-input
              placeholder="请输入搜索内容"
              v-model="search"
              @clear="back"
              @focus="focusInput()"
              v-on:input="onInput()"
              disabled
              autosize
              type="textarea"
            ></el-input>
            <!-- <img class="words-clean cursor" v-show="search!=''" @click="back" src="../../../assets/icon/icon_qingkong.png"> -->
          </div>
          <div
            class="entering"
            :style="'opacity:' + entering"
            v-show="entering"
          >
            <div id="entering-row">
              <div class="entering-row cursor" @click="searchend(item,1)" v-for="(item, key) in SearchingList" :key="key">
                <div class="entering-row-title" v-html="brightenKeyword(item.name1, search)">{{item.name1}}</div>
                <div class="entering-row-content" >
                  <span v-html="brightenKeyword(item.name2, search)">{{item.name2}}</span><span v-html="brightenKeyword(item.name3, search)">{{item.name3}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <!-- word -->
        <!-- <div class="wordes">
          <div class="wordes-left">
            <el-input
              v-model="search"
              type="textarea"
              placeholder="请输入需要翻译的文字或单词"
              clearable
              autosize
            ></el-input>
            <img class="wordes-clean cursor" v-show="search!=''" @click="cleanvalue" src="../../assets/icon/icon_qingkong.png">
          </div>
          <div class="wordes-right">
            <div class="wordes-right-content" v-show="search!=''">
              55555555555555555555555555555555555555555555555555555555555555
            </div>
            <div class="wordes-right-bottom">
              <div class="wordes-right-bottom-left cursor" v-show="search!=''" @click="showerrordialogs">
                <img src="../../assets/icon/icon_fankui.png">
                纠错
              </div>
              <div class="wordes-right-bottom-right" v-show="search!=''">
                <img class="wordes-right-bottom-right-img1 cursor" @click="copyClick" src="../../assets/icon/icon_copy.png">
                <img v-show="collectStatus==1" @click="collectClick" class="wordes-right-bottom-right-img2 cursor" src="../../assets/icon/icon_yishoucang.png">
                <img v-show="collectStatus==0" @click="collectClick" class="wordes-right-bottom-right-img2 cursor" src="../../assets/icon/icon_shoucang.png">
                <span v-show="collectStatus==1" class="iscollect">已收藏</span>
                <span v-show="collectStatus==0" class="iscollect">收藏</span>

              </div>
            </div>
          </div>
        </div> -->
        <div class="body__content__word">
          <p class="translate">{{searchname3}}</p>
          <div class="line flex between">
            <div
              class="label1 flex align cursor"
              @click="showerrordialogs('1')"
            >
              <img src="../../../assets/icon/icon_fankui.png" class="img" alt="" />
              <p>纠错</p>
            </div>
            <div class="label2 flex align">
              <img
                src="../../../assets/icon/icon_copy.png"
                class="img cursor"
                alt=""
                @click="copyClick()"
              />
              <div class="flex align cursor" @click="collectClick()">
                <img
                  src="../../../assets/icon/icon_yishoucang.png"
                  class="img"
                  alt=""
                  v-show="collectStatus==1"
                />
                <img
                  src="../../../assets/icon/icon_shoucang.png"
                  class="img"
                  alt=""
                  v-show="collectStatus==0"
                />
                <p>{{ collectStatus==1 ? "已收藏" : "收藏" }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- record -->
        <div class="body__content__record flex column between" v-show="false">
          <div class="title flex align between">
            <p class="text">历史记录</p>
            <img
              src="../../../assets/icon/icon_del.png"
              class="img cursor"
              alt=""
              @click="deleteSearchRecord"
            />
          </div>
          <div class="label flex align RecordList" v-show="SearchRecordList.length!=0">
            <p class="text cursor RecordLists" v-for="(item, key) in SearchRecordList" :key="key" @click="searchend(item,0)">
              {{ item.name1 }}
            </p>
          </div>
          <div v-show="SearchRecordList.length==0" class="history-none">
            <img src="../../../assets/image/img_none.png"/>
          </div>
        </div>
        <div class="kong flex column between">
        </div>
        <!-- paraphrase -->
        <div class="body__content__paraphrase">
          <div class="titles">
            <p>释义</p>
            <div class="titles__shadow"></div>
          </div>
          <div class="body__content__paraphrase__row flex align" v-for="(item,key) in righttext">
            <p class="tag">{{item.name1}}</p>
            <p class="text">{{item.name2}}</p>
          </div>
          <!-- <div class="body__content__paraphrase__row flex start">
            <p class="tag">【物理】</p>
            <p class="text">
              量子即一个物理量如果存在最小的不可分割的基本单位，则这个物理量是量子化的，并把最小单位称为量子。
            </p>
          </div> -->
        </div>
        <!-- not value -->
        <div class="body__content__not" v-show="false">
          <div class="body__content__not__line flex align cursor" @click="showerrordialogs('2')">
            <img src="../../../assets/icon/icon_fankui.png" class="img" alt="" />
            <p class="text">未检索到匹配单词?</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 收藏弹窗 -->
    <popupCollect :status="popupCollectStatus" :wordsid="searchid" @Closecollect="Closecollect"/>
    
    <!-- 纠正弹窗 -->
    <Errorcorrection
      :dialogVisible="showerrordialog"
      :wordsid="searchid"
      :wordtype="wordtype"
      :words="searchname1"
      :translationContent="searchname3"
      @closedialog="closedialog"
    />
  </div>
</template>

<script>
import Errorcorrection from "../../components/Errorcorrection.vue";
import popupCollect from "../../components/popupCollect.vue";
export default {
  components: {
    Errorcorrection,
    popupCollect,
  },
  data() {
    return {
      wordtype: '1', // 纠错类型
      userdata: JSON.parse(sessionStorage.getItem('userdata')), // 用户数据
      SearchingList: [], // 搜索中的
      SearchRecordList: [], // 搜索记录
      entering: 0, // 搜索输入中的下来框是否显示

      searchname1: '', // 跳转过来的name1
      searchname3: '', // 跳转过来的name3
      search: "",
      searchid:'', // 单词id
      righttext: [], // 右侧的释义
      popupCollectStatus: false, // 收藏弹窗
      collectStatus: 0, // 是否收藏
      showerrordialog: false, // 纠错弹窗
      recordList: ["量子", "liangzi", "quantum"],
    };
  },
  mounted() {
    this.getsearchvalue()
    //如果本地存储的数据historyList有值，直接赋值给data中的historyList
    if (JSON.parse(localStorage.getItem("historyList"))) {
        this.SearchRecordList = JSON.parse(localStorage.getItem("historyList"));
    }
    document.addEventListener("click", (res) => {
      // console.log(res.target.className)
      // 这个判断的效果  document.addEventListener点击页面执行hidder
      // 这样我们点击弹框里面的事件也会触发很不友好 所以给当前点击的事件加上判断而不执行hidder
      if (res.target.className == "el-input__inner") {
        return;
      }
      if (res.target.className == "record-row") {
        return;
      }
      if (res.target.className == "el-icon-delete") {
        return;
      }
      if (res.target.className == "record-row-content") {
        return;
      }
      if (res.target.className == "record-row-title") {
        return;
      }
      
      this.hidder();

    });
    const that = this
    window.addEventListener('setItem', () => {
      that.userdata = JSON.parse(sessionStorage.getItem('userdata'))
      //that.formData.deptId是data里面声明的变量
      // console.log(that.userdata,JSON.parse(sessionStorage.getItem('userdata')),'监听')
    })
  },
  methods: {
    // 关闭收藏夹
    Closecollect () {
      this.popupCollectStatus = false;
      this.ifUserCollect(this.searchid)
    },
    // 删除查询记录列表
    deleteSearchRecord () {
      localStorage.removeItem('historyList');
      this.SearchRecordList = [];
      // this.$apis.deleteSearchRecord().then((res) => {
      //   console.log(res,"学习计划")
      //   if (res.code==200) {
      //   }
      // })
    },
    // 跳转搜索结果
    searchend (e,k) {
      // console.log(e,k)
      // if (k==1&&this.userdata!=null) {
      //   let params = new FormData
      //   params.append('id', e.id)
      //   params.append('name4', e.name4)
      //   this.$apis.addSearchRecord(params).then((res) => {
      //     if (res.code==200) {
      //     }
          
      //     // console.log(res,"添加了历史")
      //   })
      // }
      if (this.userdata==null) {
        this.$store.commit("SET_LOGIN", true);

        return false
      }
      if (k==1) {
        if (k==1&&this.userdata!=null) {
          let params = new FormData
          params.append('id', e.id)
          params.append('name4', e.name4)
          this.$apis.addSearchRecord(params).then((res) => {
            if (res.code==200) {
            }
            
            // console.log(res,"添加了历史")
          })
        }
        // 没有搜索记录，把搜索值push进数组首位，存入本地
        if (!this.SearchRecordList.includes(e)) {
          this.SearchRecordList.unshift(e);
          localStorage.setItem("historyList", JSON.stringify(this.SearchRecordList));
        }else{
            //有搜索记录，删除之前的旧记录，将新搜索值重新push到数组首位
            let i =this.SearchRecordList.indexOf(e);
            this.SearchRecordList.splice(i,1)
            this.SearchRecordList.unshift(e);
            localStorage.setItem("historyList", JSON.stringify(this.SearchRecordList));
        }
        // console.log(this.SearchRecordList,"记录")
      }
      this.searchname1 = e.name1
      this.searchname3 = e.name3
      this.search = e.name1
      this.searchid = e.id
      this.getrighttext(e.id)
      this.ifUserCollect(e.id)
      // this.$router.push({
      //   path: '/Searchresult',
      //   query: {
      //     searchvalue: e.id,
      //     searchname1: e.name1,
      //     searchname3: e.name3,
      //   }
      // })
      
      // console.log('跳转')
    },
    // 点击页面
    hidder() {
      this.entering = 0
    },
    // 搜索获得焦点
    focusInput() {
      if (this.search!='') {
        this.onInput()
        return false
      }
      // if (this.userdata==null) {
      //   return false
      // }
      
      this.entering = 0
    },
    // 监听input输入
    onInput() {
      // console.log(this.search);
      if (this.search=='') {
        this.focusInput()
        // this.searchname1 = ''
        // this.searchname3 = ''
        // this.search = ''
        // this.righttext = []
        return false
      }
      this.entering = 1
      let params = new FormData
      params.append('name', this.search)
      this.$apis.homeSearch(params).then((res) => {
        if (res.code==200) {
          this.SearchingList = res.result
        }
        
        // console.log(res)
      })
      
      // var res = new RegExp("[\u4E00-\u9FA5]+");
      // if (this.search) {
      //   this.changeColor("record-row");
      // }else {
      //   document.getElementById("record-row").innerHTML = this.searchHtml
      // }
    },
    //   关键词变亮
    brightenKeyword (val, keyword) {
      // 方法1：筛选变色（区分大小写）
      // val = val + '';
      // if (val.indexOf(keyword) !== -1 && keyword !== '') {
      //   if (val === this.currentNodeLabel) {
      //     const resCurrent = val.replace(keyword, `<span style="color: #000;background-color: orange;">${keyword}</span>`)
      //     return resCurrent
      //   } else {
      //     const res = val.replace(keyword, `<span style="color: #000;background-color: yellow;">${keyword}</span>`)
      //     return res
      //   }
      // } else {
      //   return val
      // }
      // 方法2：用正则表达式 （不区分大小写）
      const Reg = new RegExp(keyword, 'i')
      if (val) {
        if (val === this.currentNodeLabel) {
          // 这里为什么使用$&不使用keyword，因为这里使用正则表达式不区分大小写，如果是文本时大写，搜索的关键字是小写也是会被匹配的，这样匹配替换掉的话，文本内的文字会被替换成搜索的keyword，也就是改成了小写，这样不太合理
          // const resCurrent = val.replace(Reg, `<span style="color: #000;background-color: orange;">${keyword}</span>`)
          const resCurrent = val.replace(Reg, `<span style="color: rgba(61, 92, 255, 1);">$&</span>`)
          return resCurrent
        } else {
          const res = val.replace(Reg, `<span style="color: rgba(61, 92, 255, 1);">$&</span>`)
          return res
        }
      }
    },

    //   面包屑点击上一个的
    back () {
        this.$router.push('/')
        this.cleanvalue()
    },
    tonav () {
      this.$router.push('/Specialtynav')
      this.cleanvalue()
    },
    // 清除翻译内容
    cleanvalue () {
      this.search = ''
      this.searchname1 = ''
      this.searchname3 = ''
      this.searchid = ''
      this.righttext = []
    },
    // 显示纠错
    showerrordialogs (val) {
      this.wordtype = val
      this.showerrordialog = true
    },
    // 关闭纠错弹窗
    closedialog () {
      // console.log('关闭纠错弹窗')
      this.showerrordialog = false
    },
    // 获得路由传的值
    getsearchvalue () {
      // this.search = this.$route.query.searchvalue
      // console.log(this.$route.query.searchname1)
      this.search = this.$route.query.searchname1
      this.searchname1 = this.$route.query.searchname1
      this.searchname3 = this.$route.query.searchname3
      this.searchid = this.$route.query.searchvalue
      this.getrighttext(this.$route.query.searchvalue)
      this.ifUserCollect(this.$route.query.searchvalue)
    },
    // 判断是否收藏
    ifUserCollect (val) {
      let params = new FormData
      params.append('id', val)
      this.$apis.ifUserCollect(params).then((res) => {
        if (res.code==200) {
          this.collectStatus = res.result.mark
        }
        
        // console.log(res,"是否收藏")
      })
    },
    // 获得右边释义
    getrighttext (val) {
      let params = new FormData
      params.append('id', val)
      this.$apis.appThreeSearch(params).then((res) => {
        if (res.code==200) {
          this.righttext = res.result
        }
        
        // console.log(res,"右侧释义")
      })
    },
    // 复制
    copyClick() {
      let url = this.searchname3;
      //新建一个文本框
      let oInput = document.createElement('input');
      //赋值给文本框
      oInput.value = url;
      document.body.appendChild(oInput);
      // 选择对象;
      oInput.select(); 
      // 执行浏览器复制命令
      document.execCommand("Copy"); 
      //复制完成删除掉输入框
      oInput.remove()
      // this.$refs.message.success('复制成功') // success调用
      // console.log('复制成功')
      // const save = function (e) {
      //   e.clipboardData.setData("text/plain", '我是复制的内容');
      //   e.preventDefault(); // 阻止默认行为
      // };
      // document.addEventListener("copy", save); // 添加一个copy事件
      // document.execCommand("copy"); // 执行copy方法
      this.$message({ message: "复制成功", type: "success" });
    },
    // 收藏点击
    collectClick() {
      if (this.userdata==null) {
        this.$store.commit("SET_LOGIN", true);

        return false
      }
      if (this.collectStatus==0) {
        this.popupCollectStatus = true;
        // this.collectStatus = true
        return;
      }
      if (this.collectStatus==1) {
        this.cancelCollectWord()
      }
      // this.collectStatus = false;
    },
    // 取消收藏
    cancelCollectWord() {
      let params = new FormData
      params.append('collectWordId', this.searchid)
      this.$apis.cancelCollectWord(params).then((res) => {
        if (res.code==200) {
          this.ifUserCollect(this.searchid)
        }
        
        // console.log(res,"取消收藏")
      })
    },
    
  },
};
</script>
<style lang="scss" scoped>

</style>
<style lang="scss">
#searchResult {
  width: 100%;
  .kong{
    width: 558px;
    min-height: 60px;
    padding: 20px 22px;
    margin-top: 16px;
    border-radius: 14px;
  }
  .words-clean {
    position: absolute;
    top: 15px;
    right: 16px;
    width: 32px;
    height: 32px;
  }
  .history-none {
    display: flex;
    align-content: center;
    justify-content: center;
    // margin-bottom: 20px;
    img {
      width: 100px;
      height: 100px;
    }
  }
  .RecordList{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .RecordLists{
      max-width: 500px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 11px 0 0 0;
    }
  }
  .enterorrec{
    position: relative;
  }
  .entering {
    width: 364px;
    max-height: 300px;
    overflow: hidden;
    // overflow: hidden;
    // overflow-y: scroll;
    // scrollbar-width: none; /* Firefox */
    // -ms-overflow-style: none; /* IE 10+ */
    position: absolute;
    top: 60px;
    padding: 0px 20px;
    background: #ffffff;
    box-shadow: 0px 0px 12px 2px rgba(184, 184, 210, 0.2);
    border-radius: 14px;
    z-index: 2;
    transition: opacity 0.3s;
    &-row {
      height: 60px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #F4F4F5;
      &-title {
        max-width: 250px;
        max-height: 40px;

        overflow: hidden;
        text-overflow: ellipsis;  /* 超出部分省略号 */
        word-break: break-all;  /* break-all(允许在单词内换行。) */  
        display: -webkit-box; /* 对象作为伸缩盒子模型显示 */
        -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
        -webkit-line-clamp: 2; /* 显示的行数 */
        color: #505560;
        font-size: 14px;
      }
      &-content {
        // width: 174px;
        flex: 1;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          text-overflow: ellipsis;
        color: #8c8c8c;
        font-size: 13px;
        margin-left: 24px;
      }
    }
    &-row:last-child{
      border-bottom: none;
    }
    &-null {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgb(164, 164, 164);
    }
    &-history {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #8c8c8c;
      font-size: 12px;
      margin-bottom: 26px;
    }
  }
  .wordes {
            width: 1220px;
            // height: 141px;
            min-height: 141px;
            position: relative;
            opacity: 0.9;
            margin-top: 20px;
            // margin-bottom: 16px;
            display: flex;
            align-content: center;
            justify-content: space-between;
            .wordes-right {
              position:relative;
              font-family: PingFangSC-Medium, PingFang SC;
              color: #8189B0;
              // padding: 18px 0 0 20px;
              width: 602px;
              // height: 141px;
              border-radius: 14px;
              opacity: 0.9;
              border: 0;
              resize: none;
              background: linear-gradient(90deg, #EEF1F9 0%, #F8FCFF 98%);
              font-size: 24px;
              padding-bottom: 40px;
            }
            .wordes-right-bottom {
              position:absolute;
              bottom: 10px;
              width: 576px;
              height: 30px;
              margin: 0px 10px 0 16px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .wordes-right-bottom-left{
                font-size: 11px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #A3A6AB;
                display: flex;
                align-items: center;
                img{
                  width: 16px;
                  height: 16px;
                  margin: 0 4px 0 0 ;
                }
              }
              .wordes-right-bottom-right{

                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #50555E;
                display: flex;
                align-items: center;
                .wordes-right-bottom-right-img1{
                  width: 22px;
                  height: 22px;
                  margin: 0 18px 0 0 ;
                }
                .wordes-right-bottom-right-img2{
                  width: 22px;
                  height: 22px;
                  margin: 0 4px 0 0 ;
                }
                .iscollect{
                  width: 36px;
                  display: flex;
                  // justify-content: flex-end;
                }
              }
            }
            .wordes-right-content {
              width: 554px;
              // height: 109px;
              margin: 18px 0 0 20px;
              overflow: auto;
              // text-overflow:ellipsis;
              // white-space: pre-warp;
              word-wrap: break-word
            }
            .wordes-clean {
              position: absolute;
              top: 19px;
              right: 16px;
              width: 32px;
              height: 32px;
            }
            .wordes-left {
              width: 602px;
              // height: 141px;
              background: linear-gradient(90deg, #EEF1F9 0%, #F8FCFF 98%);
              border-radius: 14px;
              position: relative;

              .el-textarea__inner {
                // text-indent: 10px;
                // line-height: 33px;
                margin: 18px 0 0 20px;

                font-family: PingFangSC-Medium, PingFang SC;
                color: #8189B0;
                background:rgba(255,255,255,0);
                width: 554px;
                // height: 109px;
                border: 0;
                resize: none;
                font-size: 24px;

              }
              .el-textarea__inner::placeholder {
                font-family: PingFangSC-Medium, PingFang SC;
                font-size: 24px;
                color: #8189B0;
              }
              /* 谷歌 */
              .el-textarea__inner::-webkit-input-placeholder {
                font-family: PingFangSC-Medium, PingFang SC;
                font-size: 24px;
                color: #8189B0;
              }
              /* 火狐 */
              .el-textarea__inner:-moz-placeholder {
                font-family: PingFangSC-Medium, PingFang SC;
                font-size: 24px;
                color: #8189B0;
              }
              /*ie*/
              .el-textarea__inner:-ms-input-placeholder {
                font-family: PingFangSC-Medium, PingFang SC;
                font-size: 24px;
                color: #8189B0;
              }
            }
          }
  .body {
    width: 1220px;
    margin: 0 auto;
    padding-left: 96px;
    padding-bottom: 100px;
    &__content {
      width: 100%;
      &__not {
        &__line {
          font-size: 13px;
          margin-top: 16px;
          color: #8189b0;
          margin-left: 618px;
          .text {
            text-decoration: underline;
            margin-left: 2px;
          }
          .img {
            width: 16px;
            height: 16px;
          }
        }
      }
      &__paraphrase {
        width: 564px;
        min-height: 60px;
        margin-top: 16px;
        padding: 18px 20px;
        background: #ffffff;
        box-shadow: 0px 0px 12px 2px rgba(184, 184, 210, 0.2);
        border-radius: 14px;
        &__row {
          margin-top: 14px;
          .tag {
            min-width: 64px;
            color: #8c8c8c;
            font-size: 14px;
          }
          .text {
            font-size: 13px;
          }
        }
        .titles {
          position: relative;
          font-size: 15px;
          margin-bottom: 18px;
          &__shadow {
            width: 26px;
            height: 8px;
            background: #3d5cff;
            opacity: 0.3;
            position: absolute;
            left: -6px;
            bottom: 2px;
          }
        }
      }
      &__record {
        width: 558px;
        min-height: 60px;
        padding: 20px 22px;
        background: #f9f9f9;
        margin-top: 16px;
        border-radius: 14px;
        .label {
          .text {
            padding: 2px 7px;
            background: #fff;
            border-radius: 4px;
            border: 1px solid #dbdbdb;
            font-size: 14px;
            color: #505560;
            margin-right: 10px;
          }
        }
        .title {
          .text {
            color: #8c8c8c;
            font-size: 13px;
          }
          .img {
            width: 22px;
            height: 22px;
          }
        }
      }
      &__word {
        width: 562px;
        height: 112px;
        padding: 18px 20px 10px;
        background: linear-gradient(90deg, #eef1f9 0%, #f8fcff 98%);
        border-radius: 14px;
        color: #1f1f1f;
        font-size: 22px;
        .line {
          width: 100%;
          .label1 {
            color: #a3a6ab;
            font-size: 11px;
            .img {
              width: 16px;
              height: 16px;
            }
          }
          .label2 {
            color: #a3a6ab;
            font-size: 11px;
            .img {
              width: 22px;
              height: 22px;
              margin-left: 10px;
            }
          }
        }
        .translate {
          //   margin-top: 20px;
          min-height: 80px;
          margin-bottom: 6px;

        }
      }
      &__search {
        width: 602px;
        min-height: 140px;
        background: linear-gradient(90deg, #eef1f9 0%, #f8fcff 98%);
        border-radius: 14px;
        .el-textarea__inner {
          // text-indent: 10px;
          // line-height: 33px;
          margin: 18px 0 0 0 ;

          font-family: PingFangSC-Medium, PingFang SC;
          // color: #8189B0;
          background:rgba(255,255,255,0);
          width: 554px;
          // height: 109px;
          border: 0;
          resize: none;
          font-size: 24px;
          color: #1f1f1f;

        }
        .el-textarea__inner::placeholder {
          font-family: PingFangSC-Medium, PingFang SC;
          font-size: 24px;
          color: #1f1f1f;
          // color: #8189B0;
        }
        /* 谷歌 */
        .el-textarea__inner::-webkit-input-placeholder {
          font-family: PingFangSC-Medium, PingFang SC;
          font-size: 24px;
          color: #1f1f1f;
          // color: #8189B0;
        }
        /* 火狐 */
        .el-textarea__inner:-moz-placeholder {
          font-family: PingFangSC-Medium, PingFang SC;
          font-size: 24px;
          color: #1f1f1f;
          // color: #8189B0;
        }
        /*ie*/
        .el-textarea__inner:-ms-input-placeholder {
          font-family: PingFangSC-Medium, PingFang SC;
          font-size: 24px;
          color: #1f1f1f;
          // color: #8189B0;
        }
        input {
          width: 100%;
          color: #1f1f1f;
          font-size: 24px;
          margin-top: 10px;
          border: none;
          background: none;
        }
      }
    }
    &__title {
      margin: 34px 0;
      font-size: 12px;
      color: rgb(100, 99, 99);
    }
  }
}
</style>