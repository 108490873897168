<!-- 我是登录弹框组件 -->
<template>
  <div id="login">
    <el-dialog
      title=""
      width="440px"
      :visible.sync="status"
      :before-close="handleClose"
    >
      <!-- 验证码登录 -->
      <div class="body">
        <!-- tab -->
        <div class="tab">
          <div
            :class="
              tabStatus == 0 ? 'tab-title tab-pick cursor' : 'tab-title cursor'
            "
            @click="pickCLick(0)"
          >
            验证码登录
          </div>
          <div
            :class="
              tabStatus == 1 ? 'tab-title tab-pick cursor' : 'tab-title cursor'
            "
            @click="pickCLick(1)"
          >
            密码登录
          </div>
        </div>
        <!-- form -->
        <div class="info" v-show="tabStatus == 0">
          <div class="info-input">
            <input
              type="text"
              v-model="form.account"
              placeholder="请输入手机号/邮箱"
            />
            <img
              src="../assets/icon/icon_username.png"
              class="info-input-img"
              alt=""
            />
          </div>
          <div class="info-input">
            <input
              type="text"
              class="info-input-code"
              maxlength="8"
              v-model="form.code"
              placeholder="请输入验证码"
            />
            <img
              src="../assets/icon/icon_password.png"
              class="info-input-img"
              alt=""
            />
            <div class="info-input-gain cursor" @click="codeClick()">
              {{ codeTime + codeStatus }}
            </div>
          </div>
        </div>
        <div class="info" v-show="tabStatus == 1">
          <div class="info-input">
            <input
              type="text"
              v-model="forms.username"
              placeholder="请输入手机号/邮箱"
            />
            <img
              src="../assets/icon/icon_username.png"
              class="info-input-img"
              alt=""
            />
          </div>
          <div class="info-input">
            <input
              type="password"
              class="info-input-code"
              v-model="forms.userpass"
              placeholder="请输入用户密码"
              @blur="inputBlur"
            />
            <img
              src="../assets/icon/icon_password.png"
              class="info-input-img"
              alt=""
            />
          </div>
        </div>
        <!-- 忘记密码 -->
        <div class="forget" v-show="tabStatus == 1">
          <div class="forget-body cursor" @click="forgetClick()">
            忘记密码？
          </div>
        </div>
        <!-- button -->
        <div class="button">
          <div class="button-title cursor" @click="loginin">登录</div>
        </div>
        <!-- way -->
        <div class="way">
          <div class="way-body">
            <img
              class="way-body-img cursor"
              src="../assets/icon/icon_wechat.png"
              alt=""
              @click="wayClick(0)"
            />
            <img
              class="way-body-img cursor"
              src="../assets/icon/icon_qq.png"
              alt=""
              @click="wayClick(1)"
            />
          </div>
        </div>
        <!-- agreement -->
        <div class="agreement">
          <div class="agreement-body">
            <div class="agreement-body-radio cursor" @click="radioClick()">
              <img
                src="../assets/icon/chose.png"
                v-show="!radioStatus"
                class="img"
                alt=""
              />
              <img
                src="../assets/icon/choses.png"
                v-show="radioStatus"
                class="img"
                alt=""
              />
            </div>
            <div class="agreement-body-title cursor" @click="Agreement()">
              我已阅读并同意《用户注册使用协议》
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <Globalmessage ref="message" :duration="3000" :top="100" :widths="1920" />
  </div>
</template>

<script>
import { setToken, getToken } from "../utils/auth.js";
import Globalmessage from "./Globalmessage.vue";
export default {
  components: {
    Globalmessage,
  },
  data() {
    return {
      tabStatus: 0, // tab切换
      codeStatus: "获取", // code
      codeTime: "", // code倒计时
      form: {
        account: "",
        code: "",
      }, // 验证码登录
      forms: {
        username: "",
        userpass: "",
      }, // 密码登录
    };
  },
  computed: {
    status: function () {
      return this.$store.state.loginStatus;
    },
    radioStatus: function () {
      return this.$store.state.impowerStatus;
    },
  },
  mounted() {},
  methods: {
    // 登录
    loginin() {
      // 验证码登录
      if (this.tabStatus == "0") {
        if (this.form.account == "" || this.form.code == "") {
          return false;
        }
        if (this.$store.state.impowerStatus == false) {
          this.$message.error("请阅读并同意《用户注册使用协议》");
          // this.$refs.message.error('请阅读并同意《用户注册使用协议》'); // success调用
          return false;
        }
        let params = new FormData();
        params.append("tel", this.form.account);
        params.append("verification", this.form.code);
        this.$login.Verificationlogin(params).then((res) => {
          if (res.code) {
            // console.log(res, "获得token")
            setToken(res.result.token);
            this.$login.getUserInfo().then((ress) => {
              // console.log(ress.result, "获得用户信息")
              // sessionStorage.setItem('userdata', JSON.stringify(ress.result));
              this.setSessionItem("userdata", JSON.stringify(ress.result));
              if (ress.code == 200) {
                this.handleClose();
                if (!res.result.pwd) {
                  this.$store.commit("SET_PASSWORD", true);
                }
              }
            });
          }
        });
      }
      // 密码登录
      if (this.tabStatus == "1") {
        if (this.forms.username == "" ) {
          return false;
        }
        if(this.forms.userpass == ""){
          // if(!this.CheckPassWord(this.forms.userpass)){
          //   this.$message.error("密码必须由字母、数字组成，且长度为8-22位");
          //   return false;
          // }
            this.$message.error("密码错误");
            return false;
        }
        if (this.$store.state.impowerStatus == false) {
          this.$message.error("请阅读并同意《用户注册使用协议》");
          // this.$refs.message.error('请阅读并同意《用户注册使用协议》'); // success调用
          return false;
        }
        let parames = new FormData();
        parames.append("tel", this.forms.username);
        parames.append("password", this.forms.userpass);
        this.$login.accountLogin(parames).then((res) => {
          if (res.code == 200) {
            // console.log(res, "获得token")
            setToken(res.result.token);
            this.$login.getUserInfo().then((ress) => {
              // console.log(ress.result, "获得用户信息")
              // sessionStorage.setItem('userdata', JSON.stringify(ress.result));
              this.setSessionItem("userdata", JSON.stringify(ress.result));
              if (ress.code == 200) {
                this.handleClose();
                location.reload();
              }
            });
          }
        });
      }
    },
    // 扫码登录
    wayClick(e) {
      this.$store.commit("SET_INPOWER", true);
      if (e==0) {
        this.$store.commit("SET_THIRDPARTY", true);
      }
      this.$store.commit("SET_THIRDPARTYNUMBER", e);
      const that = this
      if (e==1) {
        that.$login.qqLoginAddress().then((res) => {
          if (res.code==200) {
            console.log(res,"qq")
             var win = window.open(res.message,"TencentLogin",
                "width=700,height=400,left=600,top=300,menubar=0,scrollbars=1,resizable=1,status=1,titlebar=0,toolbar=0,location=1,copyhistory=1");
                var listener=setInterval(function(){
                  //监听窗口是否关闭
                  if(win.closed){
                    console.log('关闭',that.$route)
                    //进入这个if代表后端验证成功!直接跳转路由
                    clearInterval(listener);//关闭监听
                    //跳转路由
                    
                    // var path = that.$route.query.redirect;
                    var path = that.$route.path
                    if (path !== undefined) {
                      // that.$router.replace({
                      //   path:
                      //     path + '/#/loginSuccess'
                      // });
                      that.$router.replace('/' + 'loginSuccess');
                    }
                    
                    // that.$router.go(0) //刷新
                  }
                },500)
              // that.QRdata = res.result
          }
        })
      }
      
    },
    // 密码验证
    inputBlur(e){
      console.log(e)
      // if(this.forms.userpass){
      //   if(!this.CheckPassWord(this.forms.userpass)){
      //     this.$message.error("密码必须由字母、数字组成，且长度为8-22位");
      //   }
      // }
    },
    CheckPassWord(password) {//必须为字母加数字且长度不小于8位
      // return true;

      var str = password;
        if (str == null || str.length < 8 || str.length > 22) {
            return false;
        }
        var reg1 = new RegExp(/^[0-9A-Za-z]+$/);
        if (!reg1.test(str)) {
            return false;
        }
        var reg = new RegExp(/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/);
        if (reg.test(str)) {
            return true;
        } else {
            return false;
        }
    },
    // 忘记密码
    forgetClick() {
      this.$store.commit("SET_FORGE", true);
    },
    // 用户协议弹框
    Agreement() {
      this.$store.commit("SET_AGREEMENT", true);
    },
    // 是否同意用户协议
    radioClick() {
      this.$store.commit("SET_INPOWER", !this.radioStatus);
    },
    // 获取验证码函数
    codeClick() {
      if (this.form.account == "") {
        return false;
      }
      if (this.codeTime != "") {
        return false;
      }
      // let params = {
      //   tel: this.form.account
      // }
      let params = new FormData();
      params.append("tel", this.form.account);
      this.$login.getVerification(params).then((res) => {
        console.log(res , "res")
        if (res.code==200) {
          this.codeStatus = "S后重新获取";
          this.codeTime = 60;
          let timer = setInterval(() => {
            if (this.codeTime != 0) {
              this.codeTime--;
            } else {
              this.codeStatus = "获取";
              this.codeTime = "";
              clearInterval(timer);
            }
          }, 1000);
        }else{

        }
        
      });
      // this.codeStatus = "S后重新获取";
      // this.codeTime = 60;
      // let timer = setInterval(() => {
      //   if (this.codeTime != 0) {
      //     this.codeTime--;
      //   } else {
      //     this.codeStatus = "获取";
      //     this.codeTime = "";
      //     clearInterval(timer);
      //   }
      // }, 1000);
    },
    // 切换tab
    pickCLick(e) {
      this.tabStatus = e;
    },
    // 关闭弹框函数
    handleClose() {
      this.$store.commit("SET_LOGIN", false);
      // 验证码登录
      this.form = {
        account: "",
        code: "",
      };
      // 密码登录
      this.forms = {
        username: "",
        userpass: "",
      };
    },
  },
};
</script>

<style lang="scss">
#login {
  .body {
    padding: 0 26px;

    .forget {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      &-body {
        width: 328px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        color: #7f89b3;
        font-size: 14px;
      }
    }
    .agreement {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      &-body {
        width: 328px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        &-radio {
          .img {
            width: 26px;
            height: 26px;
            margin-top: 5px;
          }
        }
        &-title {
          color: #858597;
          font-size: 13px;
        }
      }
    }
    .way {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      &-body {
        width: 328px;
        height: 48px;
        display: flex;
        justify-content: center;
        &-img {
          width: 48px;
          height: 48px;
          margin-top: 30px;
          padding: 0 16px;
        }
      }
    }
    .button {
      display: flex;
      justify-content: center;
      &-title {
        width: 328px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 46px;
        background-color: #3d5cff;
        color: #fff;
        border-radius: 12px;
        font-size: 16px;
      }
    }
    .info {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      margin-left: 10px;
      .info-input {
        position: relative;
        &-gain {
          padding: 6px 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 20px;
          top: calc(50% - 16px);
          background-color: #3d5cff;
          border-radius: 6px;
          opacity: 0.5;
          color: #fff;
          font-size: 14px;
        }
        &-img {
          width: 22px;
          height: 22px;
          position: absolute;
          top: calc(50% - 12px);
          left: 16px;
        }
        input {
          width: 216px;
          height: 56px;
          border-radius: 12px;
          padding: 0 56px;
          margin: 9px 0;
          color: #8189b0;
          font-size: 15px;
          background-color: #f1f6fb;
          border: none;
        }
        input::-webkit-input-placeholder {
          color: #8189b0;
        }
      }
    }
    .tab {
      display: flex;
      align-items: center;
      color: #a8b4c3;
      &-title {
        font-size: 18px;
        padding: 0 12px;
      }
      &-pick {
        color: #2e3e5c;
        font-weight: bold;
      }
    }
  }
  .el-dialog {
    border-radius: 12px;
  }
}
</style>