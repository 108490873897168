<template>
  <div id="app">
    <topNav v-show="!$route.meta.shovalues" />
    <router-view/>
    <Introduce v-show="!$route.meta.shovalues" />
    <!-- 全局组件↓ -->
    <Login />
    <Agreement />
    <Forgepass />
    <Loginthirdparty />
    <Setpassword />
    <Binding />
  </div>
</template>

<script>
import Login from "@/components/Login.vue";
import Agreement from "@/components/Agreement.vue";
import Forgepass from "@/components/Forgepass.vue";
import Loginthirdparty from "@/components/Loginthirdparty.vue";
import topNav from "@/components/Nav.vue";
import Introduce from "@/components/Introduce.vue";
import Setpassword from "@/components/Setpassword.vue";
import Binding from "@/components/Binding.vue";
export default {
  data() {
    return {

    }
  },
  components: {
    topNav,
    Introduce,
    Login,
    Agreement,
    Forgepass,
    Loginthirdparty,
    Setpassword,
    Binding,
  }
}
</script>
<style>
</style>
