<template lang="">
    <div>
        
    </div>
</template>
<script>
import { setToken } from "../utils/auth.js";
export default {
  //inject: ["reload"],
  data() {
    return {
      dataParms: {
        code: "",
      },
    };
  },
  // 因为是在当前页面添加code的，界面没有刷新，只是添加参数，所以就必须监测路由参数的变化
  watch: {
    $route() {
      if (this.$route.query.code != undefined) {
        this.getWeixin(this.$route.query.code, this.$route.query.state);
      }
    },
  },
  // js代码
  created() {
    this.dataParms.code = this.getUrlKey("code");
    // console.log('code',this.dataParms.code)
    // console.log(JSON.parse(sessionStorage.getItem('userdata')))
    this.getWeixin();
  },
  methods: {
    //  公共方法
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [",", ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    // 关闭弹框函数
    handleClose() {
      this.$store.commit("SET_THIRDPARTY", false);
      this.$store.commit("SET_LOGIN", false);
    },
    // 通过code获取微信信息
    getWeixin() {
        if (this.$store.state.thirdpartyNumber==0) {
                    //调用接口，将code传给后端
            if (JSON.parse(sessionStorage.getItem("userdata")) == null) {
                // 微信登录
                let params = new FormData();
                params.append("code", this.dataParms.code);
                this.$login.authorizationCode(params).then((res) => {
                // console.log(res, "微信登录获得token")
                if (res.code == 200) {
                    if (res.result.tel) {
                    setToken(res.result.token);
                    this.$login.getUserInfo().then((ress) => {
                        console.log(ress.result, "获得用户信息");
                        // sessionStorage.setItem('userdata', JSON.stringify(ress.result));
                        this.setSessionItem("userdata", JSON.stringify(ress.result));
                        if (ress.code == 200) {
                        this.handleClose();
                        this.$router.push("/");

                        // location. reload()
                        }
                    });
                    } else {
                    // 此方法不安全
                    setToken(res.result.token);

                    //20231125  三方用户新注册需跟随手机号主用户
                    this.setSessionItem("authnickname", res.result.nickname);
                    this.setSessionItem("authtype",3);
                    this.setSessionItem("authunionId", res.result.unionId);
                    
                    this.$store.commit("SET_BINDING", true);
                    }
                }
                });
            } else {
                //微信绑定
                let params = new FormData();
                params.append("code", this.dataParms.code);
                this.$login.webReplaceUserWx(params).then((res) => {
                // console.log(res, "微信绑定")
                if (res.code == 200) {
                    // setToken(res.result.token)
                    this.$login.getUserInfo().then((ress) => {
                    // console.log(ress.result, "获得用户信息")
                    // sessionStorage.setItem('userdata', JSON.stringify(ress.result));
                    this.setSessionItem("userdata", JSON.stringify(ress.result));
                    if (ress.code == 200) {
                        // this.handleClose()
                        // if (this.$route.path!='/') {
                        this.$router.push("/Bindaccount");

                        // }else{
                        // location. reload()
                        // }
                    } else {
                        this.$router.push("/Bindaccount");
                    }
                    });
                } else {
                    this.$router.push("/Bindaccount");
                }
                });
            }
        }
        if (this.$store.state.thirdpartyNumber==1) {
            //调用接口，将code传给后端
            if (JSON.parse(sessionStorage.getItem("userdata")) == null) {
                // qq登录
                let params = new FormData();
                // params.append("code", this.dataParms.code);
                params.append("code", JSON.parse(localStorage.getItem('qqCode')));
                this.$login.qqLogin(params).then((res) => {
                    console.log(res, "qq登录获得token")
                    if (res.code == 200) {
                        if (res.result.tel) {
                            setToken(res.result.token);
                            this.$login.getUserInfo().then((ress) => {
                                console.log(ress.result, "获得用户信息");
                                // sessionStorage.setItem('userdata', JSON.stringify(ress.result));
                                this.setSessionItem("userdata", JSON.stringify(ress.result));
                                if (ress.code == 200) {
                                this.handleClose();
                                this.$router.push("/");

                                // location. reload()
                                }
                            });
                        } else {
                            // 此方法不安全
                            setToken(res.result.token);
                            
                            //20231125  三方用户新注册需跟随手机号主用户
                            this.setSessionItem("authnickname", res.result.nickname);
                            this.setSessionItem("authtype",4);
                            this.setSessionItem("authunionId", res.result.unionId);

                            this.$store.commit("SET_BINDING", true);
                        }
                    }
                });
            } else {
                //qq绑定
                let params = new FormData();
                // params.append("code", this.dataParms.code);
                params.append("code", JSON.parse(localStorage.getItem('qqCode')));
                this.$login.bindQQ(params).then((res) => {
                console.log(res, "qq绑定")
                if (res.code == 200) {
                    // setToken(res.result.token)
                    this.$login.getUserInfo().then((ress) => {
                    console.log(ress.result, "获得用户信息")
                    // sessionStorage.setItem('userdata', JSON.stringify(ress.result));
                    this.setSessionItem("userdata", JSON.stringify(ress.result));
                    if (ress.code == 200) {
                        // this.handleClose()
                        // if (this.$route.path!='/') {
                        this.$router.push("/Bindaccount");

                        // }else{
                        // location. reload()
                        // }
                    } else {
                        this.$router.push("/Bindaccount");
                    }
                    });
                } else {
                    this.$router.push("/Bindaccount");
                }
                });
            }
        }
      
    },
  },
};
</script>