<template>
  <div id="Humantranslat">
    <div class="body">
      <!-- 内容 -->
      <div class="content">
        <!-- 人工翻译 -->
        <div class="box">
          <div class="days">
            <div class="lien-title">
              <div class="lien-title-text">人工翻译</div>
              <!-- <div class="lien-back"></div> -->
            </div>
            <div class="words">
              <div class="words-left">
                <div class="words-title">
                    <div class="words-title-text">介绍</div>
                    <div class="words-back"></div>
                </div>
                <div class="words-content">
                    <div v-html="textdata.introducePc"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 人工翻译 -->
      </div>
      <!-- 内容 -->
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
      textdata: {}, // 人工翻译数据
    }
  },
  mounted () {
    this.gettextdata()
  },
  methods: {
    // 获得人工翻译数据
    gettextdata () {
      this.$translation.getHumanTranslation().then((res) => {
        // console.log(res,"人工翻译数据")
        if (res.code==200) {
          this.textdata = res.result
        }
      })
    },
  },
};
</script>

<style lang="scss">
#Humantranslat {
  width: 100%;
  margin-top: 33px;
  .body {
    width: 1169px;
    margin: 0 auto;
    padding-left: 48px;
    padding-bottom: 24px;
    .content {
      margin-top: 28px;
      display: flex;
      align-content: center;
      justify-content: space-between;
      .lien-title {
        width: 80px;
        height: 25px;
        display: flex;
        align-items: flex-end;
        position: relative;
        font-size: 18px;
        &-text {
          margin-left: 8px;
        }
        .lien-back {
          width: 26px;
          height: 8px;
          background: #3d5cff;
          opacity: 0.3;
          position: absolute;
          left: 0;
          bottom: 2px;
        }
      }
      .box {
        .days {
          .words {
            width: 1220px;
            // height: 141px;
            min-height: 780px;
            position: relative;
            opacity: 0.9;
            margin-top: 34px;
            margin-bottom: 16px;
            display: flex;
            align-content: center;
            justify-content: space-between;
            
            .words-left {
              width: 1220px;
              // height: 141px;
              background: linear-gradient(90deg, #EEF1F9 0%, #F8FCFF 98%);
              border-radius: 14px;
              padding:  0 0 24px 0;
                .words-title{
                    // width: 80px;
                    // height: 25px;
                    display: flex;
                    align-items: flex-end;
                    position: relative;
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #2F3236;
                    margin: 18px 0 0 28px;
                    &-text {
                    margin-left: 8px;
                    }
                    .words-back{
                        width: 26px;
                        height: 8px;
                        background: #3d5cff;
                        opacity: 0.3;
                        position: absolute;
                        left: 0;
                        bottom: 2px;
                    }
                }
                .words-content{
                    width: 800px;
                    min-height: 500px;
                    margin: 0 auto;
                    margin-top: 32px;
                    img{
                      width: 100%;
                      // height: 500px;
                    }
                }
            }
          }
        }
      }
    }
  }
}
</style>