<!-- 个人中心-收藏 -->
<template>
  <div id="Collection">
    <div class="content">
      <div class="content-bottom">
        <div class="content-bottom-title">
          <div class="content-top-top-left">
            <div class="content-top-top-left-text">我的收藏</div>
            <div class="content-top-top-left-back"></div>
            <div
              v-show="!isshowsearchend"
              class="newcollect cursor"
              @click="newClick"
            >
              新建文件夹
            </div>
          </div>
          <div class="content-top-top-right">
            <div class="search-smail" v-show="!isshowsearch">
              <img
                class="cursor"
                @click="changeshowsearch"
                src="../../assets/icon/icon_sear.png"
              />
            </div>
            <div class="search-big" v-show="isshowsearch">
              <div class="search-big-left">
                <img
                  class="cursor"
                  @click="searchthing"
                  src="../../assets/icon/icon_sear.png"
                />
              </div>
              <div class="search-big-right">
                <el-input
                  class="search-big-input"
                  v-model="searchval"
                  placeholder="请输入关键词"
                  @keyup.enter.native="searchthing"
                ></el-input>
                <img
                  class="cursor"
                  v-show="searchval != ''"
                  @click="cleansearchval"
                  src="../../assets/icon/icon_qingkong2.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="search-end" v-show="isshowsearchend">
          <div class="search-head">
            <div
              class="cursor"
              :class="defaultcollection == '' ? 'searchtype1' : 'searchtype2'"
              @click="changesearchtype('')"
            >
              全部
            </div>
            <div
              class="cursor"
              v-for="(item, key) in navdata"
              :class="
                item.id == defaultcollection ? 'searchtype1' : 'searchtype2'
              "
              @click="changesearchtype(item.id)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="search-bottom" v-loading="searloading">
            <div class="search-content" v-for="(item, key) in searchcontent">
              <div
                class="search-content-left cursor"
                @click="wordClick(item, key)"
              >
                <div v-html="brightenKeyword(item.name1, searchval)">
                  {{ item.name1 }}
                </div>
                <p>
                  <span v-html="brightenKeyword(item.name2, searchval)">{{
                    item.name2
                  }}</span
                  ><span v-html="brightenKeyword(item.name3, searchval)">{{
                    item.name3
                  }}</span>
                </p>
              </div>
              <div
                class="search-content-right cursor"
                @click="changecollections(item.id)"
              >
                <img class="" src="../../assets/icon/icon_yishoucang.png" />
                <!-- <img class=" cursor" v-show="item.iscollection==0" @click="changesearchcollection(key)" src="../../assets/icon/icon_shoucang.png"> -->
                <p>已收藏</p>
                <!-- <p v-show="item.iscollection==0">收藏</p> -->
              </div>
            </div>
            <div class="nodata" v-show="searchcontent.length == 0">
              暂无数据
            </div>
          </div>
        </div>
        <div class="content-content" v-show="!isshowsearchend">
          <div class="content__nav">
            <el-menu
              :default-active="defaultcollection"
              class="el-menu-vertical-demo"
              v-loading="leftloading"
            >
              <el-menu-item
                v-for="(item, key) in navdata"
                :index="item.id"
                @click="changecollect(item.id)"
              >
                <span slot="title">
                  <div>
                    <el-popover
                      placement="bottom-start"
                      popper-class="popv"
                      width="60"
                      trigger="click"
                    >
                      <div class="popv-button cursor" @click="showedit(item)">
                        编辑
                      </div>
                      <div class="popv-button2 cursor" @click="showdel(item)">
                        删除
                      </div>
                      <img
                        class="nav-button cursor"
                        slot="reference"
                        src="../../assets/icon/icon_xiugai.png"
                      />
                    </el-popover>
                  </div>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.name"
                    placement="top"
                  >
                    <div
                      style="
                        width: 180px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                      "
                    >
                      {{ item.name }}
                    </div>
                  </el-tooltip>
                  <div v-show="item.collectDefault == 0" class="defaultation">
                    默认
                  </div>
                </span>
              </el-menu-item>
            </el-menu>
          </div>
          <div class="content-table">
            <div class="table-box" v-loading="loading">
              <div
                class="tables"
                v-for="(item, key) in tabledata"
                v-show="tabledata.length != 0"
              >
                <div class="tables-title">
                  <img
                    class="cursor"
                    @click="changecollection(item.id)"
                    src="../../assets/icon/icon_yishoucang.png"
                  />
                  <!-- <img class=" cursor" v-show="item.iscollection==0" @click="changecollection(key)" src="../../assets/icon/icon_shoucang.png"> -->
                  <p class="cursor" @click="wordClick(item, key)">
                    {{ item.name1 }}
                  </p>
                </div>
                <div class="tables-text cursor" @click="wordClick(item, key)">
                  {{ item.name2 }}{{ item.name3 }}
                </div>
              </div>
              <div class="nodata" v-show="tabledata.length == 0">暂无数据</div>
            </div>

            <!-- 分页 -->
            <div class="paging">
              <!-- <div :class="currentPage==1?'nopagbutton nocursor':'pagbutton cursor'" @click="firstpage">首页</div> -->
              <input
                type="button"
                value="首页"
                @click="firstpage"
                :class="
                  currentPage == 1 ? 'nopagbutton nocursor' : 'pagbutton cursor'
                "
              />
              <!-- <div :class="currentPage==1?'nopagbutton nocursor':'pagbutton cursor'" @click="uppage">上一页</div> -->
              <input
                type="button"
                value="上一页"
                @click="uppage"
                :class="
                  currentPage == 1 ? 'nopagbutton nocursor' : 'pagbutton cursor'
                "
              />
              <el-pagination
                layout="pager"
                :page-size="pageSize"
                :page-count="totalPage"
                :current-page="currentPage"
                @current-change="currentchange"
              >
              </el-pagination>
              <!-- <div :class="currentPage==totalPage?'nopagbutton nocursor':'pagbutton cursor'" @click="nextpage">下一页</div> -->
              <input
                type="button"
                value="下一页"
                @click="nextpage"
                :class="
                  currentPage == totalPage
                    ? 'nopagbutton nocursor'
                    : 'pagbutton cursor'
                "
              />
              <!-- <div :class="currentPage==totalPage?'nopagbutton nocursor':'pagbutton cursor'" @click="lastpage">尾页</div> -->
              <input
                type="button"
                value="尾页"
                @click="lastpage"
                :class="
                  currentPage == totalPage
                    ? 'nopagbutton nocursor'
                    : 'pagbutton cursor'
                "
              />
            </div>
          </div>
        </div>
      </div>
      <Globalmessage ref="message" :duration="3000" :top="100" :widths="1196" />
    </div>
    <!-- 删除 -->
    <el-dialog
      title=""
      :visible.sync="isshowdel"
      width="410px"
      top="316px"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <div slot="title" class="dialog1-top-title">
        <span>删除“{{ deltitle }}”</span>
        <img
          class="cursor"
          @click="Closedel"
          src="../../assets/icon/search_close.png"
        />
      </div>
      <div class="dialog1-content">
        您确定要删除收藏夹“{{ deltitle }}”吗？
        <!-- 已收藏的单词不会被删除。 -->
      </div>
      <div class="foot1">
        <el-button class="but2" @click="Closedel">取消</el-button>
        <el-button class="but" @click="suredel">删除</el-button>
      </div>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog
      title=""
      :visible.sync="isshowedit"
      width="480px"
      top="316px"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <div slot="title" class="dialog1-top-title">
        <span>编辑收藏夹</span>
        <img
          class="cursor"
          @click="Closeedit"
          src="../../assets/icon/search_close.png"
        />
      </div>
      <div class="dialog2-content">
        <div class="forms-content">
          <div class="forms-content-title">收藏夹名称</div>
          <div class="forms-input">
            <el-input
              class="forms-input-input"
              v-model="edittitle"
              placeholder="选填写收藏夹名称"
            >
            </el-input>
            <img
              class="cursor"
              v-show="edittitle != ''"
              @click="cleanedit"
              src="../../assets/icon/icon_qingkong2.png"
            />
          </div>
          <div
            class="defaultshow2 cursor"
            v-show="isdefaultcollection"
            @click="changedefaultcollection"
          >
            取消默认收藏夹
          </div>
          <div
            class="defaultshow cursor"
            v-show="!isdefaultcollection"
            @click="setdefaultcollection"
          >
            设置默认收藏夹
          </div>
        </div>
      </div>
      <div class="foot2">
        <el-button
          class="but"
          @click="sureedit"
          :class="edittitle == '' ? 'nocursor' : ''"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <!-- 新建文件夹 -->
    <popupNewadd :status="popupNewaddStatus" @newCloses="newCloses" />
  </div>
</template>

<script>
import Globalmessage from "../../components/Globalmessage.vue";
import popupNewadd from "../components/popupNewadd.vue";
export default {
  components: {
    Globalmessage,
    popupNewadd,
  },
  data() {
    return {
      searloading: false, // 搜索时的加载
      popupNewaddStatus: false, // 新建文件夹弹窗
      leftloading: false, // 收藏夹加载中
      loading: false, // 加载中
      // 搜索的类型
      searchtypes: [
        {
          title: "全部",
          val: "0",
        },
        {
          title: "化学",
          val: "1",
        },
      ],
      searchtype: "0", // 选中的搜索类型
      // 搜索结果
      searchcontent: [],

      isshowsearch: false, // 是否显示搜素框
      isshowsearchend: false, // 显示搜索之后的结果
      searchval: "", // 搜素框的内容

      isshowedit: false, // 是否显示编辑弹窗
      edittitle: "", // 编辑的标题
      editdefaultcollection: "", // 编辑里设置那个是默认的
      isdefaultcollection: false, // 判断是否是默认

      isshowdel: false, // 是否显示删除弹窗
      deltitle: "", // 删除的标题
      defaultcollection: "", // 默认选中的收藏
      selectcollection: "", // 当前选中的收藏夹
      navdata: [],

      servicenumber: "H18812345678", // 客服号码
      textnumber: "123",
      filenumber: "0",
      // 分页
      totalPage: 1, // 统共页数，默认为1
      currentPage: 1, // 当前页数 ，默认为1
      pageSize: 9, // 每页显示数量
      tabledata: [], // 当前页显示内容
    };
  },
  computed: {},
  mounted() {
    this.getWebCollect();
  },
  created() {},
  methods: {
    wordClick(e, k) {
      // console.log(e)
      this.$router.push({
        path: "/searchcollection",
        query: {
          searchvalue: e.id,
          searchname1: e.name1,
          searchname3: e.name3,
        },
      });
    },
    // 新建文件夹
    newClick() {
      this.popupNewaddStatus = true;
      // this.handleClose();
    },
    // 关闭新建文件夹弹窗
    newCloses() {
      // this.defaultcollection = ''
      this.getWebCollect();
      this.popupNewaddStatus = false;
    },
    //   点击左侧收藏夹
    changecollect(val) {
      // console.log(val)
      if (val == null) {
        return false;
      }
      this.currentPage = 1;
      this.selectcollection = val;
      this.getCollectWordList(this.selectcollection);
    },
    //   获取收藏夹列表
    getWebCollect() {
      this.leftloading = true;
      this.$apis.getWebCollect().then((res) => {
        // console.log(res,"收藏夹")
        if (res.code == 200) {
          this.navdata = res.result;
          this.leftloading = false;
          if (this.navdata.length != 0) {
            for (let i = 0; i < this.navdata.length; i++) {
              if (this.navdata[i].collectDefault == 0) {
                this.defaultcollection = this.navdata[i].id;
                this.selectcollection = this.navdata[i].id;
              }
            }
            // console.log()
            this.getCollectWordList(this.selectcollection);
          }
        }
      });
    },
    getCollectWordList(val) {
      this.loading = true;
      let params = new FormData();
      params.append("pageNo", this.currentPage);
      params.append("pageSize", this.pageSize);
      params.append("collectId", val);
      // let params = {
      //     pageNo: this.currentPage,
      //     pageSize: this.pageSize,
      //     collectId: val
      // }
      this.$mycenter.getCollectWordList(params).then((res) => {
        // console.log(res,"右侧的单词")
        if (res.code == 200) {
          this.tabledata = res.result.records;
          this.totalPage = res.result.pages;
          this.loading = false;
        }
      });
    },
    //   关键词变亮
    brightenKeyword(val, keyword) {
      // 方法1：筛选变色（区分大小写）
      // val = val + '';
      // if (val.indexOf(keyword) !== -1 && keyword !== '') {
      //   if (val === this.currentNodeLabel) {
      //     const resCurrent = val.replace(keyword, `<span style="color: #000;background-color: orange;">${keyword}</span>`)
      //     return resCurrent
      //   } else {
      //     const res = val.replace(keyword, `<span style="color: #000;background-color: yellow;">${keyword}</span>`)
      //     return res
      //   }
      // } else {
      //   return val
      // }
      // 方法2：用正则表达式 （不区分大小写）
      const Reg = new RegExp(keyword, "i");
      if (val) {
        if (val === this.currentNodeLabel) {
          // 这里为什么使用$&不使用keyword，因为这里使用正则表达式不区分大小写，如果是文本时大写，搜索的关键字是小写也是会被匹配的，这样匹配替换掉的话，文本内的文字会被替换成搜索的keyword，也就是改成了小写，这样不太合理
          // const resCurrent = val.replace(Reg, `<span style="color: #000;background-color: orange;">${keyword}</span>`)
          const resCurrent = val.replace(
            Reg,
            `<span style="color: rgba(61, 92, 255, 1);">$&</span>`
          );
          return resCurrent;
        } else {
          const res = val.replace(
            Reg,
            `<span style="color: rgba(61, 92, 255, 1);">$&</span>`
          );
          return res;
        }
      }
    },
    //   改变搜索结果的收藏状态
    changesearchcollection(key) {
      this.searchcontent[key].iscollection =
        !this.searchcontent[key].iscollection;
    },
    //   改变搜索的类型
    changesearchtype(val) {
      // this.searchtype = val
      this.searloading = true;
      this.defaultcollection = val;
      this.searchend();
    },
    //   搜素
    searchthing() {
      this.defaultcollection = "";
      this.isshowsearchend = true;
      if (this.searchval == "") {
        return false;
      }
      this.searloading = true;
      this.searchend();

      // console.log('555')
    },
    // 搜索结果
    searchend() {
      let params = new FormData();
      params.append("name", this.searchval);
      params.append("collectId", this.defaultcollection);
      this.$mycenter.webSelectCollectWordList(params).then((res) => {
        // console.log(res,"搜索结果")
        if (res.code == 200) {
          this.searchcontent = res.result;
          this.searloading = false;
        }
      });
    },
    //   清空搜素框
    cleansearchval() {
      this.searchval = "";
      this.isshowsearchend = false;
      this.getWebCollect();
    },
    //   显示搜素框
    changeshowsearch() {
      this.isshowsearch = true;
    },
    //   设置为默认的收藏
    setdefaultcollection() {
      this.isdefaultcollection = true;
    },
    //   取消默认收藏
    changedefaultcollection() {
      this.isdefaultcollection = false;
    },
    //   清空编辑输入框
    cleanedit() {
      this.edittitle = "";
    },
    //   显示编辑弹窗
    showedit(val) {
      this.edittitle = val.name;
      this.editdefaultcollection = val.id;
      if (this.editdefaultcollection == this.defaultcollection) {
        this.isdefaultcollection = true;
      } else {
        this.isdefaultcollection = false;
      }
      this.isshowedit = true;
    },
    //   确定编辑
    sureedit() {
      if (this.edittitle == "") {
        return false;
      }

      // console.log(this.edittitle,this.isdefaultcollection==true?'0':'1',this.selectcollection)
      //
      let parames = new FormData();
      parames.append("id", this.selectcollection);
      parames.append("name", this.edittitle);
      parames.append(
        "collectDefault",
        this.isdefaultcollection == true ? "0" : "1"
      );
      this.$mycenter.editCollect(parames).then((res) => {
        if (res.code == 200) {
          // console.log(res,"修改成功")
          this.$message({ message: "修改成功", type: "success" });
          this.defaultcollection = "";
          this.getWebCollect();
        }
      });
      this.Closeedit();
    },
    //   关闭编辑弹窗
    Closeedit() {
      this.isshowedit = false;
    },
    //   显示删除弹窗
    showdel(val) {
      this.deltitle = val.name;
      this.isshowdel = true;
    },
    //   确定删除
    suredel() {
      //
      let parames = new FormData();
      parames.append("id", this.selectcollection);
      this.$mycenter.deleteCollect(parames).then((res) => {
        if (res.code == 200) {
          // console.log(res,"删除成功")
          this.$message({ message: "删除成功", type: "success" });
          this.defaultcollection = "";
          this.getWebCollect();
        }
      });
      this.Closedel();
    },
    //   关闭删除弹窗
    Closedel() {
      this.isshowdel = false;
    },
    //   搜索结果的改变收藏状态
    changecollections(val) {
      let params = new FormData();
      params.append("collectWordId", val);
      this.$apis.cancelCollectWord(params).then((res) => {
        if (res.code == 200) {
          // this.getCollectWordList(this.selectcollection)
          this.searloading = true;
          this.searchend();
        }

        // console.log(res,"取消收藏")
      });
      // this.tabledata[key].iscollection = !this.tabledata[key].iscollection
    },
    //   改变收藏状态
    changecollection(val) {
      let params = new FormData();
      params.append("collectWordId", val);
      this.$apis.cancelCollectWord(params).then((res) => {
        if (res.code == 200) {
          this.getCollectWordList(this.selectcollection);
        }

        // console.log(res,"取消收藏")
      });
      // this.tabledata[key].iscollection = !this.tabledata[key].iscollection
    },
    //   点击首页
    firstpage() {
      if (this.currentPage <= 1) {
        return false;
      }
      this.currentPage = 1;
      this.getCollectWordList(this.selectcollection);
    },
    //   上一页
    uppage() {
      if (this.currentPage <= 1) {
        return false;
      }
      this.currentPage = this.currentPage - 1;
      this.getCollectWordList(this.selectcollection);
    },
    // 点击尾页
    lastpage() {
      if (this.currentPage >= this.totalPage) {
        return false;
      }
      this.currentPage = this.totalPage;
      this.getCollectWordList(this.selectcollection);
    },
    // 点击下一页
    nextpage() {
      if (this.currentPage >= this.totalPage) {
        return false;
      }
      this.currentPage = this.currentPage + 1;
      this.getCollectWordList(this.selectcollection);
    },
    //   当前页数变化
    currentchange(val) {
      this.currentPage = val;
      this.getCollectWordList(this.selectcollection);
    },
  },
};
</script>
<style lang="scss">
#Collection {
  .nodata {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  .defaultshow {
    width: 95px;
    height: 26px;
    border-radius: 6px;
    border: 1px solid #dde0e6;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #8189b0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 14px 0 0 0;
  }
  .defaultshow2 {
    width: 95px;
    height: 26px;
    border-radius: 6px;
    border: 1px solid #dde0e6;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 14px 0 0 0;
    color: #fff;
    background: #3d5cff;
  }
  .el-dialog {
    border-radius: 14px;
  }
  .search-big-input {
    .el-input__inner {
      width: 404px;
      height: 56px;
      border-radius: 14px;
      background: rgba(255, 255, 255, 0);
      border: 0;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1f1f1f;
    }
    .el-input__inner::placeholder {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #a8abb8;
    }
    /* 谷歌 */
    .el-input__inner::-webkit-input-placeholder {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #a8abb8;
    }
    /* 火狐 */
    .el-input__inner:-moz-placeholder {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #a8abb8;
    }
    /*ie*/
    .el-input__inner:-ms-input-placeholder {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #a8abb8;
    }
  }
  .forms-input-input {
    .el-input__inner {
      width: 432px;
      height: 48px;
      border-radius: 14px;
      background: rgba(255, 255, 255, 0);
      border: 0;
      font-size: 18px;
    }
    .el-input__inner::placeholder {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #a8abb8;
    }
    /* 谷歌 */
    .el-input__inner::-webkit-input-placeholder {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #a8abb8;
    }
    /* 火狐 */
    .el-input__inner:-moz-placeholder {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #a8abb8;
    }
    /*ie*/
    .el-input__inner:-ms-input-placeholder {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #a8abb8;
    }
  }
}
.popv {
  background: #ffffff !important;
  box-shadow: 0px 0px 20px 2px rgba(184, 184, 210, 0.4) !important;
  border-radius: 20px !important;
  min-width: 10px !important;
  .popv-button {
    width: 60px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: #f5f6f8 1px solid;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #5e5f60;
  }
  .popv-button2 {
    width: 60px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a8abb8;
  }
}
</style>
<style lang="scss" scoped>
#Collection {
  .newcollect {
    width: 100px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 0 24px;
    border-radius: 6px;
    border: 1px solid #e2e7ff;
    font-size: 14px;
  }
  .search-end {
    width: 1140px;
    height: 890px;
    margin: 30px 0 0 24px;
    .search-bottom {
      height: 850px;
      overflow: hidden;
      overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      .search-content {
        width: 1132px;
        height: 65px;
        border-bottom: solid 1px #f4f4f5;
        margin: 20px 0 0 20px;
        display: flex;
        justify-content: space-between;
        .search-content-left {
          width: 60%;
          div {
            font-size: 17px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
          p {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #8c8c8c;
            margin: 8px 0 0 0;
          }
        }
        .search-content-right {
          width: 80px;
          height: 30px;
          background: #fff9f5;
          border-radius: 6px;
          margin: 22px 0 0 0;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #50555e;
          display: flex;
          align-items: center;
          p {
            margin: 0 0 0 4px;
          }
          img {
            width: 22px;
            height: 22px;
            margin: 0 0 0 4px;
          }
        }
      }
    }
    .search-bottom::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .search-head {
      display: flex;
      .searchtype1 {
        min-width: 54px;
        height: 27px;
        background: #3d5cff;
        border-radius: 6px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 14px 0 0;
      }
      .searchtype2 {
        min-width: 54px;
        height: 27px;
        background: #f1f1f1;
        border-radius: 6px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #50555e;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 14px 0 0;
      }
    }
  }
  .dialog1-top-title {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2e3e5c;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    img {
      position: absolute;
      right: 0px;
      width: 32px;
      height: 32px;
    }
  }
  .dialog1-content {
    width: 336px;
    height: 140px;
    margin: 0px auto 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #5e5f60;
    text-align: center;
  }
  .dialog2-content {
    width: 367px;
    height: 140px;
    margin: 0px auto 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #5e5f60;
  }
  .foot2 {
    // width: 335px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    .but {
      width: 432px;
      height: 50px;
      background: #3d5cff;
      border-radius: 12px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      border: none;
    }
  }
  .foot1 {
    width: 335px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    .but {
      width: 160px;
      height: 50px;
      background: #3d5cff;
      border-radius: 12px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      border: none;
    }
    .but2 {
      width: 160px;
      height: 50px;
      background: rgba(61, 92, 255, 0.1);
      border-radius: 12px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6975af;
      border: none;
    }
  }
  .width22 {
    width: 22px;
    height: 22px;
    margin: 0 14px 0 0;
  }
  .defaultation {
    width: 34px;
    height: 22px;
    background: #ffffff;
    border-radius: 6px;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #8189b0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 8px;
  }
  .nav-button {
    width: 22px;
    height: 22px;
    margin: 0 14px 0 0;
  }
  .nocursor {
    cursor: not-allowed;
    opacity: 0.5;
  }
  .content {
    width: 1196px;
    margin: 0 0 0 24px;
    .content-bottom {
      width: 1196px;
      min-height: 300px;
      background: #ffffff;
      box-shadow: 2px 0px 16px 0px rgba(0, 21, 41, 0.08);
      border-radius: 14px;
      // margin: 24px 0 0 0;
      padding: 0 0 27px 0;
      .content-content {
        width: 1148px;
        min-height: 200px;
        margin: 30px 0 0 24px;
        display: flex;
        // align-items: center;
        .content-table {
          width: 926px;
          height: 890px;
          background: #ffffff;
          border-radius: 14px;
          border: 1px solid #e2e7ff;
          margin: 0 0 0 11px;
          .table-box {
            height: 735px;
            margin: 13px 0 0 0;
          }
          .tables {
            width: 880px;
            height: 63px;
            border-bottom: solid 1px #f4f4f5;
            margin: 22px 0 0 22px;
            .tables-title {
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              img {
                width: 22px;
                height: 22px;
              }
              p {
                width: 90%;
                margin: 0 0 0 22px;
                font-size: 17px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
              }
            }
            .tables-text {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #8c8c8c;
              margin: 5px 0 0 26px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .content__nav {
          width: 208px;
          height: 890px;
          // margin-top: 16px;
          background: #ffffff;
          // box-shadow: 2px 0px 6px 0px rgba(0, 21, 41, 0.12);
          border-radius: 14px;
          border: 1px solid #e2e7ff;
          overflow: hidden;
          overflow-y: scroll;
          scrollbar-width: none; /* Firefox */
          -ms-overflow-style: none; /* IE 10+ */

          .el-menu-item.is-active {
            padding-left: 6px;
            // padding-left: 0 !important;
            color: #3d5cff;
            outline: none;
            background-color: #f0f3ff;
            border-right: 2px solid #3d5cff;
            span {
              display: flex;
              align-items: center;
            }
          }
          .el-menu-item {
            height: 48px;
            line-height: 48px;
            // padding: 0 40px !important;
            padding-left: 6px !important;
            font-size: 14px;
            span {
              display: flex;
              align-items: center;
            }
          }
        }
        .content__nav::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
      }
      .content-bottom-title {
        width: 1138px;
        height: 25px;
        padding: 23px 0 0 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .content-top-top-right {
      .search-big {
        width: 460px;
        height: 56px;
        background: linear-gradient(90deg, #eceff8 2%, #f7fbff 99%);
        border-radius: 14px;
        display: flex;
        .search-big-left {
          width: 56px;
          height: 56px;
          background: #e2e7ff;
          border-radius: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 22px;
            height: 22px;
          }
        }
        .search-big-right {
          position: relative;
          .search-big-input {
          }
          img {
            width: 22px;
            height: 22px;
            position: absolute;
            right: 17px;
            top: 17px;
          }
        }
      }
      .search-smail {
        display: flex;
        align-items: center;
        img {
          width: 22px;
          height: 22px;
        }
      }
    }
    .content-top-top-left {
      // width: 80px;
      height: 25px;
      display: flex;
      align-items: flex-end;
      position: relative;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2f3236;
      margin: 0 0 0 24px;
      &-text {
        margin-left: 8px;
      }
      .content-top-top-left-back {
        width: 26px;
        height: 8px;
        background: #3d5cff;
        opacity: 0.3;
        position: absolute;
        left: 0;
        bottom: 2px;
      }
    }
  }
  .forms-content {
    width: 432px;
    height: 103px;
    .forms-input {
      display: flex;
      align-items: center;
      position: relative;
      .forms-input-input {
        border: none;
        .el-input__inner {
          border: 0;
        }
      }
      img {
        width: 22px;
        height: 22px;
        position: absolute;
        right: 13px;
      }
    }
    .forms-content-title {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #5e5f60;
      margin: 0 0 15px 0;
    }
    .forms-input {
      width: 432px;
      height: 48px;
      background: #f9fafb;
      border-radius: 14px;
    }
  }
  .paging {
    margin: 64px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    ::v-deep {
      .el-pagination {
        margin: 0 12px;
        .el-pager li:not(.disabled) {
          line-height: 35px;
          width: 35px;
          height: 35px;
          font-size: 14px;
          font-family: HelveticaNeue;
          color: #565d64;
          border-radius: 100%;
        }
        .el-pager li:not(.disabled).active {
          line-height: 35px;
          width: 35px;
          height: 35px;
          background: #4e555c;
          font-size: 14px;
          font-family: HelveticaNeue;
          color: #ffffff;
        }
      }
    }
    .el-pager li.active {
      width: 32px;
      height: 32px;
      background: #4e555c;
      font-size: 14px;
      font-family: HelveticaNeue;
      color: #ffffff;
    }
    .pagbutton {
      background: #fff;
      border: none;
      width: 42px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #565d64;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .nopagbutton {
      background: #fff;
      border: none;
      width: 42px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ccd1d4;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>