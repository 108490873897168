import request from '../utils/request.js'

export function userInfo(params) {
    return request.get('/appUser/getUserInfo', params)
}
// 获得查询记录列表
export function getSearchRecordList(params) {
    return request.post('/appHome/getSearchRecordList', params)
}
// 搜索中出现的关联词
export function homeSearch(params) {
    return request.post('/appHome/homeSearch', params)
}

// 每日金句
export function getDailySentence(params) {
    return request.post('/appHome/getDailySentence', params)
}
// 今日学习计划列表
export function getStudyPlanListWeb(params) {
    return request.post('/studyPlan/getStudyPlanListWeb', params)
}

// 首页搜索记录添加
export function addSearchRecord(params) {
    return request.post('/appHome/addSearchRecord', params)
}
// 删除查询记录列表
export function deleteSearchRecord(params) {
    return request.post('/appHome/deleteSearchRecord', params)
}

// 搜索结果用的搜索内容
export function appThreeSearch(params) {
    return request.post('/appHome/appThreeSearch', params)
}

// 用户提交反馈
export function addFeedback(params) {
    return request.post('/appUser/addFeedback', params)
}
// 上传图片支持多图
export function UploadImg(params) {
    return request.post('/assist/UploadImg', params)
}

// 判定用户有无收藏(双端)
export function ifUserCollect(params) {
    return request.post('/collect/ifUserCollect', params)
}
// 获取我的收藏文件夹 -- web 不分页
export function getWebCollect(params) {
    return request.post('/collect/getWebCollect', params)
}
// 收藏单词
export function addCollectWord(params) {
    return request.post('/collect/addCollectWord', params)
}
// 取消收藏单词
export function cancelCollectWord(params) {
    return request.post('/collect/cancelCollectWord', params)
}
// 新创建收藏夹
export function addCollect(params) {
    return request.post('/collect/addCollect', params)
}

// 专业导航一级分类
export function getOneClassify(params) {
    return request.post('/appHome/getOneClassify', params)
}
// 专业导航根据1级分类id获取二级分类
export function getTwoClassifyById(params) {
    return request.post('/appHome/getTwoClassifyById', params)
}
// 专业导航搜索
export function getProfessionalSearch(params) {
    return request.post('/appHome/getProfessionalSearch', params)
}
// 根据三级分类id获取词汇数据
export function getThreeVocabularyById(params) {
    return request.post('/appHome/getThreeVocabularyById', params)
}
// 根据三级分类id获取词汇分页数据
export function getThreeVocabularyPageById(params) {
    return request.post('/appHome/getThreeVocabularyPageById', params)
}
export function getResultThesaurusList(params) {
    return request.post('/appHome/getResultThesaurusList', params)
}

// 新增学习计划
export function addStudyPlan(params) {
    return request.post('/studyPlan/addStudyPlan', params)
}
// 获取学习计划列表
export function getStudyPlanList(params) {
    return request.post('/studyPlan/getStudyPlanList', params)
}

// 获取正在学的学习计划
export function getStudyPlan(params) {
    return request.post('/studyPlan/getStudyPlan', params)
}
// 学习当前单词
export function studyPlanWord(params) {
    return request.post('/studyPlan/studyPlanWord', params)
}
// 修改学习计划
export function editStudyPlan(params) {
    return request.post('/studyPlan/editStudyPlan', params)
}
// 删除学习计划
export function delStudyPlan(params) {
    return request.post('/studyPlan/delStudyPlan', params)
}
// 切换去学习状态
export function updateToStudy(params) {
    return request.post('/studyPlan/updateToStudy', params)
}