<!-- 我是底部导航组件 -->
<template>
  <div id="introduce">
    <div class="body">
      <div class="nav">
        <img src="../assets/image/logo-bottom.png" alt="" class="logo" />
        <!-- <div class="nav-title cursor">
          <div v-for="(item, key) in navList" :key="key" @click="nextto(item)">
            {{ item.name }}
          </div>
        </div> -->
      </div>
      <div class="allnav">
        <div class="nav-left">
          
          <div class="synopsis" v-html="bottomBrief">

          </div>
          <div class="synopsis" style="margin-top: 13px;">
            <div>
              电话： 0531-88897205   4001776678    010-84464096
            </div>
            <div>
              邮箱： STEM@sunther.com
            </div>
            <div>
              地址：济南市高新区鑫盛大厦B座802
            </div>
          </div>
          <div class="relation cursor" v-html="bottomCopyright" @click="tojump('https://beian.miit.gov.cn/')">
          </div>
        </div>
        <div class="nav-con">
          <div class="nav-con-title" >
            其他产品与服务
          </div>
          <div class="nav-con-con" @click="tojump('https://client.mt.sunther.com/')">
            科工专业智能翻译服务系统

          </div>
          <div class="nav-con-con" @click="tojump('https://sci.sunther.com/')">
            SCI论文语言服务（电气领域）

          </div>
          <div class="nav-con-con" @click="tojump('http://119.3.81.111:8276/')">
            一带一路生态环境跨语言科技信息服务平台

          </div>
          <div class="nav-con-con" @click="tojump('https://pes.sunther.com/')">
            国际工程标准管理及应用系统

          </div>
        </div>
        <div class="nav-right">
          <div v-for="(item, key) in navList" :key="key" @click="nextto(item)">
            {{ item.name }}
          </div>

        </div>
        
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        {
          name: "关于我们",
          path: "/Aboutas",
        },
        {
          name: "隐私政策",
          path: "/Privacyagreement",
        },
        // {
        //   name: "翻译政策",
        //   path: "/Translationpolicy",
        // },
        {
          name: "公司官网",
          path: "http://www.sunther.com/",
        },
      ],
      bottomBrief: "",
      bottomCopyright: "",
    };
  },
  mounted() {
    this.$login.getSetting().then((ress) => {
      // console.log(ress, "下载二维码",)
      this.bottomBrief = ress.result.bottomBrief
      this.bottomCopyright = ress.result.bottomCopyright
    })
  },
  methods: {
    // 跳转外部链接
    tojump(i){
      window.open(i, '_blank') 
    },
    nextto (item) {
      if (item.name=='公司官网') {
        this.tojump(item.path)
      }else{
        if(item.path==this.$route.path){
            return false
        }
        this.$router.replace(item.path);
      }
     
    },
  },
};
</script>

<style lang="scss" scoped>
#introduce {
  min-width: 1450px;
  height: 320px;
  background: #e7e9ef;
  display: flex;
  justify-content: center;
  .body {
    width: 1317px;
    background: #e7e9ef;
    padding-top: 30px;
    padding-bottom: 52px;
    .nav-con{
      width: 500px;
      padding:  0 50px 0 50px;
      border-right: 1px solid #D4D5D6;
      .nav-con-con{
        font-size: 14px;
        margin: 0 0 10px 0;
        color: #4E4E4F;
        cursor:pointer;
      }
      .nav-con-title{
        color: #333333;
        font-size: 17px;
        margin: 0 0 20px 0 ;
        font-weight: bold;
      }
    }
    .allnav{
      display: flex;
      
    }
    .nav-right{
      width: 300px;
      padding:  0 0 0 50px;
      div{
        cursor:pointer;
        color: #333333;
        margin: 0px 0 25px;
        font-weight: bold;
      }

    }
    .nav-left{
      width: 600px;
      padding: 0 50px 0 0;
      border-right: 1px solid #D4D5D6;
    }
    .relation {
        width: 1217px;
        // color: #9199A1;
        color: #333333;
        font-size: 13px;
        margin-top: 20px;
        margin-left: 20px;
    }
    .synopsis {
        // color: #858597;
        color: #333333;
        font-size: 14px;
        // margin-top: 13px;
        margin-left: 20px;
        line-height: 22px;
        div{
          margin: 0 0 5px 0;
        }
    }
    .nav {
      width: 100%;
      display: flex;
      align-items: center;
      &-title {
        width: 410px;
        margin-left: 30px;
        margin-top: 30px;
        color: #111;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .logo {
        width: 208px;
        height: 60px;
      }
    }
  }
}
</style>