<template>
  <div id="Texttranslation">
    <div class="body">
      <!-- 内容 -->
      <div class="content">
        <!-- 文本翻译 -->
        <div class="box">
          <div class="days">
            <div class="lien-title">
              <div class="lien-title-text">文本翻译</div>
              <div class="lien-back"></div>
            </div>
            <div class="quota">
              <div class="quota-left">
                <div class="quota-left-left">
                  免费额度:
                  <span>{{freequota}}</span>
                </div>
                <div class="quota-left-right cursor" v-show="userdata.level!=0" @click="tovip">
                  获取更多额度
                  <img src="../../assets/icon/icon_more.png">
                </div>
              </div>
              <div class="quota-right">
                <div class="quota-right-word">{{language1}}</div>
                <img class="cursor" @click="changelanguage" src="../../assets/icon/icon_qiehuan.png">
                <div class="quota-right-word">{{language2}}</div>
              </div>
            </div>
            <div class="words">
              <div class="words-left">
                <el-input
                  v-model="searchValue"
                  type="textarea"
                  placeholder="请输入需要翻译的文字或单词"
                  clearable
                  autosize
                  
                ></el-input>
                  <!-- v-on:input="onInput"
                @keyup.enter.native="enterInput"
                  @blur="inputBlur" -->
                <img class="words-clean cursor" v-show="searchValue!=''" @click="cleanvalue" src="../../assets/icon/icon_qingkong.png">
              </div>
              <!-- 翻译按钮 -->
              <div class="fanyyi cursor">
                <img  @click="tofanyi(searchValue,'1')" src="../../assets/icon/icon_more7.png">
              </div>
              <div class="words-right">
                <!--  -->
                <div class="words-right-content" v-html="righttext">
                  <!-- {{righttext}} -->
                </div>
                <div class="words-right-bottom" v-show="righttext!=''">
                  <div class="words-right-bottom-left cursor" @click="showerrordialogs">
                    <img src="../../assets/icon/icon_fankui.png">
                    纠错
                  </div>
                  <div class="words-right-bottom-right" v-show="true">
                    <img class="words-right-bottom-right-img1 cursor" @click="copyClick" src="../../assets/icon/icon_copy.png">
                    
                    <!-- <img v-show="iscollect==1" @click="collectClick" class="words-right-bottom-right-img2 cursor" src="../../assets/icon/icon_yishoucang.png">
                    <img v-show="iscollect==0" @click="collectClick" class="words-right-bottom-right-img2 cursor" src="../../assets/icon/icon_shoucang.png">
                    <span v-show="iscollect==1" @click="collectClick" class="iscollect cursor">已收藏</span>
                    <span v-show="iscollect==0" @click="collectClick" class="iscollect cursor">收藏</span> -->

                  </div>
                </div>
              </div>
            </div>
            <div class="history">
              <div class="history-title">
                <div>历史记录</div>
                <img class="cursor" @click="cleanhistory" v-show="history.length!=0" src="../../assets/icon/icon_del.png">
              </div>
              <div v-show="history.length==0" class="history-none">
                <img src="../../assets/image/img_none.png"/>
              </div>
              <div v-show="history.length!=0" class="history-words">
                <div v-for="item in history" class="history-word cursor" @click="tofanyi(item,'0')">
                  {{item}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 文本翻译 -->
      </div>
      <!-- 内容 -->
      <!-- 纠错弹窗 -->
      <Errorcorrection :dialogVisible='showerrordialog' :wordsid="rightcontent.id" :wordtype="wordtype" :translationContent="righttext" :words="searchValue" @closedialog='closedialog' />
      <!-- 收藏弹窗 -->
      <popupCollect :status="popupCollectStatus" :wordsid="rightcontent.id" @Closecollect="Closecollect"/>
      <el-dialog
       class="dialogs"
        title=""
        :visible.sync="isshowdialog"
        width="380px"
        top="320px"
        :close-on-click-modal='false'
        :show-close='false'
        >
        <div slot="title" class="top-title">
            <span>友情提示</span>
        </div>
        <div class="content">
            <span v-if="istopvip==0">翻译字符超过免费额度，您可以升级会员，或购买单篇翻译</span>
            <span v-if="istopvip==1">翻译字符超过免费额度，您可以购买单篇翻译</span>
        </div>
        <div class="image">
            <img src="../../assets/image/vip.png">
        </div>
        <div v-if="istopvip==0" class="foot1">
            <el-button class="but1" @click="buysingle">单篇购买</el-button>
            <el-button class="but2" @click="tovip">立即解锁</el-button>
        </div>
        <div v-if="istopvip==1" class="foot2">
            <el-button class="but" @click="buysingle">单篇购买</el-button>
        </div>
        <div class="close cursor" @click="buyclosedialog">
            <img src="../../assets/image/Element／close.png">
        </div>
    </el-dialog>
    </div>
  </div>
</template>

<script>
import Errorcorrection from '../components/Errorcorrection.vue'
import popupCollect from "../components/popupCollect.vue";
export default {
    components: {
      Errorcorrection,
      popupCollect
    },
  data() {
    return {
      feildata: {}, // 路由信息
      buydata: {}, // 购买信息
      istopvip: 0, // 是否是只能单篇购买
      isshowdialog: false, // 友情提示弹窗
      userdata: JSON.parse(sessionStorage.getItem('userdata')), // 用户数据
      popupCollectStatus: false, // 收藏弹窗是否显示
      righttext: '', // 右侧翻译结果
      rightcontent: {}, // 右侧翻译后的所有数据
      showerrordialog: false, // 纠错弹窗
      searchValue: '', // 搜索value
      history: [], // 历史记录
      language1: '英文',
      language2: '中文',
      iscollect: 0, // 是否收藏
      freequota: '', // 免费额度
      wordtype: '3', // 纠错类型
    };
  },
  mounted() {
    this.freeCredit()
    this.getdata()
    //如果本地存储的数据texthistoryList有值，直接赋值给data中的texthistoryList
    if (JSON.parse(localStorage.getItem("texthistoryList"))) {
        this.history = JSON.parse(localStorage.getItem("texthistoryList"));
    }
    const that = this
    window.addEventListener('setItem', () => {
        that.userdata = JSON.parse(sessionStorage.getItem('userdata'))
        //that.formData.deptId是data里面声明的变量
        // console.log(that.userdata,JSON.parse(sessionStorage.getItem('userdata')),'监听')
    })
  },
  methods: {
    // 用户按下回车时触发
    enterInput(e){
      this.tofanyi(this.searchValue,1)
    },
    inputBlur(e){
      if(this.searchValue != ''){
        let is_have = this.history.filter((x)=>x == this.searchValue);
        console.log('is_have',is_have)
        if(is_have.length == 0){
          this.history.unshift(this.searchValue);
          localStorage.setItem("texthistoryList", JSON.stringify(this.history));
        }
      }
    },
    // 获得路由穿的值
    getdata () {
      // console.log(this.$route.query,"文件信息")
      this.feildata = this.$route.params
      if (this.feildata.translationContent != undefined) {
        // this.righttext = this.feildata.translationContent
        this.righttext = this.feildata.translationContent.replace(/\r\n/g,"<br/>")
      }
      if (this.feildata.content != undefined) {
        this.searchValue = this.feildata.content
      }
      // console.log(this.feildata.translationContent,this.$route.params,"文件信息")
    },
    // 单篇购买
    buysingle () {
        this.$router.push({path:'/Singlebuytext', query: this.buydata})
    },
    // 友情提示关闭
    buyclosedialog () {
      // console.log('关闭友情提示弹窗')
      this.isshowdialog = false
    },
    // 显示友情提示弹窗
    showdialog () {
      // console.log('显示友情提示弹窗')
      this.isshowdialog = true
    },
    // 去开vip
    tovip () {
      // console.log('88')
      this.$router.push({path: "/Myvip"})
    },
    // 判断是否收藏
    ifUserCollect (val) {
      let params = new FormData
      params.append('id', val)
      this.$apis.ifUserCollect(params).then((res) => {
        if (res.code==200) {
          this.iscollect = res.result.mark
        }
        
        // console.log(res,"是否收藏")
      })
    },
    // 收藏点击
    collectClick() {
      if (this.rightcontent.id==null) {
        this.$message.error('翻译正确的内容')
        return false
      }
      if (this.iscollect==0) {
        this.popupCollectStatus = true;
        // this.iscollect = true
        return;
      }
      if (this.iscollect==1) {
        this.cancelCollectWord()
      }
      // this.iscollect = false;
    },
    // 取消收藏
    cancelCollectWord() {
      let params = new FormData
      params.append('collectWordId', this.rightcontent.id)
      this.$apis.cancelCollectWord(params).then((res) => {
        if (res.code==200) {
          this.ifUserCollect(this.rightcontent.id)
        }
        
        // console.log(res,"取消收藏")
      })
    },
    // 关闭收藏夹
    Closecollect () {
      this.popupCollectStatus = false;
      this.ifUserCollect(this.rightcontent.id)
    },
    // 复制
    copyClick() {
      let url = this.righttext;
      //新建一个文本框
      let oInput = document.createElement('input');
      //赋值给文本框
      oInput.value = url;
      document.body.appendChild(oInput);
      // 选择对象;
      oInput.select(); 
      // 执行浏览器复制命令
      document.execCommand("Copy"); 
      //复制完成删除掉输入框
      oInput.remove()
      // this.$refs.message.success('复制成功') // success调用
      // console.log('复制成功')
      // const save = function (e) {
      //   e.clipboardData.setData("text/plain", '我是复制的内容');
      //   e.preventDefault(); // 阻止默认行为
      // };
      // document.addEventListener("copy", save); // 添加一个copy事件
      // document.execCommand("copy"); // 执行copy方法
      this.$message({ message: "复制成功", type: "success" });
    },
    // 翻译文本
    tofanyi (e,k) {
      // console.log(e,k)
      if (e=='') {
        return false
      }
      // if (k==1&&this.userdata!=null) {
      //   let params = new FormData
      //   params.append('id', e.id)
      //   params.append('name4', e.name4)
      //   this.$apis.addSearchRecord(params).then((res) => {
      //     if (res.code==200) {
      //     }
          
      //     // console.log(res,"添加了历史")
      //   })
      // }
      // 翻译按钮
      if (k==1) {
        const that = this
        let params = new FormData
        params.append('content', that.searchValue)
        params.append('transType', that.language1=='英文' ? '1' : '2')
        that.$translation.textTranslation(params).then((res) => {
          // console.log(res,"翻译")
          if (res.code==200) {
            
            if (res.result.mark==0) {
              // 正常可以直接翻译
              // that.righttext = res.result.translationContent
              that.righttext = res.result.translationContent.replace(/\n/g,"<br/>")

              that.rightcontent = res.result
            }else if (res.result.mark==1){
              // 可以单篇购买 也可以升级会员
              that.istopvip = 0
              that.$translation.webtextTranslationOrder(params).then((resulr) => {
                // console.log(resulr,"购买数据")
                if (resulr.code==200) {
                  that.buydata = resulr.result
                  that.showdialog()
                }
              })
            }else if (res.result.mark==2) {
              // 只能单篇购买
              that.istopvip = 1
              that.$translation.webtextTranslationOrder(params).then((resulr) => {
                // console.log(resulr,"购买数据")
                if (resulr.code==200) {
                  that.buydata = resulr.result
                  that.showdialog()
                }
              })
            }
            this.freeCredit()
            // if (this.rightcontent.id!=null) {
            //   this.ifUserCollect(this.rightcontent.id)
            // }
          }
        })
        // 没有搜索记录，把搜索值push进数组首位，存入本地
        if (!this.history.includes(e)) {
          this.history.unshift(e);
          localStorage.setItem("texthistoryList", JSON.stringify(this.history));
        }else{
            //有搜索记录，删除之前的旧记录，将新搜索值重新push到数组首位
            let i =this.history.indexOf(e);
            this.history.splice(i,1)
            this.history.unshift(e);
            localStorage.setItem("texthistoryList", JSON.stringify(this.history));
          
            
        }
        // console.log(this.history,"记录")
      }
      // 历史记录
      if (k==0) {
        this.searchValue = e
        this.tofanyi(this.searchValue,1)
      }


    },
    // 免费额度
    freeCredit () {
      this.$translation.freeCredit().then((res) => {
        // console.log(res,"免费额度")
        if (res.code==200) {
          this.freequota = res.result.remaining
        }
      })
    },
    // 显示纠错
    showerrordialogs () {
      if (this.rightcontent.id==null) {
        this.$message.error('翻译正确的内容')
        return false
      }
      this.showerrordialog = true
    },
    // 关闭纠错弹窗
    closedialog () {
      // console.log('关闭纠错弹窗')
      this.showerrordialog = false
    },
    onInput (e) {
      console.log('onInput',e)
      if (this.searchValue == '') {
        this.righttext = ''
        this.rightcontent = {}
        return;
      }

      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        if(e !== ''){
          this.tofanyi(this.searchValue,1)
        }
      }, 1000)
    },
    // 节流
    throttle(func,delay){
      let timeout;//定义一个定时器标记
      return function(){
        // 判断是否存在定时器
        if(!timeout){ 
          // 创建一个定时器
          timeout = setTimeout(()=>{
            // delay时间间隔清空定时器
            clearTimeout(timeout);
            func.call(this);
          },delay)
        }
      }
    },
    // 清除翻译内容
    cleanvalue () {
      this.searchValue = ''
      this.righttext = ''
      this.rightcontent = {}
    },
    // 清除历史记录
    cleanhistory () {
      localStorage.removeItem('texthistoryList');
      this.history = []
    },
    // 翻译转换
    changelanguage () {
      if (this.language1 == '英文') {
        this.language1 = '中文'
        this.language2 = '英文'
      } else {
        this.language1 = '英文'
        this.language2 = '中文'
      }
    }
  },
};
</script>

<style lang="scss">
#Texttranslation {
  width: 100%;
  margin-top: 33px;
  .dialogs{
    .el-dialog {
        border-radius: 12px;
    }
    .el-dialog__header {
        padding: 20px 20px 0px
    }
    .el-dialog__body {
        padding: 9px 20px 40px 20px
    }
    .top-title{
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #252223
    }
    .content{
        height: 48px;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #5E5F60
    }
    .image {
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 190px;
            height: 162px;
        }
    }
    .foot1{
        width: 332px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .but1{
            width: 156px;
            height: 50px;
            background: rgba(61, 92, 255, 0.1);
            border-radius: 12px;
            border: none;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #3D5CFF;
        }
        .but2 {
            width: 156px;
            height: 50px;
            background: #3D5CFF;
            border-radius: 12px;
            border: none;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
        }
    }
    .foot2{
        width: 332px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        .but{
            width: 240px;
            height: 50px;
            background: #3D5CFF;
            border-radius: 12px;
            border: none;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
        }
    }
    .close{
        position: absolute;
        top: 420px;
        right: 168px;
        width: 44px;
        height: 44px;
        // background: black;
        img{
        width: 44px;
        height: 44px; 
        }
    }
  }
  .body {
    width: 1169px;
    margin: 0 auto;
    padding-left: 48px;
    padding-bottom: 182px;
    .fanyyi{
      width: 60px;
      height: 60px;
      margin: 10px 0 0 0;
      img{
        width: 60px;
        height: 60px;
      }
    }
    .content {
      margin-top: 28px;
      display: flex;
      align-content: center;
      justify-content: space-between;
      .lien-title {
        width: 80px;
        height: 25px;
        display: flex;
        align-items: flex-end;
        position: relative;
        font-size: 18px;
        &-text {
          margin-left: 8px;
        }
        .lien-back {
          width: 26px;
          height: 8px;
          background: #3d5cff;
          opacity: 0.3;
          position: absolute;
          left: 0;
          bottom: 2px;
        }
      }
      .quota {
        width: 703px;
        height: 52px;
        display: flex;
        align-content: center;
        justify-content: space-between;
        margin: 30px 0 0 0 ;
        .quota-left {
          width: 335px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: #FFF6F1;
          border-radius: 14px;
          .quota-left-left {
            margin-left: 10px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FF6905;
          }
          .quota-left-right {
            font-size: 11px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #C1A491;
            display: flex;
            align-items: center;
            margin: 0 5px 0 0;
            img {
              width: 22px;
              height: 22px;
            }
          }
        }
        .quota-right {
          width: 348px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .quota-right-word {
            width: 140px;
            height: 32px;
            background: #FFFFFF;
            border-radius: 6px;
            border: 1px solid #DCDCDC;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          img {
            width: 24px;
            height: 16px;
            margin: 0 22px
          }
        }
      }
      .box {
        .days {
          .history {
            width: 602px;
            // height: 210px;
            padding-bottom: 20px;
            background: #F9F9F9;
            border-radius: 14px;
            .history-words {
              display: flex;
              flex-wrap: wrap;
              // align-items: center;
              width: 567px;
              padding: 0px 13px 0px 22px;
              height: 140px;
              overflow: hidden;
              .history-word {
                max-width: 500px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                height: 20px;
                text-align: center;
                // display: flex;
                // align-items: center;
                // justify-content: center;
                padding: 1px 7px 1px 7px;
                margin: 0 10px 5px 0;
                background: #FFFFFF;
                border-radius: 4px;
                border: 1px solid #DBDBDB;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #505560;
              }
            }
            .history-title {
              display: flex;
              align-content: center;
              justify-content: space-between;
              width: 567px;
              height: 22px;
              padding: 16px 13px 20px 22px;
              font-size: 13px;
              font-family: PingFangSC-Regular, PingFang SC;
              color: #8C8C8C;
              img {
                width: 22px;
                height: 22px;
              }
            }
            .history-none {
              display: flex;
              align-content: center;
              justify-content: center;
              // margin-bottom: 20px;
              img {
                width: 100px;
                height: 100px;
              }
            }
          }
          .words {
            width: 1290px;
            // height: 141px;
            min-height: 141px;
            position: relative;
            opacity: 0.9;
            margin-top: 20px;
            margin-bottom: 16px;
            display: flex;
            align-content: center;
            justify-content: space-between;
            .words-right {
              position:relative;
              font-family: PingFangSC-Medium, PingFang SC;
              color: #8189B0;
              // padding: 18px 0 0 20px;
              width: 602px;
              // height: 141px;
              border-radius: 14px;
              opacity: 0.9;
              border: 0;
              resize: none;
              background: linear-gradient(90deg, #EEF1F9 0%, #F8FCFF 98%);
              font-size: 24px;
              padding-bottom: 40px;
            }
            .words-right-bottom {
              position:absolute;
              bottom: 10px;
              width: 576px;
              height: 30px;
              margin: 0px 10px 0 16px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .words-right-bottom-left{
                font-size: 11px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #A3A6AB;
                display: flex;
                align-items: center;
                img{
                  width: 16px;
                  height: 16px;
                  margin: 0 4px 0 0 ;
                }
              }
              .words-right-bottom-right{

                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #50555E;
                display: flex;
                align-items: center;
                .words-right-bottom-right-img1{
                  width: 22px;
                  height: 22px;
                  margin: 0 18px 0 0 ;
                }
                .words-right-bottom-right-img2{
                  width: 22px;
                  height: 22px;
                  margin: 0 4px 0 0 ;
                }
                .iscollect{
                  width: 36px;
                  display: flex;
                  // justify-content: flex-end;
                }
              }
            }
            .words-right-content {
              width: 554px;
              // height: 109px;
              margin: 18px 0 0 20px;
              overflow: auto;
              // text-overflow:ellipsis;
              // white-space: pre-warp;
              word-wrap: break-word
            }
            .words-clean {
              position: absolute;
              top: 19px;
              right: 16px;
              width: 32px;
              height: 32px;
            }
            .words-left {
              width: 602px;
              // height: 141px;
              background: linear-gradient(90deg, #EEF1F9 0%, #F8FCFF 98%);
              border-radius: 14px;
              position: relative;

              .el-textarea__inner {
                // text-indent: 10px;
                // line-height: 33px;
                margin: 18px 0 0 20px;

                font-family: PingFangSC-Medium, PingFang SC;
                color: #8189B0;
                background:rgba(255,255,255,0);
                width: 554px;
                // height: 109px;
                border: 0;
                resize: none;
                font-size: 24px;

              }
              .el-textarea__inner::placeholder {
                font-family: PingFangSC-Medium, PingFang SC;
                font-size: 24px;
                color: #8189B0;
              }
              /* 谷歌 */
              .el-textarea__inner::-webkit-input-placeholder {
                font-family: PingFangSC-Medium, PingFang SC;
                font-size: 24px;
                color: #8189B0;
              }
              /* 火狐 */
              .el-textarea__inner:-moz-placeholder {
                font-family: PingFangSC-Medium, PingFang SC;
                font-size: 24px;
                color: #8189B0;
              }
              /*ie*/
              .el-textarea__inner:-ms-input-placeholder {
                font-family: PingFangSC-Medium, PingFang SC;
                font-size: 24px;
                color: #8189B0;
              }
            }
          }
        }
      }
    }
  }
}
</style>