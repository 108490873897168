<template>
  <div id="setpassword">
    <el-dialog
      title=""
      width="440px"
      :visible.sync="status"
      :show-close="false"
    >
      <div class="body">
        <p class="titles">设置密码</p>
        <div class="info">
          <div class="info-input">
            <input
              type="password"
              v-model="form.password"
              placeholder="请输入新密码"
            />
            <img
              src="../assets/icon/icon_mima.png"
              class="info-input-img"
              alt=""
            />
          </div>
          <div class="info-input">
            <input
              type="password"
              class="info-input-code"
              v-model="form.confirmPassword"
              placeholder="请再次输入新密码"
            />
            <img
              src="../assets/icon/icon_mima.png"
              class="info-input-img"
              alt=""
            />
          </div>
        </div>
        <!-- button -->
        <div class="button">
          <div class="button-title cursor" @click="submit()">确定</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { setPassword } from "../api/login";
export default {
  data() {
    return {
      form: {
        password: "",
        confirmPassword: "",
      },
    };
  },
  computed: {
    status: function () {
      return this.$store.state.passwordStatus;
    },
  },
  mounted() {},
  methods: {
    // 验证
    verify() {
      if(!this.CheckPassWord(this.form.password)){
        this.$message.error("密码必须由字母、数字组成，且长度为8-22位");
        return false;
      }
      if (this.form.password == "") {
        this.$message.error("请输入新密码");
        return false;
      }
      if (this.form.confirmPassword == "") {
        this.$message.error("请输入确认密码");
        return false;
      }
      if (this.form.password !== this.form.confirmPassword) {
        this.$message.error("确认密码不一致");
        return false;
      }
      return true;
    },
    CheckPassWord(password) {//必须为字母加数字且长度不小于8位
      var str = password;
        if (str == null || str.length < 8 || str.length > 22) {
            return false;
        }
        var reg1 = new RegExp(/^[0-9A-Za-z]+$/);
        if (!reg1.test(str)) {
            return false;
        }
        var reg = new RegExp(/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/);
        if (reg.test(str)) {
            return true;
        } else {
            return false;
        }
    },
    // 提交
    submit() {
      if (this.verify()) {
        // console.log("验证成功");
        let parames = new FormData();
        parames.append("password", this.form.password);
        parames.append("confirmPassword", this.form.confirmPassword);
        setPassword(parames).then((res) => {
          if (res.code == 200) {
            this.$store.commit("SET_PASSWORD", false);
          }
        });
      }
    },
    // 关闭弹框函数
    handleClose() {
      this.$store.commit("SET_PASSWORD", false);
      this.form = {
        password: "",
        confirmPassword: "",
      };
    },
  },
};
</script>

<style lang="scss">
#setpassword {
  .body {
    padding: 0 26px;
    .info {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      margin-left: 10px;
      .info-input {
        position: relative;
        &-gain {
          padding: 6px 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 20px;
          top: calc(50% - 16px);
          background-color: #3d5cff;
          border-radius: 6px;
          opacity: 0.5;
          color: #fff;
          font-size: 14px;
        }
        &-img {
          width: 22px;
          height: 22px;
          position: absolute;
          top: calc(50% - 12px);
          left: 16px;
        }
        input {
          width: 216px;
          height: 56px;
          border-radius: 12px;
          padding: 0 56px;
          margin: 9px 0;
          color: #8189b0;
          font-size: 15px;
          background-color: #f1f6fb;
          border: none;
        }
        input::-webkit-input-placeholder {
          color: #8189b0;
        }
      }
    }
    .button {
      display: flex;
      justify-content: center;
      &-title {
        width: 328px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 46px;
        background-color: #3d5cff;
        color: #fff;
        border-radius: 12px;
        font-size: 16px;
      }
    }
    .titles {
      color: #2e3e5c;
      font-size: 18px;
      font-weight: bold;
    }
  }
  .el-dialog {
    border-radius: 12px;
  }
}
</style>