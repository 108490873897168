<!-- 翻译纠错反馈 -->
<template>
  <div id="Errorcorrection">
    <el-dialog
        title=""
        :visible.sync="dialogVisible"
        width="480px"
        :close-on-click-modal='false'
        :show-close='false'
        >
        <div slot="title" class="top-title">
            <span>{{ wordtype == 2 ? '缺词反馈' : '翻译纠错反馈'}}</span>
            <img class="cursor" @click="Closedialog" src="../../assets/icon/search_close.png">
        </div>
        <div class="contentform">
            <div class="contentform-title">{{ wordtype == 2 ? '缺词文本' : '纠错文本'}}</div>
            <div class="contentform-input">
                <el-input v-model="errortext" :placeholder="wordtype == 2 ? '请输入缺词内容' : '请输入需要纠错的文本'"></el-input>
            </div>
            <div class="contentform-title">详细描述</div>
            <div class="contentform-input">
                <el-input
                  v-model="detail"
                  type="textarea"
                  maxlength="150"
                  placeholder="可详细描述问题，选填"
                ></el-input>
                <div class="maxlengths">10-150字</div>
            </div>
            <div class="contentform-title">上传图片</div>
            <div class="contentform-img">
                <div class="contentform-img-left">
                    <span v-for="(item,key) in imgSrc">
                        <img class="" :src="item" />
                        <img @click="closebutten(key)" class="closebutten cursor" src="../../assets/icon/close.png">
                    </span>
                    <div class="contentform-img-right cursor" @click="handleUpClick">
                        <i class="el-icon-picture-outline"></i>
                        <span>上传图片</span>
                        <input class="" ref="filesinput" type="file" @change="uploadImage($event)" accept="image/*" />
                    </div>
                </div>
                
            </div>
            <div class="contentform-title">联系方式</div>
            <div class="contentform-input">
                <el-input v-model="phonenumber" placeholder="选填"></el-input>
            </div>
        </div>
        <div class="foot2">
            <el-button class="but" :class="errortext!=''?'':'disables' " @click="submits">提交</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
    props: {
        dialogVisible: { // 自动关闭的延时
            type: Boolean,
            default: false
        },
        words:{
            type: String,
            default:''
        },
        translationContent:{
            type: String,
            default:''
        },
        wordsid: {
            type: String,
            default: ''
        },
        wordtype: {
            type: String,
            default: '1'
        }
    },
  data () {
    return {
        imgSrc: [], // 图片路径用来预览的
        imgupload: [], // 用来上传的图片
        textid: '', // id
        yiwen: '', // 译文
        phonenumber: '', // 联系方式
        errortext: '', // 纠错文本
        detail: ''// 详细描述
    };
  },
  watch:{
        wordsid: {  
            handler: function (val) {
                // console.log(val,"纠错id")
                if (this.wordtype!=3) {
                    this.getsearchvalue(val)
                }
            },
            immediate: true,
            deep: true
        },
        dialogVisible: {  
            handler: function (val) {
                // console.log(val,"纠错id")
                if (this.wordtype==3) {
                    this.getvalue(val)
                }
            },
            immediate: true,
            deep: true
        },
  },
  mounted () {},
  methods: {
    //   获得数据
    getvalue () {
        this.errortext = this.words
        this.yiwen = this.translationContent
    },
      // 获得路由传的值
    getsearchvalue (val) {
        this.errortext = this.words
      // this.search = this.$route.query.searchvalue
    //   console.log(this.$route.query.searchname1)
    //   this.search = this.$route.query.searchname1
    //   this.searchname1 = this.$route.query.searchname1
    //   this.searchname3 = this.$route.query.searchname3
    //   this.searchid = this.$route.query.searchvalue
    //   this.getrighttext(this.$route.query.searchvalue)
    if (val=='') {
        return false
    }
    this.getrighttext(val)
      
    },
    getrighttext (val) {
        this.yiwen = this.translationContent
    //   let params = new FormData
    //   params.append('id', val)
    //   this.$apis.appThreeSearch(params).then((res) => {
    //     if (res.code==200) {
    //       this.yiwen = res.result
    //     }
        
    //     console.log(res,"右侧释义")
    //   })
    },
    //   提交
    submits () {
        if (this.errortext=='') {
            this.$message({
                message: '请填写纠错文本',
                type: 'error'
            });
            return false
        }
        const that = this
        if (that.imgupload.length!=0) {
            let parimg = new FormData
            for(let i = 0;i<that.imgupload.length;i++){
                parimg.append("files", that.imgupload[i])
            }
            that.$apis.UploadImg(parimg).then((resimg) => {
                if (resimg.code==200) {
                    that.submitall(resimg.result.imgUrl)
                }
                
                // console.log(res,"上传图片")
            })
        }else{
            that.submitall()
        }
        
    },
    submitall (val) {
        const that = this
        let params = new FormData
        params.append('errText', that.errortext)
        params.append('backType', that.wordtype)
        params.append('id', that.wordsid)
        params.append('original', that.yiwen)
        params.append('contactInformation', that.phonenumber)
        params.append('detailedDescription', that.detail)
        if (val) {
            params.append('imgUrl', val)
        }
        // let params = {
        //     errText: that.errortext,
        //     backType: that.wordtype,
        //     id: that.wordsid,
        //     original: that.yiwen,
        //     contactInformation: that.phonenumber,
        //     detailedDescription: that.detail

        // }
        that.$apis.addFeedback(params).then((res) => {
            if (res.code==200) {
                that.$message({ message: "纠错提交成功", type: "success" });
                that.Closedialog()
            }
            
            // console.log(res,"提交")
        })
    },
    //   删除图片
    closebutten (key) {
        // console.log(key)
        this.imgSrc.splice(key, 1)
        this.imgupload.splice(key, 1)
        // for (let i = 0; i < this.imgSrc.length; i++) {
        //     if (this.imgSrc[i].id == key) {
        //     this.imgSrc.splice(i, 1)
        //     }
        // }
    },
      // 上传文件
    handleUpClick () {
      this.$refs.filesinput.click()
    },
    //   上传图片并预览
    uploadImage (e) {
        let file = e.target.files[0]; //获取第一个文件、
        this.imgupload.push(file)
        let img = new FileReader();
        img.readAsDataURL(file);
        // console.log("img:",img)
        img.onload = ({ target }) => {
        this.imgSrc.push(target.result); //将img转化为二进制数据
        // console.log("imgSrc:",this.imgSrc)
        // console.log(this.imgupload,"图片")
        };
    },
    //   关闭弹窗
    Closedialog () {
        
        this.$emit("closedialog",false)
        this.imgSrc = [] //图片路径用来预览的

        this.phonenumber = '' // 联系方式
        // this.errortext = '' // 纠错文本
        this.detail = ''// 详细描述
    },
  }
};
</script>

<style lang="scss">
#Errorcorrection {
    .disables{
        background: #3D5CFF;
        border-radius: 12px;
        opacity: 0.5;
        cursor:not-allowed;
    }
    .el-dialog {
        border-radius: 12px;
    }
    .el-dialog__header {
        padding: 20px 20px 0px
    }
    .el-dialog__body {
        padding: 9px 20px 40px 20px
    }
    .top-title{
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2E3E5C;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img{
            position: absolute;
            right: 0px;
            width: 32px;
            height: 32px;
        }
    }
    .contentform{
        margin:  32px 0 0 0;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #5E5F60;
        .contentform-title{
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #5E5F60;
        }
        .contentform-img{
            width: 432px;
            display: flex;
            flex-wrap: wrap;
            margin:  0 0 20px 0;
            .contentform-img-left{
                width: 432px;
                display: flex;
                flex-wrap: wrap;
                img{
                    width: 100px;
                    height: 100px;
                    border-radius: 14px;
                    margin: 15px 18px 0 0 ;
                }
                span{
                    position: relative;
                    .closebutten{
                        position: absolute;
                        width: 18px;
                        height: 18px;
                        top: 0px;
                        right: 0px;
                    }
                }
            }
            .contentform-img-right{
                width: 100px;
                height: 100px;
                background: #F5FAFF;
                border-radius: 14px;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 11px;
                margin: 15px 0px 0 0 ;
                i{
                    width: 27px;
                    height: 24px;
                    font-size: 26px;
                    margin: 0 0 9px 0 ;
                }
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #A8ABB8;
                input{
                    display: none;
                }
            }
        }
        .contentform-input{
            position: relative;
            .el-input__inner{
                width: 432px;
                height: 48px;
                background: #F9FAFB;
                border-radius: 14px;
                margin: 15px 0 20px 0;
                border: 0;
            }
            .el-input__inner::placeholder {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #A8ABB8;
              }
              /* 谷歌 */
            .el-input__inner::-webkit-input-placeholder {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #A8ABB8;
            }
            /* 火狐 */
            .el-input__inner:-moz-placeholder {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #A8ABB8;
            }
            /*ie*/
            .el-input__inner:-ms-input-placeholder {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #A8ABB8;
            }
            .el-textarea__inner {
                width: 432px;
                height: 100px;
                background: #F9FAFB;
                border-radius: 14px;
                margin: 15px 0 20px 0;
                border: 0;
                resize: none;

            }
            .el-textarea__inner::placeholder {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #A8ABB8;
              }
              /* 谷歌 */
            .el-textarea__inner::-webkit-input-placeholder {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #A8ABB8;
            }
            /* 火狐 */
            .el-textarea__inner:-moz-placeholder {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #A8ABB8;
            }
            /*ie*/
            .el-textarea__inner:-ms-input-placeholder {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #A8ABB8;
            }
            .maxlengths{
                position: absolute;
                right: 20px;
                bottom: 30px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #A8ABB8;
            }
        }
    }
    .foot2{
        width: 440px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        .but{
            width: 432px;
            height: 50px;
            background: #3D5CFF;
            border-radius: 12px;
            border: none;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
        }
    }
}
</style>