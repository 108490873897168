import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loginStatus: false, // 登录弹框
        agreementStatus: false, // 用户协议弹框
        impowerStatus: false, // 用户是否同意隐私协议状态
        forgeStatus: false, // 忘记密码弹框
        thirdpartyStatus: false, // 第三方扫码登录弹框
        thirdpartyNumber: 0, // 第三方扫码登录 0 微信 1 QQ
        userData: {}, // 用户数据
        passwordStatus: false, // 设置密码弹窗
        bindingStatus: false, // 绑定账号弹窗
    },
    mutations: {
        SET_LOGIN: (state, login) => {
            state.loginStatus = login
        },
        SET_AGREEMENT: (state, agreement) => {
            state.agreementStatus = agreement
        },
        SET_INPOWER: (state, impower) => {
            state.impowerStatus = impower
        },
        SET_FORGE: (state, forge) => {
            state.forgeStatus = forge
        },
        SET_THIRDPARTY: (state, forge) => {
            state.thirdpartyStatus = forge
        },
        SET_THIRDPARTYNUMBER: (state, forge) => {
            state.thirdpartyNumber = forge
        },
        SET_USER: (state, userData) => {
            state.userData = userData
        },
        SET_PASSWORD: (state, password) => {
            state.passwordStatus = password
        },
        SET_BINDING: (state, binding) => {
            state.bindingStatus = binding
        },
    },
    modules: {}
})