import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/all.css'

import * as api from './api/home.js'
Vue.prototype.$apis = api
import * as login from './api/login.js'
Vue.prototype.$login = login
import * as userdata from './api/userdata.js'
Vue.prototype.$userdata = userdata
import * as translation from './api/translation.js'
Vue.prototype.$translation = translation
import * as mycenter from './api/mycenter.js'
Vue.prototype.$mycenter = mycenter

Vue.use(Element);
Vue.prototype.setSessionItem = function (key, newVal) {
    // 创建 StorageEvent 事件
    let newStorageEvent = document.createEvent("StorageEvent");
    const storage = {
      setItem: function (k, val) {
        // console.log('setSessionItem',k,val)
        sessionStorage.setItem(k, val);
  
        // 初始化 StorageEvent 事件
        newStorageEvent.initStorageEvent(
          "setItem", // 事件别名
          false,
          false,
          k,
          null,
          val,
          null,
          null
        );
  
        // 派发事件
        window.dispatchEvent(newStorageEvent);
      },
    };
    return storage.setItem(key, newVal);
  };

Vue.config.productionTip = false

// 这里就可以进行vue-router的beforeEach拦截了，你也可以放其他地方，我比较喜欢放这
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '';
  // 这里先获取下用户信息，我偷懒用sessionStorage存了
  // 里面包含了用户权限，用户各种信息等
  const user = JSON.parse(sessionStorage.getItem('userdata'));
  // 这里必须加上to.path !== 'login'的判断，不然会陷入无限循环，
  // 因为逻辑是第一次进来，判断用户信息不存在，即!user为true，由于使用的是next('/login')而非next()，
  // 会再次进入路由跳转，next()方法没有参数是直接进入页面，不会再次进入路由拦截，有参数则会，因为跳转，
  // 所以再次进入路由，再次判断，再次进入路由，再次判断，循环往复无限循环
  // 所以一定要加to.path !== 'login'的判断
  if (!user && to.path !== '/' && to.path != '/Aboutas' && to.path != '/Privacyagreement' && to.path != '/Useragreement' && to.path != '/Vipagreement' && to.path != '/Translationpolicy' && to.path != '/loginSuccess') {
      next('/');
  } 
  // else if (to.meta.permission) {
  //     user.permission === to.meta.permission ? next() : message.alert('没有权限');
  // } 
  else {
      next();
  }
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')