<!-- 我是第三方扫码登录弹框组件 -->
<template>
  <div id="thirdparty">
    <el-dialog
      title=""
      width="440px"
      :visible.sync="status"
      :before-close="handleClose"
      center=""
    >
      <div class="title">{{ tabList[numbers] }}</div>
      <div class="expostulation">使用{{ tabList[numbers] }}扫码登录</div>
      <div class="code">
        <wxlogin
        v-show="numbers==0"
          class="wxlogin"
          self_redirect="false"
          :appid="QRdata.appid"
          :scope="QRdata.scope"
          :redirect_uri='QRdata.redirect_uri+encodeURIComponent("/#/loginSuccess")'
          :href="'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDE4MHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge2Rpc3BsYXk6IG5vbmU7fQ=='"
          rel="external nofollow">
        </wxlogin>
        <!-- {{QRdata.redirect_uri+encodeURIComponent("/loginSuccess")}} -->
        <!-- {{encodeURIComponent("http://yilian33.cnnn.net.cn/loginSuccess")}} -->
        <!-- :redirect_uri='encodeURIComponent("http://192.168.10.203:8080/")' :redirect_uri='QRdata.redirect_uri' :redirect_uri='encodeURIComponent("http://yilian33.cnnn.net.cn/")' -->
      </div>
      <div class="agreement">
        <div class="agreement-body">
          <div class="agreement-body-radio cursor" @click="radioClick()">
            <img
              src="../assets/icon/chose.png"
              v-show="!radioStatus"
              class="img"
              alt=""
            />
            <img
              src="../assets/icon/choses.png"
              v-show="radioStatus"
              class="img"
              alt=""
            />
          </div>
          <div class="agreement-body-title cursor" @click="Agreement()">
            我已阅读并同意《用户注册使用协议》
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import QRCode from 'qrcodejs2'
import wxlogin from 'vue-wxlogin';
export default {
  components: {
    wxlogin
    },
  data() {
    return {
      tabList: ["微信登录", "QQ登录"],
      QRdata:'', // 二维码
    };
  },
  computed: {
    status: function () {
      return this.$store.state.thirdpartyStatus;
    },
    numbers: function () {
      return this.$store.state.thirdpartyNumber;
    },
    radioStatus: function () {
      return this.$store.state.impowerStatus;
    },
  },
  watch:{
    status(newData, oldData){
      // console.log(newData)
      if (newData==true) {
        this.getQR()
      }

    },
  },
  mounted() {
  },
  created () {
  },
  methods: {
    
    // 
    getQR () {
      // 微信
      if (this.numbers==0) {
        this.$login.getwxQR().then((res) => {
          if (res.code==200) {
            // console.log(res,"二维码")
              this.QRdata = res.result
          }
        })
      }
      // qq
      if (this.numbers==1) {
        this.$login.qqLoginAddress().then((res) => {
          if (res.code==200) {
            console.log(res,"qq")
             var win=window.open(res.message,"TencentLogin",
                "width=700,height=400,left=600,top=300,menubar=0,scrollbars=1,resizable=1,status=1,titlebar=0,toolbar=0,location=1");
                var listener=setInterval(function(){
                  //监听窗口是否关闭
                  if(win.closed){
                    console.log('关闭')
                    //进入这个if代表后端验证成功!直接跳转路由
                    clearInterval(listener);//关闭监听
                    //跳转路由
                    var path = this.$route.query.redirect;
                    this.$router.replace({
                      path:
                        path === undefined ? "/" : path + '/'
                    });
                    this.$router.go(0) //刷新
                  }
                },500)
              // this.QRdata = res.result
          }
        })
        
      }
      
    },
    // 用户协议弹框
    Agreement() {
      this.$store.commit("SET_AGREEMENT", true);
    },
    // 是否同意用户协议
    radioClick () {
      this.$store.commit("SET_INPOWER", !this.radioStatus);
    },
    // 关闭弹框函数
    handleClose () {
      this.$store.commit("SET_THIRDPARTY", false);
      
    },
  },
};
</script>

<style lang="scss">
#thirdparty {
  .wxlogin{
    width: 200px;
    height: 200px;
    iframe{
      width: 200px;
      height: 200px;
    }
  }
  .agreement {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    &-body {
      width: 328px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      &-radio {
        .img {
          width: 26px;
          height: 26px;
          margin-top: 5px;
        }
      }
      &-title {
        color: #858597;
        font-size: 13px;
      }
    }
  }
  .code {
    // width: 160px;
    // height: 160px;
    margin: 40px auto;
    display: flex;
    justify-content: center;
    img {
      width: 160px;
      height: 160px;
    }
  }
  .expostulation {
    color: #8e8e8e;
    font-size: 14px;
    margin-top: 7px;
  }
  .title {
    color: #2e3e5c;
    font-weight: bold;
    font-size: 18px;
  }
  .el-dialog__body {
    padding: 30px 56px;
  }
  .el-dialog {
    border-radius: 12px;
  }
}
</style>