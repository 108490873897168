<!-- 个人中心-我的会员-已开通 -->
<template>
    <div id="Alreadyvip">
        <div class="content">
            
            <div class="content-bottom">
                <div class="content-bottom-title">
                    <div class="content-top-top-left">
                        <div class="content-top-top-left-text">我的会员</div>
                        <div class="content-top-top-left-back"></div>
                    </div>
                </div>
                <div class="title-botttom cursor" @click="toMyinvoice">我的发票<img  src="../../assets/icon/icon_more4.png"></div>
                <div class="content-content" v-show="userdata.level==1" v-if="!!viptable[0]">
                    <div class="vip-top" :class="'bg-img1'">
                        <p>{{viptable[0].name}}</p>
                        <span>您已订阅此会员</span>
                    </div>
                    <div class="viptitle">
                        <div class="viptitle-text">{{viptable[0].name}}权益</div>
                        <div class="viptitle-back"></div>
                    </div>
                    <div class="vip-bottom">
                        <div class="vip-bottom-text">
                            <img  src="../../assets/icon/huancun.png">
                            <p>{{viptable[0].webPermissionOne}}缓存</p>
                        </div>
                        <div class="vip-bottom-text">
                            <img  src="../../assets/icon/fanyi.png">
                            <p>{{viptable[0].webPermissionTwo}}词</p>
                        </div>
                        <div class="vip-bottom-text">
                            <img  src="../../assets/icon/zhe.png">
                            <p>付费文档 畅享 {{viptable[0].webPermissionThree}}</p>
                        </div>
                    </div>
                    <div class="vip-bottom-text2">
                        离线包功能仅供APP内使用
                    </div>
                   
                </div>
                <div class="content-content" v-show="userdata.level==2" v-if="!!viptable[1]">
                    <div class="vip-top" :class="'bg-img2'">
                        <p>{{viptable[1].name}}</p>
                        <span>您已订阅此会员</span>
                    </div>
                    <div class="viptitle">
                        <div class="viptitle-text">{{viptable[1].name}}权益</div>
                        <div class="viptitle-back"></div>
                    </div>
                    <div class="vip-bottom">
                        <div class="vip-bottom-text">
                            <img  src="../../assets/icon/huancun.png">
                            <p>{{viptable[1].webPermissionOne}}缓存</p>
                        </div>
                        <div class="vip-bottom-text">
                            <img  src="../../assets/icon/fanyi.png">
                            <p>{{viptable[1].webPermissionTwo}}词</p>
                        </div>
                        <div class="vip-bottom-text">
                            <img  src="../../assets/icon/zhe.png">
                            <p>付费文档 畅享 {{viptable[1].webPermissionThree}}</p>
                        </div>
                    </div>
                    <div class="vip-bottom-text2">
                        离线包功能仅供APP内使用
                    </div>
                   
                </div>
                <div class="content-content" v-show="userdata.level==0" v-if="!!viptable[2]">
                    <div class="vip-top" :class="'bg-img0'">
                        <p>{{viptable[2].name}}</p>
                        <span>您已订阅此会员</span>
                    </div>
                    <div class="viptitle">
                        <div class="viptitle-text">{{viptable[2].name}}权益</div>
                        <div class="viptitle-back"></div>
                    </div>
                    <div class="vip-bottom">
                        <div class="vip-bottom-text">
                            <img  src="../../assets/icon/huancun.png">
                            <p>{{viptable[2].webPermissionOne}}缓存</p>
                        </div>
                        <div class="vip-bottom-text">
                            <img  src="../../assets/icon/fanyi.png">
                            <p>{{viptable[2].webPermissionTwo}}词</p>
                        </div>
                        <div class="vip-bottom-text">
                            <img  src="../../assets/icon/zhe.png">
                            <p>付费文档 畅享 {{viptable[2].webPermissionThree}}</p>
                        </div>
                    </div>
                    <div class="vip-bottom-text2">
                        离线包功能仅供APP内使用
                    </div>
                   
                </div>
                <div class="vip-button">
                    <div class="toapplyinvoice cursor" v-show="isBill==0" @click="Applyinvoice">申请发票</div>
                    <div class="toapplyinvoice cursor" v-show="isBill==1" @click="toMyinvoice">查看发票</div>
                    <div class="toupgrade cursor" v-show="userdata.level!=0" @click="toupgrade">立即升级</div>
                </div>
                
            </div>
            <Globalmessage ref="message" :duration="3000" :top="100" :widths="1196"/>
        </div>
        
    </div>
</template>

<script>
import Globalmessage from '../../components/Globalmessage.vue'
export default {
    components: {
        Globalmessage
    },
  data () {
    return {
        userdata: JSON.parse(sessionStorage.getItem('userdata')), // 用户数据
        viplevel: '1', // vip等级
        isBill: '', // 是否开发票
        viptable: [
        ]
    };
  },
    computed: {
    },
    created () {
    },
  mounted () {
        this.$login.getUserInfo().then((ress) => {
            // console.log(ress.result, "获得用户信息")
            // sessionStorage.setItem('userdata', JSON.stringify(ress.result));
            this.setSessionItem('userdata', JSON.stringify(ress.result))
            console.log(JSON.parse(sessionStorage.getItem('userdata')),'55555')
            // if (ress.code==200) {
            //     this.$message({
            //         message: '恭喜你，修改成功',
            //         type: 'success'
            //     });
            // }
        })
      this.getdata()
      const that = this
      // 获得用户数据并保存
        
        window.addEventListener('setItem', () => {
            that.userdata = JSON.parse(sessionStorage.getItem('userdata'))
            //that.formData.deptId是data里面声明的变量
            // console.log(that.userdata,JSON.parse(sessionStorage.getItem('userdata')),'监听')
        })
        if (that.userdata.level == -1) {
            that.$router.replace('/' + 'Myvip');
        }
        
  },
  methods: {
    //   获得vip数据
    getdata () {
        this.$userdata.getMemberList().then((res) => {
            // console.log(res,'vip')
            if (res.code==200) {
                this.viptable = res.result
                // console.log('888',this.userdata.level,this.viptable[0].isBill)
                if (this.userdata.level == 1) {
                    this.isBill = this.viptable[0].isBill
                }
                if (this.userdata.level == 2) {
                    this.isBill = this.viptable[1].isBill
                }
                if (this.userdata.level == 0) {
                    this.isBill = this.viptable[2].isBill
                }
            }
        })
    },
    //   跳转去申请发票
    Applyinvoice () {
        let e =''
        if (this.userdata.level==0) {
            e = this.viptable[2].id
        }
        if (this.userdata.level==2) {
            e = this.viptable[1].id
        }
        if (this.userdata.level==1) {
            e = this.viptable[0].id
        }
        this.$router.push({
            path: '/Applyinvoice',
            query: {
            id: e,
            type: '0'
            }
        })
    },
    //   跳转去升级vip
    toupgrade () {
        this.$router.replace('/' + 'Myvip');
    },
    //   跳转去我的发票
        toMyinvoice () {
            this.$router.replace('/' + 'Myinvoice');
        }
  }
};
</script>

<style lang="scss" scoped>
#Alreadyvip {
    .vip-button{
        margin: 40px 0 0 62px;
        width: 704px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .toupgrade{
            width: 335px;
            height: 50px;
            background: #3D5CFF;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            
        }
        .toapplyinvoice{
            width: 335px;
            height: 50px;
            border-radius: 12px;
            border: 1px solid #3D5CFF;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #3D5CFF;
            
        }
    }
    
    .viptitle{
        width: 120px;
        height: 25px;
        display: flex;
        align-items: flex-end;
        position: relative;
        font-size: 15px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #141414;
        margin: 40px 0 0 24px;
        &-text {
        margin-left: 4px;
        }
        .viptitle-back {
            width: 26px;
            height: 8px;
            background: #3d5cff;
            opacity: 0.3;
            position: absolute;
            left: 0;
            bottom: 2px;
        }
    }
    .bg-img1{
        background-image: url(../../assets/image/bg_baiyin1.png);
        background-size: 1060px;
        color: #6D80A3;
    }
    .bg-img2{
        background-image: url(../../assets/image/bg_huangjin1.png);
        background-size: 1060px;
        color: #EC9437;
    }
    .bg-img0{
        background-image: url(../../assets/image/bg_bojin1.png);
        background-size: 1060px;
        color: #D5816E;
    }
    .nocursor{
        cursor:not-allowed;
    }
    .content{
        width:1196px;
        margin:  0 0 0 24px;
        .content-bottom{
            width: 1196px;
            // height: 550px;
            background: #FFFFFF;
            box-shadow: 2px 0px 16px 0px rgba(0,21,41,0.08);
            border-radius: 14px;
            // margin: 24px 0 0 0;
            padding: 0 0 24px 0;
            .content-content{
                width: 1068px;
                min-height: 298px;
                margin: 31px 0px 0 62px;
                background: #FFFFFF;
                border-radius: 22px;
                border: 1px solid #F5F7FE;
                .vip-bottom-text2{
                    font-size: 11px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #A8ABB8;
                    margin: 0 0 0 55px;
                }
              .vip-bottom{
                  display: flex;
                  margin: 24px 0 0 23px;
                  .vip-bottom-text{
                        display: flex;
                        align-items: center;
                        font-size: 13px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #8189B0;
                        margin: 0 130px 0 0;
                        p{
                            margin: 0 0 0 8px;
                        }
                      img{
                          width: 24px;
                          height: 24px;
                      }
                  }
              }
              .vip-top{
                  width: 1060px;
                  height: 140px;
                  margin: 4px 4px 0 4px;
                  p{
                    font-size: 20px;
                    font-family: PingFangTC-Semibold, PingFangTC;
                    font-weight: 600;
                    margin: 0px 0 10px 24px;
                    padding: 38px 0 0 0;
                  }
                  span{
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    margin: 0px 0 0 24px;
                  }
              }
            }
            .content-bottom-title{
                width: 80px;
                height: 25px;
                padding: 23px 0 0 0px;
                
            }
            .title-botttom{
                width: 100px;
                display: flex;
                align-items: center;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #8189B0;
                margin: 6px 0 0 31px;
                img{
                    width: 22px;
                    height: 22px;
                }
            }
            
        }
        .content-top-top-left{
            width: 80px;
            height: 25px;
            display: flex;
            align-items: flex-end;
            position: relative;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2F3236;
            margin: 0 0 0 24px;
            &-text {
            margin-left: 8px;
            }
            .content-top-top-left-back {
                width: 26px;
                height: 8px;
                background: #3d5cff;
                opacity: 0.3;
                position: absolute;
                left: 0;
                bottom: 2px;
            }
        }
        
    }
}
</style>