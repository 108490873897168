import request from '../utils/request.js'
// 获取验证码
export function getVerification(params) {
    return request.post('/appLogin/getVerification', params)
}
// 验证码登录
export function Verificationlogin(params) {
    return request.post('/appLogin/VerificationLogin', params)
}
// 账号密码登录
export function accountLogin(params) {
    return request.post('/appLogin/accountLogin', params)
}
// 退出登录
export function userQuit(params) {
    return request.post('/appLogin/userQuit', params)
}
// 忘记密码
export function forgetPassword(params) {
    return request.post('/appUser/forgetPassword', params)
}
// 获得用户信息
export function getUserInfo(params) {
    return request.post('/appUser/getUserInfo', params)
}
// 获得微信登录二维码
export function getwxQR(params) {
    return request.post('/pcLogin/webWeChatAddress', params)
}

// 获取用户相关协议
export function getUserAgreement(params) {
    return request.post('/assist/getUserAgreement', params)
}
// 双泽设置（主要获得下载的二维码
export function getSetting(params) {
    return request.post('/assist/getSetting', params)
}
// web微信授权登陆
export function authorizationCode(params) {
    return request.post('/pcLogin/authorizationCode', params)
}
// web端绑定微信
export function webReplaceUserWx(params) {
    return request.post('/tripartite/webReplaceUserWx', params)
}
// web qq 授权登陆地址
export function qqLoginAddress(params) {
    return request.post('/pcLogin/qqLoginAddress', params)
}
// web qq 授权登陆
// export function qqLogin(params) {
//     return request.post('/pcLogin/qqLogin', params)
// }
// web qq 授权登陆 20231125
export function qqLogin(params) {
    return request.post('/pcLogin/qqLoginNewPc', params)
}
// web端绑定qq
export function bindQQ(params) {
    return request.post('/pcLogin/webBindQQ', params)
}

// 手机号、邮箱登录设置密码
export function setPassword(params) {
    return request.post('/appUser/addPassword', params)
}
// 手机号、邮箱第三方登录绑定
export function bindingUser(params) {
    return request.post('/appUser/tripartiteBindingTel', params)
}
//三方登陆 需要绑定手机号
export function loginNewTel(params) {
    return request.post('/appLogin/loginNewTel', params)
}