<!-- 个人中心-我的会员 -->
<template>
    <div id="Myvip">
        <div class="content">
            <div class="content-bottom" v-loading="loading">
                <div class="content-bottom-title">
                    <div class="content-top-top-left">
                        <div class="content-top-top-left-text">我的会员</div>
                        <div class="content-top-top-left-back"></div>
                    </div>
                </div>
                <div class="content-content" >
                    <div :class="selectone==1?'isselect':'noselect cursor'" @click="selectvip(1)" v-show="userdata.level<1 && userdata.level!=0" v-if="!!listData[0]">
                      <div class="select-top " :class="selectone==1?'selbaiyin':'noselbaiyin'">
                        <p>{{listData[0].name}}</p>
                        <span>暂未订阅此会员</span>
                      </div>
                      <div class="select-bottom1 ">
                        <div class="viptitle">
                          <div class="viptitle-text">{{listData[0].name}}权益</div>
                          <div class="viptitle-back"></div>
                       </div>
                        <p class="bmoney"><span class="smoney">￥</span>{{listData[0].payPrice}}</p>
                      </div>
                      <div class="select-bottom2">
                        <img  src="../../assets/icon/huancun.png">
                        <p>{{listData[0].webPermissionOne}}缓存</p>
                      </div>
                      <div class="select-bottom3">
                        离线包功能仅供APP内使用
                      </div>
                      <div class="select-bottom2">
                        <img  src="../../assets/icon/fanyi.png">
                        <p>{{listData[0].webPermissionTwo}}词</p>
                      </div>
                      <div class="select-bottom2">
                        <img  src="../../assets/icon/zhe.png">
                        <p>付费文档 畅享 {{listData[0].webPermissionThree}}</p>
                      </div>
                    </div>
                    <div :class="selectone==2?'isselect':'noselect cursor'" @click="selectvip(2)" v-show="userdata.level<2 && userdata.level!=0" v-if="!!listData[1]">
                      <div class="select-top " :class="selectone==2?'selhuangjin':'noselhuangjin'">
                        <p>{{listData[1].name}}</p>
                        <span>暂未订阅此会员</span>
                      </div>
                      <div class="select-bottom1">
                        <div class="viptitle">
                          <div class="viptitle-text">{{listData[1].name}}权益</div>
                          <div class="viptitle-back"></div>
                       </div>
                        <p class="bmoney"><span class="smoney">￥</span>{{listData[1].payPrice}}</p>
                      </div>
                      <div class="select-bottom2">
                        <img  src="../../assets/icon/huancun.png">
                        <p>{{listData[1].webPermissionOne}}缓存</p>
                      </div>
                      <div class="select-bottom3">
                        离线包功能仅供APP内使用
                      </div>
                      <div class="select-bottom2">
                        <img  src="../../assets/icon/fanyi.png">
                        <p>{{listData[1].webPermissionTwo}}词</p>
                      </div>
                      <div class="select-bottom2">
                        <img  src="../../assets/icon/zhe.png">
                        <p>付费文档 畅享 {{listData[1].webPermissionThree}}</p>
                      </div>
                    </div>
                    <div :class="selectone==0?'isselect':'noselect cursor'" @click="selectvip(0)" v-show="userdata.level!=0" v-if="!!listData[2]">
                      <div class="select-top " :class="selectone==0?'selbojin':'noselbojin'">
                        <p>{{listData[2].name}}</p>
                        <span>暂未订阅此会员</span>
                      </div>
                      <div class="select-bottom1">
                        <div class="viptitle">
                          <div class="viptitle-text">{{listData[2].name}}权益</div>
                          <div class="viptitle-back"></div>
                        </div>
                        <p class="bmoney"><span class="smoney">￥</span>{{listData[2].payPrice}}</p>
                      </div>
                      <div class="select-bottom2">
                        <img  src="../../assets/icon/huancun.png">
                        <p>{{listData[2].webPermissionOne}}缓存</p>
                      </div>
                      <div class="select-bottom3">
                        离线包功能仅供APP内使用
                      </div>
                      <div class="select-bottom2">
                        <img  src="../../assets/icon/fanyi.png">
                        <p>{{listData[2].webPermissionTwo}}词</p>
                      </div>
                      <div class="select-bottom2">
                        <img  src="../../assets/icon/zhe.png">
                        <p>付费文档 畅享 {{listData[2].webPermissionThree}}</p>
                      </div>
                    </div>
                </div>
                <div class="payway">
                  <div class="payway-left">支付方式：</div>
                  <div class="cursor" :class="payway==0?'payway-right-select':'payway-right'" @click="changpayway(0)">
                    <img src="../../assets/icon/icon_zfb.png">
                    支付宝支付
                    <img v-show="payway==0" class="payway-right-select-img" src="../../assets/icon/icon_chose.png">
                  </div>
                  <!--  -->
                  <div class="cursor" :class="payway==1?'payway-right-select':'payway-right'" @click="changpayway(1)">
                    <img src="../../assets/icon/icon_wx.png">
                    微信支付
                    <img v-show="payway==1" class="payway-right-select-img" src="../../assets/icon/icon_chose.png">
                  </div>
                </div>
                <div class="payment">
                  <div class="payment-top">
                    <div class="payment-title">扫码支付</div>
                    <div v-show="payway==0" class="payment-small">支付宝扫码付款</div>
                    <div v-show="payway==1" class="payment-small">微信扫码付款</div>
                  </div>
                  <div class="payment-bottom">
                    <div class="payment-bottom-left">
                      <div v-show="payway==0" id="qrcode" ref="qrcode"></div>
                      <!-- <img v-show="payway==0" src="../../assets/icon/icon_qq.png"> -->
                      <!-- <img v-show="payway==1" src="../../assets/icon/icon_wechat.png"> -->
                      <div v-show="payway==1" id="qrcode2" ref="qrcode2"></div>
                    </div>
                    <div class="payment-bottom-right">
                      <div class="payment-bottom-right-pay">支付金额</div>
                      <div class="payment-bottom-right-money"><span>￥</span>{{selectlevel}}</div>
                      <div class="payment-small">支付即视为您同意<span class="payment-small-to" @click="toxieyi">《双泽会员服务协议》</span></div>
                    </div>
                  </div>
                </div>
                
            </div>
            <Globalmessage ref="message" :duration="3000" :top="100" :widths="1196"/>
        </div>
        
    </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import Globalmessage from '../../components/Globalmessage.vue'
export default {
    components: {
        Globalmessage
    },
  data () {
    return {
      loading: false, // 加载
      userdata: JSON.parse(sessionStorage.getItem('userdata')), // 用户数据
      payway: 0, // 支付方式
      selectlevel: '0', // 选中后的价格
      selectid: '', // 选中的id
      vipleve: '1', // vip等级
      selectone: '1', // 选中的会员等级
      listData: [], // 当前页显示内容
    };
  },
    computed: {
    },
    created () {
    },
  mounted () {
    this.getdata()
    const that = this
        // 获得用户数据并保存
        this.$login.getUserInfo().then((ress) => {
            // console.log(ress.result, "获得用户信息")
            // sessionStorage.setItem('userdata', JSON.stringify(ress.result));
            this.setSessionItem('userdata', JSON.stringify(ress.result))
            console.log(JSON.parse(sessionStorage.getItem('userdata')),'55555')
            // if (ress.code==200) {
            //     this.$message({
            //         message: '恭喜你，修改成功',
            //         type: 'success'
            //     });
            // }
        })

        window.addEventListener('setItem', () => {
            that.userdata = JSON.parse(sessionStorage.getItem('userdata'))
            //that.formData.deptId是data里面声明的变量
            console.log(that.userdata,JSON.parse(sessionStorage.getItem('userdata')),'监听')
        })
        if (that.userdata.level == -1) {
          that.selectone = 1
        }
        if (that.userdata.level == 1) {
          that.selectone = 2
        }
        if (that.userdata.level == 2) {
          that.selectone = 0
        }
        if (that.userdata.level == 0) {
            that.$router.replace('/' + 'Alreadyvip');
        }
        
        
  },
  beforeDestroy () {
    console.log('beforeDestroy',)
    this.closetimer()
  },

  methods: {
    toxieyi () {
      this.$router.push({ path: "/Vipagreement" });
    },
    //获取订单状态
    getStatus(val) {
      let parames = new FormData
      parames.append('id', val)
      this.$userdata.getIsMemberPay(parames).then((resul) => {
        // console.log(resul,"是否支付")
        if (resul.code == 200) {
          if (resul.result.payStatus == 1) {
            this.closetimer()
            this.$login.getUserInfo().then((ress) => {
                // console.log(ress.result, "获得用户信息")
                // sessionStorage.setItem('userdata', JSON.stringify(ress.result));
                this.setSessionItem('userdata', JSON.stringify(ress.result))
                if (ress.code==200) {
                    this.$message({
                        message: '购买成功',
                        type: 'success'
                    });
                    this.$router.push({ path: "/Alreadyvip" });
                }
            })
            
          }
        }
      })
    },
    // 清除定时器
    closetimer () {
      clearInterval(this.timer)
      this.timer = null
    },
    // 清除二维码
    clearqrcode () {
        const codeHtml = document.getElementById("qrcode");
        codeHtml.innerHTML = "";
        const codeHtml2 = document.getElementById("qrcode2");
        codeHtml2.innerHTML = "";
    },
    // 获得支付宝二维码
    getAliCode (val) {
      this.closetimer()
      this.clearqrcode()
      if (this.payway==0) {
        const that = this
        let params = new FormData
        params.append('id', val)
        that.$userdata.setAliMemberOrder(params).then((res) => {
          // console.log(res,"支付")
          if (res.code==200) {
            if (res.result.qr_code == null) {
              this.$message.error("请重新提交订单");
            }
            let a = new QRCode(that.$refs.qrcode, {
              text: res.result.qr_code, // 需要转换为二维码的内容
              width: 119,
              height: 119,
              colorDark: "black", //#000000为黑色
              colorLight: "white",
            });
            that.timer = setInterval(() => {
              that.getStatus(res.result.id);
            }, 2000);
            
          }
        })
      }
      if (this.payway==1) {
        const that = this
        let params = new FormData
        params.append('id', val)
        this.$userdata.getwechatma(params).then((res) => {
          console.log(res,"支付")
          if (res.code==200) {

            if (res.result.code_url == null) {
              this.$message.error("请重新提交订单");
            }
            let a = new QRCode(that.$refs.qrcode2, {
              text: res.result.code_url, // 需要转换为二维码的内容
              width: 119,
              height: 119,
              colorDark: "black", //#000000为黑色
              colorLight: "white",
            });
            that.timer = setInterval(() => {
              that.getStatus(res.result.id);
            }, 2000);

          }
        })
      }
      
    },
    // 获得数据
    getdata () {
      this.loading = true
      this.$userdata.getMemberList().then((res) => {
            // console.log(res,'vip')
            if (res.code==200) {
                this.listData = res.result
                if (this.userdata.level == -1) {
                  this.selectlevel = this.listData[0].payPrice
                  // this.selectid = this.listData[0].id
                  this.getAliCode(this.listData[0].id)
                }
                if (this.userdata.level == 1) {
                  this.selectlevel = this.listData[1].payPrice
                  this.getAliCode(this.listData[1].id)
                }
                if (this.userdata.level == 2) {
                  this.selectlevel = this.listData[2].payPrice
                  this.getAliCode(this.listData[2].id)
                }
                this.loading = false
            }
        })
    },
    // 切换支付方式
    changpayway (way) {
      if (this.payway == way) {
        return false
      }
      this.payway = way

      this.closetimer()
      if (this.selectone == 1) {
        this.selectlevel = this.listData[0].payPrice
        this.getAliCode(this.listData[0].id)
      }
      if (this.selectone == 2) {
        this.selectlevel = this.listData[1].payPrice
        this.getAliCode(this.listData[1].id)
      }
      if (this.selectone == 0) {
        this.selectlevel = this.listData[2].payPrice
        this.getAliCode(this.listData[2].id)
      }
      // if (this.userdata.level == -1) {
      //   this.selectlevel = this.listData[0].payPrice
      //   // this.selectid = this.listData[0].id
      //   this.getAliCode(this.listData[0].id)
      // }
      // if (this.userdata.level == 1) {
      //   this.selectlevel = this.listData[1].payPrice
      //   this.getAliCode(this.listData[1].id)
      // }
      // if (this.userdata.level == 2) {
      //   this.selectlevel = this.listData[2].payPrice
      //   this.getAliCode(this.listData[2].id)
      // }
      // this.$router.push('/Viewtranslation')
    },
    // 选择那个购买
    selectvip (key) {
      if (this.selectone==key) {
        return false
      }
      // console.log(key)
      this.selectone = key
      if (key == 1) {
        this.selectlevel = this.listData[0].payPrice
        this.getAliCode(this.listData[0].id)
      }
      if (key == 2) {
        this.selectlevel = this.listData[1].payPrice
        this.getAliCode(this.listData[1].id)
      }
      if (key == 0) {
        this.selectlevel = this.listData[2].payPrice
        this.getAliCode(this.listData[2].id)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#Myvip {
  .payment{
    width: 996px;
    height: 265px;
    background: #F7F8FC;
    border-radius: 14px;
    margin: 32px 0 0 63px ;
    // padding: 44px 0 44px 56px;
    .payment-top{
      padding: 44px 0 0 56px ;
    }
    .payment-title{
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #111111;
      margin: 0 0 2px 0 ;
    }
    .payment-small{
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9199A1;
    }
    .payment-small-to{
      cursor: pointer;
    }
    .payment-bottom{
      display: flex;
      flex-wrap: wrap;
      margin:  14px 0 0 56px;
      .payment-bottom-left{
        width: 119px;
        height: 119px;
        margin: 0 20px 0 0;
        img{
          width: 119px;
          height: 119px;
        }
      }
      .payment-bottom-right{
        .payment-bottom-right-pay{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #606266;
          margin: 25px 0 12px 0 ;
        }
        .payment-bottom-right-money{
          font-size: 36px;
          font-family: HelveticaNeue-Bold, HelveticaNeue;
          font-weight: bold;
          color: #FE550D;
          margin:  0 0 5px 0;
          span{
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FE550D;
          }
        }
      }
    }
  }
  .payway{
    width: 500px;
    height: 60px;
    display: flex;
    align-items: center;
    margin: 46px 0 0 63px;
    .payway-left {
      width: 70px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #555C62;
      margin: 0 6px 0 0;
      
    }
    .payway-right {
      width: 187px;
      height: 60px;
      border-radius: 6px;
      border: 1px solid #DDDDDD;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #111111;
      margin: 0 20px 0 0 ;
      img{
        width: 22px;
        height: 22px;
        margin: 0 6px 0 0;
      }
    }
    .payway-right-select {
      position: relative;
      width: 187px;
      height: 60px;
      border-radius: 6px;
      border: 1px solid #3D5CFF;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #111111;
      margin: 0 20px 0 0 ;
      img{
        width: 22px;
        height: 22px;
        margin: 0 6px 0 0;
      }
      .payway-right-select-img {
        position: absolute;
        bottom: -1px;
        right: -1px;
        width: 24px;
        height:24px;
        margin: 0 0 0 0;
      }
    }
  }
  .select-bottom3{
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #A8ABB8;
    margin: 0 0 0 55px;
  }
  .select-bottom1{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 29px  0 24px 0;
  }
  .select-bottom2{
    display: flex;
    align-items: center;
    margin: 16px 0 0 0 ;
    img{
      width: 24px;
      height: 24px;
      margin: 0 0 0 23px;
    }
    p{
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8189B0;
      margin: 0 0 0 8px;
    }
  }
  .viptitle{
      width: 120px;
      height: 25px;
      display: flex;
      align-items: flex-end;
      position: relative;
      font-size: 15px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #141414;
      margin: 0 0 0 20px;
      &-text {
      margin-left: 4px;
      }
      .viptitle-back {
          width: 26px;
          height: 8px;
          background: #3d5cff;
          opacity: 0.3;
          position: absolute;
          left: 0;
          bottom: 2px;
      }
  }
  .bmoney{
    font-size: 28px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #FE550D;
    margin: 0 14px 0 0;
  }
  .smoney{
    font-size: 16px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #FE550D;
  }
  .noselbaiyin{
    background-image: url(../../assets/image/bg_baiyin.png);
    background-size: 300px;
    color: #6D80A3;
  }
  .selbaiyin{
    background-image: url(../../assets/image/bg_baiyin.png);
    background-size: 335px;
     color: #6D80A3;
  }
  .noselhuangjin{
    background-image: url(../../assets/image/bg_huangjin.png);
    background-size: 300px;
    color: #EC9437;
  }
  .selhuangjin{
    background-image: url(../../assets/image/bg_huangjin.png);
    background-size: 335px;
    color: #EC9437;
  }
  .noselbojin{
    background-image: url(../../assets/image/bg_ziliao.png);
    background-size: 300px;
    color: #D5816E;
  }
  .selbojin{
    background-image: url(../../assets/image/bg_ziliao.png);
    background-size: 335px;
    color: #D5816E;
  }
    .nocursor{
        cursor:not-allowed;
    }
    .content{
        width:1196px;
        margin:  0 0 0 24px;
        .content-bottom{
            width: 1196px;
            // height: 550px;
            background: #FFFFFF;
            box-shadow: 2px 0px 16px 0px rgba(0,21,41,0.08);
            border-radius: 14px;
            // margin: 24px 0 0 0;
            padding: 0 0 24px 0;
            .content-content{
              width: 1071px;
              height: 400px;
              display: flex;
              align-items: center;
              // justify-content: space-between;
              margin: 22px auto 0;
              // align-items: center;
              .noselect{
                width: 308px;
                height: 382px;
                background: #FFFFFF;
                border-radius: 22px;
                border: 1px solid #F5F7FE;
                margin: 0 56px 0 0px;
                
                .select-top{
                  width: 300px;
                  height: 140px;
                  
                  margin: 4px 4px 0;
                  p{
                    font-size: 20px;
                    font-family: PingFangTC-Semibold, PingFangTC;
                    font-weight: 600;
                    margin: 0px 0 10px 0px;
                    padding: 38px 0 0 24px;
                    
                  }
                  span{
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    margin: 10px 0 0 24px;
                  }
                }
              }
              .isselect{
                width: 343px;
                height: 398px;
                background: #FFFFFF;
                box-shadow: 2px 0px 16px 0px rgba(0,21,41,0.2);
                border-radius: 26px;
                margin: 0 56px 0 0;
                .select-top{
                  width: 335px;
                  height: 156px;
                  
                  margin: 4px 4px 0;
                  p{
                    font-size: 24px;
                    font-family: PingFangTC-Semibold, PingFangTC;
                    font-weight: 600;
                    margin: 0px 0 8px 0px;
                    padding: 42px 0 0 42px;
                    
                  }
                  span{
                    font-size: 13px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    margin: 8px 0 0 42px;
                  }
                }
              }
            }
            .content-bottom-title{
                width: 80px;
                height: 25px;
                padding: 23px 0 0 0px;
            }
            
        }
        .content-top-top-left{
            width: 80px;
            height: 25px;
            display: flex;
            align-items: flex-end;
            position: relative;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2F3236;
            margin: 0 0 0 24px;
            &-text {
            margin-left: 8px;
            }
            .content-top-top-left-back {
                width: 26px;
                height: 8px;
                background: #3d5cff;
                opacity: 0.3;
                position: absolute;
                left: 0;
                bottom: 2px;
            }
        }
        
    }
}
</style>