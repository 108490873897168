<template>
  <div id="binding">
    <el-dialog
      title=""
      width="440px"
      :visible.sync="status"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      
    >
    <!-- :show-close="false" -->
      <div class="body">
        <p class="titles">绑定账户</p>
        <p class="hint">
          为了您的账号安全，请验证手机号码或邮箱 验证后可直接使用此账号登录
        </p>
        <!-- form -->
        <div class="info">
          <div class="info-input">
            <input
              type="text"
              v-model="form.tel"
              placeholder="请输入手机号/邮箱号"
            />
            <img
              src="../assets/icon/icon_username.png"
              class="info-input-img"
              alt=""
            />
          </div>
          <div class="info-input">
            <input
              type="text"
              class="info-input-code"
              maxlength="8"
              v-model="form.verification"
              placeholder="请输入验证码"
            />
            <img
              src="../assets/icon/icon_password.png"
              class="info-input-img"
              alt=""
            />
            <div class="info-input-gain cursor" @click="codeClick()">
              {{ codeTime + codeStatus }}
            </div>
          </div>
        </div>
        <!-- button -->
        <div class="button">
          <div class="button-title cursor" @click="submit">确定</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { bindingUser,loginNewTel } from "../api/login";
import { setToken } from "../utils/auth.js";
export default {
  data() {
    return {
      codeStatus: "获取", // code
      codeTime: "", // code倒计时
      form: {
        tel: "",
        verification: "",
      },
    };
  },
  computed: {
    status: function () {
      return this.$store.state.bindingStatus;
    },
  },
  mounted() {},
  methods: {
    // 验证
    verify() {
      if (this.form.tel == "") {
        this.$message.error("请输入账号");
        return false;
      }
      if (this.form.verification == "") {
        this.$message.error("请输入验证码");
        return false;
      }
      return true
    },
    // 提交
    submit() {
      // if (this.verify()) {
      //   let parames = new FormData();
      //   parames.append("tel", this.form.tel);
      //   parames.append("verification", this.form.verification);
      //   bindingUser(parames).then((res) => {
      //     if (res.code == 200) {
      //       this.$store.commit("SET_BINDING", false);
      //       this.$login.getUserInfo().then((ress) => {
      //           // console.log(ress.result, "获得用户信息");
      //           // sessionStorage.setItem('userdata', JSON.stringify(ress.result));
      //           this.setSessionItem("userdata", JSON.stringify(ress.result));
      //           if (ress.code == 200) {
      //             this.handleClose();

      //             // this.$router.push("/");
      //             // location. reload()
      //           }
      //         });
      //     }
      //   });
      // }
      if (this.verify()) {
        let parames = new FormData();
        parames.append("tel", this.form.tel);
        parames.append("verification", this.form.verification);

        parames.append("nickname", sessionStorage.getItem("authnickname"));
        parames.append("type", sessionStorage.getItem("authtype"));
        parames.append("unionId", sessionStorage.getItem("authunionId"));
        loginNewTel(parames).then((res) => {
          if (res.code == 200) {
             setToken(res.result.token);
            this.$store.commit("SET_BINDING", false);
            this.$login.getUserInfo().then((ress) => {
                // console.log(ress.result, "获得用户信息");
                // sessionStorage.setItem('userdata', JSON.stringify(ress.result));
                this.setSessionItem("userdata", JSON.stringify(ress.result));
                if (ress.code == 200) {
                  this.handleClose();

                  // this.$router.push("/");
                  // location. reload()
                }
              });
          }
        });
      }
    },
    // 获取验证码函数
    codeClick() {
      if (this.form.tel == "") {
        return false;
      }
      if (this.codeTime != "") {
        return false;
      }
      // let params = {
      //   tel: this.form.account
      // }
      let params = new FormData();
      params.append("tel", this.form.tel);
      this.$login.getVerification(params).then((res) => {
        console.log(res)
        if (res.code==200) {
          this.codeStatus = "S后重新获取";
          this.codeTime = 60;
          let timer = setInterval(() => {
            if (this.codeTime != 0) {
              this.codeTime--;
            } else {
              this.codeStatus = "获取";
              this.codeTime = "";
              clearInterval(timer);
            }
          }, 1000);
        }
      });
      
    },
    // 关闭弹框函数
    handleClose() {
      sessionStorage.clear()
      this.$router.push("/");
      setTimeout(() => {
        location.reload()
        
      }, 500);


      this.$store.commit("SET_BINDING", false);
      this.$store.commit("SET_THIRDPARTY", false);
      this.$store.commit("SET_LOGIN", false);
      this.form = {
        tel: "",
        verification: "",
      };
    },
  },
};
</script>

<style lang="scss">
#binding {
  .body {
    padding: 0 26px;
    .button {
      display: flex;
      justify-content: center;
      &-title {
        width: 328px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 46px;
        background-color: #3d5cff;
        color: #fff;
        border-radius: 12px;
        font-size: 16px;
      }
    }
    .info {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      margin-left: 10px;
      .info-input {
        position: relative;
        &-gain {
          padding: 6px 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 20px;
          top: calc(50% - 16px);
          background-color: #3d5cff;
          border-radius: 6px;
          opacity: 0.5;
          color: #fff;
          font-size: 14px;
        }
        &-img {
          width: 22px;
          height: 22px;
          position: absolute;
          top: calc(50% - 12px);
          left: 16px;
        }
        input {
          width: 216px;
          height: 56px;
          border-radius: 12px;
          padding: 0 56px;
          margin: 9px 0;
          color: #8189b0;
          font-size: 15px;
          background-color: #f1f6fb;
          border: none;
        }
        input::-webkit-input-placeholder {
          color: #8189b0;
        }
      }
    }
    .hint {
      color: #8189b0;
      font-size: 12px;
      margin-top: 8px;
    }
    .titles {
      color: #2e3e5c;
      font-size: 18px;
      font-weight: bold;
    }
  }
  .el-dialog {
    border-radius: 12px;
  }
}
</style>