import request from '../utils/request.js'
// 获取用户消息图标
export function getuserIcon (params) {
    return request.post('/appUser/getIcon', params)
}

// 获取用户消息
export function myInformation (params) {
    return request.post('/appUser/myInformationAll', params)
}
// 修改头像
export function editAvatar (params) {
    return request.post('/appUser/editAvatar', params)
}
// 修改单位名称
export function editCompany (params) {
    return request.post('/appUser/editCompany', params)
}
// 修改昵称
export function editNickname (params) {
    return request.post('/appUser/editNickname', params)
}

export function editUserInfo (params) {
    return request.post('/appUser/editUserInfo', params)
}

// 修改密码
export function editPassword (params) {
    return request.post('/appUser/editPassword', params)
}
// 用户注销mi
export function userLogout (params) {
    return request.post('/appLogin/userLogout', params)
}

// 个人中心绑定邮箱
export function bindingEmail (params) {
    return request.post('/appUser/bindingEmail', params)
}
// 个人中心更换邮箱
export function replaceEmail (params) {
    return request.post('/appUser/replaceEmail', params)
}
// 个人中心绑定手机号
export function bindingTel (params) {
    return request.post('/appUser/bindingTel', params)
}
// 个人中心绑定手机号
export function replaceTel (params) {
    return request.post('/appUser/replaceTel', params)
}

// 解除三方绑定
export function removeTripartite (params) {
    return request.post('/tripartite/removeTripartite', params)
}

// web 账户总览(免费剩余翻译数)
export function userOverview (params) {
    return request.post('/appUser/userOverview', params)
}
// 我的翻译订单列表
export function myTranslateOrderListWeb (params) {
    return request.post('/allOrder/myTranslateOrderListWeb', params)
}

// 获取学习提醒
export function getLearningReminder (params) {
    return request.post('/appUser/getLearningReminder', params)
}
// 学习提醒的开启关闭
export function learningReminder (params) {
    return request.post('/appUser/learningReminder', params)
}
// 学习提醒时间设置
export function learningReminderTime (params) {
    return request.post('/appUser/learningReminderTime', params)
}

// 客服
export function getCustomerService (params) {
    return request.post('/assist/getCustomerService', params)
}
// 双端提交发票申请(会员订单和 翻译订单)
export function submitBill (params) {
    return request.post('/bill/submitBill', params)
}
// 我的发票
export function getMyBill (params) {
    return request.post('/bill/getMyBillWeb', params)
}
// 获取会员列表
export function getMemberList (params) {
    return request.post('/member/getMemberList', params)
}
// web端会员订单获取支付宝支付二维码
export function setAliMemberOrder (params) {
    return request.post('/webOrder/setAliMemberOrder', params)
}
// web端会员订单获取微信支付二维码
export function getwechatma (params) {
    return request.post('/webOrder/getWxMemberOrder', params)
}
// web根据会员订单id获取此订单有无支付
export function getIsMemberPay (params) {
    return request.post('/webOrder/getIsMemberPay', params)
}